import React, { useContext } from "react";
import { formContext } from "../../routes/routes";
import { showOnSelect, multipleRender } from "./renderFunction";
import { useParams } from "react-router-dom";
export const Checkbox = ({
  label = "",
  type = "checkbox",
  name = "",
  options = [],
  css = {},
  register,
  errors,
  validation,
  style = "",
  checkBoxStyle = {},
  additonalComments = "",
  additonalLabel = "",
  condtionalLabel = "",
  requiredLable = false,
}) => {
  const schemaContext = useContext(formContext);
  let params = useParams();

  if (!Array.isArray(options)) throw new Error(`Accepted radio options as Array but got ${typeof options}`);

  let data = JSON.parse(localStorage.getItem(params.applicationID)) || {};

  return (
    <>
      {label.length ? (
        <h3 className="form-label">
          {label}
          {requiredLable ? <sup className="text-danger">*</sup> : null}
        </h3>
      ) : null}

      {additonalComments ? <label style={{ color: "#8898AA", fontSize: "13.6px", marginLeft: "8px" }}>{additonalComments}</label> : null}
      {additonalLabel ? (
        <label className="form-label">
          {additonalLabel}
          <small className="text-danger ms-1">{requiredLable ? "*required" : null}</small>
        </label>
      ) : null}
      {condtionalLabel ? (
        <label
          style={{
            color: "#0079C2",
            fontWeight: "600",
            fontSize: "17px",
          }}
        >
          {condtionalLabel}
        </label>
      ) : null}
      <div className="row">
        {options.map((option, index) => (
          <div key={index} className={style || "`mb-2`"} style={checkBoxStyle}>
            <div className="form-check" style={option.styleInner}>
              <input
                style={css}
                type={type}
                checked={option.checked && option.checked}
                className="form-check-input"
                data-option={option.value}
                name={name}
                id={option.value}
                defaultChecked={data[name] && data[name].includes(option.value) ? true : false} //option.value == data[name]
                value={option.value}
                onClick={(e) => {
                  if (e.target.checked) {
                    localStorage.setItem("renderKeyCheckBox", option.key);
                  } else {
                    localStorage.setItem("renderKeyCheckBox", [0]);
                  }
                  showOnSelect(e, option.showOnSelect, option.show, schemaContext, params.applicationID);
                  multipleRender(e, option.multipleRender, option.show, schemaContext, params.applicationID);
                }}
                {...register(name, { ...validation })}
              />

              <label className="form-check-label" htmlFor={option.value} style={css}>
                {option.label}
              </label>

              {option.linkContext && (
                <a href={option.linkAction} target="_blank">
                  {" "}
                  {option.linkContext}
                </a>
              )}

              {option.subLabel && (
                <label className="mx-2 my-1" htmlFor={option.value}>
                  {option.subLabel}
                </label>
              )}
              <div>
                <div id={`conditionalAreaCheckbox${option.value.split(" ").join("_")}`} className="" />
              </div>
            </div>
          </div>
        ))}
      </div>
      <span className="text-danger">{errors[name] && <span>Select a value for {name}</span>}</span>
    </>
  );
};
