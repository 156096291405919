import React from "react";
import Text from "./NetSuiteText";
import Date from "./NetSuiteDate";
import Dropdown from "./NetSuiteDropdown";
import TextArea from "./NetSuiteTextArea";
import Number from "./NetSuiteNumber";
const FieldAll = ({ data, handleNetSuiteChange, recordName, col, value, stage, formNumber }) => {
  return (
    <>
      <Text data={data} handleNetSuiteChange={handleNetSuiteChange} recordName={recordName} col={col} value={value} />
      <Date data={data} handleNetSuiteChange={handleNetSuiteChange} recordName={recordName} col={col} value={value} />
      <Dropdown data={data} handleNetSuiteChange={handleNetSuiteChange} recordName={recordName} col={col} value={value} stage={stage} formNumber={formNumber} />
      <TextArea data={data} handleNetSuiteChange={handleNetSuiteChange} recordName={recordName} col={col} value={value} />
      <Number data={data} handleNetSuiteChange={handleNetSuiteChange} recordName={recordName} col={col} value={value} stage={stage} />
    </>
  );
};

export default FieldAll;
