import React, { useState } from "react";
import HtmlParser from "react-html-parser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { commentsFileUpload } from "../../api/currentApplications";
import { API_URL, File } from "../../api";
import { HandleFieldType } from "../../forms/HandleFieldType";
import { useUser } from "../../hooks/useUser";

export default function CommentsModal(props) {
  let {
    applicationData,
    qId,
    addComment,
    editorState,
    setActive,
    active,
    handleComment,
    allComments,
    teamComment,
    approverComment,
    setFile,
    fileValue,
    file,
    commentsResolve,
    resolvedComment,
    commentTypes,
    DeleteFun,
    resolveCommet,
    blockUnresolved,
  } = props || {};
  const [load, setLoad] = useState(false);
  let { header, rows, id } = qId || {};
  const { roleName = "" } = useUser() || {};
  let allCommentsId = allComments.map((aID) => aID.questionID);
  let approverCommentId = approverComment.map((aID) => aID.questionID);
  let teamCommentId = teamComment.map((tID) => tID.questionID);
  let allID = [...allCommentsId, ...approverCommentId, ...teamCommentId];
  let resolveCommentId = resolvedComment.map((rID) => rID.questionID);

  const fileUpload = async (e) => {
    setLoad(true);

    const formData = new FormData();
    formData.append("applicationID", props.applicationID);
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      formData.append(`file`, files[i]);
    }
    let res = await commentsFileUpload(formData);
    if (res) {
      setFile(res.data.data);
      setLoad(false);
    }
  };
  const addRefresh = () => {
    window.location.reload();
  };

  return (
    <div>
      {load && (
        <div className="d-flex align-items-center justify-content-center  display-2 file-upload-loader">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      )}
      <div
        className="modal fade comment-modal "
        id="ongoin-comment-modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-modal="true"
        role="dialog"
        style={{ display: "none" }}
      >
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content h-100">
            <div className="modal-body p-0">
              <div className="container-fluid h-100 p-0">
                <div className="row h-100 m-0">
                  <div className="col-md-5 h-100 p-0 overflow-auto">
                    <div className="card-header px-5">
                      <h3 className="m-0">{header}</h3>
                    </div>
                    <div className="card border-0">
                      <div className="card-body">
                        <div className="row">
                          {/* <label htmlFor="" className="form-label">
                              Proposed work involves
                            </label> */}
                          {rows &&
                            rows.map((col) =>
                              col.columns.map((lab, index) => (
                                <>
                                  {lab.type === "checkbox" || lab.type === "checkboxGroup" ? (
                                    <>
                                      {lab.options.map((option) => (
                                        <>
                                          <div className="form-check">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              disabled={true}
                                              // checked={applicationData[
                                              //   lab.name
                                              // ]}
                                              checked={applicationData[lab.name] && applicationData[lab.name].includes(option.value)}
                                            />
                                            <label className="form-check-label">{option.label}</label>
                                            {option.linkContext && (
                                              <a className="form-check-label" href={option.linkAction} target="_blank">
                                                {" "}
                                                {option.linkContext}
                                              </a>
                                            )}
                                            {option.subLabel && (
                                              <label className="mx-2 my-1 form-check-label" htmlFor={option.value}>
                                                {option.subLabel}
                                              </label>
                                            )}
                                          </div>
                                        </>
                                      ))}{" "}
                                    </>
                                  ) : null}

                                  {lab.type === "multiplefile" || (lab.type === "singlefile" && applicationData && applicationData[lab.name + "filedisplay"]) ? (
                                    <div key={index}>
                                      <label htmlFor="" className="form-label">
                                        {lab.label}
                                      </label>
                                      {props.applicationData[lab.name + "filedisplay"] &&
                                        props.applicationData[lab.name + "filedisplay"].map((na, i) => (
                                          <div className="card bg-secondary border-0" key={i}>
                                            <div className="card-body p-3">
                                              <div className="d-flex align-items-center border-bottom file-download">
                                                <div className="flex-shrink-0">
                                                  <i className="fal fa-file-pdf"></i>
                                                </div>
                                                <div className="flex-grow-1 ms-3">{na.name}</div>
                                                <div className="flex-shrink-0">
                                                  <a
                                                    href={API_URL + File + props.applicationID + "_" + (props.applicationData[lab.name + "filedisplay"] && na.name)}
                                                    target="_blank"
                                                    className="btn btn-link"
                                                  >
                                                    View
                                                  </a>
                                                  <a
                                                    href={API_URL + File + props.applicationID + "_" + (props.applicationData[lab.name + "filedisplay"] && na.name)}
                                                    download
                                                    target="_blank"
                                                    className="btn btn-link"
                                                  >
                                                    Download
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  ) : null}
                                  <div className="">
                                    {lab.type === "tabel" ? (
                                      <div className="">
                                        <div className="">
                                          <ul className="row un-list p-0 m-0">
                                            {lab.heading.map((head) => (
                                              <li className="col-4 p-3">{head}</li>
                                            ))}
                                          </ul>
                                        </div>
                                        <div>
                                          {
                                            <>
                                              {lab.rowValue.map((rn) => (
                                                <ul className="row un-list p-0 m-0 stript-list">
                                                  <li className="col-4  p-3"> {rn.label} </li>
                                                  <li className="col-4  p-3">{applicationData[rn.rowData[0].name]}</li>
                                                  <li className="col-4  p-3">{applicationData[rn.rowData[1].name]}</li>
                                                </ul>
                                              ))}
                                            </>
                                          }
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                  {(lab.type != "multiplefile" && lab.type === "text") || lab.type === "number" || lab.type === "radio" || lab.type === "dropdown" ? (
                                    <div className="col-md-6 mb-3">
                                      <label htmlFor="" className="form-label">
                                        {lab.label}
                                      </label>
                                      <p className="m-0">
                                        {applicationData[lab.name]}
                                        &nbsp;&nbsp;
                                        {applicationData[lab.name1] === undefined ? " " : "+"}
                                        &nbsp;
                                        {applicationData[lab.name1]}
                                        &nbsp; &nbsp;
                                        {applicationData[lab.name1] === undefined ? " " : "Feet"}
                                      </p>
                                    </div>
                                  ) : null}
                                </>
                              )),
                            )}

                          {qId.casing == "casing" ? (
                            <div className={qId.casing == "casing" && qId.header != "" ? `card border-dark ${qId.header === "" ? "" : ""} border-bottom` : ""}>
                              <div className={qId.casing == "casing" && qId.header != "" ? "card-body" : ""}>
                                <HandleFieldType items={qId} review={true} />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 h-100 bg-secondary p-0">
                    <div className="row msg-box m-0  h-100">
                      <div className="col-md-12 p-0">
                        <div className="collapse show  h-100" id="comment2">
                          <div className="card border-0 rounded-0 chat-window bg-secondary  h-100">
                            <div className="tab-col">
                              <ul className="nav nav-tabs justify-content-between row m-0" id="myTab" role="tablist">
                                {commentTypes === "Application Review" ? null : (
                                  <>
                                    {roleName === "applicant" ? null : (
                                      <>
                                        {roleName === "Operating Engineering Representative" ? null : (
                                          <li className="nav-item col p-0" role="presentation">
                                            <button
                                              className={`nav-link ${active === "team" ? "active" : null}`}
                                              id="team-tab"
                                              data-bs-toggle="tab"
                                              data-bs-target="#team"
                                              type="button"
                                              role="tab"
                                              onClick={() => setActive("team")}
                                              aria-controls="team"
                                              aria-selected="true"
                                            >
                                              Team Comments
                                            </button>
                                          </li>
                                        )}
                                        <li className="nav-item col p-0" role="presentation">
                                          <button
                                            className={`nav-link ${active === "approver" ? "active" : null}`}
                                            id="approver-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#approver"
                                            type="button"
                                            role="tab"
                                            onClick={() => setActive("approver")}
                                            aria-controls="approver"
                                            aria-selected={active === "approver"}
                                          >
                                            Approver Comments
                                          </button>
                                        </li>
                                      </>
                                    )}
                                  </>
                                )}

                                <li className="nav-item col p-0" role="presentation">
                                  <button
                                    className={`nav-link ${active === "applicant" ? "active" : null}`}
                                    id="applicant-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#applicant"
                                    type="button"
                                    role="tab"
                                    onClick={() => setActive("applicant")}
                                    aria-controls="applicant"
                                    aria-selected={active === "applicant"}
                                  >
                                    Applicant Comments
                                  </button>
                                </li>
                              </ul>
                              {/* {active show} */}
                              <div className="tab-content" id="myTabContent">
                                <div className={`tab-pane fade ${active === "team" && "active show"}`} id="team" role="tabpanel" aria-labelledby="team-tab">
                                  <div className="card-body h-100 overflow-auto pt-3">
                                    <div className="panel-body msg_container_base container-fluid">
                                      {teamComment.map((comme) =>
                                        comme.questionID === id ? (
                                          <div className={`row msg_container ${JSON.parse(comme.from).roleName === roleName ? "base_sent justify-content-end" : "base_receive"} `}>
                                            <div className="col-md-8">
                                              <div className="d-flex justify-content-end">
                                                <div className="messages">
                                                  <p>
                                                    {HtmlParser(comme.comment)}
                                                    {comme.files &&
                                                      comme.files.map((rr) => (
                                                        <div className="row align-items-center mb-4">
                                                          <div className="col-7">
                                                            <div className="d-flex align-items-center">
                                                              <i className="fal fa-file-pdf"></i>
                                                              <p className="text-break ms-3 mb-0 ">{rr.originalName}</p>
                                                            </div>
                                                          </div>
                                                          <div className="col-5">
                                                            <a href={API_URL + File + rr.fileNameUpdated} target="_blank" className="btn btn-sm m-0 btn-link">
                                                              View
                                                            </a>
                                                            <a href={API_URL + File + rr.fileNameUpdated} target="_blank" className="btn btn-sm m-0 btn-link">
                                                              Download
                                                            </a>
                                                          </div>
                                                        </div>
                                                      ))}
                                                  </p>
                                                  <time className="text-muted">
                                                    {JSON.parse(comme.from).roleName} {comme.timeStamp}
                                                  </time>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null,
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className={`tab-pane fade ${active === "approver" && "active show"}`} id="approver" role="tabpanel" aria-labelledby="approver-tab">
                                  <div className="card-body h-100 overflow-auto pt-3">
                                    <div className="panel-body msg_container_base container-fluid">
                                      {approverComment.map((comme) =>
                                        comme.questionID === id ? (
                                          <div className={`row msg_container ${JSON.parse(comme.from).roleName === roleName ? "base_sent justify-content-end" : "base_receive"} `}>
                                            <div className="col-md-8">
                                              <div className="d-flex justify-content-end">
                                                <div className="messages">
                                                  <p>
                                                    {HtmlParser(comme.comment)}{" "}
                                                    {comme &&
                                                      comme.files &&
                                                      comme.files.map((rr) => (
                                                        <div className="row align-items-center mb-4">
                                                          <div className="col-7">
                                                            <div className="d-flex align-items-center">
                                                              <i className="fal fa-file-pdf"></i>
                                                              <p className="text-break ms-3 mb-0 ">{rr.originalName}</p>
                                                            </div>
                                                          </div>
                                                          <div className="col-5">
                                                            <a href={API_URL + File + rr.fileNameUpdated} target="_blank" className="btn btn-sm m-0 btn-link">
                                                              View
                                                            </a>
                                                            <a href={API_URL + File + rr.fileNameUpdated} target="_blank" className="btn btn-sm m-0 btn-link">
                                                              Download
                                                            </a>
                                                          </div>
                                                        </div>
                                                      ))}
                                                  </p>
                                                  <time className="text-muted">
                                                    {JSON.parse(comme.from).roleName} {comme.timeStamp}
                                                  </time>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null,
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className={`tab-pane fade ${active === "applicant" && "active show"}`} id="applicant" role="tabpanel" aria-labelledby="applicant-tab">
                                  <div className="card-body h-100 overflow-auto pt-3">
                                    <div className="panel-body msg_container_base container-fluid">
                                      {allComments &&
                                        allComments.map((comme) =>
                                          comme.questionID === id ? (
                                            <div className={`row msg_container ${JSON.parse(comme.from).roleName === roleName ? "base_sent justify-content-end" : "base_receive"} `}>
                                              <div className="col-md-8">
                                                <div className="d-flex justify-content-end">
                                                  <div className="messages">
                                                    <p>
                                                      {HtmlParser(comme.comment)}{" "}
                                                      {comme &&
                                                        comme.files &&
                                                        comme.files.map((rr) => (
                                                          <div className="row align-items-center mb-4">
                                                            <div className="col-7">
                                                              <div className="d-flex align-items-center">
                                                                <i className="fal fa-file-pdf"></i>
                                                                <p className="text-break ms-3 mb-0 ">{rr.originalName}</p>
                                                              </div>
                                                            </div>
                                                            <div className="col-5">
                                                              <a href={API_URL + File + rr.fileNameUpdated} target="_blank" className="btn btn-sm m-0 btn-link">
                                                                View
                                                              </a>
                                                              <a href={API_URL + File + rr.fileNameUpdated} target="_blank" className="btn btn-sm m-0 btn-link">
                                                                Download
                                                              </a>
                                                            </div>
                                                          </div>
                                                        ))}
                                                    </p>
                                                    <time className="text-muted">
                                                      {JSON.parse(comme.from).roleName} {comme.timeStamp}
                                                    </time>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null,
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {blockUnresolved
                              ? null
                              : ((roleName == "Operating Engineering Representative" && active != "applicant") || roleName != "Operating Engineering Representative") && (
                                  <>
                                    <div className="card-footer bg-secondary p-0">
                                      <div className="position-relative w-100 border">
                                        <div className="bg-white">
                                          <form onSubmit={addComment} className="d-flex flex-column custom-editor">
                                            <CKEditor
                                              config={{
                                                toolbar: ["bold", "italic", "strike"],
                                              }}
                                              editor={ClassicEditor}
                                              data={editorState}
                                              style={{
                                                "margin-top": "100px",
                                                border: "5px solid red",
                                              }}
                                              onChange={(event, editor) => {
                                                const data = editor.getData();
                                                handleComment(data, id);
                                              }}
                                            />

                                            <button className="btn bg-primary" type="submit" id="submit-chat">
                                              <div className="send">
                                                <span className="text-white shadow">Add Comment</span>
                                              </div>
                                            </button>
                                          </form>
                                          <div className="upload-col">
                                            <>
                                              <div>
                                                <div>
                                                  <div className="MultiFile-wrap">
                                                    <input
                                                      className="hide-input"
                                                      accept=".pdf"
                                                      type="file"
                                                      multiple
                                                      value={fileValue}
                                                      onChange={(e) => {
                                                        fileUpload(e);
                                                      }}
                                                    />
                                                  </div>
                                                  <a className="upload-btn text-primary pl-1">
                                                    <i className="fas fa-paperclip"></i>
                                                  </a>
                                                </div>
                                              </div>
                                            </>
                                          </div>
                                        </div>
                                      </div>
                                      <ul className="file-list">
                                        {" "}
                                        {file &&
                                          file.map((nam, index) => (
                                            <li>
                                              {nam.originalName}{" "}
                                              <button
                                                className="btn shadow-none text-primary p-0"
                                                onClick={() => {
                                                  DeleteFun(nam, index);
                                                }}
                                              >
                                                <i className="far fa-trash-alt"></i>
                                              </button>
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                  </>
                                )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between border-top ">
              <div className="row w-100 m-0">
                <div className="col-md-5 p-0"></div>
                <div className="col-md-7 p-0 justify-content-between d-flex">
                  <div className={`${resolveCommentId.includes(qId.id) ? "" : "col-md-3"}`}>
                    {(roleName === "Approving Representative" || roleName === "Engineering Representative") && !resolveCommentId.includes(qId.id) && allID.includes(qId.id) ? (
                      <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        Resolve Comment
                      </button>
                    ) : null}
                    {(roleName === "Approving Representative" || roleName === "Engineering Representative") && resolveCommentId.includes(qId.id) && allID.includes(qId.id) ? (
                      <button
                        type="button"
                        disabled={blockUnresolved}
                        onClick={() => {
                          resolveCommet(qId.id);
                        }}
                        className="btn btn-primary"
                      >
                        Unresolve Comment
                      </button>
                    ) : null}

                    <>
                      {resolveCommentId.includes(qId.id) ? (
                        <button type="button" className="btn btn-primary disabled" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                          <i className="far fa-check text-muted"></i> Resolved
                        </button>
                      ) : null}
                    </>
                  </div>
                  {!resolveCommentId.includes(qId.id) && (
                    <div className=" col-md-7 p-0">
                      <span className="text-red h5">
                        Adding a comment here will not automatically send notification. Please make comment at once and click on Notify button after adding a comment.
                      </span>
                    </div>
                  )}

                  <div className="col-md-2">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={addRefresh}>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title" id="resolve1">
                Are Your Sure?
              </h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p className="text-center mb-0">This section is not reversible.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn btn-primary" onClick={() => commentsResolve(qId)} data-bs-dismiss="modal">
                Resolve
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Modal title
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">...</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
