export const clearLocalStorage = () => {
  const storageVersion = localStorage.getItem("storageRestoredVersion");
  if (storageVersion !== "0.0.1") {
    const toSave = ["--v", "custID", "facilityId", "token", "userEmail", "GridView"];
    const savedStorage = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (toSave.find((each) => each === key)) {
        savedStorage[key] = localStorage.getItem(key);
      }
    }

    localStorage.clear();

    for (const key in savedStorage) {
      if (Object.hasOwnProperty.call(savedStorage, key)) {
        const value = savedStorage[key];
        localStorage.setItem(key, value);
        localStorage.setItem("storageRestoredVersion", "0.0.1");
      }
    }
  }
};
