import GetmyRole from "../utils/GetmyRole";
import { setMyRole } from "../utils/setMyRole";

export const setCustomerId = (id) => {
  const customer = GetmyRole() || {};
  customer["companyId"] = id;
  customer["details"]["companyId"] = id;
  setMyRole(customer);
  return true;
};

export const updateCustomerId = (data) => {
  const customer = GetmyRole() || {};
  const { email, role, contactRoleId, roleType, companyId } = data;

  if (roleType === "FACILITY") {
    localStorage.setItem("facilityId", companyId);
    customer["role"] = "applicant";
    customer["roleName"] = role;
  }

  if (roleType === "CONSULTANT") {
    localStorage.setItem("facilityId", "ALL");
    customer["role"] = "applicant";
    customer["roleName"] = role;
  }

  if (roleType === "REPRESENTATIVE") {
    customer["role"] = role;
    customer["roleName"] = role;
  }

  customer["email"] = email;
  customer["contactRoleId"] = contactRoleId;
  customer["roleType"] = roleType;
  customer["companyId"] = companyId;
  setMyRole(customer);
  return;
};
