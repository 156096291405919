import React from "react";
import Form220 from "./stepperComponents/Form220";

export default function NewForm220(props) {
  return (
    <div>
      <div className="pb-4">
        <div className="border-bottom py-4 mb-5 bg-white">
          <div className="container">
            <div className="d-flex justify-content-between">
              <h1 className="mb-0">New Utility Application</h1>
              <h2 className="mb-0 text-capitalize">Pipeline Occupancy Application (NCRR Form 220)</h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-form">
                <Form220 id={props.match.params.applicationID} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
