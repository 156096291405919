import React from "react";

export default function DashBoardLoading() {
  return (
    <div>
      <div className="card mb-4 position-relative">
        <div className="card-header bg-transparent">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Utility Application</label>
                <p>#AP123458</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Application Type</label>
                <p>New Wire/Conduit/Cable Occupancy Application</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Status</label>
                <div className="d-flex">
                  <p className="pe-3">Engineering review</p>
                  <p className="border-start ps-3">13 Open comments</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-header bg-transparent">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Utility Application</label>
                <p>#AP123458</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Application Type</label>
                <p>New Wire/Conduit/Cable Occupancy Application</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Status</label>
                <div className="d-flex">
                  <p className="pe-3">Engineering review</p>
                  <p className="border-start ps-3">13 Open comments</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-header bg-transparent">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Utility Application</label>
                <p>#AP123458</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Application Type</label>
                <p>New Wire/Conduit/Cable Occupancy Application</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Status</label>
                <div className="d-flex">
                  <p className="pe-3">Engineering review</p>
                  <p className="border-start ps-3">13 Open comments</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-header bg-transparent">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Utility Application</label>
                <p>#AP123458</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Application Type</label>
                <p>New Wire/Conduit/Cable Occupancy Application</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Status</label>
                <div className="d-flex">
                  <p className="pe-3">Engineering review</p>
                  <p className="border-start ps-3">13 Open comments</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-header bg-transparent">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Utility Application</label>
                <p>#AP123458</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Application Type</label>
                <p>New Wire/Conduit/Cable Occupancy Application</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Status</label>
                <div className="d-flex">
                  <p className="pe-3">Engineering review</p>
                  <p className="border-start ps-3">13 Open comments</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-header bg-transparent">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Utility Application</label>
                <p>#AP123458</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Application Type</label>
                <p>New Wire/Conduit/Cable Occupancy Application</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Status</label>
                <div className="d-flex">
                  <p className="pe-3">Engineering review</p>
                  <p className="border-start ps-3">13 Open comments</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-header bg-transparent">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Utility Application</label>
                <p>#AP123458</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Application Type</label>
                <p>New Wire/Conduit/Cable Occupancy Application</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="loading">
                <div className="line"></div>
                <label className="text-muted d-block">Status</label>
                <div className="d-flex">
                  <p className="pe-3">Engineering review</p>
                  <p className="border-start ps-3">13 Open comments</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="">
        <div className="card mb-4 position-relative">
          <div className="card-header bg-transparent">
            <div className="row align-items-center">
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">
                    Utility Application
                  </label>
                  <p>#AP123458</p>
                </div>
              </div>
              <div className="col-md-5">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">Application Type</label>
                  <p>New Wire/Conduit/Cable Occupancy Application</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">Status</label>
                  <div className="d-flex">
                    <p className="pe-3">Engineering review</p>
                    <p className="border-start ps-3">13 Open comments</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-header bg-transparent">
            <div className="row align-items-center">
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">Applicant</label>
                  <p>Chapman Corp.</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">
                    Applicant’s Primary Contact
                  </label>
                  <p>Cecilia Chapman</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">
                    Proposed work involves
                  </label>
                  <p>Installation of a new facility</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">
                    Existing Agreement for Installation?
                  </label>
                  <p>Yes</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">
                    Railroad Milepost Reference
                  </label>
                  <p>Test</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">
                    Type of proposed installation:
                  </label>
                  <p>Electric Power</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">Material</label>
                  <p>Copper</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">Maximum voltage:</label>
                  <p>123456</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">Maximum Current</label>
                  <p>1324</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-4 position-relative">
          <div className="card-header bg-transparent">
            <div className="row align-items-center">
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">
                    Utility Application
                  </label>
                  <p>#AP123458</p>
                </div>
              </div>
              <div className="col-md-5">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">Application Type</label>
                  <p>New Wire/Conduit/Cable Occupancy Application</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">Status</label>
                  <div className="d-flex">
                    <p className="pe-3">Engineering review</p>
                    <p className="border-start ps-3">13 Open comments</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-header bg-transparent">
            <div className="row align-items-center">
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">Applicant</label>
                  <p>Chapman Corp.</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">
                    Applicant’s Primary Contact
                  </label>
                  <p>Cecilia Chapman</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">
                    Proposed work involves
                  </label>
                  <p>Installation of a new facility</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">
                    Existing Agreement for Installation?
                  </label>
                  <p>Yes</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">
                    Railroad Milepost Reference
                  </label>
                  <p>Test</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">
                    Type of proposed installation:
                  </label>
                  <p>Electric Power</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">Material</label>
                  <p>Copper</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">Maximum voltage:</label>
                  <p>123456</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="loading">
                  <div className="line"></div>
                  <label className="text-muted d-block">Maximum Current</label>
                  <p>1324</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
