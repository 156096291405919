import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { triggerTransaction } from "../../api/Netsuite";
import { isTransactionAvailable } from "../../utils/isTransactionAvailable";
import { useApplication } from "../../hooks/useApplication";

export const TriggerTransactionButton = ({ role }) => {
  const location = useLocation();
  const { pathname } = location;
  const paths = pathname?.split("/") || [];
  const [isDisabled, setIsDisabled] = useState(false);

  const [loading, setLoading] = useState(false);

  const application = useApplication();

  const checkDisabled = useCallback(() => {
    setIsDisabled(!isTransactionAvailable(application));
  }, [application]);

  useEffect(() => {
    checkDisabled();
  }, [checkDisabled]);

  const _id = paths[paths.length - 1];

  const trigger = async () => {
    setLoading(true);
    try {
      await triggerTransaction(_id);
      checkDisabled();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (role !== "Approving Representative" && role !== "Approving Accountant") {
    return null;
  }

  if (loading || !application) {
    return (
      <div className="d-flex justify-content-end align-items-center">
        <button class="btn btn-primary" type="button" disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Loading...
        </button>
      </div>
    );
  }

  return (
    <div className="d-flex justify-content-end align-items-center mb-2">
      <button onClick={trigger} disabled={isDisabled} className="btn btn-primary">
        Sync NetSuite
      </button>
    </div>
  );
};
