export default function PaymentsLoading() {
  return (
    <div className="card-header  border-bottom px-0">
      <div className="loading">
        <div className="line"></div>
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="mb-0"> Engineering Fee</h2>
          <small className="text-muted"> Engineering Amount</small>
        </div>
      </div>
    </div>
  );
}
