import { atom, useRecoilValue, useSetRecoilState } from "recoil";

export const dashboard = atom({
  key: "dashboard",
  default: true,
});

export const useDashboard = () => {
  return useRecoilValue(dashboard);
};

export const useSetDashboard = () => {
  return useSetRecoilState(dashboard);
};
