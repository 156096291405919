import { atom, useRecoilValue, useSetRecoilState } from "recoil";

export const agreementsApplication = atom({
  key: "agreementsApplication",
  default: true,
});

export const useAgreementsApplication = () => {
  return useRecoilValue(agreementsApplication);
};

export const useSetAgreementsApplication = () => {
  return useSetRecoilState(agreementsApplication);
};
