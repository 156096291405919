import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import "./user.css";

import { Logo } from "../logo/Logo";
import GetmyRole from "../../utils/GetmyRole";
import { useAtoms } from "../../hooks/useAtoms";
import { useUser } from "../../hooks/useUser";
import { useContactRole } from "../../hooks/useContactRole";
import { updateCustomerId } from "../../hooks/useRole";

export const User = () => {
  const { navigation, navigationContent, hideNavigation, setOnGoingApplication, setAgreementsApplication, dashboard } = useAtoms();
  const { company = {}, contact = {}, roleName = "", role: userRole = {} } = useUser() || {};
  const { contactRoleId } = GetmyRole();
  const contactRoles = useContactRole();
  const [role, setRole] = useState(roleName);
  const history = useHistory();
  const pathName = window.location.pathname;
  const isAuth = pathName === "/login" || pathName === "/verify";
  const handleLogout = () => {
    localStorage.clear();
    history.push("/login");
  };

  useEffect(() => {
    const { role } = GetmyRole() || {};
    setRole(role);
    return history.listen(() => {
      const { role } = GetmyRole() || {};
      setRole(role);
    });
  }, [history]);

  const refresh = () => {
    let pathName = window.location.pathname;
    if (pathName === "/dashboard") {
      window.location.reload();
    }
  };

  const Agreements = () => {
    setOnGoingApplication(true);
    setAgreementsApplication(false);
  };

  const OngoingApplications = () => {
    setAgreementsApplication(true);
    setOnGoingApplication(false);
  };

  const handleOnGoingApplication = () => {
    const query = new URLSearchParams();
    query.set("filters", "allApplication");
    query.set("page", 1);
    history.push({
      pathname: "/dashboard",
      search: new URLSearchParams(query).toString(),
    });
    OngoingApplications();
  };

  const handleAgreements = () => {
    const query = new URLSearchParams();
    query.set("filters", "allAgreements");
    query.set("page", 1);
    history.push({
      pathname: "/dashboard",
      search: new URLSearchParams(query).toString(),
    });
    Agreements();
  };

  const handleChangeRole = (contactrole) => {
    const { id: contactRoleId, company, contact, role: userRole } = contactrole;
    const { email } = contact;
    const { id: companyId } = company;
    const { role, roleType } = userRole;
    const data = { email, role, contactRoleId, roleType, companyId };
    updateCustomerId(data);
    window.location.reload();
  };
  return (
    <header className={`pb-0 bg-dark ${isAuth && "position-fixed w-100"}`} id="clopse-head">
      <nav className="navbar navbar-dark navbar-expand-sm p-0">
        <div className="container-fluid">
          <Logo />
          {navigationContent && (
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mx-auto">
                {dashboard === true ? (
                  <>
                    <li className="nav-item">
                      <div style={{ cursor: "pointer" }} onClick={handleOnGoingApplication}>
                        <span className="nav-link ">Ongoing Applications</span>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div style={{ cursor: "pointer" }} onClick={handleAgreements}>
                        <span className="nav-link">Agreements</span>
                      </div>
                    </li>
                    {role === "Approving Accountant" && (
                      <li className="nav-item">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            history.push({ pathname: "/Payments" });
                          }}
                        >
                          <span className="nav-link">Payments</span>
                        </div>
                      </li>
                    )}
                  </>
                ) : (
                  <li className="nav-item">
                    {!hideNavigation && (
                      <Link to={"/dashboard"} onClick={refresh}>
                        <span className="nav-link">Dashboard</span>
                      </Link>
                    )}
                  </li>
                )}

                {role === "applicant" ? (
                  <li className="nav-item ">
                    {!hideNavigation && (
                      <Link to={`/settings/${company?.id}`}>
                        <span className="nav-link px-4">Settings</span>
                      </Link>
                    )}
                  </li>
                ) : null}

                <li className="nav-item position-relative">
                  {navigation ? (
                    <>
                      <Link to={"/dashboard"}>
                        <span className="close text-white position-absolute">
                          <small>
                            <i className="fal fa-times-circle"></i>
                          </small>
                        </span>
                      </Link>
                      <span
                        className="nav-link px-4 active"
                        style={{
                          color: "#f1c563",
                        }}
                      >
                        {navigation}
                      </span>
                    </>
                  ) : null}
                </li>
              </ul>

              <div className="" id="navbarSupportedContent">
                <ul className="nav ms-auto align-items-center">
                  <>
                    <li className="nav-item"></li>
                    <li className="nav-item">
                      <a className="nav-link help-toggle text-white" target="_blank" href={`${process.env.REACT_APP_API_URL}/static/help.pdf`} rel="noreferrer">
                        Help
                        <i className="fa fa-life-ring" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="nav-item ">
                      <div className="dropdown">
                        <div className="nav-link text-white" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                          Notification <i className="fa fa-bell"></i>
                        </div>
                        <div className="dropdown-menu dropdown-menu-end dropdown-menu-right p-4 notification-drop" aria-labelledby="dropdownMenuClickableInside">
                          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                            <p className="mb-0 fs-4 fw-bold">All caught up!</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </>
                </ul>
              </div>
            </div>
          )}

          {role && (
            <div className="dropdown nav-item">
              <span id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true" className="nav-link text-white text-end d-flex align-items-center">
                <span>
                  {contact?.email}
                  <small className="d-block text-capitalize">{roleName}</small>
                </span>
                <span className="ms-2">
                  <i className="fal fa-chevron-down"></i>
                </span>
              </span>
              <div className="dropdown-menu dropdown-menu-end dropdown-menu-right p-4 " aria-labelledby="dropdownMenuButton" data-popper-placement="bottom-end">
                <h2 className=""> Contact Details</h2>
                <div className="row border-bottom py-2">
                  <div className="col-sm-6">
                    <small className="text-muted d-block">Name</small>
                    <label>{contact?.name}</label>
                  </div>
                  <div className="col-sm-6">
                    <small className="text-muted d-block">Role</small>
                    <label>{userRole?.role}</label>
                  </div>
                </div>
                <div className="row border-bottom py-2">
                  <div className="col-sm-6">
                    <small className="text-muted d-block">Email</small>
                    <label>{contact?.email}</label>
                  </div>
                  <div className="col-sm-6">
                    <small className="text-muted d-block">Phone</small>
                    <label>{contact?.phone}</label>
                  </div>
                </div>
                <div className="row border-bottom py-2">
                  <div className="col-sm-6">
                    <small className="text-muted d-block">Street Address</small>
                    <label>{contact?.address?.address}</label>
                  </div>
                  <div className="col-sm-6">
                    <small className="text-muted d-block">City</small>
                    <label>{contact?.address?.city}</label>
                  </div>
                  <div className="col-sm-6">
                    <small className="text-muted d-block">State</small>
                    <label>{contact?.address?.state}</label>
                  </div>
                  <div className="col-sm-6">
                    <small className="text-muted d-block">Zip</small>
                    <label>{contact?.address?.zip}</label>
                  </div>
                </div>
                <div className="row border-bottom py-3" id="hide_engineerring">
                  <h2>Company Roles</h2>

                  {contactRoles?.map((contactrole) => {
                    const isActive = contactrole.id === contactRoleId;
                    return (
                      <div className="col-sm-6 py-2">
                        <div className={isActive ? "text-primary" : "text-muted"} style={{ cursor: "pointer" }} onClick={() => handleChangeRole(contactrole)}>
                          {contactrole?.role?.role} ({contactrole.company.name})
                        </div>
                      </div>
                    );
                  })}
                </div>

                <small onClick={handleLogout} style={{ cursor: "pointer" }} className="text-muted d-block pt-3">
                  Logout
                </small>
              </div>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};
