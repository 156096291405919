import SettingsComponent from "../components/settings/Settings";
import { useUser } from "../hooks/useUser";
const Page = (props) => {
  const user = useUser();
  if (!user) {
    return (
      <div className="d-flex align-items-center justify-content-center  display-2" style={{ height: "calc(100% - 85px)" }}>
        <i className="fas fa-spinner fa-spin"></i>
      </div>
    );
  }
  return <SettingsComponent {...props} user={user} />;
};

export default Page;
