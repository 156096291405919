import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import { postApplication, searchApplications, getApplications, getAgreements, filterApplications, getFilterLists } from "../../api/currentApplications";
import ListView from "./ListView";
import GridView from "./GridView";
import { useAtoms } from "../../hooks/useAtoms";
import { useUser } from "../../hooks/useUser";

let debounceTimer;

export const debounce = (callback, time) => {
  window.clearTimeout(debounceTimer);
  debounceTimer = window.setTimeout(callback, time);
};

function Dashboard() {
  let history = useHistory();
  const [sortBy, setSortBy] = useState(-1);
  const [sortType, setSortType] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [allApplications, setAllApplications] = useState(0);
  const [stagesOfActive, setStagesOfActive] = useState("allApplication");
  const [paymentmethod, setPaymentmethod] = useState([]);
  const [stages, setStages] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const { roleName = "", id: contactRoleId } = useUser() || {};
  let debounceTimer;

  const debounce = (callback, time) => {
    window.clearTimeout(debounceTimer);
    debounceTimer = window.setTimeout(callback, time);
  };

  const { checkedState, setCheckedState, setNavigation, setNavigationContent, setHideNavigation, onGoingApplication, setOnGoingApplication, agreementsApplication, setAgreementsApplication } =
    useAtoms();

  setHideNavigation(false);
  setNavigation("");
  setNavigationContent(true);
  const [currentApplicationsByUser, setCurrentApplicationsByUser] = useState([]);
  const [currentAgreementsByUser, setCurrentAgreementsByUser] = useState([]);
  const [pages, setTotalPages] = useState([]);
  const [filtercount, setFilterCount] = useState(0);
  const [lodash, setLoading] = useState(true);
  let qs = queryString.parse(window.location.search);
  useEffect(() => {
    if (Object.keys(qs).length === 0) {
      setAgreementsApplication(true);
      setOnGoingApplication(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async (roleName, contactRoleId, history) => {
      setLoading(true);
      if (roleName === "applicant") {
        let res = await getFilterLists(roleName, contactRoleId);
        setFilterList(res?.data.filterList);
      } else {
        const query = new URLSearchParams(history.location.search);
        let paymentmethod = "";
        query.forEach((value, key) => {
          if (key === "paymentmethod") {
            paymentmethod = value;
          }
        });
        setPaymentmethod(paymentmethod?.trim()?.split(" ") || []);
        if (paymentmethod !== "") {
          let res = await getFilterLists(roleName, false, paymentmethod);
          setFilterList(res?.data.filterList);
        } else {
          let res = await getFilterLists(roleName, false);
          setFilterList(res?.data.filterList);
        }
      }
      setLoading(false);
    };
    if (roleName && contactRoleId && history) {
      fetchData(roleName, contactRoleId, history);
    }
  }, [roleName, contactRoleId, history]);

  let view = localStorage.getItem("GridView") === "false" ? false : true;
  const [gridView, setGridView] = useState(Boolean(view));

  function getCurrentApplications(pageNumber = 1, agreements = agreementsApplication, application = onGoingApplication, sortType1 = false, sortBy1 = false, paymentmethod = false) {
    setLoading(true);

    if (agreements) {
      if (roleName === "applicant") {
        getApplications(pageNumber, roleName, contactRoleId, sortType1, sortBy1)
          .then(({ data }) => {
            setCurrentApplicationsByUser(data.applications || []);
            setCurrentAgreementsByUser(data.agreements || []);
            setAllApplications(data.total_applications || 0);
            setTotalPages(data.total_page || 0);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        getApplications(pageNumber, roleName, false, sortType1, sortBy1, paymentmethod)
          .then(({ data }) => {
            setCurrentApplicationsByUser(data.applications || []);
            setCurrentAgreementsByUser(data.agreements || []);
            setAllApplications(data.total_applications || 0);
            setTotalPages(data.total_page || 0);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    //  -----------------------seprate tab-----------

    if (application) {
      if (roleName === "applicant") {
        getAgreements(pageNumber, roleName, contactRoleId, sortType1, sortBy1)
          .then(({ data }) => {
            setCurrentApplicationsByUser(data.applications || []);
            setCurrentAgreementsByUser(data.agreements || []);
            setTotalPages(data.total_page || 0);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        getAgreements(pageNumber, roleName, false, sortType1, sortBy1)
          .then(({ data }) => {
            setCurrentApplicationsByUser(data.applications || []);
            setCurrentAgreementsByUser(data.agreements || []);
            setTotalPages(data.total_page || 0);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  const postApplications = async (formName, formNumber) => {
    const userID = contactRoleId;
    let data = {
      formName: formName,
      formNumber: formNumber,
      createdBy: userID,
    };
    let postForm = await postApplication(data);

    if (postForm) {
      localStorage.setItem(`${postForm.data.responseData._id}lastModifiedOn`, postForm.data.responseData.lastModifiedOn);
      if (formName === "form220") {
        history.push(`/${formName}/${postForm.data.responseData._id}`);
      } else {
        history.push(`/${"form210"}/${postForm.data.responseData._id}`);
      }
    }
  };
  const searchApplication = async (e) => {
    setLoading(true);
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setAgreementsApplication(true);
      setOnGoingApplication(false);
    } else {
      let data = "";
      if (roleName === "applicant") {
        const userID = contactRoleId;
        data = await searchApplications(userID, e.target.value, roleName);
      } else {
        data = await searchApplications("", e.target.value, roleName);
      }
      setAgreementsApplication(false);
      setOnGoingApplication(false);
      setCurrentApplicationsByUser(data?.data?.applications || []);
      setCurrentAgreementsByUser(data?.data?.agreements || []);
      setTotalPages(data?.data?.total_page || 0);
    }
    setLoading(false);
  };

  const getPaginationGroup = () => {
    let array = [];
    for (let i = 0; i < pages; i++) array.push(i + 1);
    return array;
  };

  const filterApplicationdata = async (stage, page = currentPage, sortType1 = false, sortBy1 = false, paymentmethod = false) => {
    setLoading(true);
    if (stage === "one") {
      getCurrentApplications(page, agreementsApplication, onGoingApplication, sortType1, sortBy1, paymentmethod);
    } else {
      if (roleName === "applicant") {
        let res = await filterApplications(page, stage, roleName, contactRoleId, sortType1, sortBy1);
        setCurrentApplicationsByUser(res?.data?.applications || []);
        setCurrentAgreementsByUser(res?.data?.agreements || []);
        setTotalPages(res?.data?.total_page || 0);
      } else {
        let res = await filterApplications(page, stage, roleName, false, sortType1, sortBy1, paymentmethod);
        setCurrentApplicationsByUser(res?.data?.applications || []);
        setCurrentAgreementsByUser(res?.data?.agreements || []);
        setTotalPages(res?.data?.total_page || 0);
        setFilterCount(res?.data?.filter_count || 0);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filterList?.length > 0) {
      const query = new URLSearchParams(history.location.search);
      let filters = "allApplication";
      let page = 1;
      let sortType = "";
      let sortBy = -1;
      let stages = "";
      let paymentmethod = "";
      query.forEach((value, key) => {
        if (key === "filters") {
          filters = value;
        }
        if (key === "page") {
          page = value;
        }
        if (key === "sortType") {
          sortType = value;
        }
        if (key === "sortBy") {
          sortBy = value;
        }
        if (key === "type") {
          stages = value;
        }
        if (key === "paymentmethod") {
          paymentmethod = value;
        }
      });
      setStagesOfActive(filters);
      setCurrentPage(Number(page));
      setSortType(sortType);
      setSortBy(Number(sortBy));
      setStages(stages);
      setPaymentmethod(paymentmethod?.trim()?.split(" ") || []);
      if (filters === "allApplication") {
        if (paymentmethod !== "") {
          getCurrentApplications(page, true, false, sortType, sortBy, paymentmethod);
        } else {
          getCurrentApplications(page, true, false, sortType, sortBy);
        }
      } else if (filters === "allAgreements") {
        setOnGoingApplication(true);
        setAgreementsApplication(false);
      } else {
        const data = filterList.find((each) => each.stageName === filters);
        if (paymentmethod !== "") {
          filterApplicationdata(data?.id, page, sortType, sortBy, paymentmethod);
        } else {
          filterApplicationdata(data?.id, page, sortType, sortBy);
        }
      }
    }
  }, [filterList, history.location.search]);

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    let filters = "allApplication";
    query.forEach((value, key) => {
      if (key === "filters") filters = value;
    });
    if (filters === "allApplication" || filters === "allAgreements") {
      if (onGoingApplication) {
        setStagesOfActive("allAgreements");
        getCurrentApplications(currentPage, false, true, sortType, sortBy);
      }
      if (agreementsApplication) {
        setStagesOfActive("allApplication");
        setCurrentPage(1);
        getCurrentApplications(1, true, false, sortType, sortBy);
      }
      setStages("");
    }
  }, [onGoingApplication, agreementsApplication]);

  function goToNextPage() {
    if (stagesOfActive === "allApplication" || stagesOfActive === "allAgreements") {
      if (stagesOfActive === "allAgreements") getCurrentApplications(currentPage + 1, false, true, sortType, sortBy);
      if (stagesOfActive === "allApplication") getCurrentApplications(currentPage + 1, true, false, sortType, sortBy, paymentmethod.length > 0 ? paymentmethod.join(" ") : false);
      setCurrentPage((page) => page + 1);
    } else {
      const data = filterList.find((each) => each.stageName === stagesOfActive);
      const includesPaymentMethod = paymentmethod.some((method) => ["Stripe", "ACH", "Check"].includes(method));
      if (includesPaymentMethod) {
        filterApplicationdata(data?.id, currentPage + 1, sortType, sortBy, paymentmethod.join(" "));
      } else {
        setPaymentmethod([]);
        filterApplicationdata(data?.id, currentPage + 1, sortType, sortBy);
      }
      setCurrentPage((page) => page + 1);
    }

    const query = new URLSearchParams(history.location.search);
    query.set("page", Number(currentPage) + 1);
    history.push({
      pathname: "/dashboard",
      search: new URLSearchParams(query).toString(),
    });
  }

  function goToPreviousPage() {
    if (stagesOfActive === "allApplication" || stagesOfActive === "allAgreements") {
      if (stagesOfActive === "allAgreements") getCurrentApplications(currentPage - 1, false, true, sortType, sortBy);
      if (stagesOfActive === "allApplication") getCurrentApplications(currentPage - 1, true, false, sortType, sortBy, paymentmethod.length > 0 ? paymentmethod.join(" ") : false);
      setCurrentPage((page) => page - 1);
    } else {
      const data = filterList.find((each) => each.stageName === stagesOfActive);
      const includesPaymentMethod = paymentmethod.some((method) => ["Stripe", "ACH", "Check"].includes(method));
      if (includesPaymentMethod) {
        filterApplicationdata(data.id, currentPage - 1, sortType, sortBy, paymentmethod.join(" "));
      } else {
        setPaymentmethod([]);
        filterApplicationdata(data.id, currentPage - 1, sortType, sortBy);
      }
      setCurrentPage((page) => page - 1);
    }
    const query = new URLSearchParams(history.location.search);
    query.set("page", Number(currentPage) - 1);
    history.push({
      pathname: "/dashboard",
      search: new URLSearchParams(query).toString(),
    });
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    if (stagesOfActive === "allApplication" || stagesOfActive === "allAgreements") {
      setCurrentPage(pageNumber);
      if (stagesOfActive === "allAgreements") getCurrentApplications(pageNumber, false, true, sortType, sortBy);
      if (stagesOfActive === "allApplication") getCurrentApplications(pageNumber, true, false, sortType, sortBy, paymentmethod.length > 0 ? paymentmethod.join(" ") : false);
    } else {
      const data = filterList.find((each) => each.stageName === stagesOfActive);
      const includesPaymentMethod = paymentmethod.some((method) => ["Stripe", "ACH", "Check"].includes(method));
      if (includesPaymentMethod) {
        filterApplicationdata(data.id, pageNumber, sortType, sortBy, paymentmethod.join(" "));
      } else {
        setPaymentmethod([]);
        filterApplicationdata(data.id, pageNumber, sortType, sortBy);
      }
      setCurrentPage(pageNumber);
    }
    const query = new URLSearchParams(history.location.search);
    query.set("page", pageNumber);
    history.push({
      pathname: "/dashboard",
      search: new URLSearchParams(query).toString(),
    });
  }

  const sortByAscDec = (sortType1, sortBy1) => {
    setSortType(sortType1);
    let sortby = sortBy1 === 1 ? -1 : 1;
    setSortBy(sortby);
    if (stagesOfActive === "allApplication" || stagesOfActive === "allAgreements") {
      if (stagesOfActive === "allAgreements") getCurrentApplications(currentPage, false, true, sortType1, sortby);
      if (stagesOfActive === "allApplication") getCurrentApplications(currentPage, true, false, sortType1, sortby, paymentmethod.length > 0 ? paymentmethod.join(" ") : false);
    } else {
      const data = filterList.find((each) => each.stageName === stagesOfActive);
      const includesPaymentMethod = paymentmethod.some((method) => ["Stripe", "ACH", "Check"].includes(method));
      if (includesPaymentMethod) {
        filterApplicationdata(data.id, currentPage, sortType1, sortby, paymentmethod.join(" "));
      } else {
        setPaymentmethod([]);
        filterApplicationdata(data.id, currentPage, sortType1, sortby);
      }
    }
    const query = new URLSearchParams(history.location.search);
    query.set("sortType", sortType1);
    query.set("sortBy", sortby);
    history.push({
      pathname: "/dashboard",
      search: new URLSearchParams(query).toString(),
    });
  };

  const searchPaymentMethod = async (paymentmethod) => {
    setLoading(true);
    const includesPaymentMethod = paymentmethod.some((method) => ["Stripe", "ACH", "Check"].includes(method));
    if (includesPaymentMethod) {
      let res = await getFilterLists(roleName, false, paymentmethod.join(" "));
      setFilterList(res?.data?.filterList);
      const query = new URLSearchParams(history.location.search);
      query.set("paymentmethod", paymentmethod.join(" "));
      history.push({
        pathname: "/dashboard",
        search: new URLSearchParams(query).toString(),
      });
    } else {
      setPaymentmethod([]);
      let res = await getFilterLists(roleName, false);
      setFilterList(res?.data?.filterList);
      const query = new URLSearchParams(history.location.search);
      query.delete("paymentmethod");
      history.push({
        pathname: "/dashboard",
        search: new URLSearchParams(query).toString(),
      });
    }
    setLoading(false);
  };

  return (
    <div>
      <div
        className="container-fluid"
        style={{
          background: "rgb(38 38 38)",
          paddingBottom: "10px",
          paddingTop: "3px",
        }}
      >
        <div className="search-col w-100 mt-0">
          <div className="search">
            <div className="form-floating mb-2">
              <input type="text" className="form-control" id="floatingInput" name="search" placeholder="e.g. AP123458" onChange={(e) => debounce(() => searchApplication(e), 500)} />
              <label htmlFor="floatingInput">Search an Application Number Or HNTB File Number...</label>
            </div>
          </div>
        </div>
      </div>
      <div className="py-4">
        <div className={gridView ? "container-fluid" : "container"}>
          <div className="row">
            <div className="col-md-12">
              <div className="inner-form card border-0 mb-4 bg-transparent">
                <>
                  {gridView ? (
                    <ListView
                      gridView={gridView}
                      lodash={lodash}
                      searchValue={searchValue}
                      sortByAscDec={sortByAscDec}
                      setGridView={setGridView}
                      filterApplicationdata={filterApplicationdata}
                      filterList={filterList}
                      currentApplicationsByUser={currentApplicationsByUser}
                      currentAgreementsByUser={currentAgreementsByUser}
                      checkedState={checkedState}
                      setCheckedState={setCheckedState}
                      onGoingApplication={onGoingApplication}
                      setOnGoingApplication={setOnGoingApplication}
                      agreementsApplication={agreementsApplication}
                      setAgreementsApplication={setAgreementsApplication}
                      allApplications={allApplications}
                      stagesOfActive={stagesOfActive}
                      setStagesOfActive={setStagesOfActive}
                      setCurrentPage={setCurrentPage}
                      sortBy={sortBy}
                      sortType={sortType}
                      setStages={setStages}
                      stages={stages}
                      filtercount={filtercount}
                      setPaymentmethod={setPaymentmethod}
                      paymentmethod={paymentmethod}
                      searchPaymentMethod={searchPaymentMethod}
                    />
                  ) : (
                    <GridView
                      loadash={lodash}
                      agreementsApplication={agreementsApplication}
                      searchValue={searchValue}
                      roleName={roleName}
                      postApplications={postApplications}
                      setGridView={setGridView}
                      gridView={gridView}
                      currentApplicationsByUser={currentApplicationsByUser}
                      onGoingApplication={onGoingApplication}
                      currentAgreementsByUser={currentAgreementsByUser}
                    />
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(onGoingApplication || agreementsApplication) && (
        <nav className="Page navigation example">
          {getPaginationGroup().length > 1 && (
            <ul className="pagination  justify-content-center pb-6">
              <li className="page-item" onClick={goToPreviousPage} style={currentPage === 1 ? { pointerEvents: "none", opacity: "0.6" } : null}>
                <a className="page-link" aria-label="Previous">
                  <span aria-hidden="true">«</span>
                </a>
              </li>
              {getPaginationGroup().map((item, index) => (
                <li className="page-item " key={index} onClick={(event) => changePage(event)}>
                  <a className={`page-link ${currentPage === item && "bg-primary text-white"}`}>{item}</a>
                </li>
              ))}
              <li className="page-item" onClick={goToNextPage} style={currentPage === pages ? { pointerEvents: "none", opacity: "0.6" } : null}>
                <a className="page-link" aria-label="Next">
                  <span aria-hidden="true">»</span>
                </a>
              </li>
            </ul>
          )}
        </nav>
      )}
    </div>
  );
}

export default Dashboard;
