import React from "react";
import ReactDOM from "react-dom";
import { Routes } from "./routes/routes";
import Providers from "./providers/Providers.jsx";

const checkMaintenanceStatusAndVersion = () => {
  fetch(`${process.env.REACT_APP_URL}/maintenance.json?v=${Date.now()}`).then((response) => {
    response.json().then(({ app_status, version }) => {
      const chached_version = localStorage.getItem("--v");
      if (app_status === "maintenance") {
        window.location.href = `${process.env.REACT_APP_URL}/maintenance.html`;
      }
      if (version && chached_version !== version) {
        if ("caches" in window) {
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
          console.log("Cache Deleted Successfully.");
        } else {
          console.log("Cache API is not supported in this browser.");
        }
        localStorage.setItem("--v", version);
        window.location.reload(true);
      }

      fetch(`${process.env.REACT_APP_API_URL}/returnSuccess`)
        .then(() => {
          console.log("Server Running Successfully");
        })
        .catch(() => {
          console.log("Server Stopped Running");
        });
    });
  });
};

// Check maintenance status every 30 seconds
setInterval(checkMaintenanceStatusAndVersion, 30000);

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <Routes />
    </Providers>
  </React.StrictMode>,
  document.getElementById("root"),
);
