import React, { useState, useContext, useRef } from "react";
import Text from "../Helper/Text";
import { Radio } from "../Helper/Radio";
import { Heading } from "../Helper/Heading";
import { Paragraph } from "../Helper/Paragraph";
import { Line } from "../Helper/Line";
import { TextArea } from "../Helper/Textarea";
import { Checkbox } from "../Helper/Checkbox";
import { File } from "../Helper/File";
import { Tabel } from "../Helper/Tabel";
import { Switch } from "../Helper/Switch";
import DropDown from "../Helper/DropDown";
import { MultipleFileUpload } from "../Helper/MutliFileUpload";
import { SingleFileUpload } from "../Helper/SingleFileUpload";
import { CheckboxGroup } from "../Helper/CheckboxGroup";
import Text1 from "../Helper/ParallelInput";
import Text2 from "../Helper/CrossingInput";
import TotalAerialInput from "../Helper/TotalAerialInput";
import { formContext } from "../../routes/routes";
import DummyCheckBox from "../Helper/DummyCheckBox";
import CommentsLables from "../Helper/CommentsLables";
import Break from "../Helper/Break";
import PopupButton from "../Helper/PopupButton";
import Delete from "../Helper/Delete";

export const HandleFieldType = ({ items, register, errors, setSchema = () => {}, watch, unregister, changeFunction, review, editPage }) => {
  const [parallel, setParallel] = useState(0);
  const [crossing, setCrossing] = useState(0);
  const schemaContext = useContext(formContext);
  const lastSec = useRef();
  lastSec.current = [];
  const parallelChange = (e, name) => {
    setParallel(e.target.value);

    setSchema((schemaContext.valuesToStore = [e.target.value]));

    register(name, { value: e.target.value });
  };
  const crossingChange = (e, name) => {
    setCrossing(e.target.value);

    register(name, { value: e.target.value });
  };
  let total = Number(parallel) + Number(crossing);
  const totalAerial = (e) => {};

  const getLocalStorageValue = (e) => {};

  let changeValue = schemaContext.valuesToStore;
  return (
    <>
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-fullscreen ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                RAILROAD LINE MAP
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <embed src={`${process.env.REACT_APP_API_URL}/static/rail_line_diagram.pdf`} frameBorder="0" width="100%" height="100%" />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="form-col">
        {items.header ? <h3>{items.header}</h3> : null}
        {items.rows.map((col, index) => (
          <div className="row" key={index}>
            {col.lablevalue ? (
              <>
                <div className="ms-auto text-end">
                  {" "}
                  <a
                    className="form-label"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    style={
                      col.labelStyle
                        ? {
                            color: "#0079C2",
                            fontWeight: "400",
                            fontSize: "14px",
                            textAlign: "right",
                          }
                        : null
                    }
                  >
                    {col.lablevalue}
                  </a>
                </div>
              </>
            ) : null}
            {col.columns.map((v, i) => (
              <div key={i} className={v.align ? v.align : "col 1"}>
                {v.type === "text" || v.type === "date" || v.type === "number" ? (
                  <Text
                    {...v}
                    register={register}
                    setSchema={setSchema}
                    errors={errors}
                    changeFunction={changeFunction}
                    handleChange={() => {}}
                    id={col.id}
                    parallel={parallel}
                    crossing={crossing}
                    getLocalStorageValue={getLocalStorageValue}
                    editPage={editPage}
                  />
                ) : null}
                {v.type === "comments" ? <CommentsLables {...v} editPage={editPage} /> : null}

                {v.type === "dummy" ? <DummyCheckBox {...v} register={register} errors={errors} handleChange={() => {}} id={col.id} /> : null}
                {v.type === "switch" ? <Switch {...v} register={register} errors={errors} handleChange={() => {}} id={col.id} /> : null}
                {v.type === "text1" ? <Text1 {...v} register={register} setSchema={setSchema} errors={errors} handleChange={() => {}} id={col.id} parallelChange={parallelChange} /> : null}
                {v.type === "text2" ? <Text2 {...v} register={register} setSchema={setSchema} errors={errors} handleChange={() => {}} crossingChange={crossingChange} id={col.id} /> : null}
                {v.type === "totalaerial" ? (
                  <TotalAerialInput
                    {...v}
                    register={register}
                    setSchema={setSchema}
                    errors={errors}
                    handleChange={() => {}}
                    totalAerial={totalAerial}
                    id={col.id}
                    total={total}
                    parallel={parallel}
                    crossing={crossing}
                    warningValue={changeValue}
                  />
                ) : null}
                {v.type === "checkbox" ? <Checkbox {...v} register={register} errors={errors} handleChange={() => {}} id={col.id} /> : null}
                {v.type === "checkboxGroup" ? <CheckboxGroup {...v} register={register} errors={errors} handleChange={() => {}} id={col.id} /> : null}
                {v.type === "radio" ? (
                  <Radio {...v} items={items} register={register} errors={errors} handleChange={() => {}} id={col.id} setSchema={setSchema} watch={watch} editPage={editPage} />
                ) : null}
                {v.type === "file" ? <File {...v} register={register} errors={errors} handleChange={() => {}} id={col.id} /> : null}
                {v.type === "multiplefile" ? <MultipleFileUpload {...v} register={register} errors={errors} handleChange={() => {}} id={col.id} /> : null}
                {v.type === "singlefile" ? <SingleFileUpload {...v} header={items.header} register={register} errors={errors} handleChange={() => {}} id={col.id} /> : null}

                {v.type === "textarea" ? <TextArea {...v} register={register} errors={errors} handleChange={() => {}} id={col.id} /> : null}
                {v.type === "heading" ? <Heading {...v} /> : null}
                {v.type === "delete" && !review ? <Delete {...v} /> : null}
                {v.type === "popup_button" ? <PopupButton register={register} errors={errors} handleChange={() => {}} id={col.id} review={review} {...v} /> : null}
                {v.type === "paragraph" ? <Paragraph {...v} /> : null}
                {v.type === "tabel" ? <Tabel {...v} register={register} errors={errors} handleChange={() => {}} id={col.id} unregister={unregister} editPage={editPage} /> : null}
                {v.type === "line" ? <Line {...v} /> : null}
                {v.type === "break" ? <Break /> : null}
                {v.type === "dropdown" ? <DropDown {...v} register={register} errors={errors} handleChange={() => {}} id={col.id} editPage={editPage} /> : null}
              </div>
            ))}
          </div>
        ))}
        {/* <hr /> */}
      </div>
      {items.casing !== "casing" ? <hr /> : null}
    </>
  );
};
