export const Heading = ({ text, css, label, field }) => {
  return (
    <>
      {label !== null ? <p className="text-muted d-block form-label">{label}</p> : ""}

      <p style={css}>
        {text} {field !== null ? field : null}
      </p>
    </>
  );
};
