const PipeSchema = {
  sections: [
    {
      label: "Project Information",
      formType: "form220Schema",
      questions: [
        {
          id: 1,
          header: "Proposed work ",
          rows: [
            {
              columns: [
                {
                  type: "checkbox",
                  name: "Proposed Work Involves",
                  label: "Proposed Work Involves",
                  requiredLable: true,
                  validation: {
                    required: true,
                  },
                  options: [
                    {
                      label: "Installation of a new facility",
                      value: "Installation of a new facility",
                      show: false,
                    },
                    {
                      label: "Revision to existing facility",
                      value: "Revision to existing facility",
                      showOnSelect: 2,
                      show: true,
                    },
                    {
                      label: "Upgrade to Existing Facility ",
                      value: "Upgrade to Existing Facility",
                      showOnSelect: 2,
                      show: true,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 2,
          hide: true,
          header: "Existing Agreement",

          rows: [
            {
              columns: [
                {
                  type: "radio",
                  requiredLable: true,
                  label: "Existing Agreement for Installation?",
                  name: "Existing Agreement For Installation",
                  validation: {
                    required: false,
                  },
                  options: [
                    {
                      value: "Yes Existing Agreement for Installation",
                      label: "Yes",
                      showOnSelect: 19,
                      show: true,
                    },
                    {
                      value: "No Existing Agreement for Installation",
                      label: "No",
                      showOnSelect: 0,
                      show: false,
                    },
                    {
                      value: "Unknown Existing Agreement for Installation",
                      label: "Unknown",
                      showOnSelect: 0,
                      show: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 3,
          title: "Location of installation",
          header: "Location of Installation",
          rows: [
            {
              label: "",
              columns: [
                {
                  validation: {
                    required: true,
                  },
                  requiredLable: true,
                  type: "text",
                  label: "Nearest Street",
                  name: "Nearest Street",
                  placeholder: "",
                },
                {
                  type: "text",
                  requiredLable: true,
                  label: "Nearest Town",
                  name: "Nearest Town",
                  placeholder: "",
                  validation: {
                    required: true,
                  },
                },
              ],
            },
            {
              label: "",

              columns: [
                {
                  requiredLable: true,
                  type: "dropdown",
                  label: "County",
                  name: "county",
                  options: [
                    "Select",
                    "Alamance",
                    "Cabarrus",
                    "Carteret",
                    "Craven",
                    "Jones",
                    "Davidson",
                    "Randolph",
                    "Durham",
                    "Guilford",
                    "Johnston",
                    "Lenoir",
                    "Mecklenburg",
                    "Orange",
                    "Rowan",
                    "Wake",
                    "Wayne",
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  requiredLable: true,
                  type: "dropdown",
                  label: "Country",
                  name: "Country",
                  options: ["Select", "United States"],
                  validation: {
                    required: true,
                  },
                },
                {
                  requiredLable: true,
                  type: "dropdown",
                  label: "State",
                  name: "State",
                  options: [
                    "Select",
                    "North Carolina",
                    // "Alaska",
                    // "Alabama",
                    // "Arkansas",
                    // "American Samoa",
                    // "Arizona",
                    // "California",
                    // "Colorado",
                    // "Connecticut",
                    // "District of Columbia",
                    // "Delaware",
                    // "Florida",
                    // "Georgia",
                    // "Guam",
                    // "Hawaii",
                    // "Iowa",
                    // "Idaho",
                    // "Illinois",
                    // "Indiana",
                    // "Kansas",
                    // "Kentucky",
                    // "Louisiana",
                    // "Massachusetts",
                    // "Maryland",
                    // "Maine",
                    // "Michigan",
                    // "Minnesota",
                    // "Missouri",
                    // "Mississippi",
                    // "Montana",

                    // " North Dakota",
                    // "Nebraska",
                    // "New Hampshire",
                    // "New Jersey",
                    // "New Mexico",
                    // "Nevada",
                    // "New York",
                    // "Ohio",
                    // "Oklahoma",
                    // "Oregon",
                    // "Pennsylvania",
                    // "Puerto Rico",
                    // "Rhode Island",
                    // "South Carolina",
                    // "South Dakota",
                    // "Tennessee",
                    // "Texas",
                    // "Utah",
                    // "Virginia",
                    // "Virgin Islands",
                    // "Vermont",
                    // "Washington",
                    // "Wisconsin",
                    // "West Virginia",
                    // "Wyoming",
                  ],
                  placeholder: "",
                  validation: {
                    required: true,
                  },
                },
              ],
            },

            {
              label: "",
              columns: [
                {
                  type: "number",
                  label: "Latitude",
                  name: "latitude",
                  placeholder: "",
                  requiredLable: true,
                  validation: {
                    required: true,
                  },
                },
                {
                  type: "number",
                  label: "Longitude",
                  name: "longitude",
                  placeholder: "",
                  requiredLable: true,
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 4,
          header: "Orientation of Installation",
          requiredLable: true,
          rows: [
            {
              label: "",
              columns: [
                {
                  type: "radio",
                  validation: {
                    required: true,
                  },
                  requiredLable: true,
                  label: "Orientation of proposed pipeline installation",
                  options: [
                    {
                      label: "Transverse crossing only",
                      value: "Transverse crossing only",
                      show: true,
                      showOnSelect: 5,
                      showOnHide: [6, 7],
                      key: [24],
                    },

                    {
                      label: "Longitudinal (parallel to tracks) only",
                      value: "Longitudinal parallel to tracks only",
                      show: true,
                      showOnSelect: 6,
                      showOnHide: [5, 7],
                      hideKey: [24],
                    },
                    {
                      label: "Longitudinal and transverse crossing",
                      value: "Longitudinal and transverse crossing",
                      showOnSelect: 7,
                      show: true,
                      showOnHide: [5, 6],
                      hideKey: [24],
                    },
                  ],
                  placeholder: "",
                  name: "Orientation of proposed pipeline installation",
                },
              ],
            },
          ],
        },
        {
          id: 5,
          header: "For a transverse crossing under the tracks",
          hide: true,
          rows: [
            {
              label: "For a transverse crossing under the tracks:",
              labelStyle: true,

              columns: [
                {
                  type: "dropdown",
                  //align: "col-md-4",
                  name: "RailRoad line transverse",
                  label: "Railroad Line",
                  options: ["Mainline", "H-Line ", "EC-Line"],
                  requiredLable: true,
                },
                {
                  validation: {
                    required: false,
                  },
                  type: "text",
                  label: "Railroad Milepost Reference*:",
                  name: "Railroad Milestone MP",
                  name1: "Railroad Mailstone Feet",
                  placeholder: "",
                  fieldType: "group",
                  requiredLable: true,
                },
              ],
            },
            {
              columns: [
                {
                  type: "comments",
                  comments:
                    "Use the next lowest whole milepost adjacent to the project location + feet in the direction of increasing milepost to the project location. <br/><span id='range1'></span>",
                  commentsIconIsRequired: true,
                  commentsStyle: {
                    color: "#6C757D",
                    fontSize: "13px",
                    fontStyle: "italic",
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "number",
                  name: "Total Length of Crossing on the NCRR Corridor",
                  label: "Total Length of Crossing on the NCRR Corridor:",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
                {},
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "number",
                  name: "Number of tracks to be crossed:",
                  label: "Number of tracks to be crossed:",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
                {
                  type: "number",
                  name: "Angle of crossing:",
                  label: "Angle of crossing:",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 6,
          hide: true,
          header: "For a longitudinal occupancy only",
          rows: [
            {
              label: "For a longitudinal parallel to tracks only",
              labelStyle: true,
              columns: [
                {
                  type: "dropdown",
                  // align: "col-md-4",
                  name: "RailRoad line Begin longitudinal",
                  label: "Railroad Line Begin",
                  options: ["Mainline", "H-Line ", "EC-Line"],
                  requiredLable: true,
                },
                {
                  validation: {
                    required: false,
                  },
                  requiredLable: true,

                  type: "text",
                  label: "Begin at Railroad Milepost:",
                  name: "Begin at Railroad Milepost parallel to tracks Mp",
                  name1: "Begin at Railroad Milepost parallel to tracks Feet",
                  placeholder: "",
                  fieldType: "group",
                  // validation: {
                  //   required: false,
                  // },
                },
              ],
            },
            {
              columns: [
                {
                  type: "comments",
                  commentsIconIsRequired: true,
                  commentsStyle: {
                    fontSize: "13px",
                    fontFamily: "Merriweather,serif",
                    padding: "0px",
                    fontStyle: "Italic",
                    color: "#6C757D",
                  },
                  comments:
                    "Use the next lowest whole milepost adjacent to the project location + feet in the direction of increasing milepost to the project location. <br/><span id='range2'></span>",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "dropdown",
                  requiredLable: true,
                  //align: "col-md-4",
                  name: "RailRoad line End longitudinal",
                  label: "Railroad Line End",
                  options: ["Mainline", "H-Line ", "EC-Line"],
                },
                {
                  validation: {
                    required: false,
                  },
                  type: "text",
                  label: "End at Railroad Milepost:",
                  name: "End at Railroad Milepost parallel to tracks Mp",
                  name1: "End at Railroad Milepost parallel to tracks Feet",
                  placeholder: "",
                  fieldType: "group",
                  requiredLable: true,
                  // validation: {
                  //   required: false,
                  // },
                },
              ],
            },
            {
              columns: [
                {
                  type: "comments",
                  commentsIconIsRequired: true,
                  commentsStyle: {
                    fontSize: "13px",
                    fontFamily: "Merriweather,serif",
                    padding: "0px",
                    fontStyle: "Italic",
                    color: "#6C757D",
                  },
                  comments:
                    "Use the next lowest whole milepost adjacent to the project location + feet in the direction of increasing milepost to the project location. <br/><span id='range3'></span>",
                },
              ],
            },
            {
              columns: [
                {
                  validation: {
                    required: false,
                  },
                  requiredLable: true,
                  type: "number",
                  label: "Total Length parallel on the NCRR Corridor",
                  name: "Total Length parallel on the NCRR Corridor",

                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                },
                {},
              ],
            },
            {
              columns: [
                {
                  requiredLable: true,
                  type: "number",
                  label: "Min. distance from centerline of nearest track of longitudinal portion",
                  name: "Min distance from centerline of nearest track of longitudinal portion",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                },
              ],
            },
          ],
        },
        {
          id: 7,
          hide: true,
          header: "For a longitudinal and transverse crossing",
          rows: [
            {
              label: "For a longitudinal and transverse crossing",
              labelStyle: true,
              columns: [
                {
                  type: "dropdown",
                  name: "RailRoad line Begin longitudinal + transvere",
                  //align: "col-md-4",
                  label: "Railroad Line Begin",
                  options: ["Mainline", "H-Line ", "EC-Line"],
                  requiredLable: true,
                },
                {
                  validation: {
                    required: false,
                  },
                  type: "text",
                  label: "Begin at Railroad Milepost:",
                  name: "Begin at Railroad Milepost transverse crossing Mp",
                  name1: "Begin at Railroad Milepost transverse crossing Feet",
                  placeholder: "",
                  fieldType: "group",
                  requiredLable: true,
                },
              ],
            },
            {
              columns: [
                {
                  type: "comments",
                  commentsIconIsRequired: true,
                  commentsStyle: {
                    fontSize: "13px",
                    fontFamily: "Merriweather,serif",
                    padding: "0px",
                    fontStyle: "Italic",
                    color: "#6C757D",
                  },
                  comments:
                    "Use the next lowest whole milepost adjacent to the project location + feet in the direction of increasing milepost to the project location. <br/><span id='range4'></span>",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "dropdown",
                  name: "RailRoad line End longitudinal + transvere",
                  label: "Railroad Line End",
                  //align: "col-md-4",
                  options: ["Mainline", "H-Line ", "EC-Line"],
                  requiredLable: true,
                },
                {
                  validation: {
                    required: false,
                  },
                  type: "text",
                  label: "End at Railroad Milepost:",
                  name: "End at Railroad Milepost transverse crossing Mp",
                  name1: "End at Railroad Milepost transverse crossing Feet",
                  placeholder: "",
                  fieldType: "group",
                  requiredLable: true,
                },
              ],
            },
            {
              columns: [
                {
                  type: "comments",
                  commentsIconIsRequired: true,
                  commentsStyle: {
                    fontSize: "13px",
                    fontFamily: "Merriweather,serif",
                    padding: "0px",
                    fontStyle: "Italic",
                    color: "#6C757D",
                  },
                  comments:
                    "Use the next lowest whole milepost adjacent to the project location + feet in the direction of increasing milepost to the project location. <br/><span id='range5'></span>",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  validation: {
                    required: false,
                  },
                  type: "text",
                  label: "Total Length of Crossing occupancy on the NCRR Corridor",
                  name: "Total Length of Crossing occupancy on the NCRR Corridor",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  requiredLable: true,
                },
                {
                  type: "text",
                  label: "Total Length parallel on the NCRR Corridor",
                  name: "Total Length parallel on the NCRR Corridors",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  name: "Total Length of Occupancy within the NCRR Corridor",
                  label: "Total Length of Occupancy within the NCRR Corridor",
                  type: "number",
                  disabled: true,
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "number",
                  label: "Number of tracks to be crossed:",
                  name: "Number of tracks to be crossed",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
                {
                  type: "number",
                  label: "Angle of crossing:",
                  name: "Angle of crossing",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                  label: "Min. distance from centerline of nearest track of longitudinal portion",
                  name: "Min distance from centerline of nearest track of longitudinal portions",
                  placeholder: "",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                },
              ],
            },
          ],
        },
        {
          id: 8,
          header: "Will pipeline be located entirely within the confines of a public street?",
          requiredLable: true,
          rows: [
            {
              label: "",
              columns: [
                {
                  type: "radio",
                  radioPosition: "row",
                  name: "Will pipeline be located entirely within the confines of a public street?",
                  label: "",
                  options: [
                    {
                      label: "Yes ",
                      value: "Yes Will pipeline be located entirely within the confines of a public street",
                    },
                    {
                      label: "No",
                      value: "No Will pipeline be located entirely within the confines of a public street",
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 9,
          header: "Will the installation connect to an existing facility within the NCRR Corridor?",
          requiredLable: true,
          rows: [
            {
              label: "",
              columns: [
                {
                  type: "radio",
                  radioPosition: "row",
                  name: "Will the installation connect to an existing facility within the NCRR Corridor?",
                  label: "",
                  options: [
                    {
                      label: "Yes",
                      value: "Yes Will the installation connect to an existing facility within the NCRR Corridor",
                      showOnSelect: 10,
                      show: true,
                    },
                    {
                      label: "No",
                      showOnHide: [10],
                      value: "No Will the installation connect to an existing facility within the NCRR Corridor",
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 10,
          hide: true,
          header: "Identify owner",
          rows: [
            {
              columns: [
                {
                  type: "text",
                  name: "Identify owner",
                  // label: "If yes, identify owner:",
                  //requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 11,
          header: "Are there any wires, poles or obstructions to be relocated?",
          requiredLable: true,
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Are there any wires poles or obstructions to be relocated",
                  type: "radio",
                  radioPosition: "row",
                  options: [
                    {
                      value: "Yes Are there any wires, poles or obstructions to be relocated",
                      label: "Yes",
                      showOnSelect: 12,
                      show: true,
                    },
                    {
                      label: "No",
                      value: "No Are there any wires, poles or obstructions to be relocated",
                      showOnHide: [12],
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 12,
          hide: true,
          header: "Types of wires, poles or obstructions to be relocated",
          label: "",
          rows: [
            {
              columns: [
                {
                  type: "text",
                  name: "Are there any wires, poles or obstructions to be relocated is yes",
                  //label: "If yes, Type of facility:",
                  placeholder: "",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
                {
                  type: "text",
                  name: "Owner",
                  label: "Owner:",
                  placeholder: "",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "text",
                  name: "To be relocated by",
                  label: "To be relocated by:",
                  placeholder: "",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
                {},
              ],
            },
          ],
        },
        {
          id: 13,
          header: "Will temporary track support or rip rapping be required?",
          requiredLable: true,
          rows: [
            {
              columns: [
                {
                  type: "radio",
                  radioPosition: "row",
                  name: "Will temporary track support or rip rapping be required?",
                  options: [
                    {
                      value: "Yes Will temporary track support or rip rapping be required",
                      label: "Yes",

                      showOnSelect: 14,
                      show: true,
                    },
                    {
                      value: "No Will temporary track support or rip rapping be required",
                      label: "No",
                      showOnHide: [14],
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 14,
          header: "temporary track support or rip rapping be required if yes",
          hide: true,
          rows: [
            {
              columns: [
                {
                  type: "text",
                  name: "Will temporary track support or rip rapping be required if yes",
                  // label: "If yes, Type of facility:",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 15,
          header: "Shut-off valves:",
          requiredLable: true,
          rows: [
            {
              label: "",
              columns: [
                {
                  type: "radio",
                  name: "Shut-off valves:",
                  radioPosition: "row",
                  options: [
                    {
                      value: "Yes Shut-off valves",
                      label: "Yes",
                      showOnSelect: 16,
                      show: true,
                    },
                    {
                      value: "No Shut-off valves",
                      label: "No",
                      showOnHide: [16],
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 16,
          hide: true,
          header: "Shut-off valves: if yes",
          rows: [
            {
              columns: [
                {
                  type: "text",
                  name: "Shut-off valves: if yes",
                  //label: "If yes, Type of facility:",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 17,
          header: "Vents:",
          requiredLable: true,
          rows: [
            {
              columns: [
                {
                  name: "Vents:",
                  type: "radio",
                  radioPosition: "row",
                  label: "",
                  options: [
                    {
                      value: "Yes Vents",
                      label: "Yes",
                      showOnSelect: 18,
                      show: true,
                    },
                    {
                      label: "No",
                      value: "No Vents",
                      showOnHide: [18],
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 18,
          header: "Vents Details",
          hide: true,
          rows: [
            {
              columns: [
                {
                  type: "text",
                  label: "If yes, location:",
                  name: "If yes, location: vents",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
                {
                  type: "text",
                  label: "Number:",
                  name: "Number vents",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "text",
                  label: "Size:",
                  name: "Size vents",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
                {
                  type: "text",
                  label: "Height above ground:",
                  name: "Height above ground vents",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 19,
          header: "Seals:",
          requiredLable: true,
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Seals:",
                  type: "radio",
                  radioPosition: "row",
                  options: [
                    {
                      value: "Yes Seals",
                      label: "Yes",
                      showOnSelect: 20,
                      show: true,
                    },
                    {
                      label: "No",
                      value: "No Seals",
                      showOnHide: [20],
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 20,
          header: "Seals Details",
          hide: true,
          rows: [
            {
              columns: [
                {
                  type: "text",
                  label: "If yes, Both ends:",
                  name: "If yes, Bothends: Seals",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
                {
                  type: "text",
                  label: "One end:",
                  name: "One end: Seals",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 21,
          header: "Any existing facilities to the abandoned or removed",
          requiredLable: true,
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Any existing facilities to the abandoned or removed",
                  type: "radio",
                  label: "",
                  radioPosition: "row",
                  options: [
                    {
                      value: "Yes Any existing facilities to the abandoned or removed",
                      label: "Yes",
                      showOnSelect: 22,
                      show: true,
                    },
                    {
                      showOnHide: [22],
                      label: "No",
                      value: "No Any existing facilities to the abandoned or removed",
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 22,
          hide: true,
          header: "If yes, explain: Any existing facilities to the abandoned or removed",
          rows: [
            {
              columns: [
                {
                  type: "text",
                  //label: "If yes, explain:",
                  name: "If yes, explain: Any existing facilities to the abandoned or removed",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 23,
          header: "Character of surface material:",

          rows: [
            {
              columns: [
                {
                  label: "",
                  //requiredLable: true,
                  name: "character of surface material",
                  type: "text",
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 24,
          header: "Approximate ground water level:",
          rows: [
            {
              columns: [
                {
                  label: "",
                  name: "Approximate ground water level",
                  type: "text",
                  //requiredLable: true,
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 25,
          header: "Source of information on subsurface information:",
          rows: [
            {
              columns: [
                {
                  label: "",
                  name: "Source of information on subsurface information",
                  type: "text",
                  //requiredLable: true,
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 26,
          header: "Proposed Construction",
          rows: [
            {
              label: "",
              columns: [
                {
                  type: "date",
                  label: "Start Date",
                  name: "construction start date",
                  //comments: "Optional",
                  // commentsStyle: {
                  //   color: "rgb(136, 152, 170)",
                  //   fontSize: "13.6px",
                  // },
                  // validation: {
                  //   required: true,
                  // },
                },
                {
                  type: "text",
                  label: "Name of contractor",
                  name: "name of contractor",
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
            {
              columns: [
                {
                  type: "text",
                  label: "Duration",
                  name: "construction duration",
                  // validation: {
                  //   required: true,
                  // },
                },
                {
                  type: "dropdown",
                  label: "Duration Type",
                  name: "Duration_type",
                  options: ["Select", "Days", "Weeks", "Months", "Years"],
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
            {
              columns: [
                {
                  type: "textarea",
                  label: "Define any special specifications of the installation",
                  name: "special spec of installation",
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },
        {
          id: 27,
          header: "Pipe Data:",
          rows: [
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "Material to be conveyed:",
                  name: "Material to be conveyed",
                  requiredLable: true,
                  validation: {
                    required: true,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  name: "Flammable:",
                  label: "Flammable",
                  requiredLable: true,
                  type: "radio",
                  radioPosition: "row",
                  options: [
                    {
                      value: "Yes Flammable",
                      label: "Yes",
                    },
                    {
                      label: "No",
                      value: "No Flammable",
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
                {
                  type: "text",
                  label: "Temperature",
                  requiredLable: true,
                  name: "Temperature",
                  validation: {
                    required: true,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "radio",
                  name: "If the pipe is pressurized",
                  label: "If the pipe is pressurized",
                  radioPosition: "row",
                  requiredLable: true,
                  validation: {
                    required: true,
                  },
                  options: [
                    {
                      value: "Yes If the pipe is pressurized",
                      label: "Yes",
                      showOnSelect: 28,
                      show: true,
                    },
                    {
                      value: "NoIf the pipe is pressurized",
                      label: "No",
                      showOnHide: [28],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 28,
          header: "Pressure Details",
          hide: true,
          rows: [
            {
              columns: [
                {
                  type: "text",
                  label: "Maximum working pressure:",
                  name: "Maximum working pressure",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                  fieldLabelPosition: "right",
                  fieldLabel: "(psi)",
                },
                {
                  type: "text",
                  label: "Field test pressure:",
                  name: "Field test pressure",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                  fieldLabelPosition: "right",
                  fieldLabel: "(psi)",
                },
              ],
            },
            {
              columns: [
                {
                  type: "text",
                  label: "Type of test:",
                  name: "Type of test",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
                {},
              ],
            },
          ],
        },
        {
          id: 29,
          header: "NCDOT Project Number",
          rows: [
            {
              columns: [
                {
                  type: "text",
                  label: "NCDOT Project Number",
                  name: "NCDOT Project Number",
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: "Facilities",
      questions: [
        {
          id: 30,
          header: "Facilities Type",
          rows: [
            {
              columns: [
                {
                  type: "checkbox",
                  name: "Facilities Type",
                  options: [
                    {
                      label: "Aerial Facilities",
                      value: "Aerial Facilities",
                      showOnSelect: 33,
                      multipleRender: [41],
                      show: true,
                    },
                    {
                      label: "Underground Facilities",
                      value: "Underground Facilities",
                      showOnSelect: 34,
                      multipleRender: [42, 43],
                      show: true,
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 31,
          header: "Pipe Data Sheet: NCR 102 Plate I",
          rows: [
            {
              label: "",
              columns: [
                {
                  type: "tabel",
                  heading: [" ", "CARRIER PIPE", "	CASING PIPE"],
                  rowValue: [
                    {
                      label: "Material",
                      rowData: [
                        {
                          name: "Carrier Pipe Material",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Material",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Process of Manufacture",
                      rowData: [
                        {
                          name: "Carrier Pipe Process of Manufacture",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Process of Manufacture",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Material Specifications",
                      rowData: [
                        {
                          name: "Carrier Pipe Material Specifications",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Material Specifications",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Material Grade",
                      rowData: [
                        {
                          name: "Carrier Pipe Material Grade",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Material Grade",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Minimum Yield Strength (psi) of Material	",
                      rowData: [
                        {
                          name: "Carrier Pipe Minimum Yield Strength psi of Material",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Minimum Yield Strength psi of Material	",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Mill Test Pressure (psi)",
                      rowData: [
                        {
                          name: "Carrier Pipe Mill Test Pressure psi",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Mill Test Pressure psi",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Nominal Diameter",
                      rowData: [
                        {
                          name: "Carrier Pipe Nominal Diameter",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Nominal Diameter",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Inside Diameter	",
                      rowData: [
                        {
                          name: "Carrier Pipe Inside Diameter	",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Inside Diameter	",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Outside Diameter",
                      rowData: [
                        {
                          name: "Carrier Pipe Outside Diameter",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Outside Diameter",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Weight per Foot",
                      rowData: [
                        {
                          name: "Carrier Pipe Weight per Foot",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Weight per Foot",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Wall Thickness",
                      rowData: [
                        {
                          name: "Carrier Pipe Wall Thickness",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Wall Thickness",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Type of Seam",
                      rowData: [
                        {
                          name: "Carrier Pipe Type of Seame",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Type of Seam",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Laying Lengths",
                      rowData: [
                        {
                          name: "Carrier Pipe Laying Lengths",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Laying Lengths",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Type of Joints",
                      rowData: [
                        {
                          name: "Carrier Pipe Type of Joints",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Type of Joints",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Type of Coating",
                      rowData: [
                        {
                          name: "Carrier Pipe Type of Coating",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Type of Coating",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Cathodic Protection Details",
                      rowData: [
                        {
                          name: "Carrier Pipe Cathodic Protection Details",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Cathodic Protection Details",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Protective Coating Details",
                      rowData: [
                        {
                          name: "Carrier Pipe Protective Coating Details",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Protective Coating Details",
                          type: "text",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 32,
          header: "Facilities to be installed/utilized Type:",
          type: "tabel",
          rows: [
            {
              label: "",

              columns: [
                {
                  type: "tabel",
                  isCheckboxRequired: true,
                  heading: ["Type:", "Quantity:", "Nearest Distance to Centerline of Track:"],
                  rowValue: [
                    {
                      label: "Manholes",
                      id: 1,
                      rowData: [
                        {
                          name: "Manholes Quantity ",
                          type: "number",
                        },
                        {
                          name: "Manholes Nearest Distance to Centerline of Track ",
                          type: "number",
                          fieldLabelPosition: "right",
                        },
                      ],
                    },
                    {
                      label: "Handholes",
                      id: 2,
                      rowData: [
                        {
                          name: "Handholes Quantity",
                          type: "number",
                        },
                        {
                          name: "Handholes Nearest Distance to Centerline of Track",
                          type: "number",
                          fieldLabelPosition: "right",
                        },
                      ],
                    },
                    {
                      label: "Pull boxes",
                      id: 3,
                      rowData: [
                        {
                          name: "Pullboxes Ouantity",
                          type: "number",
                        },
                        {
                          name: "Pullboxes Nearest Distance to Centerline of Track",
                          type: "number",
                          fieldLabelPosition: "right",
                        },
                      ],
                    },
                    {
                      label: "Poles (New)",
                      id: 4,
                      rowData: [
                        {
                          name: "Poles (New) Quantity",
                          type: "number",
                        },
                        {
                          name: " Poles (New) Nearest Distance to Centerline of Track",
                          type: "number",
                          fieldLabelPosition: "right",
                        },
                      ],
                    },

                    {
                      label: "Poles (Existing)",
                      id: 5,
                      rowData: [
                        {
                          name: "Poles (Existing) Quantity",
                          type: "number",
                        },
                        {
                          name: "Poles (Existing) Nearest Distance to Centerline of Track",
                          type: "number",
                          fieldLabelPosition: "right",
                        },
                      ],
                    },
                    {
                      label: "other",
                      id: 6,
                      rowData: [
                        {
                          name: "others Quantity",
                          type: "number",
                        },
                        {
                          name: "Others Nearest Distance to Centerline of Track ",
                          type: "number",
                          fieldLabelPosition: "right",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: "Facilities Details",
      questions: [
        {
          id: 33,
          header: "Aerial Facilities",
          hide: true,
          rows: [
            {
              columns: [
                {
                  type: "text",
                  label: "Total aerial length on the NCRR Corridor:",
                  name: "Total aerial length on the NCRR Corridor",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  placeholder: "",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "number",
                  label: "Number of pole lines crossed:",
                  name: "Number of pole lines crossed",
                  placeholder: "",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  validation: {
                    required: false,
                  },
                  requiredLable: true,
                  type: "text",
                  label: "Type of pipe supports:",
                  name: "Type of pipe supports:",
                  placeholder: "",
                },
              ],
            },
            {
              columns: [
                {
                  type: "text",
                  label: "Minimum height of pipe and supports above top of rail:",
                  name: "Minimum height of pipe and supports above top of rail",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  placeholder: "",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "text",
                  label: "Minimum height of pipe above wirelines:",
                  name: "Minimum height of pipe above wirelines",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  placeholder: "",
                  validation: {
                    required: false,
                  },
                  requiredLable: true,
                },
              ],
            },
            {
              columns: [
                {
                  type: "text",
                  label: "Horizontal distance of pipe supports from centerline of nearest track:",
                  name: "Horizontal distance of pipe supports from centerline of nearest track",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  placeholder: "",
                  validation: {
                    required: false,
                  },
                  requiredLable: true,
                },
              ],
            },
          ],
        },
        {
          id: 34,
          header: "Underground Facilities",
          hide: true,
          rows: [
            {
              columns: [
                {
                  label: "Method of proposed installation:",
                  name: "Method of proposed installation:",
                  type: "checkbox",
                  requiredLable: true,
                  options: [
                    {
                      value: "Bore and Jack",
                      label: "Bore and Jack",
                    },
                    {
                      label: "Tunneling (tunnel liner plate)",
                      value: "Tunneling tunnel liner plate",
                    },

                    {
                      label: "Direction boring/Horizontal Direction drilling",
                      value: "Direction boring/Horizontal Direction drilling",
                    },
                    {
                      label: "Open Cut",
                      value: "Open Cut",
                    },
                    {
                      label: "Other ",
                      value: "Other please specify",
                    },
                  ],
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  validation: {
                    required: false,
                  },
                  type: "number",
                  label: "Total buried length on the NCRR Corridor:",
                  name: "Total buried length on the NCRR Corridor",
                  placeholder: "",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  requiredLable: true,
                },
              ],
            },
            {
              columns: [
                {
                  validation: {
                    required: false,
                  },
                  requiredLable: true,
                  type: "number",
                  label: "Bury depth from base of rail to top of casing:",
                  name: "Bury depth from base of rail to top of casing",
                  placeholder: "",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                },
                {
                  requiredLable: true,
                  type: "number",
                  label: "Bury depth below ditches:",
                  name: "Bury depth below ditches",
                  placeholder: "",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "number",
                  label: "Minimum depth on the NCRR Corridor but not beneath tracks:",
                  name: "Minimum depth on the NCRR Corridor but not beneath tracks:",
                  placeholder: "",
                  fieldLabelPosition: "right",
                  requiredLable: true,
                  fieldLabel: "Feet",
                  validation: {
                    required: false,
                  },
                },
              ], //here
            },
          ],
        },
      ],
    },
    {
      label: "File Uploads",
      scroll: "File_Uploads",
      questions: [
        {
          id: 35,
          header: "Design and Construction Plans",
          rows: [
            {
              columns: [
                {
                  type: "multiplefile",
                  requiredLable: true,
                  name: "design_and_construction_plans",
                  label: "Upload design and construction plans including plan view, profile view and cross section (perpendicular to the track centerline) of the proposed facility",

                  fileTypes: ".pdf, .cad or .doc up to 100 MB",
                  additionalcomments: "Upload multiple files if needed. Uploaded files appear below.",
                  fileUpload: "multiple",
                  validation: {
                    required: true,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  name: "File Upload design and construction plans checkbox ",
                  type: "checkbox",
                  requiredLable: true,
                  options: [
                    {
                      checked: true,
                      value: "Plans clearly show the extent of proposed work affecting the NCRR Corridor",
                      label: "Plans clearly show the extent of proposed work affecting the NCRR Corridor",
                    },
                    {
                      checked: true,
                      value: "Plans drawn and printed to scale (ensure no unintended scaling occurs during printing)",
                      label: "Plans drawn and printed to scale (ensure no unintended scaling occurs during printing)",
                    },
                    {
                      checked: true,
                      value: "Plans sealed by a Professional Engineer licensed in the State of North Carolina (no crimped seals)",
                      label: "Plans sealed by a Professional Engineer licensed in the State of North Carolina (no crimped seals)",
                    },
                    {
                      checked: true,
                      value: "No aerial background shown on plans",
                      label: "No aerial background shown on plans",
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 36,
          header: "Upload specifications and computations for the proposed occupancy",
          rows: [
            {
              columns: [
                {
                  type: "singlefile",
                  requiredLable: true,
                  name: "Upload specifications and computations for the proposed occupancy",
                  label: "Upload specifications and computations for the proposed occupancy",
                  // validation: {
                  //   required: true,
                  // },
                  fileTypes: ".pdf, .cad or .doc up to 100 MB",
                },
              ],
            },
            {
              columns: [
                {
                  name: "File Upload specifications and computations checkbox ",
                  requiredLable: true,
                  type: "checkbox",
                  options: [
                    {
                      checked: true,
                      value: "Digitally signed by a Professional Engineer licensed in the State of North Carolina",
                      label: "Digitally signed by a Professional Engineer licensed in the State of North Carolina",
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: "Applicant's Checklist",
      scroll: "Applicant's_Checklist",
      questions: [
        {
          //id: "36",
          label: "",
          rows: [
            {
              columns: [
                {
                  type: "heading",
                  text: "The following is a checklist of items that shall be completed when submitting this application for a proposed Pipeline Occupancy of the NCRR Corridor. Please place a check by all items listed below once they are included with the application package. For more detailed descriptions of the requirements below see Form NCR 102 “Specifications for Pipeline Occupancy of North Carolina Railroad Company Property.”",
                  css: {
                    fontSize: "16px",
                    fontFamily: "Merriweather,serif",
                    fontWeight: "300",
                  },
                },
              ],
            },
          ],
        },
        {
          id: 37,
          header: "Application Page to Include",
          requiredLable: true,
          rows: [
            {
              columns: [
                {
                  name: "Application Page to Include",
                  type: "checkboxGroup",
                  errorMessage: true,
                  options: [
                    {
                      isChecked: false,
                      value: "Soil borings in accordance with Form NCR 102 Section 3.1",
                      label: `Soil borings in accordance with Form`,
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-102-w-Plates.pdf",
                      linkContext: "NCR 102 Section 3.1",
                    },
                    {
                      isChecked: false,
                      label: "Calculations for internal and external loads on all pipes, manholes and other facilities to be installed on NCRR’s Corridor",
                      value: "Calculations for internal and external loads on all pipes, manholes and other facilities to be installed on NCRR’s Corridor",
                    },
                    {
                      isChecked: false,
                      label: "Deflection calculations for flexible casing pipe",
                      value: "Deflection calculations for flexible casing pipe",
                    },
                    {
                      isChecked: false,
                      label: "One hundred (100) year storm drainage calculations for all pipes, ditches and other structures carrying surface drainage on NCRR Corridor and/or under NCRR track(s)",
                      value: "One hundred (100) year storm drainage calculations for all pipes, ditches and other structures carrying surface drainage on NCRR Corridor and/or under NCRR track(s)",
                    },
                    {
                      isChecked: false,
                      label: "Hydraulic analysis of any existing ditch and/or structure impacted by the proposed improvements",
                      value: "Hydraulic analysis of any existing ditch and/or structure impacted by the proposed improvements",
                      styleInner: { marginLeft: "35px" },
                    },
                    {
                      isChecked: false,
                      label: "Design calculations for overhead pipe bridges and their foundations",
                      value: "Design calculations for overhead pipe bridges and their foundations",
                      styleInner: { marginLeft: "35px" },
                    },
                    {
                      isChecked: false,
                      label: "Sheeting design calculations",
                      value: "Sheeting design calculations",
                      styleInner: { marginLeft: "35px" },
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 38,
          header: "Plan and Profile Submittal Requirements",
          requiredLable: true,

          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Plan and Profile Submittal Requirements",
                  type: "checkboxGroup",
                  errorMessage: true,
                  options: [
                    {
                      value: "All applicable requirements set forth in Form NCR 102 for all pipeline occupancy applications",
                      linkContext: "Form NCR 102 ",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-102-w-Plates.pdf",
                      label: "All applicable requirements set forth in ",
                      subLabel: "for all pipeline occupancy applications",
                      isChecked: false,
                    },
                    {
                      isChecked: false,
                      value: "Additionally, all applicable requirements set forth in Form NCR 101 if any portion of the pipeline occupancy application is aerial",
                      linkContext: "Form NCR 101",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-101-w-Plates.pdf",
                      label: "Additionally, all applicable requirements set forth in",
                      subLabel: "if any portion of the pipeline occupancy application is aerial",
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 39,
          header: "General Plan View Requirements",
          requiredLable: true,
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "General Plan View Requirements",
                  type: "checkboxGroup",
                  errorMessage: true,
                  options: [
                    {
                      isChecked: false,
                      value: "All existing and proposed railroad tracks shown and labelled",
                      label: "All existing and proposed railroad tracks shown and labelled",
                    },
                    {
                      isChecked: false,
                      value: "North arrow",
                      label: "North arrow",
                    },
                    { isChecked: false, value: "Scale", label: "Scale" },
                    {
                      isChecked: false,
                      label: "‘To’ labels for the next town, city or station in either direction along the railroad",
                      value: "‘To’ labels for the next town, city or station in either direction along the railroad",
                    },
                    {
                      isChecked: false,
                      label: "Name of the town and county in which the proposed facility is located",
                      value: "Name of the town and county in which the proposed facility is located",
                    },
                    {
                      isChecked: false,
                      label: "Angle of crossing relative to railroad track(s)",
                      value: "Angle of crossing relative to railroad track(s)",
                    },
                    {
                      isChecked: false,
                      label: "Distance (in feet) to lowest milepost (see application)",
                      value: "Distance (in feet) to lowest milepost (see application)",
                    },
                    {
                      isChecked: false,
                      value:
                        "Show and label the NCRR Corridor boundary as “NCRR Corridor” and non-operating NCRR property lines as “NCRR Property” (Remove all other Right-of-Way or Property boundaries within the NCRR Corridor)",
                      label:
                        "Show and label the NCRR Corridor boundary as “NCRR Corridor” and non-operating NCRR property lines as “NCRR Property” (Remove all other Right-of-Way or Property boundaries within the NCRR Corridor)",
                    },
                    {
                      isChecked: false,
                      value: "Show dimensions from the NCRR Corridor boundary to the centerline of the NCRR Corridor, centerline of the nearest track and the overall width of the NCRR Corridor",
                      label: "Show dimensions from the NCRR Corridor boundary to the centerline of the NCRR Corridor, centerline of the nearest track and the overall width of the NCRR Corridor",
                    },
                    {
                      isChecked: false,
                      value: "Dimension distance from the crossing to any turnouts",
                      label: "Dimension distance from the crossing to any turnouts",
                    },
                    {
                      isChecked: false,
                      value: "Indicate any facilities to be abandoned (Add note in accordance with NCR 102 Section 2.6)",
                      label: "Indicate any facilities to be abandoned (Add note in accordance with ",
                      linkContext: "NCR 102 Section 2.6",
                      subLabel: ")",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-102-w-Plates.pdf",
                      styleInner: { marginLeft: "35px" },
                    },
                    {
                      isChecked: false,
                      value: "If occupancy is within or adjacent to a roadway at-grade crossing:",
                      label: "If occupancy is within or adjacent to a roadway at-grade crossing:",
                      styleInner: { marginLeft: "35px" },
                    },
                    {
                      isChecked: false,
                      value: "Show roadway edges of pavement, dimension width and roadway name",
                      label: "Show roadway edges of pavement, dimension width and roadway name",
                      styleInner: { marginLeft: "70px" },
                    },
                    {
                      isChecked: false,
                      value: "Show edges of pavement with dimensions from edge of road to centerline of proposed pipeline",
                      label: "Show edges of pavement with dimensions from edge of road to centerline of proposed pipeline",
                      styleInner: { marginLeft: "70px" },
                    },
                    {
                      isChecked: false,
                      value: "Location of vents, if any, and height above ground",
                      label: "Location of vents, if any, and height above ground",
                    },
                    {
                      isChecked: false,
                      value: "Location of markers and an example of text on the proposed markers",
                      label: "Location of markers and an example of text on the proposed markers",
                    },
                    {
                      isChecked: false,
                      value: "Location of existing above and below ground utilities",
                      label: "Location of existing above and below ground utilities",
                    },
                    {
                      isChecked: false,
                      value: "Note indicating method of installation",
                      label: "Note indicating method of installation",
                    },
                    {
                      isChecked: false,
                      value: "Size and material of the casing pipe",
                      label: "Size and material of the casing pipe",
                    },
                    {
                      isChecked: false,
                      value: "Length of the casing pipe on NCRR Corridor",
                      label: " Length of the casing pipe on NCRR Corridor",
                    },
                    {
                      isChecked: false,
                      value: "Size and material of the carrier pipe",
                      label: "Size and material of the carrier pipe",
                    },
                    {
                      isChecked: false,
                      value:
                        "Show launching and receiving pits that are within the NCRR Corridor. Dimension from the pits to the nearest centerline of track. Dimension the length, width and depth of the pits.",
                      label:
                        "Show launching and receiving pits that are within the NCRR Corridor. Dimension from the pits to the nearest centerline of track. Dimension the length, width and depth of the pits.",
                    },
                    {
                      isChecked: false,
                      value: "Details of any excavation or sheeting necessary to install the pipeline in accordance with  NCR 102 Section 5.9.1.C",
                      label: "Details of any excavation or sheeting necessary to install the pipeline in accordance with",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-102-w-Plates.pdf",
                      linkContext: " NCR 102 Section 5.9.1.C",
                    },
                    {
                      isChecked: false,
                      value: "A cross section of the pipeline showing the carrier pipe, casing pipe and any supports",
                      label: "A cross section of the pipeline showing the carrier pipe, casing pipe and any supports",
                    },
                    {
                      isChecked: false,
                      value: "Note in accordance with NCR 102 Section 1.6.1.C.10 regarding NCRR Specifications",
                      label: "Note in accordance with",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-102-w-Plates.pdf",
                      linkContext: " NCR 102 Section 1.6.1.C.10",
                      subLabel: "regarding NCRR Specifications",
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 40,
          header: "General Profile View Requirements",
          requiredLable: true,
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "General Profile View Requirements",
                  type: "checkboxGroup",
                  errorMessage: true,
                  options: [
                    {
                      value: "Scale",
                      label: "Scale",
                      isChecked: false,
                    },
                    {
                      isChecked: false,
                      value: "Indicate which direction the section is looking",
                      label: "Indicate which direction the section is looking",
                    },
                    {
                      isChecked: false,
                      label: "All existing and proposed railroad tracks shown",
                      value: "All existing and proposed railroad tracks shown",
                    },
                    {
                      isChecked: false,
                      label: "Show and label NCRR Corridor boundary",
                      value: "Show and label NCRR Corridor boundary",
                    },
                    {
                      isChecked: false,
                      label: "Profile above the centerline of the pipe showing the relationship of the pipeline and/or casing pipe to the ground levels, tracks and other facilities",
                      value: "Profile above the centerline of the pipe showing the relationship of the pipeline and/or casing pipe to the ground levels, tracks and other facilities",
                    },
                    {
                      isChecked: false,
                      label: "Note in accordance with ",
                      value: "Note in accordance with NCR 102 Section 1.6.1.C.10 regarding NCRR Specifications",
                      linkContext: "NCR 102 Section 1.6.1.C.10",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-102-w-Plates.pdf",
                      subLabel: "regarding NCRR Specifications",
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 41,
          hide: true,
          header: "Additional Plan View Requirements for Aerial Transverse Crossings and Longitudinal Occupancies",
          rows: [
            {
              columns: [
                {
                  condtionalLabel: "",
                  name: "Additional Plan View Requirements for Aerial Transverse Crossings and Longitudinal Occupancies",
                  type: "checkboxGroup",
                  options: [
                    {
                      isChecked: false,
                      value: "Indicate the overall length of the occupancy on each page",
                      label: "Indicate the overall length of the occupancy on each page",
                    },
                    {
                      isChecked: false,
                      value: "Dimension the distance from centerline of closest track to centerline of pipeline",
                      label: "Dimension the distance from centerline of closest track to centerline of pipeline",
                    },
                  ],
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },
        {
          id: 42,
          hide: true,
          header: "Additional Profile View Requirements for Underground Transverse Crossings",
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Additional Profile View Requirements for Underground Transverse Crossings",
                  condtionalLabel: "",
                  type: "checkboxGroup",
                  options: [
                    {
                      isChecked: false,
                      value: "Show theoretical embankment lines for all existing and proposed tracks per NCR 102 Section 4.3.1.F.5. ",
                      label: "Show theoretical embankment lines for all existing and proposed tracks per ",
                      linkContext: "NCR 102 Section 4.3.1.F.5",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-102-w-Plates.pdf",
                    },
                    {
                      isChecked: false,
                      value:
                        "Show launching and receiving pits that are within the NCRR Corridor. Dimension from the pits to the nearest centerline of track. Dimension the length, width and depth of the pits. ",
                      label:
                        "Show launching and receiving pits that are within the NCRR Corridor. Dimension from the pits to the nearest centerline of track. Dimension the length, width and depth of the pits. ",
                    },
                    {
                      isChecked: false,
                      value: "Show depth of pipeline under existing ground where it enters and exits the NCRR Corridor",
                      label: "Show depth of pipeline under existing ground where it enters and exits the NCRR Corridor",
                    },
                  ],
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },
        {
          id: 43,
          hide: true,
          header: "Additional Profile View Requirements for Underground Longitudinal Occupancies",
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Additional Profile View Requirements for Underground Longitudinal Occupancies",
                  type: "checkboxGroup",
                  options: [
                    {
                      isChecked: false,
                      value: "Show the top of rail profile of the nearest track",
                      label: "Show the top of rail profile of the nearest track",
                    },
                  ],
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },

        {
          id: 44,
          hide: true,
          header: "Additional Profile View Requirements for Underground Transverse Crossings",
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Additional Profile View Requirements for Underground Transverse Crossings",

                  type: "checkboxGroup",
                  options: [
                    {
                      isChecked: false,
                      value: "Show theoretical embankment lines for all existing and proposed tracks per NCR 102 Section 4.3.1.F.5. ",
                      label: "Show theoretical embankment lines for all existing and proposed tracks per ",
                      linkContext: "NCR 102 Section 4.3.1.F.5",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-102-w-Plates.pdf",
                    },
                    {
                      isChecked: false,
                      value:
                        "Show launching and receiving pits that are within the NCRR Corridor. Dimension from the pits to the nearest centerline of track. Dimension the length, width and depth of the pits. ",
                      label:
                        "Show launching and receiving pits that are within the NCRR Corridor. Dimension from the pits to the nearest centerline of track. Dimension the length, width and depth of the pits. ",
                    },
                    {
                      isChecked: false,
                      value: "Show depth of pipeline under existing ground where it enters and exits the NCRR Corridor",
                      label: "Show depth of pipeline under existing ground where it enters and exits the NCRR Corridor",
                    },
                  ],
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },
        {
          id: 45,
          header: "Additional Profile View Requirements for Underground Longitudinal Occupancies",
          hide: true,
          label: "",
          rows: [
            {
              columns: [
                {
                  condtionalLabel: "",
                  name: "Additional Profile View Requirements for Underground Longitudinal Occupancies",
                  type: "checkboxGroup",
                  options: [
                    {
                      isChecked: false,
                      value: "Show the top of rail profile of the nearest track",
                      label: "Show the top of rail profile of the nearest track",
                    },
                  ],
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },

        {
          // id: "42",
          label: "",
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "",
                  type: "heading",
                  text: "If application is approved, applicant agrees to reimburse the North Carolina Railroad Company and the Operating Railroads for any cost incurred by the North Carolina Railroad Company and the Operating Railroads incident to installation, maintenance, and/or supervision necessitated by this pipeline installation, and further agrees to assume all liability for accidents or injuries which arise as a result of this installation.",
                  css: {
                    fontSize: "16px",
                    fontFamily: "Merriweather,serif",
                    fontWeight: "300",
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
export { PipeSchema };
