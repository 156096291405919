import React from "react";
import { InitialForm } from "./Form";

export const FacilitySignup = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <div className="container">
        <div className="w-75 m-auto">
          <InitialForm />
        </div>
      </div>
    </div>
  );
};
