import { atom, atomFamily, selectorFamily } from "recoil";
import { getContactRoleById } from "../api/crm";

export const refetchContactRole = atom({
  key: "refetchContactRole",
  default: 0,
});

export const contactRole = atomFamily({
  key: "contactRole",
  default: selectorFamily({
    key: "contactRoleSelecter",
    get:
      (id) =>
      async ({ get }) => {
        try {
          if (!id) {
            return null;
          }
          get(refetchContactRole);
          const res = await getContactRoleById(id);
          if (res.data.status) {
            return res.data.data;
          } else {
            localStorage.clear();
            window.location = "/login";
          }
        } catch (error) {
          return {};
        }
      },
  }),
});
