import { PDFDocument } from "pdf-lib";

export const checkEncrypted = async (file) => {
  if (!file) return false;
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      const fileData = event.target.result;
      const byteArray = new Uint8Array(fileData);

      try {
        await PDFDocument.load(byteArray);
        resolve(true);
      } catch (error) {
        resolve(false);
      }
    };
    reader.readAsArrayBuffer(file);
  });
};
