import { getNestedProperty } from "../utils/getObjectDetails";

export const useNetSuiteCustomer = (
  setSearchLoading,
  setNetsuiteCostumer,
  search,
  pageLimit,
  setPageData,
  setNetSuiteCustomerUpdateLoading,
  pagination,
  primaryContactEmail,
  primaryContactName,
  consultantContactEmail,
  consultantContactName,
  billingContactEmail,
  billingContactName,
  setNetSuiteContact,
  netSuiteContact,
  setNetSuiteContactCopy,
  netSuiteContactCopy,
  setNetSuiteContactUpdateLoading,
  ItemId,
  createLead,
  updateCustomer,
  netsuiteCostumer,
  setPage,
  primaryContact,
  billingContact,
  consultantContact,
) => {
  const searchCustomer = async (e) => {
    setSearchLoading(true);
    setNetsuiteCostumer([]);
    const data = {
      recordtype: "customer",
      fieldId: "entityid",
      value: e.target.value,
    };
    const response = await search(data);
    if (response && response.data && response.data.status === "Success" && response.data.message.length > 0) {
      setNetsuiteCostumer(response.data.message);
      const data = response.data.message.filter((data, i) => {
        const pageStart = 0;
        if (i < pageLimit && i >= pageStart) {
          return data;
        }
        return null;
      });
      setPageData(data);
    }
    setSearchLoading(false);
  };

  const handleCustomerSelect = async (each) => {
    setNetSuiteCustomerUpdateLoading(true);
    setNetSuiteContactUpdateLoading(true);
    try {
      const { fields, sublists, id } = each;
      const { addressbook } = sublists;
      const { companyname, isperson } = fields;
      let address;
      let city;
      let state;
      let zip;
      if (typeof addressbook === "object") {
        for (const line in addressbook) {
          if (addressbook.hasOwnProperty(line)) {
            const { addr1_initialvalue, city_initialvalue, displaystate_initialvalue, zip_initialvalue } = addressbook[line];
            if (line === "line 1") {
              address = addr1_initialvalue || "";
              city = city_initialvalue || "";
              state = displaystate_initialvalue || fields?.billstate || "";
              zip = zip_initialvalue?.split(".")[0] || zip_initialvalue || "";
              break;
            }
          }
        }
        setTimeout(() => {
          pagination.current.classList.remove("show");
        });
      }

      const cContact = {
        id: id,
        name: companyname || "N/A",
        billingPeriod: "yearly",
        stateOfFormation: "N/A",
        applicantshipType: isperson === "T" ? "Individual" : "Company",
        billingAddress: {
          address,
          city,
          state,
          zip,
          country: "United States",
          poBox: "",
        },
        mailingAddress: {
          address,
          city,
          state,
          zip,
          country: "United States",
          poBox: "",
        },
      };

      const contacts = each?.sublists?.contactroles || [];
      let primaryContact;
      let engineeringContact;
      let billingContact;
      Object.keys(contacts).forEach((each) => {
        const contact = contacts[each];
        if (!primaryContact) {
          if (primaryContactEmail === contact?.email) {
            primaryContact = contact;
          } else if (primaryContactName === contact.contactname) {
            primaryContact = contact;
          }
        }
        if (!engineeringContact) {
          if (consultantContactEmail === contact?.email) {
            engineeringContact = contact;
          } else if (consultantContactName === contact.contactname) {
            engineeringContact = contact;
          }
        }
        if (!billingContact) {
          if (billingContactEmail === contact?.email) {
            billingContact = contact;
          } else if (billingContactName === contact.contactname) {
            billingContact = contact;
          }
        }
      });

      let pContact;
      let eContact;
      let bContact;
      if (primaryContact) {
        const data = {
          recordtype: "contact",
          fieldId: "id",
          value: primaryContact.contact,
        };
        const resp = await search(data);
        const primary = resp?.data?.message[0];
        const { fields = {}, id = "", sublists = {} } = primary;
        const { nameorig = "", phone = "", billaddr1 = "", billcity = "", email = "", shipzip = "", title = "" } = fields;
        const { addressbook = {} } = sublists;
        const { displaystate_initialvalue = "" } = addressbook["line 1"] || {};
        pContact = {
          id: id,
          name: nameorig,
          email: email,
          jobTitle: title,
          phone: phone,
          address: {
            address: billaddr1,
            city: billcity,
            state: displaystate_initialvalue,
            zip: shipzip,
          },
        };
      }
      if (billingContact) {
        const data = {
          recordtype: "contact",
          fieldId: "id",
          value: billingContact.contact,
        };
        const resp = await search(data);
        const billing = resp?.data?.message[0];
        const { fields = {}, id = "", sublists = {} } = billing;
        const { nameorig = "", phone = "", billaddr1 = "", billcity = "", email = "", shipzip = "", title = "" } = fields;
        const { addressbook = {} } = sublists;
        const { displaystate_initialvalue = "" } = addressbook["line 1"] || {};
        bContact = {
          id: id,
          name: nameorig,
          email: email,
          jobTitle: title,
          phone: phone,
          address: {
            address: billaddr1,
            city: billcity,
            state: displaystate_initialvalue,
            zip: shipzip,
          },
        };
      }
      if (engineeringContact) {
        const data = {
          recordtype: "contact",
          fieldId: "id",
          value: engineeringContact.contact,
        };
        const resp = await search(data);
        const engineering = resp?.data?.message[0];
        const { fields = {}, id = "", sublists = {} } = engineering;
        const { nameorig = "", phone = "", billaddr1 = "", billcity = "", email = "", shipzip = "", title = "" } = fields;
        const { addressbook = {} } = sublists;
        const { displaystate_initialvalue = "" } = addressbook["line 1"] || {};
        eContact = {
          id: id,
          companyName: "N/A",
          name: nameorig,
          email: email,
          jobTitle: title,
          phone: phone,
          address: {
            address: billaddr1,
            city: billcity,
            state: displaystate_initialvalue,
            zip: shipzip,
          },
        };
      }
      setNetSuiteContact({
        ...netSuiteContact,
        cContact,
        pContact,
        eContact,
        bContact,
        pContactCreateNewShow: !pContact,
        eContactCreateNewShow: !eContact,
        bContactCreateNewShow: !bContact,
        showCheckBox: true,
      });
      setNetSuiteContactCopy({
        ...netSuiteContact,
        cContact,
        pContact,
        eContact,
        bContact,
        showCheckBox: true,
        pContactCreateNewShow: !pContact,
        eContactCreateNewShow: !eContact,
        bContactCreateNewShow: !bContact,
      });
      setNetSuiteCustomerUpdateLoading(false);
      setNetSuiteContactUpdateLoading(false);
    } catch (error) {
      console.log(error);
      setNetSuiteCustomerUpdateLoading(false);
      setNetSuiteContactUpdateLoading(false);
    }
  };

  const handleCreateNewLeadClick = async () => {
    const confirm = window.confirm("Are You Sure to Create New Lead");
    if (confirm) {
      const data = {
        applicationId: ItemId,
      };
      try {
        setNetSuiteCustomerUpdateLoading(true);
        setNetSuiteContactUpdateLoading(true);
        const response = await createLead(data);
        if (response.data.status === "Success") {
          const customerAndContact = response.data.data;
          setNetSuiteContact({ ...netSuiteContact, ...customerAndContact });
        } else {
          // toast.error()
          alert(response.data.message);
        }
        setNetSuiteCustomerUpdateLoading(false);
        setNetSuiteContactUpdateLoading(false);
      } catch (err) {
        setNetSuiteCustomerUpdateLoading(false);
        setNetSuiteContactUpdateLoading(false);
      }
      window.location.reload();
    }
  };

  const handleUpdateCustomerClick = async () => {
    const confirm = window.confirm("Are You Sure to Update the Below Customer");
    if (confirm) {
      if (netSuiteContact.cContact.id) {
        setNetSuiteCustomerUpdateLoading(true);
        setNetSuiteContactUpdateLoading(true);
        const object = {
          applicationId: ItemId,
          netSuiteContact,
        };
        try {
          try {
            const response = await updateCustomer(object);
            if (response.data.status === "Success") {
              const { cContact, pContact, eContact, bContact } = response.data.data;
              setNetSuiteContact({
                ...netSuiteContact,
                cContact,
                pContact,
                bContact,
                eContact,
                showCheckBox: false,
                pContactCreateNewShow: false,
                eContactCreateNewShow: false,
                bContactCreateNewShow: false,
              });
            } else {
              // toast.error()
              alert(response.data.message);
            }
          } catch (error) {
            // toast.error(error.message)
            alert(error.message);
          }
          setNetSuiteCustomerUpdateLoading(false);
          setNetSuiteContactUpdateLoading(false);
        } catch (error) {
          setNetSuiteCustomerUpdateLoading(false);
          setNetSuiteContactUpdateLoading(false);
        }
        window.location.reload();
      } else {
        // toast.error()
        alert("Please Select the Customer First or Create New Lead");
      }
    }
  };

  const changePage = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= Math.floor(netsuiteCostumer.length / pageLimit) + 1 && netsuiteCostumer) {
      setPage(pageNumber);
      const filteredData = netsuiteCostumer.filter((data, i) => {
        const pageStart = (pageNumber - 1) * pageLimit;
        const endPage = pageLimit * pageNumber;
        if (i < endPage && i >= pageStart) {
          return data;
        }
        return null;
      });
      setPageData(filteredData);
    }
    setTimeout(() => {
      pagination.current.classList.add("show");
    });
  };

  const handleCheckbox = (contact, name) => {
    const value = getNestedProperty(netSuiteContact, `${contact}.${name + "checkbox"}`) || false;
    let portalContactInfo;
    if (contact === "pContact") {
      portalContactInfo = primaryContact;
    }
    if (contact === "bContact") {
      portalContactInfo = billingContact;
    }
    if (contact === "eContact") {
      portalContactInfo = consultantContact;
    }

    setNetSuiteContact((initialvalue) => {
      const updatedContact = {
        ...initialvalue,
        [contact]: {
          ...initialvalue[contact],
          [`${name}checkbox`]: !value,
          [name]: !value ? portalContactInfo?.[name] : netSuiteContactCopy?.[contact]?.[name],
        },
      };
      if (name.includes(".")) {
        const [nestedProp, nestedKey] = name.split(".");
        return {
          ...initialvalue,
          [contact]: {
            ...initialvalue[contact],
            [nestedProp]: {
              ...initialvalue[contact][nestedProp],
              [`${nestedKey}checkbox`]: !value,
              [nestedKey]: !value ? portalContactInfo?.[nestedProp]?.[nestedKey] : netSuiteContactCopy?.[contact]?.[nestedProp]?.[nestedKey],
            },
          },
        };
      }

      return updatedContact;
    });
  };

  const handleCreateNewContactCheckbox = (name) => {
    const contact = name.split("CreateNewShowChecked")[0];
    let portalContactInfo;
    if (contact === "pContact") {
      portalContactInfo = primaryContact;
    }
    if (contact === "bContact") {
      portalContactInfo = billingContact;
    }
    if (contact === "eContact") {
      portalContactInfo = consultantContact;
    }
    setNetSuiteContact((initialvalue) => ({
      ...initialvalue,
      [name]: !initialvalue[name],
      [contact]: !initialvalue[name] ? portalContactInfo : netSuiteContactCopy?.[contact],
    }));
  };

  return {
    searchCustomer,
    handleCustomerSelect,
    handleCreateNewLeadClick,
    handleUpdateCustomerClick,
    changePage,
    handleCheckbox,
    handleCreateNewContactCheckbox,
  };
};
