import React, { useEffect, useState } from "react";
import { debounce } from "../dashboard/Dashboard";
import { getCompanyByName } from "../../api/crm";
import { useParams, useLocation } from "react-router-dom";

export const FacilityCompanyList = ({ user, onSave }) => {
  const [recentCompanies, setRecentCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const { applicationID } = useParams();
  const location = useLocation();
  const pathname = location.pathname;
  const formName = pathname.split("/")[1];

  const getRecentCompanies = (user) => {
    const { company } = user;
    const { consultantApplications = [] } = company;
    const recentCompanies = [];
    consultantApplications.forEach((each) => {
      if (each.facilityCompany) {
        recentCompanies.push(each.facilityCompany);
      }
    });
    let uniqueIds = new Set();
    const uniqueRecentCompanies = recentCompanies.filter((company) => {
      if (!uniqueIds.has(company.id)) {
        uniqueIds.add(company.id);
        return true;
      }
      return false;
    });
    setRecentCompanies(uniqueRecentCompanies);
  };

  useEffect(() => {
    getRecentCompanies(user);
  }, [user]);

  const findCompany = async (companyName) => {
    const res = await getCompanyByName(companyName, "FACILITY");
    if (res.data.status) {
      setCompanies(res.data.data);
    }
    setLoading(false);
  };

  const selectCompany = (company) => {
    setCompanies([]);
    setForm((initialValue) => ({
      ...initialValue,
      sameAsMailingAddress: false,
      ...company,
    }));
    onSave(company);
  };

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Search a Company</h4>
          <div className="col-lg-12 ui-widget select-col position-relative p-0">
            <div className="dropdown col-lg-12 p-0">
              <input
                type="text"
                className="form-control"
                id="searchBar"
                placeholder="Enter company name"
                value={form.name}
                onChange={(e) => {
                  setLoading(true);
                  setForm((initialValue) => ({
                    ...initialValue,
                    name: e.target.value,
                  }));
                  debounce(() => findCompany(e.target.value), 500);
                }}
              />
              <ul className="dropdown-menu show w-100 h-0 rounded-0 pb-0 pt-0" aria-labelledby="automplete">
                {loading && (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
                {companies.length > 0 && (
                  <li className="">
                    <button type="button" className="close px-3 pt-2 " aria-label="Close" onClick={() => setCompanies([])}>
                      <span aria-hidden="true" className="h1 mb-0">
                        &times;
                      </span>
                    </button>
                  </li>
                )}
                {companies.map((item) => {
                  return (
                    <li className="border" key={item.id}>
                      <button type="button" onClick={() => selectCompany(item)} className="dropdown-item d-flex justify-content-between cursor-pointer">
                        <span>{item.name}</span>
                        <span className="text text-primary">Select</span>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card ">
        <div className="card-header border-0">
          <div className="d-sm-flex justify-content-between align-items-center">
            <h2 className="mb-3 mb-sm-0">Recent Company</h2>
            <div className="btn-col  text-right d-flex ">
              <a href={`/signup?role=FACILITY&applicationID=${applicationID}&formName=${formName}`} className="btn btn-primary mb-3 mb-md-0 d-block d-sm-inline-block">
                Add a new Company
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="select-company">
        <div className="card mb-4 ">
          {recentCompanies.map((company) => (
            <div key={company.id} style={{ cursor: "pointer" }} className="card-body  border-bottom" onClick={() => selectCompany(company)}>
              <div className="row position-relative ">
                <div className="col-md-4">
                  <label className="text-muted d-block form-label">Company Name</label>
                  <p>{company.name}</p>
                </div>

                <div className="col-md-4">
                  <label className="text-muted d-block form-label">Street Address</label>
                  <p>{company.mailingAddress.address}</p>
                </div>
                <div className="col-md-4">
                  <label className="text-muted d-block form-label">City</label>
                  <p>{company.mailingAddress.city}</p>
                </div>
              </div>
            </div>
          ))}
          {recentCompanies.length === 0 && <div className="text-center">No Recent companies Available</div>}
        </div>
      </div>
    </div>
  );
};
