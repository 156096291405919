import CrypteLocalstroge from "./CrypteLocalstroge";

const GetmyRole = () => {
  let role = {};
  if (localStorage.getItem("custID")) {
    const getdata = localStorage.getItem("custID");
    const appRoles = CrypteLocalstroge(getdata, "DECRYPTE");
    let { key: appRole } = appRoles || {};
    if (appRole) {
      role = JSON.parse(appRole);
    }
  }
  return role;
};

export default GetmyRole;
