import logo from "../../images/logo.png";

export const AuthCard = ({ children }) => {
  return (
    <div className="bg-img h-100 d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
      <div className="container h-100">
        <div className="row align-items-center h-100">
          <div className="col-md-6 offset-md-3">
            <div className="card border-0">
              <div className="card-header bg-dark text-center">
                <a href="#" className="brand mb-4">
                  <img src={logo} alt="NCRR" style={{ width: "252px" }} />
                </a>
              </div>
              <div className="card-body">
                <div className="text-center">
                  <div className="form-col">{children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
