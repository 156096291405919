import { useEffect, useState } from "react";
import Dashboard from "../components/dashboard/Dashboard";
import { EditContactForm } from "../components/contacts/forms/EditContact";
import { useUser } from "../hooks/useUser";
import { getContactsByCompanyId } from "../api/crm";
import DashBoardLoading from "../components/loaders/DashBoardLoading";

const Page = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const user = useUser();

  const getContacts = async (companyId, contactId) => {
    const res = await getContactsByCompanyId(companyId);
    if (res.data.status) {
      const selectedContact = res?.data?.data.find((each) => each.id === contactId);
      setSelectedContact(selectedContact);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    document.body.classList.remove("modal-open");
    const childElement = document.getElementById("modal-backdrops");
    childElement.parentNode.removeChild(childElement);
  };

  useEffect(() => {
    if (user) {
      const { company, contact } = user;
      getContacts(company?.id, contact?.id);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      if (selectedContact && selectedContact?.email) {
        const { address, name, companyroles } = selectedContact;
        const isApplicant = companyroles.some(({ role: { roleType } }) => roleType === "CONSULTANT" || roleType === "FACILITY");

        if (isApplicant && (!address?.address || !name)) {
          setShowModal(true);
        }
      }
    }
  }, [selectedContact, user]);

  if (!user || !selectedContact?.email) {
    return <DashBoardLoading />;
  }

  return (
    <>
      <EditContactForm
        heading={"Please Update Your Contact Details"}
        selectedContact={selectedContact}
        showModal={showModal}
        setShowModal={setShowModal}
        getContact={() => {
          window.location.reload();
        }}
        closeModal={closeModal}
        companyId={user?.company?.id}
        ID={"edit-contact"}
      />
      <Dashboard {...props} />;
    </>
  );
};

export default Page;
