/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect } from "react";
import { Route, useHistory } from "react-router";
import { Switch } from "react-router-dom";

import { verifyUser } from "../middleware/verifyUser";
import { ProtectedRoute } from "./ProtectedRoute";
import { User } from "../components/user/User";

import Login from "../pages/login";
import Verify from "../pages/verify";

import SignupCompany from "../pages/signupCompany";
import AddContacts from "../pages/addContacts";

import Dashboard from "../pages/dashboard";
import Settings from "../pages/settings";
import Payments from "../pages/payments";

import Form210 from "../pages/form210";
import Form220 from "../pages/form220";
import AssignContacts from "../pages/assignContacts";
import ApplicantView from "../pages/applicantView";
import ApplicationReview from "../pages/applicationReview";
import EngineeringReview from "../pages/engineeringReview";
import ApplicationPayment from "../pages/applicationPayment";
import DraftAggrement from "../pages/draftAgreement";
import CompiledAgreement from "../pages/compiledAgreement";
import FinalRelease from "../pages/finalRelease";

import CompiledPayment from "../pages/compiledPayment";

import ApplicationSignatureWaiting from "../pages/applicationSignatureWaiting";
import AgreementSignatureWaiting from "../pages/agreementSignatureWaiting";
import ApplicationPaymentWaiting from "../pages/applicationPaymentWaiting";
import AgreementPaymentWaiting from "../pages/agreementPaymentWaiting";

import NotFound from "../pages/NotFound";
import HomeScreen from "../pages/homescreen";

export const formContext = createContext({});

const routes = [
  //auth
  {
    path: "/",
    Component: HomeScreen,
    isProtected: false,
  },
  //auth
  {
    path: "/login",
    Component: Login,
    isProtected: false,
  },

  //verify otp
  {
    path: "/verify",
    Component: Verify,
    isProtected: false,
  },

  //create company
  {
    path: "/signup",
    Component: SignupCompany,
    isProtected: true,
  },

  //contacts details
  {
    path: `/contacts/:companyId`,
    Component: AddContacts,
    isProtected: true,
  },

  //settings
  {
    path: "/settings/:companyId",
    Component: Settings,
    isProtected: true,
  },

  //dashboard
  {
    path: "/dashboard",
    Component: Dashboard,
    isProtected: true,
  },

  //dashboard payments
  {
    path: "/Payments",
    Component: Payments,
    isProtected: true,
    role: "Approving Accountant",
  },

  //applications
  {
    path: "/form220/:applicationID",
    Component: Form220,
    isProtected: true,
    role: "applicant",
  },
  {
    path: "/form210/:applicationID",
    Component: Form210,
    isProtected: true,
    role: "applicant",
  },
  {
    path: "/assignContacts/:applicationID",
    Component: AssignContacts,
    isProtected: true,
  },
  //applicant review
  {
    path: "/applicantView/:applicationID",
    Component: ApplicantView,
    isProtected: true,
  },

  //application review
  {
    path: "/applicationReview/:applicationID",
    Component: ApplicationReview,
    isProtected: true,
  },

  //application payment
  {
    path: "/applicationPayment/:applicationID",
    Component: ApplicationPayment,
    isProtected: true,
  },
  {
    path: "/compiledPayment/:applicationID",
    Component: CompiledPayment,
    isProtected: true,
  },

  //engineering review
  {
    path: "/review/:applicationID",
    Component: EngineeringReview,
    isProtected: true,
  },

  //draft agreement
  {
    path: "/draftAgreement/:applicationId",
    Component: DraftAggrement,
    isProtected: true,
  },

  //compiled agreement
  {
    path: "/compiledAgreement/:applicationID",
    Component: CompiledAgreement,
    isProtected: true,
  },

  //final release
  {
    path: "/finalRelease/:applicationId",
    Component: FinalRelease,
    isProtected: true,
  },

  //webhooks
  {
    path: "/applicationPaymentWaiting/:applicationId",
    Component: ApplicationPaymentWaiting,
    isProtected: true,
  },
  {
    path: "/applicationSignatureWaiting/:applicationId",
    Component: ApplicationSignatureWaiting,
    isProtected: true,
  },
  {
    path: "/agreementPaymentWaiting/:applicationId",
    Component: AgreementPaymentWaiting,
    isProtected: true,
  },
  {
    path: "/agreementSignatureWaiting/:applicationId",
    Component: AgreementSignatureWaiting,
    isProtected: true,
  },
];

export const Routes = () => {
  const history = useHistory();

  useEffect(() => {
    verifyUser(window.location, history);
    return history.listen((location) => {
      verifyUser(location, history);
    });
  }, [history]);

  return (
    <div className="vh-100">
      <div className="canvas h-100">
        <User />
        <Switch>
          {routes.map(({ path, Component, isProtected, role }) => {
            if (isProtected) {
              return <ProtectedRoute key={path} path={path} Component={Component} role={role} />;
            }

            return <Route key={path} component={Component} path={path} exact />;
          })}
          <Route component={NotFound} />
        </Switch>
      </div>
    </div>
  );
};
