import DashBoardLoading from "../components/loaders/DashBoardLoading";
import { AssignContactsPage } from "../components/assignContacts/AssignContactsPage";
import { useApplication } from "../hooks/useApplication";
import { useUser } from "../hooks/useUser";

const Page = () => {
  const application = useApplication();
  const user = useUser();
  if (!application || !user) {
    return <DashBoardLoading />;
  }

  return <AssignContactsPage />;
};

export default Page;
