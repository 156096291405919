import { useEffect, useState } from "react";
import logo from "../../images/logo.png";

import qs from "query-string";
import { useApplication, useApplicationRefetch } from "../../hooks/useApplication";
import { putNextStatus, validatePayment } from "../../api/currentApplications";
import { useHistory, useParams } from "react-router-dom";
import { useAtoms } from "../../hooks/useAtoms";
import DashBoardLoading from "../loaders/DashBoardLoading";
const stage = {
  loading: "LOADING",
  waiting: "WAITITNG",
  success: "SUCCESS",
};

export const ApplicationPayment = () => {
  let queryString = qs.parse(window.location.search);
  const [state, setState] = useState(stage.loading);
  const history = useHistory();
  const params = useParams();
  const application = useApplication();
  const refetchApplication = useApplicationRefetch();
  const { setNavigationContent } = useAtoms();

  const validate = async (applicationId, applicationStage, success, token, history) => {
    if (applicationStage === 1 && success && token) {
      setState(stage.waiting);
      await validatePayment(applicationId, token);
      await putNextStatus(applicationId, 1);
      history.push(`/applicationPaymentWaiting/${applicationId}?success=true&validated=true`);
    }
  };

  useEffect(() => {
    let timeout;
    if (application) {
      const { applicationStage } = application;
      const { success, token, validated } = queryString;
      const { applicationId } = params;
      validate(applicationId, applicationStage, success, token, history);

      if (success && validated) {
        setState(stage.success);
        setNavigationContent(true);
        timeout = setTimeout(() => {
          refetchApplication();
          history.push("/dashboard");
        }, 5000);
      } else {
        setNavigationContent(false);
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [application, queryString, params, history]);

  if (!application || state === stage.loading) {
    return <DashBoardLoading />;
  }

  return (
    <div className="bg-img h-100 d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
      <div className="container h-100">
        <div className="row align-items-center h-100">
          <div className="col-md-6 offset-md-3">
            <div className="card border-0">
              <div className="card-header bg-dark text-center">
                <img src={logo} alt="NCRR" style={{ width: "252px" }} />
              </div>
              <div className="card-body pt-1">
                {state === stage.waiting && (
                  <div className="text-center  p-1">
                    <div className=" step--complete step--active text-center pt-2 mt-2 pb-0" style={{ cursor: "default" }}>
                      <span className="step__icon steps-1 mx-auto bg-primary border-primary">
                        <i className="fas fa-spinner fs-1 fa-pulse"></i>
                      </span>
                      <br />
                    </div>
                    <div className="card-body pt-0 pb-0">
                      <h2 className="h1 mb-2 pb-2 d-block">Verifying the payment. Please wait...</h2>
                    </div>
                  </div>
                )}
                {state === stage.success && (
                  <div className="text-center  p-1">
                    <div className=" step--complete step--active text-center pt-2 mt-2 pb-0" style={{ cursor: "default" }}>
                      <span className="step__icon steps-1 mx-auto">
                        <i className="fas fa-check fs-1 "></i>
                      </span>
                      <br />
                    </div>
                    <div className="card-body pt-0 pb-0">
                      <h2 className="h1 mb-2 pb-2 d-block">Payment has been submitted successfully and is awaiting authorization from accounting.</h2>
                      <span className="fs-6 text-muted">No further actions required. You can close this window.</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
