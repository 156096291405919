import { useHideNavigation, useNavigation, useNavigationContent, useSetHideNavigation, useSetNavigation, useSetNavigationContent } from "../atoms/navigation";
import { useOnGoingApplication, useSetOnGoingApplication } from "../atoms/application";
import { useAgreementsApplication, useSetAgreementsApplication } from "../atoms/agreement";
import { useDashboard, useSetDashboard } from "../atoms/dashboard";
import { useShowHeader, useSetShowHeader } from "../atoms/header";
import { useCheckedState, useSetCheckedState } from "../atoms/checkedState";

export const useAtoms = () => {
  const navigation = useNavigation();
  const setNavigationContent = useSetNavigationContent();
  const navigationContent = useNavigationContent();
  const hideNavigation = useHideNavigation();
  const setHideNavigation = useSetHideNavigation();
  const onGoingApplication = useOnGoingApplication();
  const setOnGoingApplication = useSetOnGoingApplication();
  const agreementsApplication = useAgreementsApplication();
  const setAgreementsApplication = useSetAgreementsApplication();
  const setDashboard = useSetDashboard();
  const dashboard = useDashboard();
  const showHeader = useShowHeader();
  const setShowHeader = useSetShowHeader();
  const checkedState = useCheckedState();
  const setCheckedState = useSetCheckedState();
  const setNavigation = useSetNavigation();
  return {
    navigation,
    setNavigationContent,
    navigationContent,
    hideNavigation,
    setHideNavigation,
    onGoingApplication,
    setOnGoingApplication,
    agreementsApplication,
    setAgreementsApplication,
    setDashboard,
    dashboard,
    showHeader,
    checkedState,
    setCheckedState,
    setNavigation,
    setShowHeader,
  };
};
