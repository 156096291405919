import React from "react";
import { Modal } from "react-bootstrap";

export const Model = ({ children, heading, discription, id = "model", showModal, closeModal = () => {} }) => {
  return (
    <Modal size="lg" show={showModal} onHide={() => closeModal()} aria-labelledby={id}>
      <Modal.Header closeButton>
        <h3 className="m-0">{heading}</h3>
      </Modal.Header>
      {discription && (
        <div className="modal-header pt-0">
          <p className="text-dark mb-0">
            <i className="far fa-hand-point-right"></i>
            {discription}
          </p>
        </div>
      )}
      {children}
    </Modal>
  );
};

export const ModelFooter = ({ children }) => {
  return <Modal.Footer>{children}</Modal.Footer>;
};

export const ModelBody = ({ children }) => {
  return <Modal.Body>{children}</Modal.Body>;
};
