import { PAYMENT_LINK, REACT_APP_NCRR_Application_fee_original, REACT_APP_HNTB_Engineering_review_original, REACT_APP_NS_initial_fee_original, REACT_APP_NS_risk_finance_fee_original } from "../api";

const productionApplication = [];

const createCsvData = (data = []) => {
  const csvData = [
    [
      "APPLICATION NUMABER",
      "APPLICANT",
      "HNTB FILE#",
      "APPLICATION (PAYMENT METHOD)",
      "APPLICATION (PAYMENT AMOUNT)",
      "APPLICATION (DATE OF PAYMENT)",
      "APPLICATION (PAYMENT LINK / ACH TRANSACTION NUMBER)",
      "APPLICATION (ACCOUNTANT COMMENT)",
      "AGREEMENT (PAYMENT METHOD)",
      "AGREEMENT (PAYMENT AMOUNT)",
      "AGREEMENT (DATE OF PAYMENT)",
      "AGREEMENT (PAYMENT LINK / ACH TRANSACTION NUMBER)",
      "AGREEMENT (ACCOUNTANT COMMENT)",
      "CURRENT STATUS",
    ],
    ...data.map((each) => {
      const event1StripeResponseArray = each?.payment?.event1StripeResponse || [];
      let event1StripeResponse = event1StripeResponseArray.find((each) => each.payment_status === "paid" && each.payment_intent !== null) || {};
      if (Object.keys(event1StripeResponse).length === 0 && event1StripeResponseArray.length > 0) event1StripeResponse = event1StripeResponseArray[event1StripeResponseArray.length - 1];
      const event1payment_intent = event1StripeResponse?.payment_intent || "";
      let event1paymentType = each?.event1paymentType;
      if (!event1paymentType) {
        if (each.applicationStage >= 6 && event1payment_intent) event1paymentType = "Stripe";
        else event1paymentType = "-";
      }

      let event1paymentAmount = `$${Number(REACT_APP_HNTB_Engineering_review_original) + Number(REACT_APP_NCRR_Application_fee_original)}`;
      if (event1paymentType === "-") event1paymentAmount = "-";

      let event1paymentDate;
      try {
        event1paymentDate = each?.event1paymentOn?.toString()?.slice(0, 10) || "-";
      } catch (err) {
        event1paymentDate = "-";
      }

      let event1paymentLinkOrAchNumber;
      if (event1paymentType === "Stripe") event1paymentLinkOrAchNumber = PAYMENT_LINK + event1payment_intent;
      else if (event1paymentType === "ACH" && each.event1transactionNumber) event1paymentLinkOrAchNumber = each.event1transactionNumber;
      else event1paymentLinkOrAchNumber = "-";

      let event1AccountantComment = "-";
      if (each.event1transactionId) event1AccountantComment = each.event1transactionId;

      //Second Payment Details
      const event2StripeResponseArray = each?.payment?.event2StripeResponse || [];
      let event2StripeResponse = event2StripeResponseArray.find((each) => each.payment_status === "paid" && each.payment_intent !== null) || {};
      if (Object.keys(event2StripeResponse).length === 0 && event2StripeResponseArray.length > 0) event2StripeResponse = event2StripeResponseArray[event2StripeResponseArray.length - 1];
      const event2payment_intent = event2StripeResponse?.payment_intent || "";

      let event2paymentType = each?.event2paymentType;
      if (!event2paymentType) {
        if (each.applicationStage >= 17 && event2payment_intent) event2paymentType = "Stripe";
        else event2paymentType = "-";
      }

      let event2paymentAmount = "-";
      if (each.applicationStage >= 17 && each.applicationStage !== 26) {
        let agreement = each?.agreement;
        let riskFeeOriginal = 0;
        let license_fee = 0;
        if (agreement?.compiled && agreement?.compiled.length) {
          const signAndPaid = agreement?.compiled?.find((each) => each.action === "Sign and Paid" && each.role === "applicant") || {};
          if (signAndPaid.riskFinanceFee === "true") riskFeeOriginal = Number(REACT_APP_NS_risk_finance_fee_original);
        }
        if (agreement?.draft && agreement?.draft.length) {
          const Upload_and_Send_to_NCRR = agreement?.draft?.find((each) => each.action === "Upload and Send to NCRR" && each.role === "Engineering Representative") || {};
          if (Upload_and_Send_to_NCRR) {
            let formData = Upload_and_Send_to_NCRR?.formData || [];
            let { license_fee: license_fee2 } = (formData && formData[1]) || [];
            let licenseFee_array = Upload_and_Send_to_NCRR?.licenseFee || {};
            let { license_fee: license_fee1 } = (licenseFee_array && licenseFee_array[0]) || "";
            license_fee = license_fee2 || license_fee1;
          }
        }

        let compiledPaymentFeeOriginal = Number(REACT_APP_NS_initial_fee_original) + Number(license_fee) + Number(riskFeeOriginal);
        event2paymentAmount = "$" + compiledPaymentFeeOriginal;
      }

      let event2paymentDate = "-";
      if (each?.event2paymentOn) {
        try {
          event2paymentDate = each?.event2paymentOn?.toString()?.slice(0, 10) || "-";
        } catch (err) {
          event2paymentDate = "-";
        }
      }

      let event2paymentLinkOrAchNumber;
      if (event2paymentType === "Stripe") {
        event2paymentLinkOrAchNumber = PAYMENT_LINK + event2payment_intent;
      } else if (event2paymentType === "ACH" && each.event2transactionNumber) {
        event2paymentLinkOrAchNumber = each.event2transactionNumber;
      } else {
        event2paymentLinkOrAchNumber = "-";
      }

      let event2AccountantComment = "-";
      if (each.event2transactionId) event2AccountantComment = each.event2transactionId;
      const getCurrentStage = (stage) => {
        if (stage === 1) return "Payment - Application Payment";
        if (stage === 2) return "Applicant Review - Initial Review";
        if (stage === 3) return "Application Review - Initial Review";
        if (stage === 4) return "Engineering Review";
        if (stage === 5) return "Engineering Review";
        if (stage === 6) return "Engineering Review";
        if (stage === 7) return "Draft Agreement - Signature";
        if (stage === 8) return "Draft Agreement - Assign Signee";
        if (stage === 9) return "Draft Agreement - Validate Signature";
        if (stage === 10) return "Draft Agreement - Agreement Generation";
        if (stage === 11) return "Draft Agreement - NCRR Review";
        if (stage === 12) return "Draft Agreement - NS Review";
        if (stage === 13) return "Draft Agreement - HNTB Review";
        if (stage === 14) return "Compile Agreement - License Agreement";
        if (stage === 15) return "Compile Agreement - Upload Insurance";
        if (stage === 16) return "Compile Agreement - Validate Uploaded Insurance";
        if (stage === 17) return "Compile Agreement - Payment";
        if (stage === 18) return "Compile Agreement - Signature";
        if (stage === 19) return "Compile Agreement - Assign Signee";
        if (stage === 20) return "Compile Agreement - Validate Fee, Insurance and Agreement";
        if (stage === 21) return "Compile Agreement - Validate and Sign By V.P";
        if (stage === 22) return "Compile Agreement - Upload Letter and Validate";
        if (stage === 23) return "Compile Agreement - Validate and Sign By Operating Signee";
        if (stage === 24) return "Compile Agreement - Final Release";
        if (stage === 25) return "Final Release";
        if (stage === 26) return "Payment - Validate Application Payment";
        else return "-";
      };

      //for Old Applications which have made Payments
      if (event1paymentType === "-" && event1paymentLinkOrAchNumber === "-" && each.applicationStage >= 6 && event1payment_intent !== "") {
        event1paymentType = "Stripe";
        event1paymentLinkOrAchNumber = PAYMENT_LINK + event1payment_intent;
      }
      if (event2paymentType === "-" && event2paymentLinkOrAchNumber === "-" && each.applicationStage >= 17 && each.applicationStage !== 26 && event2payment_intent !== "") {
        event2paymentType = "Stripe";
        event2paymentLinkOrAchNumber = PAYMENT_LINK + event2payment_intent;
      }

      return [
        each?.ApplicationTitle || "-",
        each?.applicantDetails?.facilityCompany?.name || "-",
        each?.HNTBFileNumber || "-",
        event1paymentType,
        event1paymentAmount,
        event1paymentDate,
        event1paymentLinkOrAchNumber,
        event1AccountantComment,
        event2paymentType,
        event2paymentAmount,
        event2paymentDate,
        event2paymentLinkOrAchNumber,
        event2AccountantComment,
        getCurrentStage(each.applicationStage),
      ];
    }),
  ];
  return csvData;
};

export const createJustinData = () => {
  const data = productionApplication.filter((each) => each.applicationStage === 25);
  const application2023 = data.filter((each) => {
    let finalRelease = {};
    each.agreement.compiled.forEach((each) => {
      if (each.action === "Send" && each.role === "Engineering Representative") {
        finalRelease = each;
      }
    });
    const date = new Date(finalRelease.date);
    if (date.getFullYear() >= 2023) {
      return true;
    }
    return false;
  });
  const csvData = [
    ["APPLICATION NUMBER", "HNTB FILE#", "AGREEMENTED DATE"],
    ...application2023.map((each) => {
      const finalRelease = each.agreement.compiled.find((each) => {
        if (each.action === "Send" && each.role === "Engineering Representative") {
          return true;
        }
        return false;
      });

      const agreementedOn = new Date(finalRelease.date).toDateString();
      const agreementedYear = new Date(finalRelease.date).getFullYear();
      return [each?.ApplicationTitle || "-", each?.HNTBFileNumber || "-", agreementedOn, agreementedYear];
    }),
  ];
  return csvData;
};

export const createApplicantsApplication = (applications) => {
  const csvData = [
    ["APPLICATION NUMBER", "HNTB FILE#", "ACTIVITY NUMBER", "APPLICATION TYPE", "APPLICANT", "PRIMARY CONTACT", "DATE SUBMITTED", "LAST STAGE UPDATED", "CURRENT STATUS"],
    ...applications.map((each) => {
      const getCurrentStage = (stage) => {
        if (stage === 1) return "Payment - Application Payment";
        if (stage === 2) return "Applicant Review - Initial Review";
        if (stage === 3) return "Application Review - Initial Review";
        if (stage === 4) return "Engineering Review";
        if (stage === 5) return "Engineering Review";
        if (stage === 6) return "Engineering Review";
        if (stage === 7) return "Draft Agreement - Signature";
        if (stage === 8) return "Draft Agreement - Assign Signee";
        if (stage === 9) return "Draft Agreement - Validate Signature";
        if (stage === 10) return "Draft Agreement - Agreement Generation";
        if (stage === 11) return "Draft Agreement - NCRR Review";
        if (stage === 12) return "Draft Agreement - NS Review";
        if (stage === 13) return "Draft Agreement - HNTB Review";
        if (stage === 14) return "Compile Agreement - License Agreement";
        if (stage === 15) return "Compile Agreement - Upload Insurance";
        if (stage === 16) return "Compile Agreement - Validate Uploaded Insurance";
        if (stage === 17) return "Compile Agreement - Payment";
        if (stage === 18) return "Compile Agreement - Signature";
        if (stage === 19) return "Compile Agreement - Assign Signee";
        if (stage === 20) return "Compile Agreement - Validate Fee, Insurance and Agreement";
        if (stage === 21) return "Compile Agreement - Validate and Sign By V.P";
        if (stage === 22) return "Compile Agreement - Upload Letter and Validate";
        if (stage === 23) return "Compile Agreement - Validate and Sign By Operating Signee";
        if (stage === 24) return "Compile Agreement - Final Release";
        if (stage === 25) return "Final Release";
        if (stage === 26) return "Payment - Validate Application Payment";
        else return "-";
      };

      const activityNumberObject = each?.agreement?.draft?.find((each) => each.action === "Send to HNTB" && each.role === "Operating Representative");
      const activityNumber = activityNumberObject ? activityNumberObject?.formData[0] : "-" || "-";
      const laststageUpdatedLength = each?.laststageUpdated?.length || 0;
      const laststageUpdated = laststageUpdatedLength > 0 ? each?.laststageUpdated[laststageUpdatedLength - 1]?.date : "-" || "-";
      return [
        each?.ApplicationTitle || "-",
        each?.HNTBFileNumber || "-",
        activityNumber || "-",
        each?.formName || "-",
        each?.applicantDetails?.facilityCompany?.name || "-",
        each?.applicantDetails?.primaryContact?.name || "-",
        each?.submittedOn || "-",
        laststageUpdated,
        getCurrentStage(each.applicationStage),
      ];
    }),
  ];
  return csvData;
};
export default createCsvData;
