import DashBoardLoading from "../components/loaders/DashBoardLoading";
import ApplicationStatic from "../components/engineeringReview/ApplicationStatic";
import { useApplication } from "../hooks/useApplication";
import { useUser } from "../hooks/useUser";

const Page = (props) => {
  const application = useApplication();
  const user = useUser();
  if (!application || !user) {
    return <DashBoardLoading />;
  }
  return <ApplicationStatic {...props} />;
};

export default Page;
