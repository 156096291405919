import React from "react";
import logo from "../images/logo.png";
import { useAtoms } from "../hooks/useAtoms";
const Page = () => {
  const { setNavigationContent } = useAtoms();
  setNavigationContent(false);
  return (
    <div className="bg-img h-100 d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
      <div className="container h-100">
        <div className="row align-items-center h-100">
          <div className="col-md-6 offset-md-3">
            <div className="card border-0">
              <div className="card-header bg-dark text-center">
                <img src={logo} alt="NCRR" style={{ width: "252px" }} />
              </div>
              <div className="card-body pt-1">
                <div className="text-center  p-1">
                  <div className="step--complete step--active text-center pt-2 mt-2 pb-0" style={{ cursor: "default" }}>
                    <span className="step__icon steps-1 mx-auto" style={{ backgroundColor: "red", borderColor: "red" }}>
                      <i className="fas fa-exclamation-triangle fs-1 "></i>
                    </span>
                    <br />
                  </div>
                  <div className="card-body pt-0 pb-0">
                    <h2 className="h1 mb-2 pb-2 d-block">404 - Page Not Found</h2>
                    <span className="fs-6 text-muted">The page you are looking for does not exist.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
