import React from "react";
import { useHistory } from "react-router-dom";
export default function AggrementListViewCard(props) {
  let { v } = props || {};
  let { draft } = v.agreement || {};
  let { formData: formData1 } = (draft && draft[2]) || {};
  let activity_number = (formData1 && formData1[0]) || "";
  let history = useHistory();
  activity_number = activity_number.toString();
  return (
    <div style={{ cursor: "pointer" }}>
      <div
        className="row position-relative"
        onClick={() => {
          history.push(`/finalRelease/${v._id}`);
        }}
      >
        <div className="col-md-2">
          <small className="text-muted d-md-none d-block">Utility Application</small>
          <p>#{(v.ApplicationTitle && v.ApplicationTitle) || v._id}</p>
        </div>
        <div className="col-md-2">
          <small className="text-muted d-md-none d-block">HNTB FILE#</small>
          <p>{v.HNTBFileNumber}</p>
        </div>
        <div className="col-md-2">
          <small className="text-muted d-md-none d-block">Activity Number</small>
          <p>{activity_number ? activity_number : ""}</p>
        </div>
        <div className="col-md-2">
          <small className="text-muted d-md-none d-block">Application</small>
          <p>{v.applicantDetails && v?.applicantDetails?.facilityCompany?.name}</p>
        </div>

        <div className="col-md-2">
          <small className="text-muted d-md-none d-block">Applicant</small>
          <p>{v.applicantDetails && v?.applicantDetails.consultantContact?.name}</p>
        </div>
        <div className="col-md-2">
          <small className="text-muted d-md-none d-block">Proposed work involves</small>
          <p>Completed</p>
        </div>
        <hr className="mx-0"></hr>
      </div>
    </div>
  );
}
