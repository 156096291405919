import React from "react";

export default function AssignContactLoading() {
  return (
    <div className="container py-4">
      <div className="row">
        <div className="col-lg-10 m-auto loading">
          <div id="files-upload" className="">
            <div className="card mb-4 shadow ">
              <div className="card-header  border-bottom">
                <div className="loading">
                  <div className="line"></div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Upload Draft Agreement</h2>
                    <small className="text-muted">For HNTB</small>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="mb-3">
                  <div className="loading">
                    <div className="line"></div>
                    <label className="text-muted d-block">Upload Agreement</label>

                    <div className="input-group">
                      <input accept=".pdf" type="file" className="form-control" id="inputGroupFile02" />
                      <label className="input-group-text bg-secondary" for="inputGroupFile02">
                        Upload
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="loading">
                    <div className="line"></div>
                    <label className="text-muted d-block">Upload Agreement</label>

                    <div className="input-group">
                      <input accept=".pdf" type="file" className="form-control" id="inputGroupFile02" />
                      <label className="input-group-text bg-secondary" for="inputGroupFile02">
                        Upload
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="loading">
                    <div className="line"></div>
                    <label className="text-muted d-block">Upload Agreement</label>

                    <div className="input-group">
                      <input accept=".pdf" type="file" className="form-control" id="inputGroupFile02" />
                      <label className="input-group-text bg-secondary" for="inputGroupFile02">
                        Upload
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="loading">
                    <div className="line"></div>
                    <label className="text-muted d-block">Upload Agreement</label>

                    <div className="input-group">
                      <input accept=".pdf" type="file" className="form-control" id="inputGroupFile02" />
                      <label className="input-group-text bg-secondary" for="inputGroupFile02">
                        Upload
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-col text-center d-flex justify-content-between mt-4 loading">
                <div className="line"></div>
                <button className="btn btn-primary btn-lg">Save and Proceed</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
