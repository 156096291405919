import React from "react";
import { getNestedProperty } from "../../utils/getObjectDetails";

const Checkbox = ({ netSuiteContact, handleCheckbox, contact, name, protalValue }) => {
  let netSuiteValue = getNestedProperty(netSuiteContact, `${contact}.${name}`);

  if (netSuiteValue && netSuiteValue.includes(".0")) {
    netSuiteValue = netSuiteValue.replace(".0", "");
  }

  if (typeof protalValue === "number") {
    protalValue = protalValue.toString();
  }

  return netSuiteValue === protalValue ? null : (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        id={contact + "." + name}
        value={getNestedProperty(netSuiteContact, `${contact}.${name + "checkbox"}`)}
        checked={getNestedProperty(netSuiteContact, `${contact}.${name + "checkbox"}`) || false}
        name={contact + "." + name}
        onChange={() => handleCheckbox(contact, name)}
      />
      <label className="form-check-label" htmlFor={contact + "." + name}>
        {getNestedProperty(netSuiteContact, `${contact}.${name}`) ? "Update" : "Add"}
      </label>
    </div>
  );
};

export const CheckboxForContacts = ({ netSuiteContact, handleCheckbox, name }) => {
  return (
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id={name} value={netSuiteContact?.[name]} checked={netSuiteContact?.[name] || false} name={name} onChange={() => handleCheckbox(name)} />
      <label class="form-check-label" for={name}>
        Add All
      </label>
    </div>
  );
};

export default Checkbox;
