import ApplicationPaymentsLoading from "../components/loaders/ApplicationPaymentsLoading";
import { ApplicationPayment } from "../components/webhooks/ApplicationPayment";
import { useApplication } from "../hooks/useApplication";
import { useUser } from "../hooks/useUser";

const Page = () => {
  const application = useApplication();
  const user = useUser();
  if (!application || !user) {
    return <ApplicationPaymentsLoading />;
  }
  return <ApplicationPayment />;
};

export default Page;
