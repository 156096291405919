import axios from "axios";
const URL = process.env.REACT_APP_API_URL;

const sendotp = (email) => {
  return axios.post(`${URL}/auth/sendOtp`, {
    email,
  });
};

const login = (email, otp) => {
  return axios.post(`${URL}/auth/login`, {
    email,
    otp,
  });
};

export { sendotp, login };
