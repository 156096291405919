import React from "react";

export default function UploadDraftLoading() {
  return (
    <div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div id="files-upload" className="">
              <div className="card mb-4 shadow ">
                <div className="card-header  border-bottom">
                  <div className="loading">
                    <div className="line"></div>
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Upload Draft Agreement</h2>
                      <small className="text-muted">For HNTB</small>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="mb-3">
                    <div className="loading">
                      <div className="line"></div>
                      <label className="text-muted d-block">Upload Agreement</label>

                      <div className="input-group">
                        <input accept=".pdf" type="file" className="form-control" id="inputGroupFile02" />
                        <label className="input-group-text bg-secondary" for="inputGroupFile02">
                          Upload
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="loading">
                      <div className="line"></div>
                      <label className="text-muted d-block">Enter License Fee</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
                <div className="card-footer justify-content-center loading">
                  <div className="line"></div>
                  <a href="agreement-review-activenumber.html" className="d-block text-white">
                    Upload and Send to Norfolk Southern <i className="fal fa-chevron-right ms-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
