import React, { useEffect, useState } from "react";
import { SelectRole } from "../components/companyRole/SelectRole";
import { FacilitySignup } from "../components/facility/FacilitySignup";
import { ConsultantSignup } from "../components/consultant/ConsultantSignup";
import { useAtoms } from "../hooks/useAtoms";
import { useLocation } from "react-router-dom";

const RoleType = {
  facility: "FACILITY",
  consoltant: "CONSULTANT",
};

const Page = () => {
  const [companyRole, setCompanyRole] = useState(null);
  const { setNavigationContent } = useAtoms();
  const location = useLocation();

  // Parse the query parameters from the location object
  const queryParams = new URLSearchParams(location.search);
  const role = queryParams.get("role");
  const applicationID = queryParams.get("applicationID");
  const form = queryParams.get("form");

  useEffect(() => {
    if (role === "FACILITY") {
      setCompanyRole(RoleType.facility);
    }
  }, [applicationID, role, form]);

  setNavigationContent(false);
  return (
    <div>
      {!companyRole && <SelectRole setCompanyRole={setCompanyRole} />}

      {companyRole === RoleType.facility && <FacilitySignup />}

      {companyRole === RoleType.consoltant && <ConsultantSignup />}
    </div>
  );
};

export default Page;
