import React from "react";

const Number = ({ data, handleNetSuiteChange, recordName, col, value, stage }) => {
  if ((stage = 10)) {
    var disablevalue = data.disabled ? data.disabled : false;
  } else {
    var disablevalue = data.disabled || data.validateFinalHideField || data.validateHideField ? data.disabled || data.validateFinalHideField || data.validateHideField : false;
  }
  return (
    <>
      {data.type === "number" && (
        <div className="mb-3">
          <label forhtml={data.name} className="text-muted form-label">
            {data.label}
          </label>

          <input
            type={data.type}
            className="form-control text-dark"
            name={data.name}
            placeholder={data.placeholder}
            onChange={(e) => handleNetSuiteChange(data, e, recordName, col)}
            id={data.name}
            value={value}
            disabled={disablevalue}
          />
        </div>
      )}
    </>
  );
};

export default Number;
