import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { createStore } from "redux";
import allReducer from "../redux/reducers";
import { Provider } from "react-redux";
import ErrorBoundary from "../errors/ErrorBoundary";
import { Toaster } from "react-hot-toast";
import "../css/font.css";
import "../css/styles.css";
import "../css/custom.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import GetmyRole from "../utils/GetmyRole";

const store = createStore(allReducer);

const Providers = ({ children }) => {
  const customer = GetmyRole();
  const currentUrl = window.location.href;

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
  return (
    <Provider store={store}>
      <RecoilRoot>
        <BrowserRouter>
          <ErrorBoundary customer={customer} url={currentUrl}>
            <Toaster position="bottom-right" />
            {children}
          </ErrorBoundary>
        </BrowserRouter>
      </RecoilRoot>
    </Provider>
  );
};

export default Providers;
