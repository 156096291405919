import React, { useRef } from "react";

export default function Text1({
  label = "",
  name = "",
  name1 = "",
  type = "text",
  defaultValue = "",
  placeholder = "",
  css = {},
  validation,
  register,
  errors,
  handleChange,
  id,
  fieldLabel = "",
  fieldLabelPosition = "",
  fieldType = "",
  comments = "",
  commentsIconIsRequired = false,
  showOnChange = [],
  commentsStyle = {},
  condtionalLabel = "",
  disabled = false,
  parallelChange = () => {},
  setSchema = () => {},
  requiredLable = false,
}) {
  const lastSec = useRef();
  lastSec.current = [];
  const eventHandle = (e, name) => {
    parallelChange(e, name);
  };
  return (
    <div>
      <label className="form-label d-flex justify-content-between">
        {label}
        <small className="text-danger ms-1">{requiredLable ? "*required" : null}</small>
      </label>
      <input
        style={css}
        className="form-control"
        type={"number"}
        step="0.0001"
        defaultValue={defaultValue}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        onChange={(e) => eventHandle(e, name)}
        aria-label="Default"
        aria-describedby="inputGroup-sizing-default"
      />
    </div>
  );
}
