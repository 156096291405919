/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { cancelApplicationApi, getcurrentStatus, getFormData, putNextStatus } from "../../api/currentApplications";
import { updateCustomer, search, createLead, getNesuiteContactDetails } from "../../api/Netsuite";
import { useHistory } from "react-router-dom";
import CommonNavigation from "../commonNavigation/CommonNavigation";
import PromptComponent from "../promptComponent/PromptComponent";
import { debounce } from "../dashboard/Dashboard";
import Checkbox, { CheckboxForContacts } from "../ui/NetSuiteCheckbox";
import { useAtoms } from "../../hooks/useAtoms";
import { useUser } from "../../hooks/useUser";
import { useApplication } from "../../hooks/useApplication";
import { useNetSuiteCustomer } from "../../hooks/useNetSuiteCustomer";

export default function ApplicantView(props) {
  let ItemId = props.match.params.applicationID;
  let history = useHistory();
  const { roleName } = useUser() || {};
  const { applicantDetails, applicationStage, netsuiteContactDetails } = useApplication() || {};
  const { billingContact, facilityCompany, consultantCompany, primaryContact } = applicantDetails || {};

  let consultantContact;

  if (applicantDetails) {
    consultantContact = {
      ...applicantDetails.consultantContact,
      companyName: consultantCompany?.name,
    };
  }

  const pageLimit = 10;
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [netSuiteCustomerUpdateLoading, setNetSuiteCustomerUpdateLoading] = React.useState(false);
  const [netSuiteContactUpdateLoading, setNetSuiteContactUpdateLoading] = React.useState(false);
  const [netsuiteCostumer, setNetsuiteCostumer] = React.useState([]);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [netSuiteContact, setNetSuiteContact] = React.useState({
    cContact: {},
    pContact: {},
    bContact: {},
    eContact: {},
    showCheckBox: false,
    pContactCreateNewShow: false,
    bContactCreateNewShow: false,
    eContactCreateNewShow: false,
    pContactCreateNewShowChecked: false,
    bContactCreateNewShowChecked: false,
    eContactCreateNewShowChecked: false,
  });
  const [netSuiteContactIds, setNetSuiteContactIds] = React.useState(false);

  const [netSuiteContactCopy, setNetSuiteContactCopy] = React.useState({
    cContact: {},
    pContact: {},
    bContact: {},
    eContact: {},
    showCheckBox: false,
    pContactCreateNewShow: false,
    bContactCreateNewShow: false,
    eContactCreateNewShow: false,
    pContactCreateNewShowChecked: false,
    bContactCreateNewShowChecked: false,
    eContactCreateNewShowChecked: false,
  });

  let showCheckBox = netSuiteContact.showCheckBox;

  const { setNavigation, setHideNavigation } = useAtoms();
  setHideNavigation(false);
  const [currentStep, setCurrentStep] = useState("");
  const [laodSubmit, setLoadSubmit] = useState(false);
  const [currentUser, setCurrentUser] = useState(false);
  const [applicationTitle, setApplicationTitle] = React.useState();
  const pagination = React.useRef(null);
  useEffect(async () => {
    let res = await getcurrentStatus(ItemId);
    setCurrentStep(res.data.data.currentStep);
    localStorage.setItem(ItemId + "currentStage", res.data.data.currentStep);
    getFormData(ItemId).then(({ data }) => {
      setApplicationTitle(data.ApplicationTitle);
    });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(async () => {
    const data = await getNesuiteContactDetails({
      applicationId: ItemId,
      worker: "customerAndContact",
    });
    if (data && data.data && data.data.status === "Success" && data.data.data !== null) {
      setNetSuiteContactIds(data?.data?.data?.cContact?.id ? true : false);
      setNetSuiteContact(data.data.data);
    }
  }, []);

  const movetoNext = async () => {
    if (!netSuiteContactIds) {
      // toast.error("")
      alert("Please add the customer before continuing");
      return;
    }
    setLoadSubmit(true);
    let value = await putNextStatus(ItemId, 1, "APPLICANT_VIEW");

    if (value.data) {
      setLoadSubmit(false);
      setCurrentStep(value.data.data.currentStep);
      localStorage.setItem(ItemId + "currentStage", value.data.data.currentStep);
      setCurrentUser(true);
      history.push(`/applicationReview/${ItemId}`);
    }
  };

  setNavigation(`Applicant View ${" "} ${applicationTitle ? applicationTitle : ItemId} `);

  const { searchCustomer, handleCustomerSelect, handleCreateNewLeadClick, handleUpdateCustomerClick, changePage, handleCheckbox, handleCreateNewContactCheckbox } = useNetSuiteCustomer(
    setSearchLoading,
    setNetsuiteCostumer,
    search,
    pageLimit,
    setPageData,
    setNetSuiteCustomerUpdateLoading,
    pagination,
    primaryContact?.email,
    primaryContact?.name,
    consultantContact?.email,
    consultantContact?.name,
    billingContact?.email,
    billingContact?.name,
    setNetSuiteContact,
    netSuiteContact,
    setNetSuiteContactCopy,
    netSuiteContactCopy,
    setNetSuiteContactUpdateLoading,
    ItemId,
    createLead,
    updateCustomer,
    netsuiteCostumer,
    setPage,
    primaryContact,
    billingContact,
    consultantContact,
  );

  const cancelApplication = async () => {
    const isOk = window.confirm("Are You Sure to cancel this application?");
    if (isOk) {
      await cancelApplicationApi(ItemId);
      history.push("/dashboard");
    }
  };

  return (
    <div>
      {!currentUser && <PromptComponent applicationId={ItemId} currentStage={localStorage.getItem(ItemId + "currentStage") && localStorage.getItem(ItemId + "currentStage")} />}

      <div className="pb-4">
        <div className="border-bottom mb-4 bg-white">
          <div className="w-75 mx-auto">
            <div className="">
              <CommonNavigation ItemId={ItemId} />
            </div>
          </div>
        </div>
        <div className={laodSubmit ? "submitting" : null}></div>
        <div className="container mt-4">
          <div className="row applicant-section">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between align-items-center">
                {roleName === "Approving Representative" ? (
                  <>
                    <h1 className="m-0">Work in Progress</h1>
                    <button disabled={!netSuiteContactIds || currentStep > 2} className="btn-primary btn-lg btn d-flex justify-content-between align-items-center py-3" onClick={movetoNext}>
                      <span>Continue </span>
                      <i className="fal fa-chevron-right fs-3  ms-3"></i>
                    </button>
                  </>
                ) : (
                  <>
                    <h1 className="m-0 ">Work in Progress</h1>
                    <div className="d-flex justify-content-between align-items-center">
                      {roleName === "Engineering Representative" && (
                        <button className="btn-danger btn-lg btn d-flex justify-content-between align-items-center py-3" onClick={cancelApplication}>
                          <span>Cancel </span>
                          <i className="fal fa-trash fs-5 ms-3"></i>
                        </button>
                      )}
                      <button disabled className="btn-primary btn-lg btn d-flex justify-content-between align-items-center py-3 ">
                        <span>Continue </span>
                        <i className="fal fa-chevron-right fs-5  ms-3"></i>
                      </button>
                    </div>
                  </>
                )}
              </div>
              <hr className="mx-0" />
              {roleName === "applicant" ? (
                <h2 className="text-center display-3">
                  {currentStep === 2
                    ? "Your Company and Contact details are to be verified.\nOnce verified, we will begin review of your application."
                    : "Your Company and Contact details were verified."}
                </h2>
              ) : (
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="row m-0">
                        <th className="col-5">
                          <h3 className="m-0">Utility Portal Details</h3>
                        </th>
                        <th className="col-2">
                          <h3 className="m-0">Update Netsuite?</h3>
                        </th>
                        <th className="col-5">
                          <div className="d-md-flex justify-content-between align-items-center">
                            <h3 className="m-0">Netsuite Details</h3>
                            <button onClick={handleUpdateCustomerClick} disabled={applicationStage !== 2 || !showCheckBox} className="btn btn-primary px-4 py-2">
                              Sync Customer
                            </button>
                            <button onClick={handleCreateNewLeadClick} disabled={applicationStage !== 2 || netsuiteContactDetails?.cContact?.id} className="btn btn-primary px-4 py-2">
                              Create New
                            </button>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="row m-0">
                        <td colSpan="3" className="p-0">
                          <table className="table m-0">
                            <tbody className="border-0">
                              <tr className="border-bottom-0 row m-0">
                                <td className="col-5 border-bottom-0">
                                  <h4 className="m-0">Applicant Details</h4>
                                </td>
                                <td className="col-2 border-bottom-0">
                                  <h4 className="m-0"></h4>
                                </td>
                                <td className="col-5 border-bottom-0">
                                  <SearchBar
                                    searchCustomer={searchCustomer}
                                    pagination={pagination}
                                    searchLoading={searchLoading}
                                    netsuiteCostumer={netsuiteCostumer}
                                    pageData={pageData}
                                    handleCustomerSelect={handleCustomerSelect}
                                    changePage={changePage}
                                    page={page}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td colSpan="3" className="p-0">
                          <table className="table m-0 border-bottom-0">
                            <tbody className="border-0">
                              <tr className="light-gray row m-0">
                                <td className="col-5 py-1">
                                  <ul className="list-none p-0 row g-0 m-0">
                                    <li className="text-muted col-md-5 text-md-end ">Legal Name</li>
                                    <li className="col-md-7">{facilityCompany?.name}</li>
                                  </ul>
                                </td>
                                <td className="col-2 py-1">
                                  {showCheckBox && (
                                    <ul className="list-none p-0 m-0">
                                      <li>
                                        <div className="form-check p-0">
                                          <i className="fas fa-ban px-1"></i>
                                          <label className="form-check-label text-muted" for="check1">
                                            Not Allowed
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  )}
                                </td>
                                <td className="col-5 py-1">
                                  {netSuiteCustomerUpdateLoading ? (
                                    <div className="d-flex justify-content-center">
                                      <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  ) : (
                                    netSuiteContact?.cContact?.name
                                  )}
                                </td>
                              </tr>
                              <tr className="row m-0">
                                <td className="col-5 py-1">
                                  <ul className="list-none p-0 row g-0 m-0">
                                    <li className="text-muted col-md-5 text-md-end ">Billing</li>
                                    <li className="col-md-7">{facilityCompany?.billingPeriod}</li>
                                  </ul>
                                </td>
                                <td className="col-2 py-1">
                                  {showCheckBox && (
                                    <ul className="list-none p-0 m-0">
                                      <li>
                                        <div className="form-check p-0">
                                          <i className="fas fa-ban px-1"></i>
                                          <label className="form-check-label text-muted" for="check1">
                                            Not Allowed
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  )}
                                </td>
                                <td className="col-5 py-1">
                                  {netSuiteCustomerUpdateLoading ? (
                                    <div className="d-flex justify-content-center">
                                      <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  ) : (
                                    netSuiteContact?.cContact?.billingPeriod
                                  )}
                                </td>
                              </tr>
                              <tr className="light-gray row m-0">
                                <td className="col-5 py-1">
                                  <ul className="list-none p-0 row g-0 m-0">
                                    <li className="text-muted col-md-5 text-md-end ">Applicant is a</li>
                                    <li className="col-md-7 px-3">{facilityCompany?.applicantshipType}</li>
                                  </ul>
                                </td>
                                <td className="col-2 py-1">
                                  {showCheckBox && (
                                    <ul className="list-none p-0 m-0">
                                      <li>
                                        <div className="form-check p-0">
                                          <i className="fas fa-ban px-1"></i>
                                          <label className="form-check-label text-muted" for="check1">
                                            Not Allowed
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  )}
                                </td>
                                <td className="col-5 py-1">
                                  {netSuiteCustomerUpdateLoading ? (
                                    <div className="d-flex justify-content-center">
                                      <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  ) : (
                                    netSuiteContact?.cContact?.applicantshipType
                                  )}
                                </td>
                              </tr>

                              <tr className="row m-0">
                                <td className="col-5 py-1 border-bottom-0">
                                  <ul className="list-none p-0 row g-0 m-0">
                                    <li className="text-muted col-md-5  text-md-end ">State of formation</li>
                                    <li className="col-md-7">{facilityCompany?.stateOfFormation}</li>
                                  </ul>
                                </td>
                                <td className="col-2 py-1 border-bottom-0"></td>
                                <td className="col-5 py-1 border-bottom-0">
                                  {netSuiteCustomerUpdateLoading ? (
                                    <div className="d-flex justify-content-center">
                                      <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  ) : (
                                    netSuiteContact?.cContact?.stateOfFormation
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr className="border-0 row m-0">
                        <td colSpan="3" className="p-0  border-top-0">
                          <table className="table m-0">
                            <thead>
                              <tr className="border-bottom-0 row m-0">
                                <td className="col-5 border-bottom-0 border-top-0 pt-5">
                                  <h4 className="m-0 ">Street Address</h4>
                                </td>
                                <td className="col-2 border-bottom-0 border-top-0 pt-5">
                                  <h4 className="m-0"></h4>
                                </td>
                                <td className="col-5 border-bottom-0 border-top-0 pt-5"></td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="light-gray row m-0">
                                <td className="col-5 py-1">
                                  <ul className="list-none p-0 row g-0 m-0">
                                    <li className="text-muted col-md-5 text-md-end ">Street Address</li>
                                    <li className="col-md-7">{facilityCompany?.billingAddress?.address}</li>
                                  </ul>
                                </td>
                                <td className="col-2 py-1">
                                  {showCheckBox && (
                                    <ul className="list-none p-0 m-0">
                                      <li>
                                        <div className="form-check p-0">
                                          <i className="fas fa-ban px-1"></i>
                                          <label className="form-check-label text-muted" for="check1">
                                            Not Allowed
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  )}
                                </td>
                                <td className="col-5 py-1">
                                  {netSuiteCustomerUpdateLoading ? (
                                    <div className="d-flex justify-content-center">
                                      <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  ) : (
                                    netSuiteContact?.cContact?.billingAddress?.address
                                  )}
                                </td>
                              </tr>
                              <tr className="row m-0">
                                <td className="col-5 py-1">
                                  <ul className="list-none p-0 row g-0 m-0">
                                    <li className="text-muted col-md-5 text-md-end ">City</li>
                                    <li className="col-md-7">{facilityCompany?.billingAddress?.city}</li>
                                  </ul>
                                </td>
                                <td className="col-2 py-1">
                                  {showCheckBox && (
                                    <ul className="list-none p-0 m-0">
                                      <li>
                                        <div className="form-check p-0">
                                          <i className="fas fa-ban px-1"></i>
                                          <label className="form-check-label text-muted" for="check1">
                                            Not Allowed
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  )}
                                </td>
                                <td className="col-5 py-1">
                                  {netSuiteCustomerUpdateLoading ? (
                                    <div className="d-flex justify-content-center">
                                      <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  ) : (
                                    netSuiteContact?.cContact?.billingAddress?.city
                                  )}
                                </td>
                              </tr>
                              <tr className="light-gray row m-0">
                                <td className="col-5 py-1">
                                  <ul className="list-none p-0 row g-0 m-0">
                                    <li className="text-muted col-md-5 text-md-end ">State</li>
                                    <li className="col-md-7 px-3">{facilityCompany?.billingAddress?.state}</li>
                                  </ul>
                                </td>
                                <td className="col-2 py-1">
                                  {showCheckBox && (
                                    <ul className="list-none p-0 m-0">
                                      <li>
                                        <div className="form-check p-0">
                                          <i className="fas fa-ban px-1"></i>
                                          <label className="form-check-label text-muted" for="check1">
                                            Not Allowed
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  )}
                                </td>
                                <td className="col-5 py-1">
                                  {netSuiteCustomerUpdateLoading ? (
                                    <div className="d-flex justify-content-center">
                                      <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  ) : (
                                    netSuiteContact?.cContact?.billingAddress?.state
                                  )}
                                </td>
                              </tr>

                              <tr className="row m-0">
                                <td className="col-5 py-1">
                                  <ul className="list-none p-0 row g-0 m-0">
                                    <li className="text-muted col-md-5  text-md-end ">Zip</li>
                                    <li className="col-md-7">{facilityCompany?.billingAddress?.zip}</li>
                                  </ul>
                                </td>
                                <td className="col-2 py-1">
                                  {showCheckBox && (
                                    <ul className="list-none p-0 m-0">
                                      <li>
                                        <div className="form-check p-0">
                                          <i className="fas fa-ban px-1"></i>
                                          <label className="form-check-label text-muted" for="check1">
                                            Not Allowed
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  )}
                                </td>
                                <td className="col-5 py-1">
                                  {netSuiteCustomerUpdateLoading ? (
                                    <div className="d-flex justify-content-center">
                                      <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  ) : (
                                    netSuiteContact?.cContact?.billingAddress?.zip
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="3" className="p-0  border-top-0">
                          <table className="table m-0">
                            <thead>
                              <tr className="border-bottom-0 row m-0">
                                <td className="col-5 border-bottom-0  border-top-0 pt-5">
                                  <h4 className="m-0 ">Mailing Address</h4>
                                </td>
                                <td className="col-2 border-bottom-0  border-top-0 pt-5">
                                  <h4 className="m-0"></h4>
                                </td>
                                <td className="col-5 border-bottom-0  border-top-0 pt-5"></td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="light-gray row m-0">
                                <td className="col-5 py-1">
                                  <ul className="list-none p-0 row g-0 m-0">
                                    <li className="text-muted col-md-5 text-md-end ">Mailing address/ P.O Box</li>
                                    <li className="col-md-7">{facilityCompany?.mailingAddress?.address}</li>
                                  </ul>
                                </td>
                                <td className="col-2 py-1">
                                  {showCheckBox && (
                                    <ul className="list-none p-0 m-0">
                                      <li>
                                        <div className="form-check p-0">
                                          <i className="fas fa-ban px-1"></i>
                                          <label className="form-check-label text-muted" for="check1">
                                            Not Allowed
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  )}
                                </td>
                                <td className="col-5 py-1">
                                  {netSuiteCustomerUpdateLoading ? (
                                    <div className="d-flex justify-content-center">
                                      <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  ) : (
                                    netSuiteContact?.cContact?.mailingAddress?.address
                                  )}
                                </td>
                              </tr>
                              <tr className="row m-0">
                                <td className="col-5 py-1">
                                  <ul className="list-none p-0 row g-0 m-0">
                                    <li className="text-muted col-md-5 text-md-end ">City</li>
                                    <li className="col-md-7">{facilityCompany?.mailingAddress?.city}</li>
                                  </ul>
                                </td>
                                <td className="col-2 py-1">
                                  {showCheckBox && (
                                    <ul className="list-none p-0 m-0">
                                      <li>
                                        <div className="form-check p-0">
                                          <i className="fas fa-ban px-1"></i>
                                          <label className="form-check-label text-muted" for="check1">
                                            Not Allowed
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  )}
                                </td>
                                <td className="col-5 py-1">
                                  {netSuiteCustomerUpdateLoading ? (
                                    <div className="d-flex justify-content-center">
                                      <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  ) : (
                                    netSuiteContact?.cContact?.mailingAddress?.city
                                  )}
                                </td>
                              </tr>
                              <tr className="light-gray row m-0">
                                <td className="col-5 py-1">
                                  <ul className="list-none p-0 row g-0 m-0">
                                    <li className="text-muted col-md-5 text-md-end ">State</li>
                                    <li className="col-md-7 px-3">{facilityCompany?.mailingAddress?.state}</li>
                                  </ul>
                                </td>
                                <td className="col-2 py-1">
                                  {showCheckBox && (
                                    <ul className="list-none p-0 m-0">
                                      <li>
                                        <div className="form-check p-0">
                                          <i className="fas fa-ban px-1"></i>
                                          <label className="form-check-label text-muted" for="check1">
                                            Not Allowed
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  )}
                                </td>
                                <td className="col-5 py-1">
                                  {netSuiteCustomerUpdateLoading ? (
                                    <div className="d-flex justify-content-center">
                                      <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  ) : (
                                    netSuiteContact?.cContact?.mailingAddress?.state
                                  )}
                                </td>
                              </tr>
                              <tr className="row m-0">
                                <td className="col-5 py-1">
                                  <ul className="list-none p-0 row g-0 m-0">
                                    <li className="text-muted col-md-5  text-md-end ">Zip</li>
                                    <li className="col-md-7">{facilityCompany?.mailingAddress?.zip}</li>
                                  </ul>
                                </td>
                                <td className="col-2 py-1">
                                  {showCheckBox && (
                                    <ul className="list-none p-0 m-0">
                                      <li>
                                        <div className="form-check p-0">
                                          <i className="fas fa-ban px-1"></i>
                                          <label className="form-check-label text-muted" for="check1">
                                            Not Allowed
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  )}
                                </td>
                                <td className="col-5 py-1">
                                  {netSuiteCustomerUpdateLoading ? (
                                    <div className="d-flex justify-content-center">
                                      <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  ) : (
                                    netSuiteContact?.cContact?.mailingAddress?.zip
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="3" className="p-0  border-top-0">
                          <table className="table m-0">
                            <thead>
                              <tr className="border-bottom-0 row m-0">
                                <td className="col-5 border-bottom-0  border-top-0 pt-5">
                                  <h4 className="m-0 ">Primary Contact</h4>
                                </td>
                                <td className="col-2 border-bottom-0  border-top-0 pt-5">
                                  {netSuiteContact.pContactCreateNewShow && primaryContact?.id && (
                                    <CheckboxForContacts netSuiteContact={netSuiteContact} handleCheckbox={handleCreateNewContactCheckbox} name={"pContactCreateNewShowChecked"} />
                                  )}
                                </td>
                                <td className="col-5 border-bottom-0  border-top-0 pt-5  d-flex flex-end justify-content-end align-items-center"></td>
                              </tr>
                            </thead>
                            <tbody>
                              <TableRow
                                label={"Name"}
                                value={primaryContact?.name}
                                showCheckBox={showCheckBox}
                                createNewShow={netSuiteContact.pContactCreateNewShow}
                                netSuiteContact={netSuiteContact}
                                checkBoxName={"name"}
                                portalValue={primaryContact?.name}
                                contact={"pContact"}
                                className="light-gray"
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                                netSuiteValue={netSuiteContact?.pContact?.name}
                              />
                              <TableRow
                                label={"Title"}
                                value={primaryContact?.jobTitle}
                                showCheckBox={showCheckBox}
                                createNewShow={netSuiteContact.pContactCreateNewShow}
                                netSuiteContact={netSuiteContact}
                                checkBoxName={"jobTitle"}
                                portalValue={primaryContact?.jobTitle}
                                contact={"pContact"}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                                netSuiteValue={netSuiteContact?.pContact?.jobTitle}
                              />
                              <TableRow
                                label={"Email"}
                                value={primaryContact?.email}
                                showCheckBox={showCheckBox}
                                createNewShow={netSuiteContact.pContactCreateNewShow}
                                netSuiteContact={netSuiteContact}
                                checkBoxName={"email"}
                                portalValue={primaryContact?.email}
                                contact={"pContact"}
                                className="light-gray"
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                                netSuiteValue={netSuiteContact?.pContact?.email}
                              />
                              <TableRow
                                label={"Phone"}
                                value={primaryContact?.phone}
                                showCheckBox={showCheckBox}
                                createNewShow={netSuiteContact.pContactCreateNewShow}
                                netSuiteContact={netSuiteContact}
                                checkBoxName={"phone"}
                                portalValue={primaryContact?.phone}
                                contact={"pContact"}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                                netSuiteValue={netSuiteContact?.pContact?.phone}
                              />
                              <TableRow
                                label={"Street Address"}
                                value={primaryContact?.address?.address}
                                showCheckBox={showCheckBox}
                                createNewShow={netSuiteContact.pContactCreateNewShow}
                                netSuiteContact={netSuiteContact}
                                checkBoxName={"address.address"}
                                portalValue={primaryContact?.address?.address}
                                contact={"pContact"}
                                className="light-gray"
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                                netSuiteValue={netSuiteContact?.pContact?.address?.address}
                              />

                              <TableRow
                                label={"City"}
                                value={primaryContact?.address?.city}
                                showCheckBox={showCheckBox}
                                createNewShow={netSuiteContact.pContactCreateNewShow}
                                netSuiteContact={netSuiteContact}
                                checkBoxName={"address.city"}
                                portalValue={primaryContact?.address?.city}
                                contact={"pContact"}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                                netSuiteValue={netSuiteContact?.pContact?.address?.city}
                              />
                              <TableRow
                                label={"State"}
                                value={primaryContact?.address?.state}
                                checkBoxName={"address.state"}
                                portalValue={primaryContact?.address?.state}
                                netSuiteValue={netSuiteContact?.pContact?.address?.state}
                                contact={"pContact"}
                                createNewShow={netSuiteContact.pContactCreateNewShow}
                                className="light-gray"
                                showCheckBox={showCheckBox}
                                netSuiteContact={netSuiteContact}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                              />
                              <TableRow
                                label={"Zip"}
                                value={primaryContact?.address?.zip}
                                netSuiteValue={netSuiteContact?.pContact?.address?.zip}
                                checkBoxName={"address.zip"}
                                portalValue={primaryContact?.address?.zip}
                                contact={"pContact"}
                                createNewShow={netSuiteContact.pContactCreateNewShow}
                                showCheckBox={showCheckBox}
                                netSuiteContact={netSuiteContact}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                              />
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="p-0  border-top-0">
                          <table className="table m-0">
                            <thead>
                              <tr className="border-bottom-0 row m-0">
                                <td className="col-5 border-bottom-0  border-top-0 pt-5">
                                  <h4 className="m-0 ">Billing Contact</h4>
                                </td>
                                <td className="col-2 border-bottom-0  border-top-0 pt-5">
                                  {netSuiteContact.bContactCreateNewShow && billingContact?.id && (
                                    <CheckboxForContacts netSuiteContact={netSuiteContact} handleCheckbox={handleCreateNewContactCheckbox} name={"bContactCreateNewShowChecked"} />
                                  )}
                                </td>
                                <td className="col-5 border-bottom-0  border-top-0 pt-5  d-flex flex-end justify-content-end align-items-center"></td>
                              </tr>
                            </thead>
                            <tbody>
                              <TableRow
                                label={"Name"}
                                value={billingContact?.name}
                                showCheckBox={showCheckBox}
                                createNewShow={netSuiteContact.bContactCreateNewShow}
                                netSuiteContact={netSuiteContact}
                                checkBoxName={"name"}
                                portalValue={billingContact?.name}
                                contact={"bContact"}
                                className="light-gray"
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                                netSuiteValue={netSuiteContact?.bContact?.name}
                              />
                              <TableRow
                                label={"Title"}
                                value={billingContact?.jobTitle}
                                showCheckBox={showCheckBox}
                                createNewShow={netSuiteContact.bContactCreateNewShow}
                                netSuiteContact={netSuiteContact}
                                checkBoxName={"jobTitle"}
                                portalValue={billingContact?.jobTitle}
                                contact={"bContact"}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                                netSuiteValue={netSuiteContact?.bContact?.jobTitle}
                              />
                              <TableRow
                                label={"Email"}
                                value={billingContact?.email}
                                showCheckBox={showCheckBox}
                                createNewShow={netSuiteContact.bContactCreateNewShow}
                                netSuiteContact={netSuiteContact}
                                checkBoxName={"email"}
                                portalValue={billingContact?.email}
                                contact={"bContact"}
                                className="light-gray"
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                                netSuiteValue={netSuiteContact?.bContact?.email}
                              />
                              <TableRow
                                label={"Phone"}
                                value={billingContact?.phone}
                                showCheckBox={showCheckBox}
                                createNewShow={netSuiteContact.bContactCreateNewShow}
                                netSuiteContact={netSuiteContact}
                                checkBoxName={"phone"}
                                portalValue={billingContact?.phone}
                                contact={"bContact"}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                                netSuiteValue={netSuiteContact?.bContact?.phone}
                              />
                              <TableRow
                                label={"Street Address"}
                                value={billingContact?.address?.address}
                                showCheckBox={showCheckBox}
                                createNewShow={netSuiteContact.bContactCreateNewShow}
                                netSuiteContact={netSuiteContact}
                                checkBoxName={"address.address"}
                                portalValue={billingContact?.address?.address}
                                contact={"bContact"}
                                className="light-gray"
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                                netSuiteValue={netSuiteContact?.bContact?.address?.address}
                              />

                              <TableRow
                                label={"City"}
                                value={billingContact?.address?.city}
                                showCheckBox={showCheckBox}
                                createNewShow={netSuiteContact.bContactCreateNewShow}
                                netSuiteContact={netSuiteContact}
                                checkBoxName={"address.city"}
                                portalValue={billingContact?.address?.city}
                                contact={"bContact"}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                                netSuiteValue={netSuiteContact?.bContact?.address?.city}
                              />
                              <TableRow
                                label={"State"}
                                value={billingContact?.address?.state}
                                checkBoxName={"address.state"}
                                portalValue={billingContact?.address?.state}
                                netSuiteValue={netSuiteContact?.bContact?.address?.state}
                                contact={"bContact"}
                                createNewShow={netSuiteContact.bContactCreateNewShow}
                                className="light-gray"
                                showCheckBox={showCheckBox}
                                netSuiteContact={netSuiteContact}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                              />
                              <TableRow
                                label={"Zip"}
                                value={billingContact?.address?.zip}
                                netSuiteValue={netSuiteContact?.bContact?.address?.zip}
                                checkBoxName={"address.zip"}
                                portalValue={billingContact?.address?.zip}
                                contact={"bContact"}
                                createNewShow={netSuiteContact.bContactCreateNewShow}
                                showCheckBox={showCheckBox}
                                netSuiteContact={netSuiteContact}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                              />
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="3" className="p-0  border-top-0">
                          <table className="table m-0">
                            <thead>
                              <tr className="border-bottom-0 row m-0">
                                <td className="col-5 border-bottom-0  border-top-0 pt-5">
                                  <h4 className="m-0 ">Engineering Representative</h4>
                                </td>
                                <td className="col-2 border-bottom-0  border-top-0 pt-5">
                                  {netSuiteContact.eContactCreateNewShow && consultantContact?.id && (
                                    <CheckboxForContacts netSuiteContact={netSuiteContact} handleCheckbox={handleCreateNewContactCheckbox} name={"eContactCreateNewShowChecked"} />
                                  )}
                                </td>
                                <td className="col-5 border-bottom-0  border-top-0 pt-5  d-flex flex-end justify-content-end align-items-center"></td>
                              </tr>
                            </thead>
                            <tbody>
                              <TableRow
                                label={"Consultant's Company Name"}
                                value={consultantContact?.companyName}
                                checkBoxName={"companyName"}
                                portalValue={consultantContact?.companyName}
                                netSuiteValue={netSuiteContact?.eContact?.companyName}
                                contact={"eContact"}
                                createNewShow={netSuiteContact.eContactCreateNewShow}
                                className="light-gray"
                                showCheckBox={showCheckBox}
                                netSuiteContact={netSuiteContact}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                              />
                              <TableRow
                                label={"Name"}
                                value={consultantContact?.name}
                                checkBoxName={"name"}
                                portalValue={consultantContact?.name}
                                netSuiteValue={netSuiteContact?.eContact?.name}
                                contact={"eContact"}
                                createNewShow={netSuiteContact.eContactCreateNewShow}
                                showCheckBox={showCheckBox}
                                netSuiteContact={netSuiteContact}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                              />
                              <TableRow
                                label={"Title"}
                                value={consultantContact?.jobTitle}
                                checkBoxName={"jobTitle"}
                                portalValue={consultantContact?.jobTitle}
                                netSuiteValue={netSuiteContact?.eContact?.jobTitle}
                                contact={"eContact"}
                                createNewShow={netSuiteContact.eContactCreateNewShow}
                                className="light-gray"
                                showCheckBox={showCheckBox}
                                netSuiteContact={netSuiteContact}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                              />
                              <TableRow
                                label={"Email"}
                                value={consultantContact?.email}
                                checkBoxName={"email"}
                                portalValue={consultantContact?.email}
                                netSuiteValue={netSuiteContact?.eContact?.email}
                                contact={"eContact"}
                                createNewShow={netSuiteContact.eContactCreateNewShow}
                                showCheckBox={showCheckBox}
                                netSuiteContact={netSuiteContact}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                              />
                              <TableRow
                                label={"Phone"}
                                value={consultantContact?.phone}
                                checkBoxName={"phone"}
                                portalValue={consultantContact?.phone}
                                netSuiteValue={netSuiteContact?.eContact?.phone}
                                contact={"eContact"}
                                createNewShow={netSuiteContact.eContactCreateNewShow}
                                className="light-gray"
                                showCheckBox={showCheckBox}
                                netSuiteContact={netSuiteContact}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                              />
                              <TableRow
                                label={"Street Address"}
                                value={consultantContact?.address?.address}
                                checkBoxName={"address.address"}
                                portalValue={consultantContact?.address?.address}
                                netSuiteValue={netSuiteContact?.eContact?.address?.address}
                                contact={"eContact"}
                                createNewShow={netSuiteContact.eContactCreateNewShow}
                                showCheckBox={showCheckBox}
                                netSuiteContact={netSuiteContact}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                              />

                              <TableRow
                                label={"City"}
                                value={consultantContact?.address?.city}
                                checkBoxName={"address.city"}
                                portalValue={consultantContact?.address?.city}
                                netSuiteValue={netSuiteContact?.eContact?.address?.city}
                                contact={"eContact"}
                                createNewShow={netSuiteContact.eContactCreateNewShow}
                                className="light-gray"
                                showCheckBox={showCheckBox}
                                netSuiteContact={netSuiteContact}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                              />
                              <TableRow
                                label={"State"}
                                value={consultantContact?.address?.state}
                                checkBoxName={"address.state"}
                                portalValue={consultantContact?.address?.state}
                                netSuiteValue={netSuiteContact?.eContact?.address?.state}
                                contact={"eContact"}
                                createNewShow={netSuiteContact.eContactCreateNewShow}
                                showCheckBox={showCheckBox}
                                netSuiteContact={netSuiteContact}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                              />
                              <TableRow
                                label={"Zip"}
                                value={consultantContact?.address?.zip}
                                netSuiteValue={netSuiteContact?.eContact?.address?.zip}
                                checkBoxName={"address.zip"}
                                portalValue={consultantContact?.address?.zip}
                                contact={"eContact"}
                                createNewShow={netSuiteContact.eContactCreateNewShow}
                                className="light-gray"
                                showCheckBox={showCheckBox}
                                netSuiteContact={netSuiteContact}
                                handleCheckbox={handleCheckbox}
                                loading={netSuiteContactUpdateLoading}
                              />
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const TableRow = ({ label, value, showCheckBox, createNewShow, netSuiteContact, checkBoxName, portalValue, contact, handleCheckbox, loading, netSuiteValue, className = "" }) => {
  return (
    <tr className={`${className} row m-0`}>
      <td className="col-5 py-1">
        <ul className="list-none p-0 row g-0 m-0">
          <li className="text-muted col-md-5  text-md-end ">{label}</li>
          <li className="col-md-7">{value}</li>
        </ul>
      </td>
      <td className="col-2 py-1">
        {showCheckBox && !createNewShow && (
          <ul className="list-none p-0 m-0">
            <li className="">
              <Checkbox netSuiteContact={netSuiteContact} name={checkBoxName} protalValue={portalValue} contact={contact} handleCheckbox={handleCheckbox} />
            </li>
          </ul>
        )}
      </td>
      <td className="col-5 py-1">
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          netSuiteValue
        )}
      </td>
    </tr>
  );
};

const SearchBar = ({ searchCustomer, pagination, searchLoading, netsuiteCostumer, pageData, handleCustomerSelect, changePage, page }) => {
  return (
    <div className="row g-3 align-items-center">
      <div className="col-auto">
        <h4 htmlFor="automplete" className=" m-0">
          Customer
        </h4>
      </div>
      <div className="col-lg-9 ui-widget select-col position-relative">
        <input
          type="text"
          id="automplete"
          className="form-control  border-dark"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          placeholder="Search customer by name"
          onChange={(e) => debounce(() => searchCustomer(e), 500)}
        />
        <ul className="dropdown-menu show w-100 h-0 rounded-0 pb-0 pt-0" aria-labelledby="automplete" id="custom_pagination" ref={pagination}>
          {searchLoading && (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {netsuiteCostumer.length > 0 && (
            <li>
              <button type="button" className="close  pr-2 h3" aria-label="Close" onClick={() => setTimeout(() => pagination.current.classList.remove("show"))}>
                <span aria-hidden="true">&times;</span>
              </button>
            </li>
          )}
          {netsuiteCostumer.length > 0 &&
            pageData.map((each) => {
              return (
                <li className="border-bottom" key={each.id} onClick={() => handleCustomerSelect(each)}>
                  <a className="dropdown-item d-flex justify-content-between">
                    <span>{each.fields.entityid}</span>
                    <span className="text text-primary">Select</span>
                  </a>
                </li>
              );
            })}
          {netsuiteCostumer.length > 14 && (
            <nav aria-label="Page navigation example" aria-expanded="false">
              <ul className="pagination d-flex justify-content-center">
                <li className="page-item">
                  <a className="page-link" aria-label="Previous" onClick={() => changePage(page - 1)}>
                    <span aria-hidden="false">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" onClick={() => changePage(1)}>
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" onClick={() => changePage(2)}>
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" onClick={() => changePage(3)}>
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" onClick={() => changePage(page + 1)} aria-label="Next">
                    <span aria-hidden="false">&raquo;</span>
                    <span className="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          )}
        </ul>
      </div>
    </div>
  );
};
