const selectedOperatingSigneeReducer = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_SELECTED_OPERATING_SIGNEE":
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export default selectedOperatingSigneeReducer;
