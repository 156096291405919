import React from "react";

export default function Text2({
  label = "",
  name = "",
  name1 = "",
  type = "text",
  defaultValue = "",
  placeholder = "",
  css = {},
  validation,
  register,
  errors,
  handleChange,
  id,
  fieldLabel = "",
  fieldLabelPosition = "",
  fieldType = "",
  comments = "",
  commentsIconIsRequired = false,
  showOnChange = [],
  commentsStyle = {},
  condtionalLabel = "",
  disabled = false,
  setSchema = () => {},
  crossingChange = () => {},
  requiredLable = false,
}) {
  return (
    <div>
      <label className="form-label d-flex justify-content-between">
        {label}
        <small className="text-danger ms-1">{requiredLable ? "*required" : null}</small>
      </label>
      <input
        style={css}
        className="form-control"
        type={"number"}
        defaultValue={defaultValue}
        placeholder={placeholder}
        name={name}
        step="0.0001"
        disabled={disabled}
        onChange={(e) => crossingChange(e, name)}
        aria-label="Default"
        aria-describedby="inputGroup-sizing-default"
      />
    </div>
  );
}
