const countyReducer = (state = "", action) => {
  switch (action.type) {
    case "UPDATE_COUNTY":
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export default countyReducer;
