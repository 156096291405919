import React, { useState } from "react";
import { State, ApplicantType } from "../data/StateValues";
import { useHistory, useLocation } from "react-router";
import CompanyDetails from "../ui/CompanyDetails";
import { createFacilityCompany, getCompanyByName } from "../../api/crm";
import GetmyRole from "../../utils/GetmyRole";
import { setCustomerId } from "../../hooks/useRole";
import { useUser } from "../../hooks/useUser";
import toast from "react-hot-toast";

const initialValue = {
  name: "",
  sameAsMailingAddress: true,
  mailingAddress: {
    address: "",
    city: "",
    state: "North Carolina",
    zip: "",
    poBox: "",
    country: "United States",
  },
  billingAddress: {
    address: "",
    city: "",
    state: "North Carolina",
    zip: "",
    poBox: "",
    country: "United States",
  },
  billingPeriod: "YEARLY",
  applicantshipType: "",
  stateOfFormation: "North Carolina",
  createdBy: "",
};

const initialError = {
  name: "",
  mailingAddress: {
    address: "",
    city: "",
    state: "",
    zip: "",
    poBox: "",
    country: "",
  },
  billingAddress: {
    address: "",
    city: "",
    state: "",
    zip: "",
    poBox: "",
    country: "",
  },
  billingPeriod: "",
  applicantshipType: "",
  stateOfFormation: "",
  createdBy: "",
};

export const InitialForm = (props) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isCompanyExist, setIsCompanyExist] = useState(false);
  const [form, setForm] = useState(initialValue);
  const [error, setError] = useState(initialError);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const applicationID = queryParams.get("applicationID");
  const formName = queryParams.get("formName");
  const redirectTo = queryParams.get("redirectTo");
  const user = useUser();
  const validateZipCode = (zip) => {
    const zipCodePattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
    return zipCodePattern.test(zip);
  };

  const handleChange = (e) => {
    const { type, name, value } = e.target;
    const types = ["text", "select-one", "radio"];
    const mailType = ["mailingAddress", "billingAddress"];

    if (mailType.find((each) => name.startsWith(each))) {
      const mailType = name.split(".")[0];
      const mailingName = name.split(".")[1];
      if (types.includes(type)) {
        setForm((initialForm) => ({
          ...initialForm,
          [mailType]: {
            ...initialForm[mailType],
            [mailingName]: value,
          },
        }));
      }
      checkError();
      return;
    }

    if (types.includes(type)) {
      setForm((initialForm) => ({
        ...initialForm,
        [name]: value,
      }));
      checkError();
    }

    if (type === "checkbox") {
      setForm((initialForm) => ({
        ...initialForm,
        [name]: !initialForm[name],
      }));
      checkError();
    }
  };

  const handleError = (error) => {
    setError({ ...initialError, ...error });
  };

  const checkError = () => {
    let errors = {};

    if (!form.name) {
      errors.name = "*required";
    }
    if (!form.mailingAddress.address) {
      errors.mailingAddress = { address: "*required" };
    }
    if (!form.mailingAddress.city) {
      errors.mailingAddress = { ...errors.mailingAddress, city: "*required" };
    }
    if (!form.mailingAddress.state) {
      errors.mailingAddress = { ...errors.mailingAddress, state: "*required" };
    }
    if (!form.mailingAddress.zip) {
      errors.mailingAddress = { ...errors.mailingAddress, zip: "*required" };
    } else if (!validateZipCode(form.mailingAddress.zip)) {
      errors.mailingAddress = { ...errors.mailingAddress, zip: "Invalid Zip code" };
    }
    if (!form.sameAsMailingAddress) {
      if (!form.billingAddress.address) {
        errors.billingAddress = { address: "*required" };
      }
      if (!form.billingAddress.city) {
        errors.billingAddress = { ...errors.billingAddress, city: "*required" };
      }
      if (!form.billingAddress.state) {
        errors.billingAddress = { ...errors.billingAddress, state: "*required" };
      }
      if (!form.billingAddress.zip) {
        errors.billingAddress = { ...errors.billingAddress, zip: "*required" };
      } else if (!validateZipCode(form.billingAddress.zip)) {
        errors.billingAddress = { ...errors.billingAddress, zip: "Invalid Zip code" };
      }
    }
    if (!form.billingPeriod) {
      errors.billingPeriod = "*required";
    }
    if (!form.applicantshipType) {
      errors.applicantshipType = "*required";
    }
    if (!form.stateOfFormation) {
      errors.stateOfFormation = "*required";
    }

    if (Object.keys(errors).length > 0) {
      handleError(errors);
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(initialError);

    const result = checkError();
    try {
      if (result) {
        const { email } = GetmyRole();

        const res = await getCompanyByName(form.name, "FACILITY");

        if (!res.data.status) throw new Error("Something went wrong!");

        const companies = res.data.data;

        const companyName = form.name.trim().toLowerCase();

        const data = { ...form, createdBy: email };

        if (form.sameAsMailingAddress) {
          data["billingAddress"] = data["mailingAddress"];
        }

        const isCompanyExist = companies.find(({ name }) => name.toLowerCase() === companyName);

        if (isCompanyExist && isCompanyExist.companyType === "FACILITY") {
          data["id"] = isCompanyExist.id;
        } else {
          delete data.mailingAddress["id"];
          delete data.billingAddress["id"];
          delete data["createdOn"];
          delete data.mailingAddress["createdOn"];
          delete data.billingAddress["createdOn"];
        }

        const response = await createFacilityCompany(data);
        const company = response.data.data;

        if (user?.role?.roleType === "CONSULTANT") {
          if (redirectTo) {
            history.push(redirectTo);
            return;
          }

          // For Consultant it is for Application
          localStorage.setItem("facilityId", "ALL");
          history.push(`/${formName}/${applicationID}?companyID=${company.id}`);
          return;
        }

        if (response.data.status) {
          setCustomerId(company.id);

          // For Facility
          localStorage.setItem("facilityId", company?.id);
          history.push(`/contacts/${company.id}`);
          return;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={loading ? "submitting" : null}></div>
      <form onSubmit={handleSubmit} className="py-4">
        <div className="inner-form card mb-4">
          <div className=" border-bottom card-header">
            <h2> Facility Owner Details</h2>
            <p className="text-dark mb-0">
              <i className="far fa-hand-point-right"></i> Following information creates your profile in the NCRR portal and this information is used with all utility applications in the future.
            </p>
          </div>

          <CompanyDetails
            form={form}
            setForm={setForm}
            setIsCompanyExist={setIsCompanyExist}
            disabled={loading}
            error={error}
            type={"FACILITY"}
            Heading={"Facility Owner Details"}
            label={"Facility Owner Legal Name"}
          />

          <div className="card-body" id="owner-details">
            <div className="d-flex justify-content-between">
              <h3>Mailing Address</h3>
            </div>
            <div className="row">
              <div className="row">
                <div className="col">
                  <div className="mt-3">
                    <label className="form-label d-flex justify-content-between">
                      Street Address
                      <small className="text-danger ms-1">{error.mailingAddress.address}</small>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="mailingAddress.address"
                      name="mailingAddress.address"
                      disabled={loading}
                      onChange={handleChange}
                      value={form.mailingAddress.address}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mt-3">
                    <label className="form-label d-flex justify-content-between">
                      City
                      <small className="text-danger ms-1">{error.mailingAddress.city}</small>
                    </label>

                    <input className="form-control" type="text" id="mailingAddress.city" name="mailingAddress.city" onChange={handleChange} value={form.mailingAddress.city} disabled={loading} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mt-3">
                    <label className="form-label d-flex justify-content-between">
                      State
                      <small className="text-danger ms-1">{error.mailingAddress.state}</small>
                    </label>
                    <select className="form-select  mb-3" name="mailingAddress.state" id="mailingAddress.state" onChange={handleChange} value={form.mailingAddress.state} disabled={loading}>
                      {State.map((name, index) => (
                        <option value={name} key={index}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div class="col">
                  <div className="mt-3">
                    <label class="form-label d-flex justify-content-between">Country</label>
                    <select class="form-select mb-3" onChange={handleChange} value={form.mailingAddress.country} disabled={loading}>
                      <option value="US">United States</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="my-3">
                    <label className="form-label d-flex justify-content-between">
                      Zip
                      <small className="text-danger ms-1">{error.mailingAddress.zip}</small>
                    </label>

                    <input className="form-control" type="text" id="mailingAddress.zip" name="mailingAddress.zip" onChange={handleChange} value={form.mailingAddress.zip} disabled={loading} />
                  </div>
                </div>
                <div>
                  <div>
                    <div class="form-check  mb-4">
                      <input
                        type="checkbox"
                        name="sameAsMailingAddress"
                        id="sameAsMailingAddress"
                        className="form-check-input"
                        checked={form.sameAsMailingAddress}
                        onChange={handleChange}
                        disabled={loading}
                      />
                      <label class="form-check-label" for="sameAsMailingAddress">
                        Company Billing Address is same as Mailing Address
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {!form.sameAsMailingAddress ? (
                <>
                  <div class=" mt-3">
                    <div class="d-flex justify-content-between">
                      <h3 class="form-check px-0">
                        <label class="form-check-label" for="billing">
                          Billing Address
                        </label>
                      </h3>
                    </div>
                    <small class="text-muted d-block mt-3">
                      The billing contact is an additional person who can log in and has same permissions as the primary contact i.e. they can create new applications and view historical agreements
                      and related information.
                    </small>

                    <div className="row">
                      <div className="col">
                        <div className="mt-3">
                          <label className="form-label d-flex justify-content-between">
                            Street Address
                            <small className="text-danger ms-1">{error.billingAddress.address}</small>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="billingAddress.address"
                            name="billingAddress.address"
                            onChange={handleChange}
                            disabled={loading}
                            value={form.billingAddress.address}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="mt-3">
                          <label className="form-label d-flex justify-content-between">
                            City
                            <small className="text-danger ms-1">{error.billingAddress.city}</small>
                          </label>

                          <input className="form-control" type="text" id="billingAddress.city" name="billingAddress.city" onChange={handleChange} value={form.billingAddress.city} disabled={loading} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mt-3">
                          <label className="form-label d-flex justify-content-between">
                            State
                            <small className="text-danger ms-1">{error.billingAddress.state}</small>
                          </label>
                          <select className="form-select  mb-3" name="billingAddress.state" id="billingAddress.state" onChange={handleChange} value={form.billingAddress.state} disabled={loading}>
                            {State.map((name, index) => (
                              <option value={name} key={index}>
                                {name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <div className="mt-3">
                          <label class="form-label d-flex justify-content-between">Country</label>
                          <select class="form-select mb-3" onChange={handleChange} value={form.billingAddress.country} disabled={loading}>
                            <option value="US">United States</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="my-3">
                          <label className="form-label d-flex justify-content-between">
                            Zip
                            <small className="text-danger ms-1">{error.billingAddress.zip}</small>
                          </label>

                          <input className="form-control" type="text" id="billingAddress.zip" name="billingAddress.zip" onChange={handleChange} value={form.billingAddress.zip} disabled={loading} />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="row">
                <div className="col-md-12 mt-3">
                  <div className="">
                    <label className="form-label d-flex justify-content-between">
                      Billing
                      <small className="text-danger ms-1">{error.billingPeriod}</small>
                    </label>
                    <div className="row">
                      <div className="col-md-4 undefined">
                        <div className="form-check">
                          <input
                            type="radio"
                            name="billingPeriod"
                            id="billingPeriod"
                            value={"YEARLY"}
                            className="form-check-input"
                            checked={form.billingPeriod === "YEARLY"}
                            onChange={handleChange}
                            disabled={loading}
                          />
                          <label className="form-check-label" htmlFor="billingPeriod">
                            Yearly
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label className="form-label d-flex justify-content-between">
                    Applicant is a<small className="text-danger ms-1">{error.applicantshipType}</small>
                  </label>
                  <div className="row">
                    {ApplicantType.map((name, index) => (
                      <div key={index} className={"col-md-4"}>
                        <div className="form-check">
                          <input
                            type="radio"
                            name={"applicantshipType"}
                            value={name}
                            id={name}
                            checked={form.applicantshipType === name}
                            className="form-check-input"
                            onChange={handleChange}
                            disabled={loading}
                          />
                          <label htmlFor={name}>{name}</label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="mt-3">
                    <label className="form-label d-flex justify-content-between">
                      State of formation
                      <small className="text-danger ms-1">{error.stateOfFormation}</small>
                    </label>
                    <select className="form-select mb-3" name="stateOfFormation" id="stateOfFormation" onChange={handleChange} value={form.stateOfFormation} disabled={loading}>
                      {State.map((name, index) => (
                        <option value={name} key={index}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="btn-col text-center d-flex justify-content-end">
              <button type="submit" className="btn btn-primary btn-lg">
                {loading ? (
                  <>
                    <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                    Submitting...{" "}
                  </>
                ) : (
                  "Save and Proceed"
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
