export const isTransactionAvailable = (application) => {
  const { applicationStage, salesorderValidated = false, applicationVersion } = application;
  if (applicationStage < 1) return false;

  if (applicationVersion === "0.0.1") {
    if (applicationStage >= 1 && applicationStage !== 2 && applicationStage !== 3) {
      const isOpportunityExist = {
        unit: {
          exist: checkExist("unit", application),
        },
        opportunity: {
          exist: checkExist("opportunity", application),
        },
      };

      if (!isOpportunityExist.unit.exist) {
        return true;
      }

      if (!isOpportunityExist.opportunity.exist) {
        return true;
      }
    }

    if (applicationStage === 26) return false;

    if (applicationStage >= 6) {
      const isCashsaleAndVendorBillExist = {
        cashsale: {
          exist: checkExist("cashsale", application),
          updated: checkUpdated("cashsale", application),
          id: getInternalId("cashsale", application),
        },
        vendorbill: {
          exist: checkExist("vendorbill", application),
          updated: checkUpdated("vendorbill", application),
          id: getInternalId("vendorbill", application),
        },
      };

      if (isCashsaleAndVendorBillExist.cashsale.exist || isCashsaleAndVendorBillExist?.cashsale?.updated) {
        if (!isCashsaleAndVendorBillExist.cashsale.updated) {
          return true;
        }
      } else {
        return true;
      }

      if (isCashsaleAndVendorBillExist.vendorbill.exist || isCashsaleAndVendorBillExist?.vendorbill?.updated) {
        if (!isCashsaleAndVendorBillExist.vendorbill.updated) {
          return true;
        }
      } else {
        return true;
      }
    }

    if (applicationStage >= 7) {
      const isUnitAndOpportunityExist = {
        unit: {
          exist: checkExist("unit", application),
          updated: checkUpdated("unit", application),
          id: getInternalId("unit", application),
        },
        opportunity: {
          exist: checkExist("opportunity", application),
          updated: checkUpdated("opportunity", application),
          id: getInternalId("opportunity", application),
        },
      };

      if (isUnitAndOpportunityExist.unit.exist) {
        if (!isUnitAndOpportunityExist.unit.updated) {
          return true;
        }
      } else {
        return true;
      }

      if (isUnitAndOpportunityExist.opportunity.exist) {
        if (!isUnitAndOpportunityExist.opportunity.updated) {
          return true;
        }
      } else {
        return true;
      }
    }

    if (applicationStage >= 7) {
      const isCashsaleAndVendorBillExist = {
        cashsale: {
          exist: checkExist("cashsale", application),
          updated: checkUpdated("cashsale", application),
          id: getInternalId("cashsale", application),
        },
        vendorbill: {
          exist: checkExist("vendorbill", application),
          updated: checkUpdated("vendorbill", application),
          id: getInternalId("vendorbill", application),
        },
      };

      if (isCashsaleAndVendorBillExist.cashsale.exist || isCashsaleAndVendorBillExist?.cashsale?.updated) {
        if (!isCashsaleAndVendorBillExist.cashsale.updated) {
          return true;
        }
      } else {
        return true;
      }

      if (isCashsaleAndVendorBillExist.vendorbill.exist || isCashsaleAndVendorBillExist?.vendorbill?.updated) {
        if (!isCashsaleAndVendorBillExist.vendorbill.updated) {
          return true;
        }
      } else {
        return true;
      }
    }

    if (applicationStage >= 11) {
      const isAgreementExist = {
        agreement: {
          exist: checkExist("agreement", application),
          updated: checkUpdated("agreement", application, "updated"),
          updated0: checkUpdated("agreement", application, "updated0"),
          id: getInternalId("agreement", application),
        },
        utility: {
          exist: checkExist("utility", application),
          updated: checkUpdated("utility", application),
          id: getInternalId("utility", application),
        },
      };

      const isUnitAndOpportunityExist = {
        unit: {
          exist: checkExist("unit", application),
          updated: checkUpdated("unit", application),
          updated1: checkUpdated("unit", application, "updated1"),
          id: getInternalId("unit", application),
        },
        opportunity: {
          exist: checkExist("opportunity", application),
          updated: checkUpdated("opportunity", application),
          updated1: checkUpdated("opportunity", application, "updated1"),
          id: getInternalId("opportunity", application),
        },
      };

      const { netSuite: netSuiteApplication, netsuiteContactDetails, applicantDetails } = application;
      const { agreementcontactprimary = {}, agreementcontactbilling = {}, agreementcontactengineering = {} } = netSuiteApplication;

      const { pContact = {}, bContact = {}, eContact = {} } = netsuiteContactDetails;
      const { primaryContact = {}, billingContact = {}, consultantContact = {} } = applicantDetails;

      const { netSuiteAgreementContactId: pContactId } = pContact || {};
      const { netSuiteAgreementContactId: bContactId } = bContact || {};
      const { netSuiteAgreementContactId: eContactId } = eContact || {};

      const isSupplementExist = {
        exist: checkExist("supplement", application),
        updated0: checkUpdated("supplement", application, "updated0"),
        updated: checkUpdated("supplement", application),
        id: getInternalId("supplement", application),
      };
      if (checkSupplimentApplication(application)) {
        //Supplement
        if (isSupplementExist.exist) {
          if (!isSupplementExist.updated0) {
            return true;
          }
        } else {
          return true;
        }
      } else {
        if (isAgreementExist.agreement.exist) {
          if (!isAgreementExist.agreement.updated && !isAgreementExist.agreement.updated0) {
            return true;
          }
        } else {
          return true;
        }

        if (primaryContact?.id) {
          if (!agreementcontactprimary?.updated) {
            if (!pContactId) {
              return true;
            } else {
              return true;
            }
          }
        }

        if (billingContact?.id) {
          if (!agreementcontactbilling?.updated) {
            if (primaryContact?.id !== billingContact?.id) {
              if (!bContactId) {
                return true;
              } else {
                return true;
              }
            }
          }
        }

        if (consultantContact?.id) {
          if (!agreementcontactengineering?.updated) {
            if (primaryContact?.id !== consultantContact?.id && billingContact?.id !== consultantContact?.id) {
              if (!eContactId) {
                return true;
              } else {
                return true;
              }
            }
          }
        }
      }

      if (isUnitAndOpportunityExist.unit.exist) {
        if (!isUnitAndOpportunityExist.unit.updated1) {
          return true;
        }
      }

      if (isUnitAndOpportunityExist.opportunity.exist) {
        if (!isUnitAndOpportunityExist.opportunity.updated1) {
          return true;
        }
      }

      if (isAgreementExist.utility.exist) {
        if (!isAgreementExist.utility.updated) {
          return true;
        }
      } else {
        return true;
      }
    }

    if (applicationStage >= 17) {
      const isinsuranceExist = {
        insurance: {
          exist: checkExist("insurance", application),
          updated: checkUpdated("insurance", application),
          id: getInternalId("insurance", application),
        },
      };

      const isAgreementExist = {
        agreement: {
          exist: checkExist("agreement", application),
          updated01: checkUpdated("agreement", application, "updated01"),
          updated: checkUpdated("agreement", application, "updated"),
          id: getInternalId("agreement", application),
        },
      };

      if (isinsuranceExist.insurance.exist) {
        if (!isinsuranceExist.insurance.updated) {
          return true;
        }
      } else {
        return true;
      }

      if (!checkSupplimentApplication(application)) {
        if (isAgreementExist.agreement.exist) {
          if (!isAgreementExist.agreement.updated && !isAgreementExist.agreement.updated01) {
            return true;
          }
        }
      }
    }

    if (applicationStage >= 18 && checkLicenseFee(application)) {
      const isSalesOrderExist = {
        salesorder: {
          exist: checkExist("salesorder", application),
          updated: checkUpdated("salesorder", application),
          id: getInternalId("salesorder", application),
        },
      };

      if (isSalesOrderExist.salesorder.exist) {
        if (!isSalesOrderExist.salesorder.updated) {
          return true;
        }
      } else {
        return true;
      }
    }

    if (applicationStage >= 18) {
      const isCustomerDepositCashSale2VendorBill2Exist = {
        customerdeposit: {
          exist: checkExist("customerdeposit", application),
          updated: checkUpdated("customerdeposit", application),
          id: getInternalId("customerdeposit", application),
        },
        cashsale2: {
          exist: checkExist("cashsale2", application),
          updated: checkUpdated("cashsale2", application),
          id: getInternalId("cashsale2", application),
        },
        vendorbill2: {
          exist: checkExist("vendorbill2", application),
          updated: checkUpdated("vendorbill2", application),
          id: getInternalId("vendorbill2", application),
        },
      };

      if (checkLicenseFee(application)) {
        if (isCustomerDepositCashSale2VendorBill2Exist.customerdeposit.exist || isCustomerDepositCashSale2VendorBill2Exist?.customerdeposit?.updated) {
          if (!isCustomerDepositCashSale2VendorBill2Exist.customerdeposit.updated) {
            return true;
          }
        } else {
          return true;
        }
      }

      if (isCustomerDepositCashSale2VendorBill2Exist.cashsale2.exist || isCustomerDepositCashSale2VendorBill2Exist.cashsale2.updated) {
        if (!isCustomerDepositCashSale2VendorBill2Exist.cashsale2.updated) {
          return true;
        }
      } else {
        return true;
      }

      if (isCustomerDepositCashSale2VendorBill2Exist.vendorbill2.exist || isCustomerDepositCashSale2VendorBill2Exist.vendorbill2.updated) {
        if (!isCustomerDepositCashSale2VendorBill2Exist.vendorbill2.updated) {
          return true;
        }
      } else {
        return true;
      }
    }

    if (applicationStage >= 24 && salesorderValidated) {
      const isAgreementUpdateSalesOrderUtilityExist = {
        agreement: {
          exist: checkExist("agreement", application),
          updated: checkUpdated("agreement", application),
          id: getInternalId("agreement", application),
        },
        updatesalesorder: {
          exist: checkExist("salesorder", application),
          updated: checkUpdated("updatesalesorder", application),
          id: getInternalId("updatesalesorder", application),
        },
      };

      const isSupplementExist = {
        exist: checkExist("supplement", application),
        updated: checkUpdated("supplement", application),
        id: getInternalId("supplement", application),
      };

      if (checkSupplimentApplication(application)) {
        if (isSupplementExist.exist) {
          if (!isSupplementExist.updated) {
            return true;
          }
        }
      } else {
        if (isAgreementUpdateSalesOrderUtilityExist.agreement.exist) {
          if (!isAgreementUpdateSalesOrderUtilityExist.agreement.updated) {
            return true;
          }
        } else {
          return true;
        }
      }

      if (checkLicenseFee(application)) {
        if (isAgreementUpdateSalesOrderUtilityExist.updatesalesorder.exist) {
          if (!isAgreementUpdateSalesOrderUtilityExist.updatesalesorder.updated) {
            return true;
          }
        }
      }
    }

    return false;
  }
  return false;
};

const checkLicenseFee = (application = {}) => {
  const { applicationStage = "", agreement = {} } = application;

  if (applicationStage < 15) {
    return false;
  }

  if (applicationStage > 25) {
    return false;
  }

  const { draft = [] } = agreement;
  const licenseAgreement = draft.find((each) => each.action === "Upload and Send to NCRR" && each.role === "Engineering Representative") || null;

  const license_fee = licenseAgreement?.licenseFee[0]?.license_fee || "";
  const licenseFee = Number(license_fee);
  if (licenseFee > 0) {
    return true;
  }
  return false;
};

const types = [
  "unit",
  "opportunity",
  "cashsale",
  "vendorbill",
  "salesorder",
  "insurance",
  "customerdeposit",
  "cashsale2",
  "vendorbill2",
  "agreement",
  "supplement",
  "agreementcontactprimary",
  "agreementcontactbilling",
  "agreementcontactengineering",
  "updatesalesorder",
  "utility",
];

const checkExist = (type, application) => {
  if (!type) throw new Error("type is required");

  if (!types.includes(type)) throw new Error("invalid type");

  if (!application) throw new Error("application is required");
  const { netSuite = {} } = application;

  const checkInternalId = (internalId) => {
    if (!internalId || internalId === "") return false;
    return true;
  };

  return checkInternalId(netSuite?.[type]?.id);
};

const checkUpdated = (type, application, updatedName = "updated") => {
  if (!type) throw new Error("type is required");

  if (!types.includes(type)) throw new Error("invalid type");

  if (!application) throw new Error("application is required");

  const { netSuite = {} } = application;

  const checkRecordUpdated = (updated) => {
    if (updated === true) return true;
    return false;
  };

  return checkRecordUpdated(netSuite[type]?.[updatedName]);
};

const getInternalId = (type, application) => {
  if (!type) throw new Error("type is required");

  if (!types.includes(type)) throw new Error("invalid type");

  if (!application) throw new Error("application is required");
  const { netSuite = {} } = application;

  const checkInternalId = (internalId) => {
    if (!internalId || internalId === "") return null;
    return internalId;
  };

  return checkInternalId(netSuite[type]?.id);
};

const checkSupplimentApplication = (application) => {
  if (application?.supplement) {
    const { agreement = "", supplement = false } = application.supplement;

    if (supplement && agreement) {
      return true;
    }
  }
  return false;
};
