import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { getContactsByCompanyId } from "../../api/crm";
import GetmyRole from "../../utils/GetmyRole";
import { AddContactForm } from "./forms/AddContact";
import { EditContactForm } from "./forms/EditContact";

export const ContactsDetails = () => {
  const [selectedContact, setSelectedContact] = useState(null);
  let [showAddModal, setAddShowModal] = useState(false);
  let [showEditModal, setEditShowModal] = useState(false);
  const [contacts, setContacts] = useState([]);
  const { companyId } = useParams();
  const history = useHistory();
  const { email } = GetmyRole() || {};

  const getContacts = async (companyId) => {
    const res = await getContactsByCompanyId(companyId);
    if (res.data.status) {
      setContacts(res.data.data);
    }
  };

  useEffect(() => {
    getContacts(companyId);
  }, [companyId]);

  //to Open Model if his Contact doesnt exist
  useEffect(() => {
    if (!contacts.find((each) => each.email === email)) {
        openModal();
    }
  }, [contacts, email]);

  const handleEditClick = (contact) => {
    setSelectedContact(contact);
    setEditShowModal(true);
    document.body.classList.add("modal-open");
    const backdrop = document.createElement("div");
    backdrop.setAttribute("id", "modal-backdrops");
    backdrop.classList.add("modal-backdrop", "fade", "show");
    document.body.appendChild(backdrop);
  };

  const openModal = () => {
    setAddShowModal(true);
    document.body.classList.add("modal-open");
    if (!document.getElementById("modal-backdrops")) {
    const backdrop = document.createElement("div");
    backdrop.setAttribute("id", "modal-backdrops");
    backdrop.classList.add("modal-backdrop", "fade", "show");
    document.body.appendChild(backdrop);
    }
  };

  const closeModal = () => {
    setEditShowModal(false);
    setAddShowModal(false);
    document.body.classList.remove("modal-open");
    const childElement = document.getElementById("modal-backdrops");
    childElement.parentNode.removeChild(childElement);
  };

  return (
    <>
      <div className="card-body" id="Customer-details">
        <div className="d-flex justify-content-between">
          <h2>Contact Details</h2>
          <p className="m-0 text-muted"></p>
        </div>
        {contacts.map((contact) => (
          <div className="card  position-relative mb-4 " key={contact.id}>
            <div className="delete-edit d-flex justify-content-end mt-2 mr-2">
              <button type="button" onClick={() => handleEditClick(contact)} className="btn p-1 text-primary">
                <i className="far fa-edit"></i>
              </button>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-2">
                  <label className="text-muted d-block form-label">Name</label>
                  <p>{contact.name}</p>
                </div>
                <div className="col-md-2">
                  <label className="text-muted d-block form-label">Job Title</label>
                  <p>{contact.jobTitle}</p>
                </div>
                <div className="col-md-3">
                  <label className="text-muted d-block form-label">Email</label>
                  <p>{contact.email}</p>
                </div>
                <div className="col-md-2">
                  <label className="text-muted d-block form-label">Phone</label>
                  <p>{contact.phone}</p>
                </div>
                <div className="col-md-3">
                  <label className="text-muted d-block form-label">Role</label>
                  <ul>
                    {contact.companyroles.map((each) => {
                      if (each.company.id === companyId) {
                        return <li>{each.role.role}</li>;
                      }
                      return null;
                    })}
                  </ul>
                </div>

                <div className="d-flex justify-content-between my-3">
                  <h3 className="text-muted">Address</h3>
                  <p className="m-0 text-muted"></p>
                </div>
                <div className="col-md-2">
                  <label className="text-muted d-block form-label">Street Address</label>
                  <p>{contact?.address?.address}</p>
                </div>
                <div className="col-md-2">
                  <label className="text-muted d-block form-label">City</label>
                  <p>{contact?.address?.city}</p>
                </div>
                <div className="col-md-3">
                  <label className="text-muted d-block form-label">Country</label>
                  <p>{contact?.address?.country}</p>
                </div>
                <div className="col-md-2">
                  <label className="text-muted d-block form-label">State</label>
                  <p>{contact?.address?.state}</p>
                </div>
                <div className="col-md-2 ">
                  <label className="text-muted d-block form-label">Zip</label>
                  <p>{contact?.address?.zip}</p>
                </div>
              </div>
            </div>
          </div>
        ))}

        {contacts.length === 0 && <div className="d-flex justify-content-center align-items-center">No contacts found</div>}
        <hr />
        <div className="btn-col text-center d-flex justify-content-between">
          <button type="button" onClick={() => openModal()} className="btn btn-outline-primary btn-lg">
            Add New Contact
          </button>
          <button type="button" disabled={!contacts.find((each) => each.email === email)} className="btn btn-primary btn-lg" onClick={() => history.push("/dashboard")}>
            Go to Dashboard
          </button>
        </div>
      </div>
      <AddContactForm showModal={showAddModal} setShowModal={setAddShowModal} getContact={getContacts} closeModal={closeModal} ID={"new-contact"} />
      <EditContactForm selectedContact={selectedContact} showModal={showEditModal} setShowModal={setEditShowModal} getContact={getContacts} closeModal={closeModal} ID={"edit-contact"} />
    </>
  );
};
