import axios from "axios";
import { API_URL } from ".";
const config = {
  headers: {
    authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

function search(data) {
  // const data={ "recordtype":"Address", "fieldId":"id", "value":25563 }
  if (data.recordtype && data.fieldId && data.value) {
    return axios.put(`${API_URL}/netsuite/search`, data, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } else {
    return "Required Field recordtype & fieldId && value";
  }
}

function createLead(data) {
  // const data={ "applicationId":"641bfc103872ae22a88cede4","worker":"createLead" }
  // return true;
  if (data.applicationId) {
    return axios.post(`${API_URL}/netsuite/createLead`, data, config);
  } else {
    return "Required Field applicationId & worker";
  }
}

function updateCustomer(data) {
  if (data.applicationId) {
    return axios.post(`${API_URL}/netsuite/updateCustomer`, data, config);
  } else {
    return "Required Field applicationId";
  }
}

function getNesuiteContactDetails(data) {
  // const data={"applicationId":"641bfc103872ae22a88cede4","worker":"createLead" }
  // return true;
  if (data.applicationId && data.worker) {
    return axios.put(`${API_URL}/netsuite/contactDetails`, data, config);
  } else {
    return "Required Field applicationId & worker";
  }
}

function getNetsuiteInitialValue(applicationID, record) {
  if (applicationID && record) {
    return axios.get(`${API_URL}/netsuite/netSuiteInitialValue?applicationID=${applicationID}&record=${record}`, config);
  } else {
    return "Required Field applicationId and record";
  }
}

function netSuiteUpsertRecords(applicationID, record, data) {
  if (applicationID && record) {
    return axios.post(`${API_URL}/netsuite/netSuiteUpsertRecords?applicationID=${applicationID}&record=${record}`, data, config);
  } else {
    return "Required Field applicationId and record";
  }
}

function salesOrderValidate(applicationID) {
  if (applicationID) {
    return axios.post(`${API_URL}/netsuite/salesOrderValidate?applicationID=${applicationID}`, {}, config);
  } else {
    return "Required Field applicationId and record";
  }
}

function getFees() {
  return axios.get(`${API_URL}/netsuite/getfees`, config);
}

function triggerTransaction(applicationID) {
  if (applicationID) {
    return axios.put(`${API_URL}/netsuite/triggerTransaction?id=${applicationID}`, {}, config);
  } else {
    return "Required Field applicationId";
  }
}

function upsertSupplementRecord(applicationID, data) {
  if (applicationID) {
    return axios.post(`${API_URL}/netsuite/upsertSupplementRecord?applicationID=${applicationID}`, data, config);
  } else {
    return "Required Field applicationId";
  }
}

const getCompanyByNameFromNetSuite = async (name, type) => {
  const object = {
    recordtype: "customer",
    fieldId: "entityid",
    value: name,
  };
  const response = await search(object);
  const { status, message: data } = response.data;
  if (status === "Success" && data?.length > 0) {
    return data.map((each) => {
      const { fields, id, sublists } = each;
      const { addressbook } = sublists;
      const { companyname, isperson } = fields;
      let address;
      let city;
      let state;
      let zip;
      if (typeof addressbook === "object") {
        for (const line in addressbook) {
          if (addressbook.hasOwnProperty(line)) {
            const { addr1_initialvalue, city_initialvalue, displaystate_initialvalue, zip_initialvalue } = addressbook[line];
            if (line === "line 1") {
              address = addr1_initialvalue || "";
              city = city_initialvalue || "";
              state = displaystate_initialvalue || fields?.billstate || "";
              zip = zip_initialvalue?.split(".")[0] || zip_initialvalue || "";
              break;
            }
          }
        }
      }

      return {
        id,
        isNetSuite: true,
        name: companyname,
        billingPeriod: "YEARLY",
        stateOfFormation: "North Carolina",
        applicantshipType: isperson === "T" ? "Individual" : "Limited Liability Company",
        billingAddress: {
          address,
          city,
          state,
          zip,
          country: "United States",
          poBox: "",
        },
        mailingAddress: {
          address,
          city,
          state,
          zip,
          country: "United States",
          poBox: "",
        },
      };
    });
  }
  return [];
};

export {
  createLead,
  updateCustomer,
  search,
  getNesuiteContactDetails,
  getNetsuiteInitialValue,
  netSuiteUpsertRecords,
  salesOrderValidate,
  getFees,
  triggerTransaction,
  upsertSupplementRecord,
  getCompanyByNameFromNetSuite,
};
