import React from "react";
import { useApplication } from "../../hooks/useApplication";

export const ApplicantDetailsModel = () => {
  const { applicantDetails = {} } = useApplication();
  const { primaryContact, billingContact, consultantContact, facilityCompany } = applicantDetails || {};
  return (
    <div className="modal fade" id="applicant-detail" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="1" aria-labelledby="applicant-detail" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
        <div className="modal-content h-100">
          <div className="modal-header">
            <h2 className="modal-title" id="resolve1">
              Applicant Information
            </h2>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner-form  card border-0 mb-4">
                    <div className="">
                      <div id="project-applicant">
                        <div className="card mb-4">
                          <div className="card-header bg-transparent">
                            <h3 className="mb-0">Applicant's Details</h3>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-3 mb-3">
                                <label className="text-muted d-block">Applicant's Legal Name:</label>
                                <p>
                                  {" "}
                                  <span id="copyTarget1">{facilityCompany?.name}</span>.
                                  <a href="#" className="ms-2" id="copyButton1">
                                    <i className="fal fa-copy" onClick={() => navigator.clipboard.writeText(facilityCompany?.name)}></i>
                                  </a>
                                </p>
                              </div>
                              <div className="col-md-3 mb-3">
                                <label className="text-muted d-block">Billing:</label>
                                <p>
                                  <span id="copyTarget2">{facilityCompany?.billingPeriod}</span>
                                  <a href="#" className="ms-2" id="copyButton2">
                                    <i className="fal fa-copy" onClick={() => navigator.clipboard.writeText(facilityCompany?.billingPeriod)}></i>
                                  </a>
                                </p>
                              </div>
                              <div className="col-md-3 mb-3">
                                <label className="text-muted d-block">Applicant is a:</label>
                                <p>
                                  <span id="copyTarget3">{facilityCompany?.applicantshipType}</span>
                                  <a href="#" className="ms-2" id="copyButton3">
                                    <i className="fal fa-copy" onClick={() => navigator.clipboard.writeText(facilityCompany?.applicantshipType)}></i>
                                  </a>
                                </p>
                              </div>
                              <div className="col-md-3 mb-3">
                                <label className="text-muted d-block">State of formation:</label>
                                <p>{facilityCompany?.stateOfFormation}</p>
                              </div>
                            </div>
                            <div className="card mb-4">
                              <div className="card-header">
                                <h4 className="text-muted mb-0">Applicant's Mailing Address</h4>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="text-muted d-block">Applicant's Mailing Address:</label>
                                    <p>
                                      <span id="copyTarget4">{facilityCompany?.mailingAddress?.address}</span>.
                                      <a href="#" className="ms-2" id="copyButton4">
                                        <i className="fal fa-copy" onClick={() => navigator.clipboard.writeText(facilityCompany?.mailingAddress?.address)}></i>
                                      </a>
                                    </p>
                                  </div>
                                  <div className="col-md-3">
                                    <label className="text-muted d-block">City</label>
                                    <p>{facilityCompany?.mailingAddress?.city}</p>
                                  </div>
                                  <div className="col-md-3">
                                    <label className="text-muted d-block">State</label>
                                    <p>{facilityCompany?.mailingAddress?.state}</p>
                                  </div>
                                  <div className="col-md-3">
                                    <label className="text-muted d-block">Zip</label>
                                    <p>{facilityCompany?.mailingAddress?.zip}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header">
                                <h4 className="text-muted mb-0">Applicant's Billing Address</h4>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="text-muted d-block">Billing Address\P.O. Box:</label>
                                    <p>
                                      <span id="copyTarget5">{facilityCompany?.billingAddress?.address}</span>.
                                      <a href="#" className="ms-2" id="copyButton5">
                                        <i className="fal fa-copy" onClick={() => navigator.clipboard.writeText(facilityCompany?.billingAddress?.address)}></i>
                                      </a>
                                    </p>
                                  </div>
                                  <div className="col-md-3">
                                    <label className="text-muted d-block">City</label>
                                    <p>{facilityCompany?.billingAddress?.city}</p>
                                  </div>
                                  <div className="col-md-3">
                                    <label className="text-muted d-block">State</label>
                                    <p>{facilityCompany?.billingAddress?.state}</p>
                                  </div>
                                  <div className="col-md-3">
                                    <label className="text-muted d-block">Zip</label>
                                    <p>{facilityCompany?.billingAddress?.zip}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card mb-4 ">
                          <div className="card-header bg-transparent">
                            <div className="d-sm-flex justify-content-between align-items-center">
                              <h3 className="mb-3 mb-sm-0">Applicant's Contacts</h3>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="">
                              <div className="card position-relative mb-3">
                                <div className="card-header">
                                  <div className="d-sm-flex justify-content-between">
                                    <h4 className="text-muted mb-0">Applicant's Primary Contact</h4>
                                  </div>
                                </div>
                                <div className="card-body ">
                                  <div className="row">
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Name</label>
                                      <p className="text">
                                        <span id="copyTarget6">{consultantContact?.name}</span>
                                        <a href="#" className="ms-2" id="copyButton6">
                                          <i className="fal fa-copy" onClick={() => navigator.clipboard.writeText(consultantContact?.name)}></i>
                                        </a>
                                      </p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Title</label>
                                      <p className="text">{consultantContact?.jobTitle}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Email</label>
                                      <p className="text">{consultantContact?.email}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Tel Number</label>
                                      <p className="text">{consultantContact?.phone}</p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Street Address\P.O. Box:</label>
                                      <p>{consultantContact?.address?.address}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">City</label>
                                      <p>{consultantContact?.address?.city}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">State</label>
                                      <p>{consultantContact?.address?.state}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Zip</label>
                                      <p>{consultantContact?.address?.zip}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card mb-3 position-relative">
                                <div className="card-header">
                                  <div className="d-sm-flex justify-content-between">
                                    <h4 className="text-muted mb-0">Applicant's Billing Contact</h4>
                                  </div>
                                </div>
                                <div className="card-body ">
                                  <div className="row">
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Name</label>
                                      <p className="text">
                                        <span id="copyTarget7">{billingContact?.name}</span>
                                        <a href="#" className="ms-2" id="copyButton7">
                                          <i className="fal fa-copy" onClick={() => navigator.clipboard.writeText(billingContact?.name)}></i>
                                        </a>
                                      </p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Title</label>
                                      <p className="text">{billingContact?.jobTitle}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Email</label>
                                      <p className="text">{billingContact?.email}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Tel Number</label>
                                      <p className="text">{billingContact?.address?.zip}</p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Street Address</label>
                                      <p>{billingContact?.address?.address}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">City</label>
                                      <p>{billingContact?.address?.city}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">State</label>
                                      <p>{billingContact?.address?.state}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Zip</label>
                                      <p>{billingContact?.address?.zip}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card mb-3">
                                <div className="card-header">
                                  <div className="d-sm-flex justify-content-between">
                                    <h4 className="text-muted mb-0">Applicant's Engineering Representative</h4>
                                  </div>
                                </div>
                                <div className="card-body ">
                                  <div className="row">
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Name</label>
                                      <p>{primaryContact?.name}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Title</label>
                                      <p>{primaryContact?.jobTitle}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Email</label>
                                      <p>{primaryContact?.email}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Tel Number</label>
                                      <p>{"--"}</p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Street Address\P.O. Box:</label>
                                      <p>{primaryContact?.address?.address}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">City</label>
                                      <p>{primaryContact?.address?.city}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">State</label>
                                      <p>{primaryContact?.address?.state}</p>
                                    </div>
                                    <div className="col-md-3">
                                      <label className="text-muted d-block">Zip</label>
                                      <p>{primaryContact?.address?.zip}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
