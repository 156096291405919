export const showOnSelect = (e, showOnSelect, show, schemaContext, params) => {
  if (e.target.checked) {
    if (showOnSelect && show === true) {
      const newSchema = schemaContext.schema.sections.map((question) => {
        const newQuestions = question.questions.map((hideFunction) => {
          if (showOnSelect === hideFunction.id) {
            return (hideFunction.hide = false);
          }
          return hideFunction;
        });
        return { ...question, questions: newQuestions };
      });
      schemaContext.setSchema({ sections: newSchema });
      localStorage.setItem(params + "schema", JSON.stringify({ sections: newSchema }));
      // console.log("renderfunction 14")
      const newSchemaValidation = schemaContext.schema.sections.map((section) => {
        section.questions.map((row) => {
          if (row.id === showOnSelect) {
            row.rows.map((col) => {
              col.columns.map((valid) => valid.validation && (valid.validation.required = true));
            });
          }
          return row;
        });
        return section;
      });
      schemaContext.setSchema({ sections: newSchemaValidation });
      localStorage.setItem(params + "schema", JSON.stringify({ sections: newSchemaValidation }));
      // console.log("renderfunction 36")
    } else {
      const newSchema = schemaContext.schema.sections.map((question) => {
        question.questions.map((hideFunction) => {
          if (showOnSelect === hideFunction.id) {
            hideFunction.hide = true;
          }
        });
        return question;
      });
      schemaContext.setSchema({ sections: newSchema });
      localStorage.setItem(params + "schema", JSON.stringify({ sections: newSchema }));
      // console.log("renderfunction 48")
      const newSchemaValidation = schemaContext.schema.sections.map((section) => {
        section.questions.map((row) => {
          if (row.id === showOnSelect) {
            row.rows.map((col) => {
              col.columns.map((valid) => valid.validation && (valid.validation.required = false));
            });
          }
          return row;
        });
        return section;
      });
      schemaContext.setSchema({ sections: newSchemaValidation });
      localStorage.setItem(params + "schema", JSON.stringify({ sections: newSchemaValidation }));
      // console.log("renderfunction 70")
    }
  } else {
    const newSchema = schemaContext.schema.sections.map((question) => {
      question.questions.map((hideFunction) => {
        if (showOnSelect === hideFunction.id) {
          hideFunction.hide = true;
        }
      });
      return question;
    });
    schemaContext.setSchema({ sections: newSchema });
    localStorage.setItem(params + "schema", JSON.stringify({ sections: newSchema }));
    // console.log("renderfunction 83")
    const newSchemaValidation = schemaContext.schema.sections.map((section) => {
      section.questions.map((row) => {
        if (row.id === showOnSelect) {
          row.rows.map((col) => {
            col.columns.map((valid) => valid.validation && (valid.validation.required = false));
          });
        }
        return row;
      });
      return section;
    });
    schemaContext.setSchema({ sections: newSchemaValidation });
    localStorage.setItem(params + "schema", JSON.stringify({ sections: newSchemaValidation }));
    // console.log("renderfunction 102")
  }
};
export const showOnHide = (e, showOnHide, show, schemaContext, params) => {
  if (showOnHide) {
    const newSchema = schemaContext.schema.sections.map((question) => {
      question.questions.map((hideFunction) => {
        showOnHide.map((d) => {
          if (d === hideFunction.id) {
            hideFunction.hide = true;
          }
        });
      });
      return question;
    });
    schemaContext.setSchema({ sections: newSchema });
    localStorage.setItem(params + "schema", JSON.stringify({ sections: newSchema }));
    // console.log("renderfunction 119")
    const newSchemaValidation = schemaContext.schema.sections.map((section) => {
      section.questions.map((row) => {
        if (
          showOnHide.map((r) => {
            if (r === row.id) {
              row.rows.map((col) => {
                col.columns.map((valid) => valid.validation && (valid.validation.required = false));
              });
            }
          })
        ) {
        }
        return row;
      });
      return section;
    });
    schemaContext.setSchema({ sections: newSchemaValidation });
    localStorage.setItem(params + "schema", JSON.stringify({ sections: newSchemaValidation }));
    // console.log("renderfunction 144")
  }
};
export const multipleRender = (e, multipleRender, show, schemaContext, params) => {
  if (e.target.checked) {
    const newSchema = schemaContext.schema.sections.map((question) => {
      question.questions.map((hideFunction) => {
        multipleRender &&
          multipleRender.map((d) => {
            if (d === hideFunction.id) {
              hideFunction.hide = false;
            }
          });
      });
      return question;
    });
    schemaContext.setSchema({ sections: newSchema });
    localStorage.setItem(params + "schema", JSON.stringify({ sections: newSchema }));
    // console.log("renderfunction 168")
  } else {
    const newSchema = schemaContext.schema.sections.map((question) => {
      question.questions.map((hideFunction) => {
        multipleRender &&
          multipleRender.map((d) => {
            if (d === hideFunction.id) {
              hideFunction.hide = true;
            }
          });
      });
      return question;
    });
    schemaContext.setSchema({ sections: newSchema });
    localStorage.setItem(params + "schema", JSON.stringify({ sections: newSchema }));
    // console.log("renderfunction 183")
  }
};
export const showCheckBoxGroup = (e, key, type, schemaContext) => {
  if (e.target.checked) {
    if (type === "checkbox") {
      schemaContext.setCheckBoxKey(key);
    }
    if (type === "radio") {
      schemaContext.setRadioKey(key);
    }
  }
};
