/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { createContext, useContext } from "react";
import { useForm } from "react-hook-form";
import { HandleForms } from "../HandleForms";
import { formContext } from "../../routes/routes";
import { useParams } from "react-router-dom";
export const watchContext = createContext();

export const FormOnAFly = ({ children, submit, setSchema, indication, index, lastIndex, array, hide, editPage }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    unregister,
    clearErrors,
  } = useForm({
    criteriaMode: "all",
  });
  let params = useParams();
  const merge = (a, b) => Object.assign({}, a, ...Object.entries(b).map(([k, v]) => (v === undefined ? {} : { [k]: v })));
  React.useEffect(() => {
    const subscription = watch(async (value, { name, type }) => {
      let values = JSON.parse(localStorage.getItem(params.applicationID));
      let date = new Date();
      localStorage.setItem(params.applicationID + "lastModifiedOn", date);
      if (values) {
        // values = merge(values,value)
        if (
          value["RailRoad line transverse"] === undefined ||
          value["RailRoad line Begin longitudinal"] === undefined ||
          value["RailRoad line End longitudinal"] === undefined ||
          value["RailRoad line Begin longitudinal + transvere"] === undefined ||
          value["RailRoad line End longitudinal + transvere"] === undefined
        ) {
          value["RailRoad line transverse"] = values["RailRoad line transverse"];
          value["RailRoad line Begin longitudinal"] = values["RailRoad line Begin longitudinal"];
          value["RailRoad line End longitudinal"] = values["RailRoad line End longitudinal"];
          value["RailRoad line Begin longitudinal + transvere"] = values["RailRoad line Begin longitudinal + transvere"];
          value["RailRoad line End longitudinal + transvere"] = values["RailRoad line End longitudinal + transvere"];
        }
        if (
          values["RailRoad line transverse"] === undefined ||
          values["RailRoad line Begin longitudinal"] === undefined ||
          values["RailRoad line End longitudinal"] === undefined ||
          values["RailRoad line Begin longitudinal + transvere"] === undefined ||
          values["RailRoad line End longitudinal + transvere"] === undefined
        )
          // values = { ...value,...values};

          values = { ...values, ...value };
        values["RailRoad line transverse"] = localStorage.setItem(params.applicationID, JSON.stringify(values));
        // console.log("Local storage updated");
      } else {
        localStorage.setItem(params.applicationID, JSON.stringify(value));
        // console.log("Local storage updated");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const schemaContext = useContext(formContext);

  const changeFunction = (name, e) => {
    let data = {
      [name]: e,
    };
    let values = JSON.parse(localStorage.getItem(params.applicationID));
    if (values) {
      values = { ...values, ...data };
      localStorage.setItem(params.applicationID, JSON.stringify(values));
      // console.log("Local storage updated");
    } else {
      localStorage.setItem(params.applicationID, JSON.stringify(data));
      // console.log("Local storage updated");
    }
  };
  const w = watch();

  // if(w["RailRoad line transverse"] === undefined || w["Railroad Milestone MP"] === undefined){
  //   var existing = JSON.parse(localStorage.getItem(params.applicationID));
  //   if (existing !== null) {
  //     if(existing["RailRoad line transverse"] && w["RailRoad line transverse"] === undefined){
  //       w["RailRoad line transverse"]= existing["RailRoad line transverse"];
  //     }
  //     if(existing["Railroad Milestone MP"] && w["Railroad Milestone MP"] === undefined){
  //       w["Railroad Milestone MP"]= Number(existing["Railroad Milestone MP"]);
  //     }
  //   }
  // }

  const fun = () => {};
  let radioKey = (localStorage.getItem("renderKeyRadio") && localStorage.getItem("renderKeyRadio").split(",")) || [];
  let checkKey = (localStorage.getItem("renderKeyCheckBox") && localStorage.getItem("renderKeyCheckBox").split(",")) || [];
  let hideKey = (localStorage.getItem("hideKey") && localStorage.getItem("hideKey").split(",")) || [];

  let checkArray = checkKey.map((c) => Number(c));
  let radioArray = radioKey.map((r) => Number(r));
  let hideK = hideKey.map((k) => Number(k));
  let user;
  for (var i = 0; i < radioArray.length; i++) {
    user = radioArray[i];
    if (checkArray.indexOf(user) >= 0) {
      let schema = schemaContext.schema.sections.map((sec) => {
        if (sec.label === "Applicant's Checklist") {
          sec.questions.map((question) => {
            if (question.id === user) {
              question.hide = false;
            }
          });
        }
        return sec;
      });
    }
  }
  let hides;
  if (hideKey) {
    for (var i = 0; i < radioArray.length; i++) {
      hides = radioArray[i];
      if (checkArray.indexOf(hides) >= 0) {
        schemaContext.schema.sections.map((sec) => {
          if (sec.label === "Applicant's Checklist") {
            sec.questions.map((question) => {
              if (question.id === hides) {
                question.hide = true;
              }
            });
          }
          return sec;
        });
      }
    }
  }

  return (
    <>
      <form
        //onSubmit={handleSubmit((data) => submit(data, index))}
        onSubmit={handleSubmit((data) => {
          window.scrollTo(0, 0);
          return submit(data, index);
        })}
        className="" //d-flex flex-column align-items-center
      >
        <watchContext.Provider value={w}>
          <HandleForms
            setSchema={setSchema}
            children={children}
            register={register}
            unregister={unregister}
            errors={errors}
            clearErrors={clearErrors}
            watch={watch}
            changeFunction={changeFunction}
            editPage={editPage}
          />
        </watchContext.Provider>

        <div className="d-flex justify-content-center">
          <button type="submit" className="btn btn-primary btn-lg mt-4" disabled={index + 1 === lastIndex && array.includes(0)}>
            {/* {indication ? (
              <>
                {" "}
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                Submitting...{" "}
              </>
            ) : ( */}

            <>{editPage ? "save" : index + 1 === lastIndex ? "Submit Application" : "Next Step"} </>
            {/* )} */}
          </button>
        </div>
      </form>
    </>
  );
};
