import FinalReleasePage from "../components/compiledAgreement/FinalReleasePage";
import DashBoardLoading from "../components/loaders/DashBoardLoading";
import { useApplication } from "../hooks/useApplication";
import { useUser } from "../hooks/useUser";

const Page = (props) => {
  const application = useApplication();
  const user = useUser();
  if (!application || !user) {
    return <DashBoardLoading />;
  }
  return <FinalReleasePage {...props} />;
};

export default Page;
