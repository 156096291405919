import React, { useState, useEffect } from "react";
import { HandleFieldType } from "../HandleFieldType";
import Popup from "./Popup";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { formContext } from "../../routes/routes";

function PopupButton({ conduitlist = [], carrier_position_index, css, text, cbody, register, errors, handleChange, addbody, head, id, review }) {
  let [buttonPopup, setButtonPopup] = useState(false);
  const [addCeriier, setAddCeriier] = useState(" ");

  let params = useParams();
  let applicationID = params.applicationID;
  let local_schema = JSON.parse(localStorage.getItem(`${applicationID}schema`)) || {};
  const local_application_details = JSON.parse(localStorage.getItem(applicationID)) || {};
  const schemaContext = useContext(formContext);

  const handleSubmit = (e, text) => {
    e.preventDefault();
    setAddCeriier(text);

    if (text === "Add casing") {
      let index = 0;
      let count = 0;
      schemaContext.schema.sections.forEach((section) => {
        if (section.label === "Facilities Details") {
          section.questions.forEach((question) => {
            if (question.header.includes("casing")) {
              count++;
            }
          });
        }
      });

      const lastCount = count;
      for (let casing of addbody.rows) {
        casing.columns.forEach((element) => {
          if (element?.text?.includes("Add Carrier")) {
            element.carrier_position_index = lastCount;
          } else {
            element.text = local_application_details[element.text];
          }
        });
      }

      addbody = Object.assign(addbody, { carrierindex: index });

      addbody.header = `${addbody.header}#${count + 1}`;
      addbody.index = count;
      addbody.id = addbody.id + count; //casing question id update
      addbody.rows[0].columns[0].carrier_index_value = count;
      local_schema.sections[2].questions.push(addbody);

      let carrierindex = 0;
      schemaContext.schema.sections.forEach((section, sectionindex) => {
        if (section.label === "Facilities Details") {
          local_schema.sections[sectionindex].questions.forEach((question, questionindex) => {
            if (question.header.includes("casing")) {
              local_schema.sections[sectionindex].questions[questionindex].header = "casing#" + ++carrierindex;
            }
          });
        }
      });
      schemaContext.setSchema(local_schema);
    }

    if (text === "Add Carrier") {
      for (let carrier of addbody.rows) {
        carrier.columns.forEach((element) => {
          if (element.wire === "wire") {
            element.text = "";
            element.text = local_application_details["Wire details"];
          } else {
            element.text = local_application_details[element.text];
          }
        });
      }

      schemaContext.schema.sections.forEach((section, sectionindex) => {
        if (section.label === "Facilities Details") {
          section.questions.forEach((question, index) => {
            if (question.index === carrier_position_index) {
              addbody.carrier_position_index = carrier_position_index;
              let conduitlistlength = local_schema.sections[2].questions[index].rows[3].columns[0].conduitlist.length;
              addbody.conduit_position_index = conduitlistlength;
              addbody.rows[0].columns[0].carrier_index_value = carrier_position_index;
              addbody.rows[0].columns[0].conduit_index_value = addbody.conduit_position_index;
              addbody.id += addbody.conduit_position_index;
              local_schema.sections[sectionindex].questions[index].rows[3].columns[0].conduitlist.push(addbody);
              local_schema.sections[sectionindex].questions[index].rows[3].columns[0].conduitlist.forEach((carrier, conduitindex) => {
                local_schema.sections[sectionindex].questions[index].rows[3].columns[0].conduitlist[conduitindex].id += conduitindex;
              });
            }
          });
        }
      });

      schemaContext.setSchema(local_schema);
    }

    localStorage.setItem(`${applicationID}schema`, JSON.stringify(local_schema));

    //to Empty the Value
    if (text === "Add casing") {
      if (document.getElementById("TOTAL BURIED LENGTH ON THE NCRR CORRIDOR")) {
        document.getElementById("TOTAL BURIED LENGTH ON THE NCRR CORRIDOR").value = "";
      }
      if (document.getElementById("BURY DEPTH FROM BASE OF RAIL TO TOP OF CASING")) {
        document.getElementById("BURY DEPTH FROM BASE OF RAIL TO TOP OF CASING").value = "";
      }
      if (document.getElementById("BURY DEPTH BELOW DITCHES")) {
        document.getElementById("BURY DEPTH BELOW DITCHES").value = "";
      }
      if (document.getElementById("MINIMUM DEPTH ON THE NCRR CORRIDOR BUT NOT BENEATH TRACKS")) {
        document.getElementById("MINIMUM DEPTH ON THE NCRR CORRIDOR BUT NOT BENEATH TRACKS").value = "";
      }
    }
    if (text === "Add Carrier") {
      if (document.getElementById("ENCASEMENT MATERIAL")) {
        document.getElementById("ENCASEMENT MATERIAL").value = "";
      }
      if (document.getElementById("NOMINAL DIAMETER")) {
        document.getElementById("NOMINAL DIAMETER").value = "";
      }
      if (document.getElementById("WALL THICKNESS")) {
        document.getElementById("WALL THICKNESS").value = "";
      }
      if (document.getElementById("Wire details")) {
        document.getElementById("Wire details").value = "";
      }
    }
    setButtonPopup(false);
  };

  useEffect(() => {
    if (addCeriier === "Add casing") {
      if (document.getElementById("TOTAL BURIED LENGTH ON THE NCRR CORRIDOR")) {
        document.getElementById("TOTAL BURIED LENGTH ON THE NCRR CORRIDOR").value = "";
      }
      if (document.getElementById("BURY DEPTH FROM BASE OF RAIL TO TOP OF CASING")) {
        document.getElementById("BURY DEPTH FROM BASE OF RAIL TO TOP OF CASING").value = "";
      }
      if (document.getElementById("BURY DEPTH BELOW DITCHES")) {
        document.getElementById("BURY DEPTH BELOW DITCHES").value = "";
      }
      if (document.getElementById("MINIMUM DEPTH ON THE NCRR CORRIDOR BUT NOT BENEATH TRACKS")) {
        document.getElementById("MINIMUM DEPTH ON THE NCRR CORRIDOR BUT NOT BENEATH TRACKS").value = "";
      }
    }
    if (addCeriier === "Add Carrier") {
      if (document.getElementById("ENCASEMENT MATERIAL")) {
        document.getElementById("ENCASEMENT MATERIAL").value = "";
      }
      if (document.getElementById("NOMINAL DIAMETER")) {
        document.getElementById("NOMINAL DIAMETER").value = "";
      }
      if (document.getElementById("WALL THICKNESS")) {
        document.getElementById("WALL THICKNESS").value = "";
      }
      if (document.getElementById("Wire details")) {
        document.getElementById("Wire details").value = "";
      }
    }
  }, [buttonPopup, addCeriier]);

  const popUp = (text) => {
    setAddCeriier(text);
    setButtonPopup(true);
  };
  return (
    <>
      <div className={text !== "Add casing" ? "card border-dark" : ""}>
        <div className={text !== "Add casing" ? "card-header border-dark bg-transparent" : ""}>
          {conduitlist.map((carrier, index) => {
            carrier.header = "carrier#" + (index + 1);
            carrier.conduit_position_index = index;
            return <HandleFieldType items={carrier} review={review} />;
          })}

          <div className="d-flex align-items-center justify-content-between">
            {review && head == "Casings, Carriers and Wires" ? null : <h3 className="m-0">{head}</h3>}

            {!review ? (
              <a className="btn btn-outline-primary" style={text === "Add Carrier" ? { width: "100%" } : null} onClick={() => popUp(text)}>
                {text}
              </a>
            ) : (
              ""
            )}

            <Popup category={cbody.header} trigger={buttonPopup} setTrigger={setButtonPopup}>
              <HandleFieldType items={cbody} register={register} errors={errors} handleChange={handleChange} />
              <div className="d-flex justify-content-end align-items-center pt-3">
                <a className="btn btn-secondary" onClick={() => setButtonPopup(false)}>
                  Close
                </a>
                <a className="btn btn-primary text-white" onClick={(e) => handleSubmit(e, text)}>
                  {text}
                </a>
              </div>
            </Popup>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopupButton;
