const addawire = {
  header: "Type of Wires",
  carrier_index: 0,
  conduit_index: 0,
  rows: [
    {
      columns: [
        {
          type: "radio",
          name: "Type of Wires",
          label: "Type of Wires",
          requiredLable: true,
          validation: {
            required: true,
          },
          options: [
            {
              label: "Cable TV",
              value: "Cable TV",
              showOnSelect: 2,
              show: false,
            },
            {
              label: "Telephone",
              value: "Telephone",
              showOnSelect: 2,
              show: true,
            },
            {
              label: "Electric Power ",
              value: "Electric Power",
              showOnSelect: 2,
              show: true,
            },
            {
              label: "Communications",
              value: "Communications",
              showOnSelect: 2,
              show: false,
            },
            {
              label: "Communications",
              value: "Communications",
              showOnSelect: 2,
              show: false,
            },
          ],
        },
      ],
    },
  ],
};
const conduitbody = {
  header: "carrier",
  conduit_position_index: 0,
  carrier_position_index: 0,
  id: 38,
  rows: [
    {
      columns: [
        {
          type: "delete",
          carrier_index_value: 0,
          conduit_index_value: 0,
        },
      ],
    },
    {
      columns: [
        {
          type: "heading",
          label: "ENCASEMENT MATERIAL:",
          text: "ENCASEMENT MATERIAL",
          field: " ",
          validation: {
            required: false,
          },
        },
        {
          type: "heading",
          label: "NOMINAL DIAMETER:",
          text: "NOMINAL DIAMETER",
          field: "Inches",
          validation: {
            required: false,
          },
        },
        {
          type: "heading",
          label: "WALL THICKNESS:",
          text: "WALL THICKNESS",
          field: "Inches",
          validation: {
            required: false,
          },
        },
      ],
    },
    {
      columns: [
        {
          type: "heading",
          label: "ENCLOSED WIRES:",
          wire: "wire",
          text: "",
        },
      ],
    },
  ],
};
const addaconduit = {
  header: "Carriers",
  index: 0,
  rows: [
    {
      columns: [
        {
          type: "text",
          label: "ENCASEMENT MATERIAL:",
          name: "ENCASEMENT MATERIAL",
        },
        {
          type: "number",
          label: "NOMINAL DIAMETER:",
          name: "NOMINAL DIAMETER",
          fieldLabelPosition: "right",
          fieldLabel: "Inches",
        },
      ],
    },
    {
      columns: [
        {
          type: "number",
          label: "WALL THICKNESS:",
          name: "WALL THICKNESS",
          fieldLabelPosition: "right",
          fieldLabel: "Inches",
        },
      ],
    },
    {
      columns: [
        {
          type: "heading",
          text: "Wire details",
        },
      ],
    },
    {
      columns: [
        {
          type: "textarea",
          label: "Example:3 Cable TV wires, 2 Fiber Optic wires ",
          name: "Wire details",
        },
      ],
    },
  ],
};
const addacarrier = {
  header: "Casings",
  rows: [
    {
      columns: [
        {
          type: "number",
          label: "TOTAL BURIED LENGTH ON THE NCRR CORRIDOR",
          name: "TOTAL BURIED LENGTH ON THE NCRR CORRIDOR",
          fieldLabelPosition: "right",
          fieldLabel: "Feet",
        },
      ],
    },
    {
      columns: [
        {
          type: "number",
          label: "BURY DEPTH FROM BASE OF RAIL TO TOP OF CASING",
          name: "BURY DEPTH FROM BASE OF RAIL TO TOP OF CASING",
          fieldLabelPosition: "right",
          fieldLabel: "Feet",
        },
      ],
    },
    {
      columns: [
        {
          type: "number",
          label: "BURY DEPTH BELOW DITCHES",
          name: "BURY DEPTH BELOW DITCHES",
          fieldLabelPosition: "right",
          fieldLabel: "Feet",
        },
      ],
    },
    {
      columns: [
        {
          type: "number",
          label: "MINIMUM DEPTH ON THE NCRR CORRIDOR BUT NOT BENEATH TRACKS",
          name: "MINIMUM DEPTH ON THE NCRR CORRIDOR BUT NOT BENEATH TRACKS",
          fieldLabelPosition: "right",
          fieldLabel: "Feet",
        },
      ],
    },
  ],
};
const carrierbody = {
  header: "casing",
  casing: "casing",
  index: 0,
  id: 39,
  rows: [
    {
      columns: [
        {
          type: "delete",
          carrier_index_value: 0,
          text: "Edit casing",
          cbody: addacarrier,
        },
      ],
    },
    {
      columns: [
        {
          type: "heading",
          label: "TOTAL BURIED LENGTH ON THE NCRR CORRIDOR",
          text: "TOTAL BURIED LENGTH ON THE NCRR CORRIDOR",
          field: "Feet",
          validation: {
            required: false,
          },
        },
        {
          type: "heading",
          label: "BURY DEPTH FROM BASE OF RAIL TO TOP OF CASING",
          text: "BURY DEPTH FROM BASE OF RAIL TO TOP OF CASING",
          field: "Feet",
          validation: {
            required: false,
          },
        },
      ],
    },
    {
      columns: [
        {
          type: "heading",
          field: "Feet",
          label: "BURY DEPTH BELOW DITCHES",
          text: "BURY DEPTH BELOW DITCHES",
          validation: {
            required: false,
          },
        },
        {
          type: "heading",
          label: "MINIMUM DEPTH ON THE NCRR CORRIDOR BUT NOT BENEATH TRACKS",
          text: "MINIMUM DEPTH ON THE NCRR CORRIDOR BUT NOT BENEATH TRACKS",
          field: "Feet",
          validation: {
            required: false,
          },
        },
      ],
    },
    {
      columns: [
        {
          carrier_position_index: 0,
          type: "popup_button",
          text: "Add Carrier",
          cbody: addaconduit,
          addbody: conduitbody,
          conduitlist: [],
          validation: {
            required: false,
          },
        },
      ],
    },
  ],
};
const wireOccupanySchema = {
  sections: [
    {
      label: "Project Information",
      formType: "form210Schema",
      questions: [
        {
          id: 1,
          header: "Proposed work",
          rows: [
            {
              columns: [
                {
                  type: "checkbox",
                  name: "Proposed Work Involves",
                  label: "Proposed Work Involves",
                  requiredLable: true,
                  validation: {
                    required: true,
                  },
                  options: [
                    {
                      label: "Installation of a new facility",
                      value: "Installation of a new facility",
                      show: false,
                    },
                    {
                      label: "Revision to existing facility",
                      value: "Revision to existing facility",
                      showOnSelect: 2,
                      show: true,
                    },
                    {
                      label: "Upgrade to Existing Facility ",
                      value: "Upgrade to Existing Facility",
                      showOnSelect: 2,
                      show: true,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 2,
          hide: true,
          header: "Existing Agreement",

          rows: [
            {
              columns: [
                {
                  type: "radio",
                  requiredLable: true,
                  label: "Existing Agreement for Installation?",
                  name: "Existing Agreement For Installation",
                  validation: {
                    required: false,
                  },
                  options: [
                    {
                      value: "Yes Existing Agreement for Installation",
                      label: "Yes",
                      showOnSelect: 19,
                      show: true,
                    },
                    {
                      value: "No Existing Agreement for Installation",
                      label: "No",
                      showOnSelect: 0,
                      show: false,
                    },
                    {
                      value: "Unknown Existing Agreement for Installation",
                      label: "Unknown",
                      showOnSelect: 0,
                      show: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 3,
          title: "Location of installation",
          header: "Location of Installation",
          rows: [
            {
              label: "",
              columns: [
                {
                  validation: {
                    required: true,
                  },
                  requiredLable: true,
                  type: "text",
                  label: "Nearest Street",
                  name: "Nearest Street",
                  placeholder: "",
                },
                {
                  type: "text",
                  requiredLable: true,
                  label: "Nearest Town",
                  name: "Nearest Town",
                  placeholder: "",
                  validation: {
                    required: true,
                  },
                },
              ],
            },
            {
              label: "",

              columns: [
                {
                  requiredLable: true,
                  type: "dropdown",
                  label: "County",
                  name: "county",
                  options: [
                    "Select",
                    "Alamance",
                    "Cabarrus",
                    "Carteret",
                    "Craven",
                    "Davidson",
                    "Durham",
                    "Guilford",
                    "Johnston",
                    "Jones",
                    "Lenoir",
                    "Mecklenburg",
                    "Orange",
                    "Randolph",
                    "Rowan",
                    "Wake",
                    "Wayne",
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  requiredLable: true,
                  type: "dropdown",
                  label: "Country",
                  name: "Country",
                  options: ["Select", "United States"],
                  validation: {
                    required: true,
                  },
                },
                {
                  requiredLable: true,
                  type: "dropdown",
                  label: "State",
                  name: "State",
                  options: [
                    "Select",
                    "North Carolina",
                    // "Alaska",
                    // "Alabama",
                    // "Arkansas",
                    // "American Samoa",
                    // "Arizona",
                    // "California",
                    // "Colorado",
                    // "Connecticut",
                    // "District of Columbia",
                    // "Delaware",
                    // "Florida",
                    // "Georgia",
                    // "Guam",
                    // "Hawaii",
                    // "Iowa",
                    // "Idaho",
                    // "Illinois",
                    // "Indiana",
                    // "Kansas",
                    // "Kentucky",
                    // "Louisiana",
                    // "Massachusetts",
                    // "Maryland",
                    // "Maine",
                    // "Michigan",
                    // "Minnesota",
                    // "Missouri",
                    // "Mississippi",
                    // "Montana",

                    // " North Dakota",
                    // "Nebraska",
                    // "New Hampshire",
                    // "New Jersey",
                    // "New Mexico",
                    // "Nevada",
                    // "New York",
                    // "Ohio",
                    // "Oklahoma",
                    // "Oregon",
                    // "Pennsylvania",
                    // "Puerto Rico",
                    // "Rhode Island",
                    // "South Carolina",
                    // "South Dakota",
                    // "Tennessee",
                    // "Texas",
                    // "Utah",
                    // "Virginia",
                    // "Virgin Islands",
                    // "Vermont",
                    // "Washington",
                    // "Wisconsin",
                    // "West Virginia",
                    // "Wyoming",
                  ],
                  placeholder: "",
                  validation: {
                    required: true,
                  },
                },
              ],
            },

            {
              label: "",
              columns: [
                {
                  type: "number",
                  label: "Latitude",
                  name: "latitude",
                  placeholder: "",
                  requiredLable: true,
                  validation: {
                    required: true,
                  },
                },
                {
                  type: "number",
                  label: "Longitude",
                  name: "longitude",
                  placeholder: "",
                  requiredLable: true,
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 4,
          header: "Orientation of Installation",
          requiredLable: true,
          rows: [
            {
              label: "",
              columns: [
                {
                  type: "radio",
                  validation: {
                    required: true,
                  },
                  requiredLable: true,
                  label: "Orientation of proposed pipeline installation",
                  options: [
                    {
                      label: "Transverse crossing only",
                      value: "Transverse crossing only",
                      show: true,
                      showOnSelect: 5,
                      showOnHide: [6, 7],
                      key: [24],
                    },

                    {
                      label: "Longitudinal (parallel to tracks) only",
                      value: "Longitudinal parallel to tracks only",
                      show: true,
                      showOnSelect: 6,
                      showOnHide: [5, 7],
                      hideKey: [24],
                    },
                    {
                      label: "Longitudinal and transverse crossing",
                      value: "Longitudinal and transverse crossing",
                      showOnSelect: 7,
                      show: true,
                      showOnHide: [5, 6],
                      hideKey: [24],
                    },
                  ],
                  placeholder: "",
                  name: "Orientation of proposed pipeline installation",
                },
              ],
            },
          ],
        },
        {
          id: 5,
          header: "For a transverse crossing under the tracks",
          hide: true,
          rows: [
            {
              label: "For a transverse crossing under the tracks:",
              lablevalue: "Railroad line Map",
              labelStyle: true,

              columns: [
                {
                  type: "dropdown",
                  //align: "col-md-4",
                  name: "RailRoad line transverse",
                  label: "Railroad Line",
                  options: ["Mainline", "H-Line ", "EC-Line"],
                  requiredLable: true,
                },
                {
                  validation: {
                    required: true,
                  },
                  type: "text",
                  label: "Railroad Milepost Reference*:",
                  name: "Railroad Milestone MP",
                  name1: "Railroad Mailstone Feet",
                  placeholder: "",
                  fieldType: "group",
                  requiredLable: true,
                },
              ],
            },
            {
              columns: [
                {
                  type: "comments",
                  comments:
                    "Use the next lowest whole milepost adjacent to the project location + feet in the direction of increasing milepost to the project location. <br/><span id='range1'></span>",
                  commentsIconIsRequired: true,
                  commentsStyle: {
                    color: "#6C757D",
                    fontSize: "13px",
                    fontStyle: "italic",
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "number",
                  name: "Total Length of Crossing on the NCRR Corridor",
                  label: "Total Length of Crossing on the NCRR Corridor:",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
                {},
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "number",
                  name: "Number of tracks to be crossed:",
                  label: "Number of tracks to be crossed:",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
                {
                  type: "number",
                  name: "Angle of crossing:",
                  label: "Angle of crossing:",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 6,
          hide: true,
          header: "For a longitudinal occupancy only",
          rows: [
            {
              label: "For a longitudinal parallel to tracks only",
              lablevalue: "Railroad line Map",
              labelStyle: true,
              columns: [
                {
                  type: "dropdown",
                  // align: "col-md-4",
                  name: "RailRoad line Begin longitudinal",
                  label: "Railroad Line Begin",
                  options: ["Mainline", "H-Line ", "EC-Line"],
                  requiredLable: true,
                },
                {
                  validation: {
                    required: true,
                  },
                  requiredLable: true,

                  type: "text",
                  label: "Begin at Railroad Milepost:",
                  name: "Begin at Railroad Milepost parallel to tracks Mp",
                  name1: "Begin at Railroad Milepost parallel to tracks Feet",
                  placeholder: "",
                  fieldType: "group",
                  validation: {
                    required: true,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "comments",
                  commentsIconIsRequired: true,
                  commentsStyle: {
                    fontSize: "13px",
                    fontFamily: "Merriweather,serif",
                    padding: "0px",
                    fontStyle: "Italic",
                    color: "#6C757D",
                  },
                  comments:
                    "Use the next lowest whole milepost adjacent to the project location + feet in the direction of increasing milepost to the project location. <br/><span id='range2'></span>",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "dropdown",
                  requiredLable: true,
                  //align: "col-md-4",
                  name: "RailRoad line End longitudinal",
                  label: "Railroad Line End",
                  options: ["Mainline", "H-Line ", "EC-Line"],
                },
                {
                  validation: {
                    required: true,
                  },
                  type: "text",
                  label: "End at Railroad Milepost:",
                  name: "End at Railroad Milepost parallel to tracks Mp",
                  name1: "End at Railroad Milepost parallel to tracks Feet",
                  placeholder: "",
                  fieldType: "group",
                  requiredLable: true,
                  validation: {
                    required: true,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "comments",
                  commentsIconIsRequired: true,
                  commentsStyle: {
                    fontSize: "13px",
                    fontFamily: "Merriweather,serif",
                    padding: "0px",
                    fontStyle: "Italic",
                    color: "#6C757D",
                  },
                  comments:
                    "Use the next lowest whole milepost adjacent to the project location + feet in the direction of increasing milepost to the project location. <br/><span id='range3'></span>",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  validation: {
                    required: false,
                  },
                  requiredLable: true,
                  type: "text",
                  label: "Total Length parallel on the NCRR Corridor",
                  name: "Total Length parallel on the NCRR Corridors",
                  placeholder: "",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                },
                {},
              ],
            },
            {
              columns: [
                {
                  requiredLable: true,
                  type: "text",
                  label: "Min. distance from centerline of nearest track of longitudinal portion",
                  name: "Min distance from centerline of nearest track of longitudinal portion",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  placeholder: "",
                },
              ],
            },
          ],
        },
        {
          id: 7,
          hide: true,
          header: "For a longitudinal and transverse crossing",
          rows: [
            {
              label: "For a longitudinal and transverse crossing",
              lablevalue: "Railroad line Map",
              labelStyle: true,
              columns: [
                {
                  type: "dropdown",
                  name: "RailRoad line Begin longitudinal + transvere",
                  //align: "col-md-4",
                  label: "Railroad Line Begin",
                  options: ["Mainline", "H-Line ", "EC-Line"],
                  requiredLable: true,
                },
                {
                  validation: {
                    required: true,
                  },
                  type: "text",
                  label: "Begin at Railroad Milepost:",
                  name: "Begin at Railroad Milepost transverse crossing Mp",
                  name1: "Begin at Railroad Milepost transverse crossing Feet",
                  placeholder: "",
                  fieldType: "group",
                  requiredLable: true,
                },
              ],
            },
            {
              columns: [
                {
                  type: "comments",
                  commentsIconIsRequired: true,
                  commentsStyle: {
                    fontSize: "13px",
                    fontFamily: "Merriweather,serif",
                    padding: "0px",
                    fontStyle: "Italic",
                    color: "#6C757D",
                  },
                  comments:
                    "Use the next lowest whole milepost adjacent to the project location + feet in the direction of increasing milepost to the project location. <br/><span id='range4'></span>",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "dropdown",
                  name: "RailRoad line End longitudinal + transvere",
                  label: "Railroad Line End",
                  //align: "col-md-4",
                  options: ["Mainline", "H-Line ", "EC-Line"],
                  requiredLable: true,
                },
                {
                  validation: {
                    required: true,
                  },
                  type: "text",
                  label: "End at Railroad Milepost:",
                  name: "End at Railroad Milepost transverse crossing Mp",
                  name1: "End at Railroad Milepost transverse crossing Feet",
                  placeholder: "",
                  fieldType: "group",
                  requiredLable: true,
                },
              ],
            },
            {
              columns: [
                {
                  type: "comments",
                  commentsIconIsRequired: true,
                  commentsStyle: {
                    fontSize: "13px",
                    fontFamily: "Merriweather,serif",
                    padding: "0px",
                    fontStyle: "Italic",
                    color: "#6C757D",
                  },
                  comments:
                    "Use the next lowest whole milepost adjacent to the project location + feet in the direction of increasing milepost to the project location. <br/><span id='range5'></span>",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  validation: {
                    required: false,
                  },
                  type: "text",
                  label: "Total Length of Crossing occupancy on the NCRR Corridor",
                  name: "Total Length of Crossing occupancy on the NCRR Corridor",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  requiredLable: true,
                },
                {
                  type: "text",
                  label: "Total Length parallel on the NCRR Corridor",
                  name: "Total Length parallel on the NCRR Corridor",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  name: "Total Length of Occupancy within the NCRR Corridor",
                  label: "Total Length of Occupancy within the NCRR Corridor",
                  type: "number",
                  disabled: true,
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "number",
                  label: "Number of tracks to be crossed:",
                  name: "Number of tracks to be crossed",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
                {
                  type: "number",
                  label: "Angle of crossing:",
                  name: "Angle of crossing",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "text",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                  label: "Min. distance from centerline of nearest track of longitudinal portion",
                  name: "Min distance from centerline of nearest track of longitudinal portions",
                  placeholder: "",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                },
              ],
            },
          ],
        },
        {
          id: 8,
          header: "Installation Information",
          requiredLable: true,
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Installation is",
                  label: "Installation is",
                  requiredLable: true,
                  type: "checkbox",
                  align: "",
                  style: "col-md-3",
                  options: [
                    {
                      value: "Trunk",
                      label: "Trunk",
                    },
                    {
                      label: "Distribution",
                      value: "Distribution",
                    },
                    {
                      label: "Transmission",
                      value: "Transmission",
                    },
                    {
                      label: "Other",
                      value: "Other please specify installation is ",
                      show: true,
                      showOnSelect: 9,
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },

        {
          id: 9,
          hide: true,
          rows: [
            {
              columns: [
                {
                  label: "Other Installation is",
                  type: "text",
                  name: "other Installation",
                  requiredLable: true,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 25,
          rows: [
            {
              columns: [
                {
                  requiredLable: true,
                  name: "Will the installation connect to an existing facility within the NCRR Corridor",
                  label: "Will the installation connect to an existing facility within the NCRR Corridor",
                  type: "radio",
                  align: "col-md-12 mb-4",
                  options: [
                    {
                      value: "Yes Will the installation connect to an existing facility within the NCRR Corridor",
                      label: "Yes",
                      showOnSelect: 10,
                      show: true,
                    },
                    {
                      label: "No",
                      value: "No Will the installation connect to an existing facility within the NCRR Corridor",
                      showOnHide: [10],
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 10,
          hide: true,
          header: "Installation connect to an existing facility within the NCRR Corridor",
          rows: [
            {
              columns: [
                {
                  validation: {
                    required: false,
                  },
                  requiredLable: true,
                  type: "text",
                  name: "Installation connect to an existing facility within the NCRR Corridor",
                  label: "Installation connect to an existing facility within the NCRR Corridor",
                },
              ],
            },
          ],
        },
        {
          id: 11,
          label: "Proposed construction:",
          header: "Proposed construction:",
          rows: [
            {
              label: "",
              columns: [
                {
                  //comments: "Optional",
                  type: "date",
                  label: "Start Date:",
                  name: "Start Date",
                  placeholder: "",
                  commentsStyle: {
                    color: "rgb(136, 152, 170)",
                    fontSize: "13.6px",
                  },
                },
                {
                  type: "text",
                  label: "Name of contractor:",
                  name: "Name of contractor",
                  placeholder: "",
                  // validation: {
                  //   required: false,
                  // },
                },
              ],
            },
            {
              columns: [
                {
                  type: "number",
                  label: "Duration:",
                  name: "Duration",
                  placeholder: "",
                  // validation: {
                  //   required: false,
                  // },
                },
                {
                  type: "dropdown",

                  label: "Duration Type",

                  name: "Duration_type",
                  options: ["Select", "Days", "Weeks", "Months", "Years"],
                  // validation: {
                  //   required: false,
                  // },
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "textarea",
                  label: "Define any special specifications of the installation:",
                  name: "Define any special specifications of the installation",
                  placeholder: "",
                },
              ],
            },
            {
              columns: [
                {
                  type: "text",
                  label: "NCDOT Project Number",
                  name: "NCDOT Project Number",
                  // validation: {
                  //   required: false,
                  // },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: "Facilities",
      scroll: "Facilities",
      questions: [
        {
          id: 12,
          header: "Facilities",
          rows: [
            {
              columns: [
                {
                  type: "checkbox",
                  style: "col-md-3",
                  name: "Facilities Type",
                  label: "Facilities",
                  validation: {
                    required: true,
                  },
                  options: [
                    {
                      label: "Aerial Facilities",
                      value: "Aerial_Facilities",
                      showOnSelect: 13,
                      multipleRender: [26, 27, 28],
                      show: true,
                      key: [24],
                    },
                    {
                      label: "Underground Facilities",
                      value: "Underground_Facilities",
                      showOnSelect: 15,
                      multipleRender: [29, 30, 31, 32],
                      show: true,
                      key: [24],
                    },
                  ],
                  placeholder: "",
                },
              ],
            },
          ],
        },
        {
          id: 33,
          header: "Pipe Data Sheet: NCR 102 Plate I",
          rows: [
            {
              label: "",
              columns: [
                {
                  type: "tabel",
                  heading: [" ", "CARRIER PIPE", "	CASING PIPE"],
                  rowValue: [
                    {
                      label: "Material",
                      rowData: [
                        {
                          name: "Carrier Pipe Material",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Material",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Process of Manufacture",
                      rowData: [
                        {
                          name: "Carrier Pipe Process of Manufacture",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Process of Manufacture",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Material Specifications",
                      rowData: [
                        {
                          name: "Carrier Pipe Material Specifications",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Material Specifications",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Material Grade",
                      rowData: [
                        {
                          name: "Carrier Pipe Material Grade",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Material Grade",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Minimum Yield Strength (psi) of Material	",
                      rowData: [
                        {
                          name: "Carrier Pipe Minimum Yield Strength psi of Material",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Minimum Yield Strength psi of Material	",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Mill Test Pressure (psi)",
                      rowData: [
                        {
                          name: "Carrier Pipe Mill Test Pressure psi",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Mill Test Pressure psi",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Nominal Diameter",
                      rowData: [
                        {
                          name: "Carrier Pipe Nominal Diameter",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Nominal Diameter",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Inside Diameter	",
                      rowData: [
                        {
                          name: "Carrier Pipe Inside Diameter	",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Inside Diameter	",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Outside Diameter",
                      rowData: [
                        {
                          name: "Carrier Pipe Outside Diameter",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Outside Diameter",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Weight per Foot",
                      rowData: [
                        {
                          name: "Carrier Pipe Weight per Foot",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Weight per Foot",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Wall Thickness",
                      rowData: [
                        {
                          name: "Carrier Pipe Wall Thickness",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Wall Thickness",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Type of Seam",
                      rowData: [
                        {
                          name: "Carrier Pipe Type of Seame",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Type of Seam",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Laying Lengths",
                      rowData: [
                        {
                          name: "Carrier Pipe Laying Lengths",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Laying Lengths",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Type of Joints",
                      rowData: [
                        {
                          name: "Carrier Pipe Type of Joints",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Type of Joints",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Type of Coating",
                      rowData: [
                        {
                          name: "Carrier Pipe Type of Coating",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Type of Coating",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Cathodic Protection Details",
                      rowData: [
                        {
                          name: "Carrier Pipe Cathodic Protection Details",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Cathodic Protection Details",
                          type: "text",
                        },
                      ],
                    },
                    {
                      label: "Protective Coating Details",
                      rowData: [
                        {
                          name: "Carrier Pipe Protective Coating Details",
                          type: "text",
                        },
                        {
                          name: "Casing Pipe Protective Coating Details",
                          type: "text",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 16,
          header: "Facilities to be installed/utilized Type:",
          requiredLable: true,
          type: "tabel",
          rows: [
            {
              label: "",

              columns: [
                {
                  type: "tabel",
                  isCheckboxRequired: true,
                  heading: ["Type:", "Quantity:", "Nearest Distance to Centerline of Track:"],
                  rowValue: [
                    {
                      label: "Manholes",
                      id: 1,
                      rowData: [
                        {
                          name: "Manholes Quantity ",
                          type: "number",
                        },
                        {
                          name: "Manholes Nearest Distance to Centerline of Track ",
                          type: "number",
                          fieldLabelPosition: "right",
                        },
                      ],
                    },
                    {
                      label: "Handholes",
                      id: 2,
                      rowData: [
                        {
                          name: "Handholes Quantity",
                          type: "number",
                        },
                        {
                          name: "Handholes Nearest Distance to Centerline of Track",
                          type: "number",
                          fieldLabelPosition: "right",
                        },
                      ],
                    },
                    {
                      label: "Pull boxes",
                      id: 3,
                      rowData: [
                        {
                          name: "Pullboxes Ouantity",
                          type: "number",
                        },
                        {
                          name: "Pullboxes Nearest Distance to Centerline of Track",
                          type: "number",
                          fieldLabelPosition: "right",
                        },
                      ],
                    },
                    {
                      label: "Poles (New)",
                      id: 4,
                      rowData: [
                        {
                          name: "Poles (New) Quantity",
                          type: "number",
                        },
                        {
                          name: " Poles (New) Nearest Distance to Centerline of Track",
                          type: "number",
                          fieldLabelPosition: "right",
                        },
                      ],
                    },

                    {
                      label: "Poles (Existing)",
                      id: 5,
                      rowData: [
                        {
                          name: "Poles (Existing) Quantity",
                          type: "number",
                        },
                        {
                          name: "Poles (Existing) Nearest Distance to Centerline of Track",
                          type: "number",
                          fieldLabelPosition: "right",
                        },
                      ],
                    },
                    {
                      label: "other",
                      id: 6,
                      rowData: [
                        {
                          name: "others Quantity",
                          type: "number",
                        },
                        {
                          name: "Others Nearest Distance to Centerline of Track ",
                          type: "number",
                          fieldLabelPosition: "right",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: "Facilities Details",
      questions: [
        {
          id: 13,
          header: "Aerial Facilities",
          hide: true,
          rows: [
            {
              columns: [
                {
                  type: "text",
                  requiredLable: true,
                  label: "Total aerial length on the NCRR Corridor:",
                  name: "Total aerial length on the NCRR Corridor",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  placeholder: "",
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "text",
                  requiredLable: true,
                  label: "Number of cables or wires:",
                  name: "Number of cables or wires",
                  placeholder: "",
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  validation: {
                    required: false,
                  },
                  type: "text",
                  requiredLable: true,
                  label: "Type of wire supports:",
                  name: "Type of wire supports",
                  placeholder: "",
                },
                {
                  type: "text",
                  label: "Size:",
                  name: "Size",
                  requiredLable: true,
                  placeholder: "",
                  validation: {
                    required: false,
                  },
                },
                {
                  type: "text",
                  label: "False dead ends:",
                  name: "False dead ends",
                  requiredLable: true,
                  placeholder: "",
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "text",
                  requiredLable: true,
                  label: "Height of wires above top of rail at 65°F:",
                  name: "Height of wires above top of rail at 65°F",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  placeholder: "",
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "text",
                  requiredLable: true,
                  label: "Sag in Spans at 65°F:",
                  name: "Sag in Spans at 65°F:",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  placeholder: "",
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "text",
                  requiredLable: true,
                  label: "Height of wires above railroad communication and signal wires at 65°F:",
                  name: "Height of wires above railroad communication and signal wires at 65°F",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  placeholder: "",
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "text",
                  requiredLable: true,
                  label: "Horizontal distance from railroad communication and signal wires:",
                  name: "Horizontal distance from railroad communication and signal wires",
                  fieldLabel: "Feet",
                  fieldLabelPosition: "right",
                  placeholder: "",
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  type: "text",
                  requiredLable: true,
                  label: "Height of wire supports above ground:",
                  name: "Height of wire supports above ground",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                  placeholder: "",
                  validation: {
                    required: false,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 15,
          header: "Underground Facilities",
          hide: true,
          rows: [
            {
              columns: [
                {
                  label: "Method of proposed installation:",
                  name: "Method of proposed installation:",
                  type: "checkbox",
                  requiredLable: true,

                  options: [
                    {
                      value: "Bore and Jack",
                      label: "Bore and Jack",
                      showOnSelect: 20,
                      show: true,
                    },

                    {
                      label: "Tunneling (tunnel liner plate)",
                      value: "Tunneling tunnel liner plate",
                    },
                    {
                      label: "Direction boring/Horizontal Direction drilling",
                      value: "Direction boring/Horizontal Direction drilling",
                    },
                    {
                      label: "Open Cut",
                      value: "Open Cut",
                    },
                    {
                      label: "Other (please specify)",
                      value: "Other please specify",
                    },
                  ],
                  validation: {
                    required: false,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  validation: {
                    required: false,
                  },
                  type: "text",
                  requiredLable: true,
                  label: "Total buried length on the NCRR Corridor:",
                  name: "Total buried length on the NCRR Corridor",
                  fieldLabelPosition: "right",
                  fieldLabel: "Feet",
                },
              ],
            },

            {
              columns: [
                {
                  type: "number",
                  label: "Number empty:",
                  requiredLable: true,
                  name: "Number empty",
                  placeholder: "",
                  validation: {
                    required: false,
                  },
                },
                {
                  type: "number",
                  label: "Number filled:",
                  name: "Number filled",
                  requiredLable: true,
                  placeholder: "",
                  validation: {
                    required: false,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 37,
          casing: "casing",
          header: "",
          rows: [
            {
              carriers: "carriers",
              columns: [
                {
                  type: "popup_button",
                  head: "Casings, Carriers and Wires",
                  text: "Add casing",
                  cbody: addacarrier,
                  addbody: carrierbody,
                  validation: {
                    required: false,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: "File Uploads",
      questions: [
        {
          id: 17,
          header: "Design and Construction Plans",
          rows: [
            {
              columns: [
                {
                  type: "multiplefile",
                  mode: true,
                  name: "design_and_construction_plans",
                  requiredLable: true,
                  label: "Upload design and construction plans including plan view, profile view and cross section view of the proposed facility.",
                  fileTypes: ".pdf, .cad or .doc up to 100 MB",
                  additionalcomments: "Upload multiple files if needed. Uploaded files appear below.",
                  fileUpload: "multiple",
                  validation: {
                    required: true,
                  },
                },
              ],
            },
            {
              columns: [
                {
                  name: "File Upload design and construction plans checkbox ",
                  title: "File Upload design and construction plans checkbox ",
                  type: "checkbox",
                  options: [
                    {
                      checked: true,
                      value: "Plans clearly show the extent of proposed work affecting the NCRR Corridor",
                      label: "Plans clearly show the extent of proposed work affecting the NCRR Corridor",
                    },
                    {
                      checked: true,
                      value: "Plans drawn and printed to scale (ensure no unintended scaling occurs during printing)",
                      label: "Plans drawn and printed to scale (ensure no unintended scaling occurs during printing)",
                    },
                    {
                      checked: true,
                      value: "Plans sealed by a Professional Engineer licensed in the State of North Carolina (no crimped seals)",
                      label: "Plans sealed by a Professional Engineer licensed in the State of North Carolina (no crimped seals)",
                    },
                    {
                      checked: true,
                      value: "No aerial background shown on plans",
                      label: "No aerial background shown on plans",
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 18,
          header: "Specifications and Computations",
          rows: [
            {
              columns: [
                {
                  type: "singlefile",
                  mode: false,
                  requiredLable: true,
                  name: "Upload specifications and computations for the proposed occupancy",
                  label: "Upload specifications and computations for the proposed occupancy",
                  validation: {
                    required: true,
                  },
                  fileTypes: ".pdf, .cad or .doc up to 100 MB",
                },
              ],
            },
            {
              columns: [
                {
                  name: "File Upload specifications and computations checkbox ",
                  type: "checkbox",
                  title: "File Upload specifications and computations checkbox ",
                  options: [
                    {
                      checked: true,
                      value: "Sealed by a Professional Engineer licensed in the State of North Carolina (no crimped seals)",
                      label: "Sealed by a Professional Engineer licensed in the State of North Carolina (no crimped seals)",
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 19,
          header: "EXISTING AGREEMENT",
          hide: true,
          rows: [
            {
              columns: [
                {
                  type: "singlefile",
                  mode: false,
                  name: "UPLOAD EXISTING AGREEMENT BETWEEN THE APPLICANT AND NORTH CAROLINA RAILROAD AND/OR NORFOLK SOUTHERN RAILWAY (OR PREDECESSORS)",
                  label: "UPLOAD EXISTING AGREEMENT BETWEEN THE APPLICANT AND NORTH CAROLINA RAILROAD AND/OR NORFOLK SOUTHERN RAILWAY (OR PREDECESSORS)",
                  fileTypes: ".pdf, .cad or .doc up to 100 MB",
                },
              ],
            },
          ],
        },
        {
          id: 20,
          header: "Soil borings",
          hide: true,
          rows: [
            {
              columns: [
                {
                  type: "singlefile",
                  mode: false,
                  name: "SOIL BORINGS",
                  label: "NCR 102 SECTION 3.1 SOIL BORINGS*",
                  fileTypes: ".pdf, .cad or .doc up to 100 MB",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: "Applicant's Checklist",
      questions: [
        {
          rows: [
            {
              columns: [
                {
                  type: "heading",
                  text: "The following is a checklist of items that shall be completed when submitting this application for a proposed Wire/Carrier/Cable Occupancy of NCRR Corridor. Please place a check by all items listed below once they are included with the application package. For more detailed descriptions of the requirements below see Form NCR 101",
                  css: {
                    fontSize: "16px",
                    fontFamily: "Merriweather,serif",
                    fontWeight: "300",
                  },
                },
              ],
            },
          ],
        },
        {
          id: 21,
          header: "Plan and Profile Submittal Requirements",
          requiredLable: true,
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Plan and Profile Submittal Requirements",
                  title: "Plan and Profile Submittal Requirements",
                  //checkboxGroup:true,
                  type: "checkboxGroup",
                  errorMessage: true,
                  options: [
                    {
                      value: "All applicable requirements set forth in Form NCR 101 for all wireline occupancy applications",
                      linkContext: "Form NCR 101 ",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-101-w-Plates.pdf",
                      label: "All applicable requirements set forth in ",
                      subLabel: "for all wireline occupancy applications",
                      isChecked: false,
                    },
                    {
                      value: "Additionally, all applicable requirements set forth in Form NCR 102 if any portion of the wireline occupancy application is underground",
                      linkContext: "Form NCR 102",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-102-w-Plates.pdf",
                      label: "Additionally, all applicable requirements set forth in",
                      subLabel: "if any portion of the wireline occupancy application is underground",
                      isChecked: false,
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 22,
          header: "General Plan View Requirements",
          requiredLable: true,
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "General Plan View Requirements",
                  type: "checkboxGroup",
                  errorMessage: true,
                  title: "General Plan View Requirements",
                  options: [
                    {
                      value: "All existing and proposed railroad tracks shown and labelled",
                      label: "All existing and proposed railroad tracks shown and labelled",
                      isChecked: false,
                    },
                    {
                      value: "North arrow",
                      label: "North arrow",
                      isChecked: false,
                    },
                    {
                      value: "Scale",
                      label: "Scale",
                      isChecked: false,
                    },
                    {
                      label: "‘To’ labels for the next town, city or station in either direction along the railroad",
                      value: "‘To’ labels for the next town, city or station in either direction along the railroad",
                      isChecked: false,
                    },
                    {
                      label: "Name of the town and county in which the proposed facility is located",
                      value: "Name of the town and county in which the proposed facility is located",
                    },
                    {
                      label: "Angle of crossing relative to railroad track(s)",
                      value: "Angle of crossing relative to railroad track(s)",
                      isChecked: false,
                    },
                    {
                      label: "Distance (in feet) to lowest milepost (see application)",
                      value: "Distance (in feet) to lowest milepost (see application)",
                      isChecked: false,
                    },
                    {
                      value:
                        "Show and label the NCRR Corridor boundary as “NCRR Corridor” and non-operating NCRR property lines as “NCRR Property” (Remove all other Right-of-Way or Property boundaries within the NCRR Corridor)",
                      label:
                        "Show and label the NCRR Corridor boundary as “NCRR Corridor” and non-operating NCRR property lines as “NCRR Property” (Remove all other Right-of-Way or Property boundaries within the NCRR Corridor)",
                      isChecked: false,
                    },
                    {
                      value: "Show dimensions from the NCRR Corridor boundary to the centerline of the NCRR Corridor, centerline of the nearest track and the overall width of the NCRR Corridor",
                      label: "Show dimensions from the NCRR Corridor boundary to the centerline of the NCRR Corridor, centerline of the nearest track and the overall width of the NCRR Corridor",
                    },
                    {
                      value: "If occupancy is within or adjacent to a roadway at-grade crossing",
                      label: "If occupancy is within or adjacent to a roadway at-grade crossing:",
                      isChecked: false,
                    },
                    {
                      value: "Show roadway edges of pavement, dimension width and roadway name",
                      label: "Show roadway edges of pavement, dimension width and roadway name",
                      styleInner: { marginLeft: "35px" },
                      isChecked: false,
                    },
                    {
                      value: "Show edges of pavement with dimensions from edge of road to centerline of proposed/existing poles",
                      label: "Show edges of pavement with dimensions from edge of road to centerline of proposed/existing poles",
                      styleInner: { marginLeft: "35px" },
                      isChecked: false,
                    },
                    {
                      value: "Show existing warning devices (flashers, gates, etc.) and clearances from devices to proposed wire line / poles",
                      label: "Show existing warning devices (flashers, gates, etc.) and clearances from devices to proposed wire line / poles",
                      styleInner: { marginLeft: "35px" },
                      isChecked: false,
                    },
                    {
                      value: "Location of all existing and proposed poles and distance from edge of pole to nearest railroad track centerline",
                      label: "Location of all existing and proposed poles and distance from edge of pole to nearest railroad track centerline",
                      styleInner: { marginLeft: "35px" },
                      isChecked: false,
                    },
                    {
                      value: "Number of size and material of power wires, as well as number of pairs/fibers in communication cables",
                      label: "Number of size and material of power wires, as well as number of pairs/fibers in communication cables",
                      styleInner: { marginLeft: "35px" },
                      isChecked: false,
                    },
                    {
                      value: "Nominal voltage of line",
                      label: "Nominal voltage of line",
                      styleInner: { marginLeft: "35px" },
                      isChecked: false,
                    },
                    {
                      value: "Base diameter, height, class and bury of poles",
                      label: "Base diameter, height, class and bury of poles",
                      styleInner: { marginLeft: "35px" },
                      isChecked: false,
                    },
                    {
                      value: "Location, number, size and material of anchors and all guying for poles and arms",
                      label: "Location, number, size and material of anchors and all guying for poles and arms",
                      styleInner: { marginLeft: "35px" },
                      isChecked: false,
                    },
                    {
                      value: "Indicate any facilities to be abandoned",
                      label: "Indicate any facilities to be abandoned",
                      styleInner: { marginLeft: "35px" },
                      isChecked: false,
                    },
                    {
                      value: "Show roadway edges of pavement, dimension width and roadway name",
                      label: "Show roadway edges of pavement, dimension width and roadway name",
                      styleInner: { marginLeft: "35px" },
                      isChecked: false,
                    },
                    {
                      value: "Note in accordance with NCR 101 Section 1.6.1.C.9 regarding NCRR Specifications",
                      label: "Note in accordance with ",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-101-w-Plates.pdf",
                      linkContext: "NCR 101 Section 1.6.1.C.9",
                      subLabel: "regarding NCRR Specifications",
                      styleInner: { marginLeft: "35px" },
                      isChecked: false,
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 23,
          header: "General Profile View Requirements",
          //requiredLable: true,
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "General Profile View Requirements",
                  type: "checkboxGroup",
                  errorMessage: true,
                  title: "General Profile View Requirements",
                  options: [
                    {
                      value: "Scale",
                      label: "Scale",
                      isChecked: false,
                    },
                    {
                      value: "Draw the profile perpendicular to the track centerline",
                      label: "Draw the profile perpendicular to the track centerline",
                      isChecked: false,
                    },
                    {
                      value: "Indicate which direction the section is looking",
                      label: "Indicate which direction the section is looking",
                      isChecked: false,
                    },
                    {
                      value: "All existing and proposed railroad tracks shown",
                      label: "All existing and proposed railroad tracks shown",
                      isChecked: false,
                    },
                    {
                      value: "Show and label NCRR Corridor boundary as “NCRR Corridor”",
                      label: "Show and label NCRR Corridor boundary as “NCRR Corridor”",
                      isChecked: false,
                    },
                    {
                      value: "Show number and location of wires, voltage, power, ground and neutral wires, etc.",
                      label: "Show number and location of wires, voltage, power, ground and neutral wires, etc.",
                      isChecked: false,
                    },
                    {
                      value: "Note in accordance with NCR 101 Section 1.6.1.C.9 regarding NCRR Specifications ",
                      label: "Note in accordance with",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-101-w-Plates.pdf",
                      linkContext: "NCR 101 Section 1.6.1.C.9",
                      subLabel: "regarding NCRR Specifications ",
                      isChecked: false,
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 24,
          hide: true,

          label: "Additional Profile View Requirements for Aerial Transverse Crossings",
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Additional Profile View Requirements for Aerial Transverse Crossings ",
                  type: "checkboxGroup",
                  title: "Additional Profile View Requirements for Aerial Transverse Crossings",
                  options: [
                    {
                      isChecked: false,
                      value: "Actual vertical clearance measured from top of high rail for each track to bottom of lowest wire/cable ",
                      label: "Actual vertical clearance measured from top of high rail for each track to bottom of lowest wire/cable ",
                    },
                    {
                      isChecked: false,
                      value: "Location of poles and distance from edge of pole to nearest railroad track centerline ",
                      label: "Location of poles and distance from edge of pole to nearest railroad track centerline ",
                    },
                    {
                      isChecked: false,
                      value: "Dimension span length across tracks from pole to pole ",
                      label: "Dimension span length across tracks from pole to pole ",
                    },
                    {
                      isChecked: false,
                      value: "Show distance from bottom of sag at 65oF to top of high rail ",
                      label: "Show distance from bottom of sag at 65oF to top of high rail ",
                    },
                    {
                      isChecked: false,
                      value: "Show pole top configuration and attachment heights of existing and proposed wires ",
                      label: "Show pole top configuration and attachment heights of existing and proposed wires ",
                    },
                  ],
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },
        {
          id: 26,
          hide: true,
          header: "Additional Profile View Requirements for Aerial Transverse Crossings",
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Additional Profile View Requirements for Aerial Transverse Crossings",
                  type: "checkboxGroup",
                  //errorMessage: true,
                  title: "Additional Profile View Requirements for Aerial Transverse Crossings",
                  options: [
                    {
                      isChecked: false,
                      value: "Actual vertical clearance measured from top of high rail for each track to bottom of lowest wire/cable ",
                      label: "Actual vertical clearance measured from top of high rail for each track to bottom of lowest wire/cable ",
                    },
                    {
                      isChecked: false,
                      value: "Location of poles and distance from edge of pole to nearest railroad track centerline ",
                      label: "Location of poles and distance from edge of pole to nearest railroad track centerline ",
                    },
                    {
                      isChecked: false,
                      value: "Dimension span length across tracks from pole to pole ",
                      label: "Dimension span length across tracks from pole to pole ",
                    },
                    {
                      isChecked: false,
                      value: "Show distance from bottom of sag at 65oF to top of high rail ",
                      label: "Show distance from bottom of sag at 65oF to top of high rail ",
                    },
                    {
                      isChecked: false,
                      value: "Show pole top configuration and attachment heights of existing and proposed wires ",
                      label: "Show pole top configuration and attachment heights of existing and proposed wires ",
                    },
                  ],
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },
        {
          id: 27,
          hide: true,
          header: "Additional Profile View Requirements for Aerial Longitudinal Occupancies",
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Additional Profile View Requirements for Aerial Longitudinal Occupancies",
                  type: "checkboxGroup",
                  //errorMessage: true,
                  title: "Additional Profile View Requirements for Aerial Longitudinal Occupancies ",
                  options: [
                    {
                      isChecked: false,
                      value: "Show the top of rail profile of the nearest track Aerial Longitudinal Occupancies ",
                      label: "Show the top of rail profile of the nearest track ",
                    },
                    {
                      isChecked: false,
                      value: "Elevations",
                      label: "Elevations ",
                    },
                    {
                      isChecked: false,
                      value: "Show assigned pole numbers ",
                      label: "Show assigned pole numbers ",
                    },
                    {
                      isChecked: false,
                      value: "Show pole top configuration or attachment heights for each wireline ",
                      label: "Show pole top configuration or attachment heights for each wireline ",
                    },
                  ],
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },
        {
          id: 28,
          hide: true,
          header: "Additional Plan View Requirements for Aerial Transverse Crossings and Longitudinal Occupancies",
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Additional Plan View Requirements for Aerial Transverse Crossings and Longitudinal Occupancies",
                  type: "checkboxGroup",
                  //errorMessage: true,
                  title: "Additional Plan View Requirements for Aerial Transverse Crossings and Longitudinal Occupancies",
                  options: [
                    {
                      isChecked: false,
                      value: "Dimension distance between poles, from centerline of closest track to wireline, and show assigned pole numbers",
                      label: "Dimension distance between poles, from centerline of closest track to wireline, and show assigned pole numbers",
                    },
                  ],
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },
        {
          id: 29,
          header: "Additional Plan View Requirements for Underground Transverse Crossings",
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Additional Plan View Requirements for Underground Transverse Crossings",
                  title: "Additional Plan View Requirements for Underground Transverse Crossings",
                  type: "checkboxGroup",
                  options: [
                    {
                      isChecked: false,
                      value: "Dimension distance from the crossing to any turnouts",
                      label: "Dimension distance from the crossing to any turnouts",
                    },
                    {
                      isChecked: false,
                      value: "Location of markers and an example of text on the proposed markers",
                      label: "Location of markers and an example of text on the proposed markers",
                    },
                    {
                      isChecked: false,
                      value: "Note indicating method of installation",
                      label: "Note indicating method of installation",
                    },
                    {
                      isChecked: false,
                      value:
                        "Show launching and receiving pits that are within the NCRR Corridor. Dimension from the pits to the nearest centerline of track. Dimension the length, width and depth of the pits.",
                      label:
                        "Show launching and receiving pits that are within the NCRR Corridor. Dimension from the pits to the nearest centerline of track. Dimension the length, width and depth of the pits.",
                    },
                    {
                      isChecked: false,
                      value: "Details of any excavation or sheeting necessary to install the conduit in accordance with NCR 102 Section 5.9.1.C",
                      label: "Details of any excavation or sheeting necessary to install the conduit in accordance with",
                      linkContext: "NCR 102 Section 5.9.1.C",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-102-w-Plates.pdf",
                    },
                    {
                      isChecked: false,
                      value: "Size and material of the conduit",
                      label: "Size and material of the conduit",
                      styleInner: { marginLeft: "35px" },
                    },
                    {
                      isChecked: false,
                      value: "Length of the conduit on NCRR Corridor",
                      label: "Length of the conduit on NCRR Corridor",
                      styleInner: { marginLeft: "35px" },
                    },
                    {
                      isChecked: false,
                      value: "Cross section of the wireline showing the conduit, number of innerduct and, wires contained within each innerduct and if any innerduct are empty.",
                      label: "Cross section of the wireline showing the conduit, number of innerduct and, wires contained within each innerduct and if any innerduct are empty.",
                      styleInner: { marginLeft: "35px" },
                    },

                    {
                      isChecked: false,
                      label: "Note in accordance with ",
                      value: "Note in accordance with NCR 101 Section 1.6.1.C.9 regarding NCRR Specifications",
                      linkContext: "NCR 101 Section 1.6.1.C.9",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-101-w-Plates.pdf",
                      subLabel: "regarding NCRR Specifications",
                    },
                  ],
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },
        {
          id: 30,
          header: "Additional Profile View Requirements for Underground Transverse Crossings",
          rows: [
            {
              columns: [
                {
                  name: "Additional Profile View Requirements for Underground Transverse Crossings",
                  title: "Additional Profile View Requirements for Underground Transverse Crossings",

                  type: "checkboxGroup",
                  options: [
                    {
                      isChecked: false,
                      value: "Show theoretical embankment lines for all existing and proposed tracks per NCR 102 Section 4.3.1.F.5. ",
                      label: "Show theoretical embankment lines for all existing and proposed tracks per ",
                      linkContext: "NCR 102 Section 4.3.1.F.5",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-102-w-Plates.pdf",
                    },
                    {
                      isChecked: false,
                      value:
                        "Show launching and receiving pits that are within the NCRR Corridor. Dimension from the pits to the nearest centerline of track. Dimension the length, width and depth of the pits. ",
                      label:
                        "Show launching and receiving pits that are within the NCRR Corridor. Dimension from the pits to the nearest centerline of track. Dimension the length, width and depth of the pits. ",
                    },
                  ],
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },
        {
          id: 31,
          header: "Additional Plan View Requirements for Underground Longitudinal Occupancies",
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Additional Plan View Requirements for Underground Longitudinal Occupancies",
                  condtionalLabel: "",
                  title: "Additional Plan View Requirements for Underground Longitudinal Occupancies",
                  type: "checkboxGroup",
                  options: [
                    {
                      isChecked: false,
                      value: "Location of markers and an example of text on the proposed markers",
                      label: "Location of markers and an example of text on the proposed markers",
                    },
                    {
                      isChecked: false,
                      value: "Location of existing above and below ground utilities",
                      label: "Location of existing above and below ground utilities ",
                    },
                    {
                      isChecked: false,
                      value: "Note indicating method of installation                      ",
                      label: "Note indicating method of installation                      ",
                    },
                    {
                      isChecked: false,
                      value: "Size and material of the conduit",
                      label: "Size and material of the conduit",
                    },
                    {
                      isChecked: false,
                      value: "Length of the conduit on NCRR Corridor                      ",
                      label: "Length of the conduit on NCRR Corridor                      ",
                    },
                    {
                      isChecked: false,
                      value: "Indicate the overall length of the occupancy on each page",
                      label: "Indicate the overall length of the occupancy on each page",
                    },
                    {
                      isChecked: false,
                      value: "Cross section of the wireline showing the conduit, number of innerduct and, wires contained within each innerduct and if any innerduct are empty.                      ",
                      label: "Cross section of the wireline showing the conduit, number of innerduct and, wires contained within each innerduct and if any innerduct are empty.                      ",
                    },
                  ],
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },
        {
          id: 32,
          header: "Additional Profile View Requirements for Underground Longitudinal Occupancies ",
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Additional Profile View Requirements for Underground Longitudinal Occupancies ",
                  condtionalLabel: "",
                  title: "Additional Profile View Requirements for Underground Longitudinal Occupancies ",
                  type: "checkboxGroup",
                  options: [
                    {
                      isChecked: false,
                      value: "Show the top of rail profile of the nearest track",
                      label: "Show the top of rail profile of the nearest track",
                    },
                  ],
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },

        {
          id: 35,
          hide: true,
          header: "Additional Profile View Requirements for Underground Transverse Crossings",
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Additional Profile View Requirements for Underground Transverse Crossings",
                  condtionalLabel: "",
                  title: "Additional Profile View Requirements for Underground Transverse Crossings",
                  type: "checkboxGroup",
                  options: [
                    {
                      isChecked: false,
                      value: "Show theoretical embankment lines for all existing and proposed tracks per NCR 102 Section 4.3.1.F.5. ",
                      label: "Show theoretical embankment lines for all existing and proposed tracks per ",
                      linkContext: "NCR 102 Section 4.3.1.F.5",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-102-w-Plates.pdf",
                    },
                    {
                      isChecked: false,
                      value:
                        "Show launching and receiving pits that are within the NCRR Corridor. Dimension from the pits to the nearest centerline of track. Dimension the length, width and depth of the pits. ",
                      label:
                        "Show launching and receiving pits that are within the NCRR Corridor. Dimension from the pits to the nearest centerline of track. Dimension the length, width and depth of the pits. ",
                    },
                  ],
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },
        {
          id: 36,
          hide: true,
          header: "Additional Plan View Requirements for Underground Transverse Crossings",
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "Additional Plan View Requirements for Underground Transverse Crossings",
                  condtionalLabel: "",
                  title: "Additional Plan View Requirements for Underground Transverse Crossings",
                  type: "checkboxGroup",
                  options: [
                    {
                      isChecked: false,
                      value: "Dimension distance from the crossing to any turnouts",
                      label: "Dimension distance from the crossing to any turnouts",
                    },
                    {
                      isChecked: false,
                      value: "Location of markers and an example of text on the proposed markers",
                      label: "Location of markers and an example of text on the proposed markers",
                    },
                    {
                      isChecked: false,
                      value: "Note indicating method of installation",
                      label: "Note indicating method of installation",
                    },
                    {
                      isChecked: false,
                      value:
                        "Show launching and receiving pits that are within the NCRR Corridor. Dimension from the pits to the nearest centerline of track. Dimension the length, width and depth of the pits.",
                      label:
                        "Show launching and receiving pits that are within the NCRR Corridor. Dimension from the pits to the nearest centerline of track. Dimension the length, width and depth of the pits.",
                    },
                    {
                      isChecked: false,
                      value: "Details of any excavation or sheeting necessary to install the conduit in accordance with NCR 102 Section 5.9.1.C",
                      label: "Details of any excavation or sheeting necessary to install the conduit in accordance with",
                      linkContext: "NCR 102 Section 5.9.1.C",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-102-w-Plates.pdf",
                    },
                    {
                      isChecked: false,
                      value: "Size and material of the conduit",
                      label: "Size and material of the conduit",
                      styleInner: { marginLeft: "35px" },
                    },
                    {
                      isChecked: false,
                      value: "Length of the conduit on NCRR Corridor",
                      label: "Length of the conduit on NCRR Corridor",
                      styleInner: { marginLeft: "35px" },
                    },
                    {
                      isChecked: false,
                      value: "Cross section of the wireline showing the conduit, number of innerduct and, wires contained within each innerduct and if any innerduct are empty.",
                      label: "Cross section of the wireline showing the conduit, number of innerduct and, wires contained within each innerduct and if any innerduct are empty.",
                      styleInner: { marginLeft: "35px" },
                    },

                    {
                      isChecked: false,
                      label: "Note in accordance with ",
                      value: "Note in accordance with NCR 101 Section 1.6.1.C.9 regarding NCRR Specifications",
                      linkContext: "NCR 101 Section 1.6.1.C.9",
                      linkAction: "https://www.ncrr.com/wp-content/uploads/2019/11/2019-07-30-Form-NCR-101-w-Plates.pdf",
                      subLabel: "regarding NCRR Specifications",
                    },
                  ],
                  // validation: {
                  //   required: true,
                  // },
                },
              ],
            },
          ],
        },
        {
          // id: "42",
          label: "",
          rows: [
            {
              label: "",
              columns: [
                {
                  name: "",
                  type: "heading",
                  text: "If the application is approved, the Applicant agrees to reimburse the North Carolina Railroad Company and the Operating Railroads for any cost incurred by the North Carolina Railroad Company and the Operating Railroads incident to installation, maintenance, and/or supervision necessitated by this wireline installation, and further agrees to assume all liability for accidents or injuries which arise as a result of this installation.",
                  css: {
                    fontSize: "16px",
                    fontFamily: "Merriweather,serif",
                    fontWeight: "300",
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
export { wireOccupanySchema };
