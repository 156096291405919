import { useParams } from "react-router";
export const TextArea = ({ label = "", name = "", css = {}, defaultValue = "", placeholder = "", validation, register, errors, requiredLable = false }) => {
  let params = useParams();
  let data = JSON.parse(localStorage.getItem(params.applicationID)) || {};
  return (
    <div className="input d-flex flex-column py-2">
      <label className="form-label d-flex justify-content-between">
        {label}
        <small className="text-danger ms-1">{requiredLable ? "*required" : null}</small>
      </label>
      <textarea id={name} style={css} className="form-control" defaultValue={data[name]} placeholder={placeholder} rows="3" {...register(name, { ...validation })} />
      <span className="text-danger">{errors[name] && <span>Enter a valid value for {label}</span>}</span>
    </div>
  );
};
