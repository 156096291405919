import React from "react";
import { ContactsDetails } from "./ContactsDetail";
import { useAtoms } from "../../hooks/useAtoms";

export const Contacts = () => {
  const { setNavigationContent } = useAtoms();

  setNavigationContent(false);
  return (
    <>
      <div className="review-page">
        <div className="canvas">
          <div className="h-100">
            <div className="container" style={{ marginTop: "84px" }}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="">
                    <div></div>
                    <form action="contact-detail-autofill.html" method="post">
                      <div className="inner-form card mb-4">
                        <div className=" border-bottom card-header">
                          <h2>Add Contact Details</h2>
                          <p className="text-dark mb-0">
                            <i className="far fa-hand-point-right"></i> &nbsp;Please provide details of other individuals within your Facility Owner who will be involved in the process for
                            applications. It's essential to include accurate information to ensure smooth coordination and efficient processing of application requests.
                          </p>
                        </div>
                        <ContactsDetails />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
