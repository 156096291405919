const State = [
  "North Carolina",
  "Alaska",
  "Alabama",
  "Arkansas",
  "American Samoa",
  "Arizona",
  "California",
  "Colorado",
  "Connecticut",
  "District of Columbia",
  "Delaware",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Iowa",
  "Idaho",
  "Illinois",
  "Indiana",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Massachusetts",
  "Maryland",
  "Maine",
  "Michigan",
  "Minnesota",
  "Missouri",
  "Mississippi",
  "Montana",
  " North Dakota",
  "Nebraska",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "Nevada",
  "New York",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Virginia",
  "Virgin Islands",
  "Vermont",
  "Washington",
  "Wisconsin",
  "West Virginia",
  "Wyoming",
];
const ApplicantType = ["Corporation", "Limited Partnership", "Limited Liability Company", "General Partnership", "Sole Proprietorship", "Individual", "Government Entity", "Other"];
const RoleType = [
  { name: "Facility Owner", value: "FACILITY" },
  { name: "Engineering Representative for Facility Owner", value: "CONSULTANT" },
];
const AssignContactData = {
  header: "",
  Heading: [
    {
      label: "SIGNATORY",
      Tooltip: "",
      Select: "",
    },
    {
      label: "ENGINEERING CONSULTANT",
      Select: "",
    },
    {
      label: "BILLING REPRESENTATIVE ",
      Tooltip: "",
      Select: "",
    },
  ],
};
export { State, ApplicantType, RoleType, AssignContactData };
