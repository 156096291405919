import React, { useEffect, useState } from "react";
import { getCompaniesByCompanyType, getCompanyById, updateConsultantCompany } from "../../api/crm";
import GetmyRole from "../../utils/GetmyRole";
import { useUserRefetch } from "../../hooks/useUser";
import toast from "react-hot-toast";
import { Model, ModelBody, ModelFooter } from "../models/Model";
import { useParams } from "react-router-dom";

const initialValue = {
  id: "",
  name: "",
  updatedBy: "",
};

const initialError = {
  name: "",
};

const companyExist = (company, name, id) => {
  return company.name.toUpperCase() === name.toUpperCase() && id !== company.id;
};

export const ConsultantEditModel = ({ showModal, closeModal = () => {} }) => {
  const { companyId } = useParams();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(initialValue);
  const [error, setError] = useState(initialError);
  const [companies, setCompanies] = useState([]);
  const refetchUser = useUserRefetch();

  const getCompany = async (id) => {
    const res = await getCompanyById(id);
    if (res?.data?.data) {
      const company = res.data.data;
      setForm((initialValue) => ({ ...initialValue, ...company }));
    }
  };

  const getAllCompanies = async () => {
    const res = await getCompaniesByCompanyType("CONSULTANT");
    if (res?.data?.data) {
      const companies = res?.data?.data || [];
      setCompanies(companies);
    }
  };

  useEffect(() => {
    if (companyId) {
      getCompany(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    getAllCompanies();
  }, []);

  const handleChange = (e) => {
    const { type, name, value } = e.target;
    const types = ["text"];
    setError(initialError);

    if (types.includes(type)) {
      setForm((initialForm) => ({
        ...initialForm,
        [name]: value,
      }));
      checkError({ ...form, [name]: value });
    }
  };

  const handleError = (error) => {
    setError({ ...initialError, ...error });
  };

  const checkError = (errorform) => {
    if (!errorform) {
      errorform = form;
    }

    let errors = {};
    const alreadyExist = companies.find((company) => companyExist(company, errorform.name, errorform.id));
    if (alreadyExist) {
      errors.name = `*Engineering Company name is already taken. Please contact ${process.env.REACT_APP_SUPPORT_EMAIL || "NCRR Support"}, for further information`;
    }
    if (!errorform.name) {
      errors.name = "*required";
    }
    if (Object.keys(errors).length > 0) {
      handleError(errors);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(initialError);
    const result = checkError();
    try {
      if (result) {
        const { email } = GetmyRole();
        const data = { ...form, updatedBy: email };
        await updateConsultantCompany(data);
        toast.success("Updated Successfully!.");
        refetchUser();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  return (
    <div onKeyDown={handleKeyPress}>
      {showModal && (
        <Model heading={"Update Engineering Representative for Facility Owner Details"} id="edit-consultant" showModal={showModal} closeModal={closeModal}>
          <form onSubmit={handleSubmit}>
            <ModelBody>
              <div className="card border-0 inner-form">
                <div className="inner-form card mb-4">
                  <div className="card-body">
                    <div id="company-details">
                      <div className="row ">
                        <div className="col">
                          <div className="mt-3">
                            <label className="form-label d-flex justify-content-between">
                              Engineering Representative for Facility Owner Legal Name
                              <small className="text-danger ms-1">{error.name}</small>
                            </label>
                            <div className="container p-0">
                              <div className="dropdown col-lg-12 p-0">
                                <input type="text" className="form-control" id="name" name="name" value={form.name} onChange={handleChange} />
                              </div>
                            </div>
                            <div id="" className="form-text">
                              *Please ensure that the exact legal name is provided with no abbreviations.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModelBody>
            <ModelFooter>
              <button type="button" className="btn btn-secondary btn-lg" onClick={() => closeModal()}>
                Close
              </button>
              <button type="submit" className="btn btn-primary btn-lg">
                {loading && (
                  <>
                    <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                    Updating...{" "}
                  </>
                )}
                Update
              </button>
            </ModelFooter>
          </form>
        </Model>
      )}
    </div>
  );
};
