import React from "react";

export default function InitalReviewLoading(props) {
  let { form } = props;
  return (
    <div className="col-lg-9">
      {form ? (
        ""
      ) : (
        <div className="card border-0">
          <div className="card-body p-0">
            <div className="row text-center mb-4">
              <div className="col-md-4 ">
                <div className="loading">
                  <div className="line"></div>
                  <div className="d-grid">
                    <a href="agreement-review.html" className="btn btn-primary text-start d-flex  align-items-center justify-content-between">
                      {" "}
                      <span>
                        For NCRR <br /> Approve Now
                      </span>{" "}
                      <i className="fal fa-chevron-right fs-3"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="loading">
                  <div className="line"></div>
                  <div className="d-grid">
                    <a href="#" className="btn btn-outline-success text-start  d-flex align-items-center pointer-none">
                      {" "}
                      <i className="fal fa-check fs-3  me-3"></i>
                      <span>
                        Approved by
                        <br />
                        Norfolk Southern
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="loading">
                  <div className="line"></div>
                  <div className="d-grid">
                    <a href="agreement-review.html" className="btn  btn-outline-light text-start  d-flex   align-items-center pointer-none">
                      <i className="fal fa-clock fs-3 me-3"></i>
                      <span>
                        Awaiting Approval
                        <br />
                        By HNTB
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div id="card2">
        <div className="inner-form card mb-4">
          <div className="text-center border-bottom card-header">
            <h2 className="mb-0">Project Information</h2>
          </div>
          <div className=" form-col">
            <div className="card-body">
              <div className="block">
                <div id="col8" className="section mb-2 position-relative side-nav-col">
                  <div className="row">
                    <div className="col-sm-6 ">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block d-block">Proposed work involves (check all that apply)</label>
                        <p className="text">Installation of a new facility</p>
                      </div>
                    </div>
                    <div className="col-sm-6 ">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block d-block">Existing Agreement for Installation?</label>
                        <p className="text">Yes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" form-col">
            <div className="card-body">
              <div className="block">
                <div id="col9" className="section mb-2 position-relative side-nav-col">
                  <div className="loading mb-3">
                    <div className="line"></div>
                    <h3 className="d-block">Location of Installation:</h3>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            NearestStreet:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            NearestTown:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            County:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            State:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            Latitude:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            Longitude:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" form-col">
            <div className="card-body">
              <div className="block">
                <div id="col9" className="section mb-2 position-relative side-nav-col">
                  <div className="loading mb-3">
                    <div className="line"></div>
                    <h3 className="d-block">Location of Installation:</h3>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            NearestStreet:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            NearestTown:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            County:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            State:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            Latitude:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            Longitude:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
