const Netsuite = {
  header: "Netsuite",
  sections: [
    //  defaultDraftShow:true, for Draft Agreement field
    //  defaultValidateShow:true, for validate License Agreement field
    //  defaultfinalShow:true, for final field
    {
      label: " Netsuite",
      questions: [
        {
          id: 1,
          header: "Customer",
          recordName: "CUSTOMER",
          final: "true",
          Link: process.env.REACT_APP_NETSUITE_URL + "common/entity/custjob.nl?id=",
          netSuiteIdKeyName: "customer",
          rows: [],
        },
        {
          id: 2,
          header: "Property",
          recordName: "PROPERTY",
          final: "true",
          Link: process.env.REACT_APP_NETSUITE_URL + "common/custom/custrecordentry.nl?rectype=38&id=",
          netSuiteIdKeyName: "property",
          rows: [],
        },
        {
          id: 3,
          header: "Unit",
          recordName: "UNIT",
          final: "true",
          draftAgreement: "true",
          Link: process.env.REACT_APP_NETSUITE_URL + "common/custom/custrecordentry.nl?rectype=39&id=",
          netSuiteIdKeyName: "unit",

          rows: [
            {
              label: "",

              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "UNIT VAL MAP",
                  name: "UNIT VAL MAP",
                  keyName: "unitvalmap",
                  placeholder: "",
                  defaultDraftShow: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "UNIT CODE",
                  name: "UNIT CODE",
                  keyName: "unitcode",
                  disabled: "true",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "UNIT PROPERTY",
                  name: "UNIT PROPERTY",
                  keyName: "unitproperty",
                  disabled: "true",
                  disabled: "true",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "PROPERTY AND UNIT CODE",
                  name: "PROPERTY AND UNIT CODE",
                  keyName: "propertyandunitcode",
                  disabled: "true",
                  disabled: "true",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "UNIT NEAREST CITY",
                  name: "UNIT NEAREST CITY",
                  keyName: "unitnearestcity",
                  disabled: "true",
                  disabled: "true",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "UNIT NEAREST STREET",
                  name: "UNIT NEAREST STREET",
                  keyName: "unitneareststreet",
                  disabled: "true",
                  disabled: "true",
                  placeholder: "",
                },
              ],
            },
          ],
        },
        {
          id: 4,
          header: "Opportunity",
          recordName: "OPPORTUNITY",
          final: "true",
          draftAgreement: "true",
          Link: process.env.REACT_APP_NETSUITE_URL + "accounting/transactions/opprtnty.nl?id=",
          netSuiteIdKeyName: "opportunity",

          rows: [
            {
              columns: [
                {
                  col: "col-md-12",
                  type: "textarea",
                  label: "SCOPE OF WORK",
                  name: "SCOPE OF WORK",
                  dataTooltip:
                    "Annual charge for one (1) 432-count fiber optic cable encased within one (1) 1.25-inch HDPE conduit, one (1) 288-count fiber optic cable encased within one (1) 1.25-inch HDPE conduit, two (2) spare 1.25-inch HDPE conduits, all encased within (1) 6-inch HDPE casing pipe, under and across the right of way or property and any tracks of Company, at Milepost 085.55-NC, Valuation Station 4413 plus 68 feet, at or near Yeargen Road in Garner, Wake County, North Carolina.",
                  placeholder: "",
                  keyName: "scopeofwork",
                  defaultDraftShow: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "CUSTOMER",
                  name: "CUSTOMER",
                  placeholder: "",
                  disabled: "true",
                  keyName: "customer",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "OPPORTUNITY STATUS",
                  name: "OPPORTUNITY STATUS",
                  placeholder: "",
                  disabled: "true",
                  keyName: "opportunitystatus",
                  finalReleaseValue: "Closed-Won",
                },
              ],
            },
            // {
            //   label: "",
            //   columns: [
            //     {
            //       col: "col-md-6",
            //       type: "text",
            //       label: "PROBABILITY",
            //       name: "PROBABILITY",
            //       placeholder: "",
            //       disabled: "true",
            //       disabled: "true",
            //       keyName: "opportunitystatus",
            //     },
            //   ],
            // },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "date",
                  label: "RECEIVED DATE",
                  name: "RECEIVED DATE",
                  placeholder: "",
                  disabled: "true",
                  keyName: "receiveddate",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "SALES REP",
                  name: "SALES REP",
                  placeholder: "",
                  disabled: "true",
                  keyName: "salesrep",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "ACTION STATUS",
                  name: "ACTION STATUS",
                  placeholder: "",
                  disabled: "true",
                  keyName: "actionstatus",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "DEPARTMENT",
                  name: "DEPARTMENT",
                  placeholder: "",
                  disabled: "true",
                  keyName: "department",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "PROPERTY",
                  name: "PROPERTY",
                  placeholder: "",
                  disabled: "true",
                  keyName: "property",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "UNIT",
                  name: "UNIT",
                  placeholder: "",
                  disabled: "true",
                  keyName: "unit",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "APPLICATIONTITLE",
                  name: "APPLICATIONTITLE",
                  placeholder: "",
                  disabled: "true",
                  keyName: "applicationtitle",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-12",
                  type: "text",
                  label: "LINK",
                  name: "LINK",
                  placeholder: "",
                  disabled: "true",
                  keyName: "link",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "HNTB FILE NUMBER",
                  name: "HNTB FILE NUMBER",
                  placeholder: "",
                  disabled: "true",
                  keyName: "hntbfilenumber",
                },
              ],
            },
          ],
        },
        {
          id: 7,
          header: "Cash Sale",
          recordName: "CASHSALE",
          final: "true",
          applicationPayment: "true",
          Link: process.env.REACT_APP_NETSUITE_URL + "accounting/transactions/cashsale.nl?id=",
          netSuiteIdKeyName: "cashsale",

          rows: [
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "MEMO",
                  name: "MEMO",
                  placeholder: "",
                  keyName: "memo",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "CHECK",
                  name: "CHECK",
                  keyName: "check",
                  placeholder: "",
                  disabled: false,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "date",
                  label: "DATE",
                  name: "DATE",
                  keyName: "date",
                  placeholder: "",
                  disabled: false,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "SALES REP",
                  name: "SALES REP",
                  placeholder: "",
                  keyName: "salesrep",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "DEPARTMENT",
                  name: "DEPARTMENT",
                  keyName: "department",
                  placeholder: "",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "BILLING ADDRESS",
                  name: "BILLING ADDRESS",
                  keyName: "billingaddress",
                  placeholder: "",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "BILLLING COUNTRY",
                  name: "BILLLING COUNTRY",
                  keyName: "billingcountry",
                  placeholder: "",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "APPLICATION TITLE",
                  name: "APPLICATION TITLE",
                  keyName: "applicationtitle",
                  placeholder: "",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-12",
                  type: "text",
                  label: "LINK",
                  name: "LINK",
                  placeholder: "",
                  keyName: "link",
                  disabled: true,
                },
              ],
            },
            {
              header: "Items",
              label: "LINE 1",
              itemId: 1,
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "ITEM",
                  name: "ITEM",
                  placeholder: "",
                  keyName: "item",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              itemId: 1,
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "DESCRIPTION",
                  name: "DESCRIPTION",
                  keyName: "discription",
                  placeholder: "",
                  disabled: true,
                },
              ],
            },
            {
              label: "LINE 2",
              itemId: 2,
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "ITEM",
                  name: "ITEM",
                  keyName: "item",
                  placeholder: "",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              itemId: 2,
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "DESCRIPTION",
                  name: "DESCRIPTION",
                  keyName: "discription",
                  placeholder: "",
                  disabled: true,
                },
              ],
            },
          ],
        },
        {
          id: 9,
          header: "Vendor Bill",
          recordName: "VENDORBILL",
          final: "true",
          applicationPayment: "true",
          Link: process.env.REACT_APP_NETSUITE_URL + "accounting/transactions/transaction.nl?id=",
          netSuiteIdKeyName: "vendorbill",

          rows: [
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "MEMO",
                  name: "MEMO",
                  placeholder: "",
                  keyName: "memo",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "BILL NUMBER",
                  name: "BILL NUMBER",
                  placeholder: "",
                  keyName: "billnumber",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "date",
                  label: "BILL DATE",
                  name: "BILL DATE",
                  placeholder: "",
                  keyName: "billdate",
                  disabled: false,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "date",
                  label: "DUE DATE",
                  name: "DUE DATE",
                  placeholder: "",
                  keyName: "duedate",
                  disabled: false,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "VENDOR",
                  name: "VENDOR",
                  placeholder: "",
                  keyName: "vendor",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "NEXT APPROVER",
                  name: "NEXT APPROVER",
                  placeholder: "",
                  keyName: "nextapprover",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "APPROVAL STATUS",
                  name: "APPROVAL STATUS",
                  placeholder: "",
                  keyName: "approvalstatus",
                  disabled: true,
                },
              ],
            },

            {
              label: "",
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "APPLICATION TITLE",
                  name: "APPLICATION TITLE",
                  placeholder: "",
                  keyName: "applicationtitle",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-12",
                  type: "text",
                  label: "LINK",
                  name: "LINK",
                  placeholder: "",
                  keyName: "link",
                  disabled: true,
                },
              ],
            },
            {
              header: " Items",
              label: "LINE 1",
              itemId: 1,
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "ITEM",
                  name: "ITEM",
                  placeholder: "",
                  keyName: "item",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              itemId: 1,
              columns: [
                {
                  col: "col-md-6",
                  type: "text",
                  label: "PROPERTY",
                  name: "PROPERTY",
                  keyName: "property",
                  placeholder: "",
                  disabled: true,
                },
              ],
            },
          ],
        },
        {
          id: 5,
          header: "Insurance",
          recordName: "INSURANCE",
          final: "true",
          validateInsurance: "true",
          Link: process.env.REACT_APP_NETSUITE_URL + "common/custom/custrecordentry.nl?rectype=357&id=",
          netSuiteIdKeyName: "insurance",

          rows: [
            {
              label: "",

              columns: [
                {
                  col: "col-md-6",
                  type: "dropdown",
                  label: "NORFOLK SOUTHERN COI REQUIRED",
                  name: "NORFOLK SOUTHERN COI REQUIRED",
                  keyName: "norfolksoutherncoirequired",
                  defaultValidateShow: true,
                  options: ["Yes", "No"],
                },
              ],
            },

            {
              label: "",

              columns: [
                {
                  col: "col-md-6",
                  type: "dropdown",
                  label: "NORFOLK SOUTHERN COI APPROVED",
                  name: "NORFOLK SOUTHERN COI APPROVED",
                  keyName: "norfolksoutherncoiapproved",
                  defaultValidateShow: true,
                  options: ["Yes", "No"],
                },
              ],
            },

            {
              label: "",

              columns: [
                {
                  type: "dropdown",
                  label: "SEND COI NOTIFICATION LETTER",
                  name: "SEND COI NOTIFICATION LETTER",
                  keyName: "sendcoinotificationletter",
                  defaultValidateShow: true,
                  options: ["No", "Yes"],
                },
              ],
            },

            {
              label: "",

              columns: [
                {
                  type: "dropdown",
                  label: "ADDITIONAL INSURED ON COI",
                  name: "ADDITIONAL INSURED ON COI",
                  keyName: "additionalinsuredoncoi",
                  defaultValidateShow: true,
                  options: ["Yes", "No"],
                },
              ],
            },

            {
              label: "",

              columns: [
                {
                  type: "dropdown",
                  label: "ADDITIONAL INSURED ON COI-AUTO",
                  name: "ADDITIONAL INSURED ON COI-AUTO",
                  keyName: "additionalinsuredoncoiauto",
                  defaultValidateShow: true,
                  options: ["Yes", "No"],
                },
              ],
            },
            {
              label: "",

              columns: [
                {
                  type: "dropdown",
                  label: "PER STATUTE-WORKER'S COMP",
                  name: "PER STATUTE-WORKER'S COMP",
                  keyName: "perstatuteworkerscomp",
                  defaultValidateShow: true,
                  options: ["Yes", "No"],
                },
              ],
            },

            {
              label: "",
              columns: [
                {
                  type: "number",
                  label: "AGREEMENT REQUIREMENT AMOUNT-GL",
                  name: "AGREEMENT REQUIREMENT AMOUNT-GL",
                  keyName: "agreementrequirementamountgl",
                  defaultValidateShow: true,
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "AGREEMENT REQUIREMENT AMOUNT-RPL",
                  name: "AGREEMENT REQUIREMENT AMOUNT-RPL",
                  keyName: "agreementrequirementamountrpl",
                  defaultValidateShow: true,
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "NORFOLK SOUTHERN RISK FINANCE FEE",
                  name: "NORFOLK SOUTHERN RISK FINANCE FEE",
                  keyName: "norfolksouthernriskfinancefee",
                  disabled: "true",
                  keyName: "norfolksouthernriskfinancefee",
                  disabled: "true",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-12",
                  type: "text",
                  label: "LINK",
                  name: "LINK",
                  disabled: "true",
                  keyName: "link",
                  disabled: "true",
                  keyName: "link",
                  placeholder: "",
                },
              ],
            },
          ],
        },
        {
          id: 6,
          header: "Sales Order",
          recordName: "SALESORDER",
          final: "true",
          licenseFee: "0",
          validatecompile: "true",
          Link: process.env.REACT_APP_NETSUITE_URL + "accounting/transactions/salesord.nl?id=",
          netSuiteIdKeyName: "salesorder",

          rows: [
            {
              label: "",

              columns: [
                {
                  type: "dropdown",
                  label: "START NEW CONTRACT",
                  name: "START NEW CONTRACT",
                  keyName: "startnewcontract",
                  defaultfinalShow: true,
                  options: ["Yes", "No"],
                },
              ],
            },
            {
              label: "",

              columns: [
                {
                  type: "dropdown",
                  label: "AGREEMENT PAYMENT METHOD",
                  name: "AGREEMENT PAYMENT METHOD",
                  defaultfinalShow: true,
                  keyName: "agreementpaymentmethod",
                  options: ["Terms"],
                },
              ],
            },
            {
              label: "",

              columns: [
                {
                  type: "dropdown",
                  label: "BILL DAY IN ADVANCE",
                  name: "BILL DAY IN ADVANCE",
                  defaultfinalShow: true,
                  keyName: "billdayinadvance",
                  options: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                  ],
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "STATUS",
                  name: "STATUS",
                  placeholder: "",
                  keyName: "status",
                  disabled: "true",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "SALES REP",
                  name: "SALES REP",
                  placeholder: "",
                  keyName: "salesrep",
                  disabled: "true",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "DEPARTMENT",
                  name: "DEPARTMENT",
                  placeholder: "",
                  keyName: "departmant",
                  disabled: "true",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "EMAIL ADDRESS(S) TO SEND CUSTOMER INVOICE",
                  name: "EMAIL ADDRESS(S) TO SEND CUSTOMER INVOICE",
                  placeholder: "",
                  keyName: "emailaddresstosendcustomerinvoice",
                  disabled: "true",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "PROPERTY",
                  name: "PROPERTY",
                  placeholder: "",
                  keyName: "property",
                  disabled: "true",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "UNIT",
                  name: "UNIT",
                  placeholder: "",
                  keyName: "unit",
                  disabled: "true",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "CONTRACT BILLING DAY",
                  name: "CONTRACT BILLING DAY",
                  placeholder: "",
                  keyName: "contractbillingday",
                  disabled: "true",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-12",
                  type: "text",
                  label: "LINK",
                  name: "LINK",
                  placeholder: "",
                  keyName: "link",
                  disabled: "true",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-12",
                  type: "textarea",
                  label: "MEMO",
                  name: "MEMO",
                  placeholder: "",
                  keyName: "memo",
                  disabled: "true",
                },
              ],
            },
            {
              header: "Items",
              label: "LINE 1",
              itemId: 1,
              columns: [
                {
                  type: "text",
                  label: "CHARGE CODE",
                  name: "CHARGE CODE",
                  placeholder: "",
                  keyName: "chargecode",
                  disabled: "true",
                },
              ],
            },
            {
              label: "",
              itemId: 1,
              columns: [
                {
                  type: "date",
                  label: "AGREEMENT START DATE",
                  name: "AGREEMENT START DATE",
                  placeholder: "",
                  keyName: "agreementstartdate",
                  disabled: "true",
                },
              ],
            },
            {
              label: "",
              itemId: 1,
              columns: [
                {
                  type: "date",
                  label: "FIRST AUTO BILL DATE",
                  name: "FIRST AUTO BILL DATE",
                  placeholder: "",
                  keyName: "firstautobilldate",
                  disabled: "true",
                },
              ],
            },
            {
              label: "",
              itemId: 1,
              columns: [
                {
                  type: "date",
                  label: "NEXT ESCALATION DATE",
                  name: "NEXT ESCALATION DATE",
                  placeholder: "",
                  keyName: "nextescalationdate",
                  disabled: "true",
                },
              ],
            },
            {
              label: "",
              itemId: 1,
              columns: [
                {
                  type: "text",
                  label: "AMOUNT(LICENSE FEE)",
                  name: "AMOUNT(LICENSE FEE)",
                  placeholder: "",
                  keyName: "amount",
                  disabled: "true",
                },
              ],
            },
            {
              label: "",
              itemId: 1,
              columns: [
                {
                  type: "text",
                  label: "RATE(LICENSE FEE)",
                  name: "RATE(LICENSE FEE)",
                  placeholder: "",
                  keyName: "rate",
                  disabled: "true",
                },
              ],
            },

            {
              label: "",
              itemId: 1,
              columns: [
                {
                  type: "dropdown",
                  label: "ESCALATION PERIOD IN MONTHS",
                  name: "ESCALATION PERIOD IN MONTHS",
                  disabled: "true",
                  keyName: "escalationperiodinmonths",
                  options: ["12"],
                },
              ],
            },
            {
              label: "",
              itemId: 1,
              columns: [
                {
                  type: "number",
                  label: "ESCALATION PERCENT",
                  name: "ESCALATION PERCENT",
                  disabled: "true",
                  keyName: "escalationpercent",
                },
              ],
            },
            {
              label: "",
              itemId: 1,
              columns: [
                {
                  type: "dropdown",
                  label: "AGREEMENT END-OF-TERM TYPE",
                  name: "AGREEMENT END-OF-TERM TYPE",
                  disabled: "true",
                  keyName: "agreementendofterms",
                  options: ["Perpetual"],
                },
              ],
            },

            {
              label: "",
              itemId: 1,
              columns: [
                {
                  col: "col-md-12",
                  type: "textarea",
                  label: "DESCRIPTION FOR CUSTOMER INVOICE",
                  name: "DESCRIPTION FOR CUSTOMER INVOICE",
                  disabled: "true",
                  keyName: "discriptionforcustomerinvoice",
                  placeholder: "",
                },
              ],
            },
          ],
        },

        {
          id: 8,
          header: "Cash Sales 2",
          recordName: "CASHSALE2",
          final: "true",
          compilePayment: "true",
          Link: process.env.REACT_APP_NETSUITE_URL + "accounting/transactions/cashsale.nl?id=",
          netSuiteIdKeyName: "cashsale2",

          rows: [
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "MEMO",
                  name: "MEMO",
                  keyName: "memo",
                  placeholder: "",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "CHECK",
                  name: "CHECK",
                  placeholder: "",
                  keyName: "check",
                  disabled: false,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "date",
                  label: "DATE",
                  name: "DATE",
                  placeholder: "",
                  keyName: "date",
                  disabled: false,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "SALES REP",
                  name: "SALES REP",
                  placeholder: "",
                  keyName: "salesrep",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "DEPARTMENT",
                  name: "DEPARTMENT",
                  placeholder: "",
                  keyName: "department",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "BILLING ADDRESS",
                  name: "BILLING ADDRESS",
                  placeholder: "",
                  keyName: "billingaddress",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "BILLLING COUNTRY",
                  name: "BILLLING COUNTRY",
                  placeholder: "",
                  keyName: "billingcountry",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "HNTB FILE NUMBER",
                  name: "HNTB FILE NUMBER",
                  placeholder: "",
                  keyName: "hntbfilenumber",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "APPLICATION TITLE",
                  name: "APPLICATION TITLE",
                  placeholder: "",
                  keyName: "applicationtitle",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-12",
                  type: "text",
                  label: "LINK",
                  name: "LINK",
                  placeholder: "",
                  keyName: "link",
                  disabled: true,
                },
              ],
            },
            {
              header: "Items",
              label: "LINE 1",
              itemId: 1,
              columns: [
                {
                  type: "text",
                  label: "ITEM",
                  name: "ITEM",
                  placeholder: "",
                  keyName: "item",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              itemId: 1,
              columns: [
                {
                  type: "text",
                  label: "AMOUNT",
                  name: "AMOUNT",
                  placeholder: "",
                  keyName: "amount",
                  disabled: true,
                },
              ],
            },

            {
              // header:" Items",
              label: "LINE 2",
              itemId: 2,
              columns: [
                {
                  type: "text",
                  label: "ITEM",
                  name: "ITEM",
                  placeholder: "",
                  keyName: "item",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              itemId: 2,
              columns: [
                {
                  type: "text",
                  label: "AMOUNT",
                  name: "AMOUNT",
                  placeholder: "",
                  keyName: "amount",
                  disabled: true,
                },
              ],
            },
          ],
        },

        {
          id: 10,
          header: "Vender Bill 2",
          recordName: "VENDORBILL2",
          final: "true",
          compilePayment: "true",
          Link: process.env.REACT_APP_NETSUITE_URL + "accounting/transactions/transaction.nl?id=",
          netSuiteIdKeyName: "vendorbill2",

          rows: [
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "MEMO",
                  name: "MEMO",
                  placeholder: "",
                  keyName: "memo",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "BILL NUMBER",
                  name: "BILL NUMBER",
                  placeholder: "",
                  keyName: "billnumber",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "date",
                  label: "BILL DATE",
                  name: "BILL DATE",
                  placeholder: "",
                  keyName: "billdate",
                  disabled: false,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "date",
                  label: "DUE DATE",
                  name: "DUE DATE",
                  placeholder: "",
                  keyName: "duedate",
                  disabled: false,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "VENDOR",
                  name: "VENDOR",
                  placeholder: "",
                  keyName: "vendor",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "NEXT APPROVER",
                  name: "NEXT APPROVER",
                  placeholder: "",
                  keyName: "nextapprover",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "APPROVAL STATUS",
                  name: "APPROVAL STATUS",
                  placeholder: "",
                  keyName: "approvalstatus",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "AMOUNT",
                  name: "AMOUNT",
                  placeholder: "",
                  keyName: "amount",
                  disabled: true,
                },
              ],
            },

            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "HNTB FILE NUMBER",
                  name: "HNTB FILE NUMBER",
                  placeholder: "",
                  keyName: "hntbfilenumber",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "APPLICATION TITLE",
                  name: "APPLICATION TITLE",
                  placeholder: "",
                  keyName: "applicationtitle",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-12",
                  type: "text",
                  label: "LINK",
                  name: "LINK",
                  placeholder: "",
                  keyName: "link",
                  disabled: true,
                },
              ],
            },
            {
              header: "Items",
              label: "LINE 1",
              itemId: 1,
              columns: [
                {
                  type: "text",
                  label: "ITEM",
                  name: "ITEM",
                  placeholder: "",
                  keyName: "item",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              itemId: 1,
              columns: [
                {
                  type: "text",
                  label: "AMOUNT",
                  name: "AMOUNT",
                  placeholder: "",
                  keyName: "amount",
                  disabled: true,
                },
              ],
            },

            {
              // header:" Items",
              label: "LINE 2",
              itemId: 2,
              columns: [
                {
                  type: "text",
                  label: "ITEM",
                  name: "ITEM",
                  placeholder: "",
                  keyName: "item",
                  disabled: true,
                },
              ],
            },
            {
              label: "",
              itemId: 2,
              columns: [
                {
                  type: "text",
                  label: "AMOUNT",
                  name: "AMOUNT",
                  placeholder: "",
                  keyName: "amount",
                  disabled: true,
                },
              ],
            },
          ],
        },
        {
          id: 11,
          header: "Customer Deposit",
          recordName: "CUSTOMERDEPOSIT",
          licenseFee: "0",
          final: "true",
          compilePayment: "true",
          Link: process.env.REACT_APP_NETSUITE_URL + "accounting/transactions/custdep.nl?whence=&id=",
          netSuiteIdKeyName: "customerdeposit",

          rows: [
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "MEMO",
                  name: "MEMO",
                  keyName: "memo",
                  disabled: true,
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "CHECK #",
                  name: "CHECK #",
                  keyName: "check#",
                  placeholder: "",
                  disabled: false,
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "PAYMENT AMOUNT (LICENSE FEE)",
                  name: "PAYMENT AMOUNT (LICENSE FEE)",
                  disabled: true,
                  keyName: "paymentamount",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "DEPARTMENT",
                  name: "DEPARTMENT",
                  disabled: true,
                  keyName: "department",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: " PROPERTY",
                  name: "UTILITY PROPERTY",
                  disabled: true,
                  keyName: "property",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: " UNIT",
                  name: "UTILITY UNIT",
                  disabled: true,
                  placeholder: "",
                  keyName: "unit",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "PAYMENT METHOD",
                  name: "PAYMENT METHOD",
                  disabled: true,
                  keyName: "paymentmethod",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "HNTB FILE NUMBER",
                  name: "HNTB FILE NUMBER",
                  disabled: true,
                  keyName: "hntbfilenumber",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "APPLICATION TITLE",
                  name: "APPLICATION TITLE",
                  disabled: true,
                  placeholder: "",
                  keyName: "applicationtitle",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-12",
                  type: "text",
                  label: "LINK",
                  name: "LINK",
                  disabled: true,
                  keyName: "link",
                  placeholder: "",
                },
              ],
            },
          ],
        },
        {
          id: 12,
          header: "Agreement",
          recordName: "AGREEMENT",
          draftAgreement: "true",
          validateInsurance: "true",
          final: "true",
          validatecompile: "true",
          Link: process.env.REACT_APP_NETSUITE_URL + "common/custom/custrecordentry.nl?rectype=365&id=",
          netSuiteIdKeyName: "agreement",

          rows: [
            {
              label: "",
              Hide: "true",
              columns: [
                {
                  Hide: "true",
                  validateHide: "true",
                  finalShow: true,
                  type: "date",
                  label: "AGREEMENT START DATE",
                  name: "AGREEMENT START DATE",
                  defaultfinalShow: true,
                  keyName: "agreementstartdate",
                  placeholder: "",
                },
              ],
            },
            // {
            //   label: "",

            //   columns: [
            //     {
            //       Hide: "true",
            //       validateHide: "true",
            //       finalShow: true,
            //       type: "date",
            //       label: "AGREEMENT END DATE",
            //       name: "AGREEMENT END DATE",
            //       defaultfinalShow: true,
            //       keyName: "agreementenddate",
            //       placeholder: "",
            //     },
            //   ],
            // },
            {
              label: "",

              columns: [
                {
                  Hide: "true",
                  validateHide: "true",
                  finalShow: true,
                  type: "date",
                  label: "BILLING START DATE",
                  name: "BILLING START DATE",
                  defaultfinalShow: true,
                  keyName: "billingstartdate",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",

              Draft: "true",
              columns: [
                {
                  Hide: "true",
                  type: "dropdown",
                  label: "INSURANCE REQUIRED",
                  name: "INSURANCE REQUIRED",
                  placeholder: "",
                  defaultValidateShow: true,
                  validateFinalHideField: "true",
                  // validateHideField:"true",
                  keyName: "insurancerequired",

                  options: ["Yes-during Construction", "Yes", "No"],
                },
              ],
            },
            {
              label: "",

              compile: "true",
              columns: [
                {
                  type: "dropdown",
                  label: "IS THIS AGREEMENT BILLABLE",
                  name: "IS THIS AGREEMENT BILLABLE",
                  placeholder: "",
                  keyName: "isthisagreementbillable",
                  validateFinalHideField: "true",
                  validateHideField: "true",
                  defaultDraftShow: true,
                  options: ["Yes", "No"],
                },
              ],
            },
            {
              label: "",

              columns: [
                {
                  type: "number",
                  label: "CHARGE % INCREASE ANNUAL",
                  name: "CHARGE % INCREASE ANNUAL",
                  // disabled: "true",
                  validateHideField: "true",
                  validateFinalHideField: "true",
                  keyName: "charge%increaseannual",
                  defaultDraftShow: true,
                  placeholder: "",
                },
              ],
            },
            {
              label: "",

              columns: [
                {
                  type: "dropdown",
                  label: "BILLING FREQUENCY",
                  name: "BILLING FREQUENCY",
                  // disabled: "true",
                  validateHideField: "true",
                  validateFinalHideField: "true",
                  keyName: "billingfrequency",
                  defaultDraftShow: true,
                  placeholder: "",
                  options: ["Annual", "Monthly", "One-time", "Quarterly", "Semi-annual", "Non-billable"],
                },
              ],
            },

            {
              label: "",

              columns: [
                {
                  type: "text",
                  label: "CANCELLATION TERMS",
                  name: "CANCELLATION TERMS",
                  disabled: "true",
                  keyName: "cancellationterms",
                  defaultDraftShow: true,
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-12",
                  type: "textarea",
                  dataTooltip: "SCOPE OF WORK",
                  label: "SCOPE OF WORK",
                  name: "SCOPE OF WORK",
                  dataTooltip:
                    " Enter the SCOPE OF WORK which is used for Opportunity, Sales Order, Agreement, etc. For example, 'Annual charge for one (1) 432-count fiber optic cable encased within one (1) 1.25-inch HDPE conduit, one (1) 288-count fiber optic cable encased within one (1) 1.25-inch HDPE conduit, two (2) spare 1.25-inch HDPE conduits, all encased within (1) 6-inch HDPE casing pipe, under and across the right of way or property and any tracks of Company, at Milepost 085.55-NC, Valuation Station 4413 plus 68 feet, at or near Yeargen Road in Garner, Wake County, North Carolina.'",
                  disabled: "true",
                  keyName: "scopeofwork",
                  placeholder: "",
                },
              ],
            },

            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "AGREEMENT STATUS",
                  name: "AGREEMENT STATUS",
                  disabled: "true",
                  keyName: "agreementstatus",
                  keyName: "agreementstatus",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "AGREEMENT TYPE",
                  name: "AGREEMENT TYPE",
                  disabled: "true",
                  keyName: "agreementtype",
                  keyName: "agreementtype",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "AGREEMENT SUBTYPE",
                  name: "AGREEMENT SUBTYPE",
                  disabled: "true",
                  keyName: "agreementsubtype",
                  keyName: "agreementsubtype",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "SALES REP",
                  name: "SALES REP",
                  disabled: "true",
                  keyName: "salesrep",
                  keyName: "salesrep",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "MANAGER APPROVAL",
                  name: "MANAGER APPROVAL",
                  disabled: "true",
                  keyName: "managerapproval",
                  keyName: "managerapproval",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "AGREEMENT PROPERTY",
                  name: "AGREEMENT PROPERTY",
                  disabled: "true",
                  keyName: "agreementproperty",
                  keyName: "agreementproperty",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "AGREEMENT UNIT",
                  name: "AGREEMENT UNIT",
                  disabled: "true",
                  keyName: "agreementunit",
                  keyName: "agreementunit",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              compile: "true",
              compile: "true",
              columns: [
                {
                  Hide: "true",
                  type: "text",
                  label: "NS ACTIVITY",
                  name: "NS ACTIVITY",
                  disabled: "true",
                  keyName: "nsactivity",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              compile: "true",
              compile: "true",
              columns: [
                {
                  Hide: "true",
                  type: "text",
                  label: "ORIGINAL CHARGE AMOUNT(LICENSE FEE)",
                  name: "ORIGINAL CHARGE AMOUNT(LICENSE FEE)",
                  disabled: "true",
                  keyName: "originalchargeamount",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "HNTB FILE NUMBER",
                  name: "HNTB FILE NUMBER",
                  disabled: "true",
                  keyName: "hntbfilenumber",
                  keyName: "hntbfilenumber",
                  placeholder: "",
                },
              ],
            },

            {
              label: "",
              columns: [
                {
                  col: "col-md-12",
                  type: "text",
                  label: "LINK",
                  name: "LINK",
                  disabled: "true",
                  keyName: "link",
                  placeholder: "",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "APPLICATION TITLE",
                  name: "APPLICATION TITLE",
                  disabled: "true",
                  keyName: "applicationtitle",
                  placeholder: "",
                },
              ],
            },
          ],
        },
        {
          id: 13,
          header: "Utility",
          recordName: "UTILITY",
          draftAgreement: "true",
          final: "true",
          Link: process.env.REACT_APP_NETSUITE_URL + "common/custom/custrecordentry.nl?rectype=368&id=",
          netSuiteIdKeyName: "utility",

          rows: [
            {
              label: "",
              formType: 210,
              columns: [
                {
                  type: "dropdown",
                  label: "UTILITY CATEGORY",
                  name: "UTILITY CATEGORY 210",
                  keyName: "utilitycategory",
                  // disabled:"true",
                  defaultDraftShow: true,
                  options: ["Select", "Cable TV", "Electrical", "Fiber Optic", "Telephone", "None"],
                },
              ],
            },
            {
              label: "",
              formType: 220,
              columns: [
                {
                  type: "dropdown",
                  label: "UTILITY CATEGORY",
                  name: "UTILITY CATEGORY 220",
                  keyName: "utilitycategory",
                  defaultDraftShow: true,
                  options: ["Select", "Compressed Air", "Drainage", "Fuel/Oil", "Natural Gas", "Sewer", "Steam", "Water", "None"],
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "dropdown",
                  label: "ENGINEERING REP APPROVAL",
                  name: "ENGINEERING REP APPROVAL",
                  placeholder: "",
                  defaultDraftShow: true,
                  // disabled:"true",
                  keyName: "engineeringrepapproval",
                  options: ["Yes", "Yes-during construction", "No"],
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "POLES SUPPORT",
                  name: "POLES SUPPORT",
                  placeholder: "",
                  defaultDraftShow: true,
                  keyName: "polessupport",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  // dataTooltip: "SCOPE OF WORK",
                  label: "FT WITHIN ROW",
                  name: "FT WITHIN ROW",
                  defaultDraftShow: true,
                  placeholder: "",
                  keyName: "ftwithinrow",
                  keyName: "ftwithinrow",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "HEIGHT",
                  name: "HEIGHT",
                  defaultDraftShow: true,
                  placeholder: "",
                  keyName: "height",
                  keyName: "height",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "DEPTH",
                  name: "DEPTH",
                  placeholder: "",
                  defaultDraftShow: true,
                  // disabled: "true",
                  keyName: "depth",
                  keyName: "depth",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  col: "col-md-12",
                  type: "textarea",
                  label: "WIRES CABLES UNITS",
                  name: "WIRES CABLES UNITS",
                  dataTooltip: "Enter the WIRES CABLES UNITS which is used for Utility in # WIRES/CABLES/UNITS.  For example, '(2) .540/.860 coax cable lashed to 1/4 steel strand'",
                  disabled: "true",
                  keyName: "wirescablesunits",
                  defaultDraftShow: true,
                  placeholder: "",
                },
              ],
            },

            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: " UNIT",
                  name: "UTILITY UNIT",
                  placeholder: "",
                  disabled: "true",
                  keyName: "utilityunit",
                  disabled: "true",
                  keyName: "utilityunit",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: " PROPERTY",
                  name: "UTILITY PROPERTY",
                  placeholder: "",
                  disabled: "true",
                  keyName: "utilityproperty",
                  disabled: "true",
                  keyName: "utilityproperty",
                },
              ],
            },

            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "UTILITY TYPE",
                  name: "UTILITY TYPE",
                  placeholder: "",
                  disabled: "true",
                  keyName: "utilitytype",
                  disabled: "true",
                  keyName: "utilitytype",
                },
              ],
            },
            {
              label: "",
              columns: [
                {
                  type: "text",
                  label: "RELATION TO CORRIDOR",
                  name: "RELATION TO CORRIDOR",
                  placeholder: "",
                  disabled: "true",
                  keyName: "relationtocorridor",
                  disabled: "true",
                  keyName: "relationtocorridor",
                },
              ],
            },
          ],
        },
        {
          id: 14,
          header: "Cloud Extend",
          recordName: "CLOUDEXTENTEND",
          Link: "https://5252905-sb1.app.netsuite.com/app/common/custom/custrecordentry.nl?rectype=368&id=1573",
          final: "true",
          netSuiteIdKeyName: "cloudextend",
          rows: [],
        },
      ],
    },
  ],
};
export { Netsuite };
