export function getNestedProperty(obj, keyPath) {
  const keys = keyPath.split(".");
  let nestedObj = obj;
  for (const key of keys) {
    nestedObj = nestedObj?.[key];
    if (nestedObj === undefined) {
      break;
    }
  }
  return nestedObj;
}
