import CompiledLicenseAgreement from "../components/compiledAgreement/CompiledLicenseAgreement";
import UploadDraftLoading from "../components/loaders/UploadDraftLoading";
import { useApplication } from "../hooks/useApplication";
import { useUser } from "../hooks/useUser";

const Page = (props) => {
  const application = useApplication();
  const user = useUser();
  if (!application || !user) {
    return <UploadDraftLoading />;
  }
  return <CompiledLicenseAgreement {...props} />;
};

export default Page;
