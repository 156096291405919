/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ApplicationListViewCard from "./ApplicationListViewCard";
import AggrementListViewCard from "./AggrementListViewCard";
import { getApplicantApplications, postApplication } from "../../api/currentApplications";
import DashBoardLoading from "../loaders/DashBoardLoading";
import FilterLoading from "../loaders/FilterLoading";
import { useUser } from "../../hooks/useUser";
import { CSVLink } from "react-csv";
import { createApplicantsApplication } from "../../utils/createCsvData";

export default function ListView(props) {
  let {
    lodash,
    gridView,
    setGridView,
    currentApplicationsByUser = [],
    currentAgreementsByUser = [],
    onGoingApplication,
    agreementsApplication,
    filterList,
    filterApplicationdata,
    allApplications,
    stagesOfActive,
    setStagesOfActive,
    setCurrentPage,
    sortByAscDec,
    sortBy,
    sortType,
    setStages,
    searchValue,
    searchPaymentMethod,
    setPaymentmethod,
    paymentmethod,
  } = props || {};
  let count = currentApplicationsByUser.length;
  let completedCount = currentAgreementsByUser.length;
  const [csvLoading, setCsvLoading] = useState(true);
  const [dashboardApplications, setDashboardApplications] = useState([]);

  let history = useHistory();

  const { company = {}, roleName = "", role = {}, id: contactRoleId } = useUser() || {};

  const postApplications = async (formName, formNumber) => {
    let data = {
      formName: formName,
      formNumber: formNumber,
      createdBy: contactRoleId,
    };
    let postForm = await postApplication(data);

    if (postForm) {
      localStorage.setItem(`${postForm.data.responseData._id}lastModifiedOn`, postForm.data.responseData.lastModifiedOn);

      history.push(`/${formName}/${postForm.data.responseData._id}`);
    }
  };

  useEffect(() => {
    if (contactRoleId) {
      getApplicantApplications(contactRoleId)
        .then((res) => {
          setDashboardApplications(createApplicantsApplication(res?.data?.applications));
        })
        .catch((error) => {
          console.log(error);
          setDashboardApplications([]);
        })
        .finally(() => {
          setCsvLoading(false);
        });
    }
  }, [contactRoleId]);
  return (
    <>
      <div className="card-header border-0">
        <div className="d-sm-flex justify-content-between align-items-center">
          {agreementsApplication || searchValue ? <h2 className="mb-3 mb-sm-0">Ongoing Applications</h2> : null}
          {onGoingApplication ? <h2 className="mb-3 mb-sm-0">Agreements</h2> : null}

          {roleName === "applicant" && !onGoingApplication ? (
            <div className="btn-col text-right">
              <button onClick={() => postApplications("form210", 210)} className="btn btn-primary mb-3 mb-md-0 d-block d-sm-inline-block">
                New Wire/Conduit/Cable Occupancy Application
              </button>
              <button
                onClick={() => {
                  postApplications("form220", 220);
                }}
                className="btn btn-primary mb-3 mb-md-0 d-block d-sm-inline-block"
              >
                New Pipeline Occupancy Application
              </button>
              {roleName === "applicant" && csvLoading ? (
                <button className="btn btn-primary" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                </button>
              ) : (
                <CSVLink className="btn btn-primary" filename="applications-ncrr.csv" data={dashboardApplications}>
                  Export Dashboard
                </CSVLink>
              )}
              {role.roleType === "CONSULTANT" && (
                <div className="dropdown">
                  <button className="btn btn-secondary mb-3 mb-md-0 d-block d-sm-inline-block " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Facility Owners
                  </button>
                  <ul className="dropdown-menu" style={{ minWidth: "10rem" }}>
                    {company?.companyType === "CONSULTANT" && <RenderFacilities consultantApplications={company?.consultantApplications || []} />}
                  </ul>
                </div>
              )}

              <a
                className="btn  mb-3 mb-md-0 d-block d-sm-inline-block"
                onClick={() => {
                  localStorage.setItem("GridView", !gridView);
                  setGridView(!gridView);
                }}
              >
                <i className="far fa-th"></i>
              </a>
            </div>
          ) : !onGoingApplication ? (
            <div className=" col-md-2 d-flex justify-content-between">
              <header className="p-0 card-header">
                <div className="container-fluid">
                  <div className="navbar d-flex  justify-content-between align-items-center p-0 m-0">
                    {searchValue ? null : (
                      <ul className="nav subnav me-auto d-flex flex-wrap">
                        {(roleName === "Approving Accountant" || roleName === "Approving Representative") && (
                          <div className="dropdown p-0">
                            <div className="toggle p-0" type="button" id="paymentmethods" data-bs-toggle="dropdown" aria-expanded="false">
                              <li className="nav-item text-primary">
                                <a className={stagesOfActive === "Payment method" ? "nav-link active" : "nav-link"}>Payment method</a>
                              </li>
                            </div>

                            <ul className="dropdown-menu  p-1" aria-labelledby="paymentmethods">
                              <li>
                                <div className="py-2 ps-3">
                                  <div className="form-check ">
                                    <input
                                      className="form-check-input "
                                      onClick={(e) => {
                                        const checked = e.target.checked;
                                        if (checked) {
                                          if (!paymentmethod.includes("Stripe")) {
                                            searchPaymentMethod([...paymentmethod, "Stripe"]);
                                          }
                                        } else {
                                          searchPaymentMethod(paymentmethod.filter((method) => method !== "Stripe"));
                                        }
                                      }}
                                      checked={paymentmethod.includes("Stripe")}
                                      type="checkbox"
                                      value="Stripe"
                                      id="flexCheckDisabled"
                                    />
                                    <label className="form-check-label  ms-2" htmlFor="flexCheckDisabled">
                                      Stripe
                                    </label>
                                  </div>
                                  <div className="form-check pt-1">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onClick={(e) => {
                                        const checked = e.target.checked;
                                        if (checked) {
                                          if (!paymentmethod.includes("ACH")) {
                                            searchPaymentMethod([...paymentmethod, "ACH"]);
                                          }
                                        } else {
                                          searchPaymentMethod(paymentmethod.filter((method) => method !== "ACH"));
                                        }
                                      }}
                                      checked={paymentmethod.includes("ACH")}
                                      value="ACH"
                                      id="flexCheckDisabled1"
                                    />
                                    <label className="form-check-label ms-2" htmlFor="flexCheckDisabled1">
                                      ACH
                                    </label>
                                  </div>

                                  <div className="form-check pt-1">
                                    <input
                                      className="form-check-input "
                                      type="checkbox"
                                      onClick={(e) => {
                                        const checked = e.target.checked;
                                        if (checked) {
                                          if (!paymentmethod.includes("Check")) {
                                            searchPaymentMethod([...paymentmethod, "Check"]);
                                          }
                                        } else {
                                          searchPaymentMethod(paymentmethod.filter((method) => method !== "Check"));
                                        }
                                      }}
                                      checked={paymentmethod.includes("Check")}
                                      value="Check"
                                      id="flexCheckDisabled2"
                                    />
                                    <label className="form-check-label ms-2" htmlFor="flexCheckDisabled2">
                                      Check
                                    </label>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              </header>

              <a
                className="btn  mb-3 mb-md-0 d-block d-sm-inline-block"
                onClick={() => {
                  localStorage.setItem("GridView", !gridView);
                  setGridView(!gridView);
                }}
              >
                <i className="far fa-th"></i>
              </a>
            </div>
          ) : (
            <a
              className="btn  mb-3 mb-md-0 d-block d-sm-inline-block"
              onClick={() => {
                localStorage.setItem("GridView", !gridView);
                setGridView(!gridView);
              }}
            >
              <i className="far fa-th"></i>
            </a>
          )}
        </div>
      </div>
      {lodash ? (
        <FilterLoading />
      ) : (
        !onGoingApplication && (
          <header className="p-0 card-header border-top">
            <div className="container-fluid">
              <div className="navbar d-flex  justify-content-between align-items-center p-0 m-0">
                {searchValue ? null : (
                  <ul className="nav subnav me-auto d-flex flex-wrap">
                    <li
                      className="nav-item text-primary border-bottom m-0"
                      onClick={() => {
                        setCurrentPage(1);
                        // filterApplicationdata("one");
                        setStagesOfActive("allApplication");
                        setStages("one");
                        const query = { filters: "allApplication", page: 1 };
                        const includesPaymentMethod = paymentmethod.some((method) => ["Stripe", "ACH", "Check"].includes(method));
                        if (includesPaymentMethod) {
                          query.paymentmethod = paymentmethod.join(" ").trim();
                          filterApplicationdata("one", 1, false, false, paymentmethod.join(" ").trim());
                        } else {
                          setPaymentmethod([]);
                          filterApplicationdata("one", 1);
                        }
                        history.push({
                          pathname: "/dashboard",
                          search: new URLSearchParams(query).toString(),
                        });
                      }}
                    >
                      <a className={stagesOfActive === "allApplication" ? "nav-link active px-2" : "nav-link px-2"}>
                        <span className="badges-count me-2">{allApplications ? allApplications : 0}</span>
                        {"All"}
                      </a>
                    </li>
                    {filterList &&
                      filterList.map((data, index) => (
                        <li
                          className="nav-item text-primary border-bottom m-0"
                          key={index}
                          onClick={() => {
                            setCurrentPage(1);
                            setStages(data.id);
                            setStagesOfActive(data.stageName);
                            const query = { filters: data.stageName, page: 1 };
                            const includesPaymentMethod = paymentmethod.some((method) => ["Stripe", "ACH", "Check"].includes(method));
                            if (includesPaymentMethod) {
                              query.paymentmethod = paymentmethod.join(" ").trim();
                              filterApplicationdata(data.id, 1, false, false, paymentmethod.join(" ").trim());
                            } else {
                              setPaymentmethod([]);
                              filterApplicationdata(data.id, 1);
                            }
                            history.push({
                              pathname: "/dashboard",
                              search: new URLSearchParams(query).toString(),
                            });
                          }}
                        >
                          <a className={stagesOfActive === data.stageName ? "nav-link active px-2" : "nav-link px-2"}>
                            <span className="badges-count me-2">{data.list}</span>
                            {data.stageName}
                          </a>
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            </div>
          </header>
        )
      )}

      {!onGoingApplication ? (
        <div className="card  position-relative mb-4">
          <div className="card-body ">
            <div className="row heading mb-5 d-md-flex d-none " style={{ borderBottom: "1px solid black" }}>
              <div
                className="col-md-1 d-flex pl-2"
                onClick={() => {
                  sortByAscDec("APPLICATION", sortBy);
                }}
              >
                <small className="text-muted d-block dashboard-width pt-1"> Application </small>
                <p className=" d-flex flex-column fs-5 ">
                  <a className={sortBy === 1 && sortType === "APPLICATION" ? "fw-bold" : "text-muted"}>&#8963;</a>
                  <a style={{ marginTop: "-35px" }} className={sortBy === -1 && sortType === "APPLICATION" ? "fw-bold" : "text-muted"}>
                    &#8964;
                  </a>
                </p>
              </div>
              <div
                className="col-md-1 d-flex "
                onClick={() => {
                  sortByAscDec("HNTB_FILE", sortBy);
                }}
              >
                <small className="text-muted d-block dashboard-widths pt-1"> HNTB File#</small>
                <p className=" d-flex flex-column fs-5 ">
                  <a className={sortBy === 1 && sortType === "HNTB_FILE" ? "fw-bold" : "text-muted"}>&#8963;</a>
                  <a style={{ marginTop: "-35px" }} className={sortBy === -1 && sortType === "HNTB_FILE" ? "fw-bold" : "text-muted"}>
                    &#8964;
                  </a>
                </p>
              </div>
              <div
                className="col-md-1 mb-1 d-flex "
                onClick={() => {
                  sortByAscDec("ACTIVITY_NUMBER", sortBy);
                }}
              >
                <small className="text-muted d-block dashboard-width pt-1">Activity Number</small>
                <p className=" d-flex flex-column fs-5 ">
                  <a className={sortBy === 1 && sortType === "ACTIVITY_NUMBER" ? "fw-bold" : "text-muted"}>&#8963;</a>
                  <a style={{ marginTop: "-35px" }} className={sortBy === -1 && sortType === "ACTIVITY_NUMBER" ? "fw-bold" : "text-muted"}>
                    &#8964;
                  </a>
                </p>
              </div>
              <div
                className="col-md-1 d-flex "
                onClick={() => {
                  sortByAscDec("APPLICATION_TYPE", sortBy);
                }}
              >
                <small className="text-muted d-block dashboard-width pt-1">Application Type</small>
                <p className=" d-flex flex-column fs-5 ">
                  <a className={sortBy === 1 && sortType === "APPLICATION_TYPE" ? "fw-bold" : "text-muted"}>&#8963;</a>
                  <a style={{ marginTop: "-35px" }} className={sortBy === -1 && sortType === "APPLICATION_TYPE" ? "fw-bold" : "text-muted"}>
                    &#8964;
                  </a>
                </p>
              </div>
              <div
                className="col-md-1 d-flex "
                onClick={() => {
                  sortByAscDec("APPLICANT", sortBy);
                }}
              >
                <small className="text-muted d-block dashboard-width pt-1">Applicant</small>
                <p className=" d-flex flex-column fs-5 ">
                  <a className={sortBy === 1 && sortType === "APPLICANT" ? "fw-bold" : "text-muted"}>&#8963;</a>
                  <a style={{ marginTop: "-35px" }} className={sortBy === -1 && sortType === "APPLICANT" ? "fw-bold" : "text-muted"}>
                    &#8964;
                  </a>
                </p>
              </div>
              <div className="col-md-1">
                <small className="text-muted d-block pt-1">Primary Contact</small>
              </div>
              <div
                className="col-md-1 d-flex "
                onClick={() => {
                  sortByAscDec("DATE_SUBMITTED", sortBy);
                }}
              >
                <small className="text-muted d-block dashboard-width pt-1">Date Submitted</small>
                <p className=" d-flex flex-column fs-5 ">
                  <a className={sortBy === 1 && sortType === "DATE_SUBMITTED" ? "fw-bold" : "text-muted"}>&#8963;</a>
                  <a style={{ marginTop: "-35px" }} className={sortBy === -1 && sortType === "DATE_SUBMITTED" ? "fw-bold" : "text-muted"}>
                    &#8964;
                  </a>
                </p>
              </div>
              <div
                className="col-md-1 d-flex "
                onClick={() => {
                  sortByAscDec("LAST_STAGE_UPDATE", sortBy);
                }}
              >
                <small className="text-muted d-block dashboard-width pt-1">Last Stage Update</small>
                <p className=" d-flex flex-column fs-5 ">
                  <a className={sortBy === 1 && sortType === "LAST_STAGE_UPDATE" ? "fw-bold" : "text-muted"}>&#8963;</a>
                  <a style={{ marginTop: "-35px" }} className={sortBy === -1 && sortType === "LAST_STAGE_UPDATE" ? "fw-bold" : "text-muted"}>
                    &#8964;
                  </a>
                </p>
              </div>
              {roleName === "Approving Accountant" && (
                <div className="col-md-1 ">
                  <small className="text-muted d-block pt-1">payment</small>
                </div>
              )}
              <div className="col-md-1 ">
                <small className="text-muted d-block pt-1">Comments</small>
              </div>

              <div
                className="col-md-1 d-flex "
                onClick={() => {
                  sortByAscDec("STATUS", sortBy);
                }}
              >
                <small className="text-muted d-block dashboard-width pt-1">Status</small>
                <p className=" d-flex flex-column fs-5">
                  <a className={sortBy === 1 && sortType === "STATUS" ? " fw-bold" : "text-muted"}>&#8963;</a>
                  <a style={{ marginTop: "-35px" }} className={sortBy === -1 && sortType === "STATUS" ? " fw-bold" : "text-muted"}>
                    &#8964;
                  </a>
                </p>
              </div>
              <div className={`${roleName === "Approving Accountant" ? "col-md-1 mb-2" : "col-md-2 mb-2"}`}>
                <small className="text-muted d-block pt-1">Action</small>
              </div>
            </div>
            {lodash ? (
              <DashBoardLoading />
            ) : (
              currentApplicationsByUser.length > 0 &&
              currentApplicationsByUser.map((v, i) => (
                <>
                  {(v.applicationStage === -1 && roleName != "applicant") || v.applicationStage === 0 ? null : v.applicationStage !== 25 ? (
                    <>
                      {(roleName === "Engineering Representative" && v.applicationStage < 6) || (roleName === "Operating Representative" && v.applicationStage < 6) ? null : (
                        <ApplicationListViewCard v={v} />
                      )}
                    </>
                  ) : null}
                  {count === 0 ? <div className="card-body text-center" key={i}></div> : null}
                </>
              ))
            )}
            {currentApplicationsByUser.length === 0 ? (
              <div className="card-body text-center">
                <p className="my-5 text-muted fst-italic">No Current Application</p>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {!agreementsApplication ? (
        <>
          {searchValue ? (
            <div className="card-header border-0">
              <h2 className="mb-0">Agreements</h2>{" "}
            </div>
          ) : null}

          <div className="card position-relative mb-4">
            <div className="card-body">
              <div className="row heading mb-5 d-md-flex d-none ">
                <div
                  className="col-md-2 d-flex pl-2"
                  onClick={() => {
                    sortByAscDec("APPLICATION", sortBy);
                  }}
                >
                  <small className="text-muted d-flex align-items-center">Application</small>
                  <p className=" d-flex flex-column fs-5  pt-3 ps-3">
                    <a className={sortBy === 1 && sortType === "APPLICATION" ? "fw-bold" : "text-muted"}>&#8963;</a>
                    <a style={{ marginTop: "-35px" }} className={sortBy === -1 && sortType === "APPLICATION" ? "fw-bold" : "text-muted"}>
                      &#8964;
                    </a>
                  </p>
                </div>
                <div
                  className="col-md-2  d-flex "
                  onClick={() => {
                    sortByAscDec("HNTB_FILE", sortBy);
                  }}
                >
                  <small className="text-muted d-flex align-items-center">HNTB FILE#</small>
                  <p className=" d-flex flex-column fs-5  pt-3 ps-3">
                    <a className={sortBy === 1 && sortType === "HNTB_FILE" ? "fw-bold" : "text-muted"}>&#8963;</a>
                    <a style={{ marginTop: "-35px" }} className={sortBy === -1 && sortType === "HNTB_FILE" ? "fw-bold" : "text-muted"}>
                      &#8964;
                    </a>
                  </p>
                </div>
                <div
                  className="col-md-2  d-flex "
                  onClick={() => {
                    sortByAscDec("ACTIVITY_NUMBER", sortBy);
                  }}
                >
                  <small className="text-muted d-flex align-items-center">Activity Number</small>
                  <p className=" d-flex flex-column fs-5  pt-3 ps-3">
                    <a className={sortBy === 1 && sortType === "ACTIVITY_NUMBER" ? "fw-bold" : "text-muted"}>&#8963;</a>
                    <a style={{ marginTop: "-35px" }} className={sortBy === -1 && sortType === "ACTIVITY_NUMBER" ? "fw-bold" : "text-muted"}>
                      &#8964;
                    </a>
                  </p>
                </div>
                <div
                  className="col-md-2 d-flex "
                  onClick={() => {
                    sortByAscDec("APPLICANT", sortBy);
                  }}
                >
                  <small className="text-muted d-flex align-items-center">Applicant</small>
                  <p className=" d-flex flex-column fs-5 pt-3 ps-3">
                    <a className={sortBy === 1 && sortType === "APPLICANT" ? "fw-bold" : "text-muted"}>&#8963;</a>
                    <a style={{ marginTop: "-35px" }} className={sortBy === -1 && sortType === "APPLICANT" ? "fw-bold" : "text-muted"}>
                      &#8964;
                    </a>
                  </p>
                </div>
                <div className="col-md-2 d-flex">
                  <small className="text-muted d-flex align-items-center">Applicant’s Primary Contact</small>
                </div>

                <div className="col-md-2  d-flex ">
                  <small className="text-muted d-flex align-items-center">Status</small>
                </div>
              </div>
              {lodash ? (
                <DashBoardLoading />
              ) : (
                currentAgreementsByUser.length > 0 &&
                currentAgreementsByUser.map((v, i) => (
                  <div key={i}>
                    <AggrementListViewCard v={v} />
                  </div>
                ))
              )}
              {completedCount === 0 ? (
                <div className="card-body text-center">
                  <p className="my-5 text-muted fst-italic">No Current Agreements</p>
                </div>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
export const RenderFacilities = ({ consultantApplications = [] }) => {
  const facilities = new Set();
  const facilityApplications = consultantApplications?.filter((each) => {
    if (facilities.has(each?.facilityCompany?.id)) {
      return false;
    }
    facilities.add(each?.facilityCompany?.id);
    return true;
  });

  const handleFacilityClick = (id) => {
    localStorage.setItem("facilityId", id);
    window.location.reload();
  };

  const facilityId = localStorage.getItem("facilityId");

  return (
    <>
      <li onClick={() => handleFacilityClick("ALL")}>
        <a className={` dropdown-item ${facilityId === "ALL" ? "text-primary" : " text-muted"}`}>All</a>
      </li>

      {facilityApplications?.map((each) => {
        if (!each?.facilityCompany) {
          return null;
        }

        const { id, name } = each?.facilityCompany;
        return (
          <li onClick={() => handleFacilityClick(id)}>
            <a className={`dropdown-item ${facilityId === id ? "text-primary " : " text-muted"}`}>{name}</a>
          </li>
        );
      })}
    </>
  );
};
