/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getLicenseStatus, getOperatingSigneeData, sendNotify, putNextStatus, updateOperatingSignee } from "../../api/currentApplications";
import UploadDraftLoading from "../loaders/UploadDraftLoading";
import { API_URL, File, REACT_APP_NS_risk_finance_fee_original } from "../../api";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { actionSelectedOperatingSignee } from "../../redux/actions";
import checkFileName from "../../utils/checkSpecialCharacter";
import { useLocation } from "react-router";
import CompiledPayment from "../compiledPayment/CompiledPayment";
import { useUser } from "../../hooks/useUser";
import { useApplication, useApplicationRefetch } from "../../hooks/useApplication";
export default function UploadLetterAndValidate(props) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const onSubmit = (d) => {
    if (!characterError.Construction_Approval_Letter) props.uploadLetterAndValidate();
  };
  const { roleName = "" } = useUser() || {};
  const query = new URLSearchParams(useLocation().search);
  const [compileValue, setCompileValue] = React.useState([]);
  const [applicationData, setApplicationData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  let [searchdata, setSearchdata] = React.useState([]);
  const [compileSignedValue, setCompileSignedValue] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [characterError, setCharacterError] = React.useState({
    Construction_Approval_Letter: false,
    Construction_Approval_LetterName: "",
    Recent_Insurance_Approvals: false,
    Recent_Insurance_ApprovalsName: "",
  });
  const [riskFinanceFee, setReskFinanceFee] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.email) : []);
  };
  const application = useApplication();
  const applicationRefetch = useApplicationRefetch();
  React.useEffect(() => {
    dispatch(actionSelectedOperatingSignee(selectedValue));
  }, [selectedValue]);
  let { ItemId } = props;

  React.useEffect(async () => {
    setLoading(true);
    setApplicationData(application.selectedOperatingSignee);
    let response = await getLicenseStatus(ItemId);
    setCompileValue(response.data.data);
    let value = response.data.data.compiled && response.data.data.compiled.filter((data) => data.action === "Sign and Paid");
    setReskFinanceFee(value[0].riskFinanceFee === "true" ? true : false);
    let responses = await getOperatingSigneeData();
    let values = responses.data.list;
    let result = [];
    values.map((item, index) => {
      let OperatingSigneeName = item.name;
      let OperatingSigneeEmail = item.email;
      result[index] = {
        value: OperatingSigneeName,
        label: OperatingSigneeName,
        email: OperatingSigneeEmail,
      };
    });
    setSearchdata(result);

    const object = {
      applicationID: ItemId,
      signerEmail: ["Test"],
      agreementType: "CompiledAgreement",
    };
    let res = await sendNotify(object);
    setCompileSignedValue(res.data.responseData);
    setLoading(false);
  }, []);

  let selectOpeatingName = searchdata.filter((data) => {
    return data.email === applicationData[0];
  });

  let { compiled, downloadSignedDocuments2 } = compileValue || [];
  let compiles0 = (compiled && compiled[0]) || [];
  let { action, date, formData, role } = compiles0 || {};
  let filedata = formData && formData[0];
  let { fileNameOriginal, fileNameUpdated, signedDocumentsEnvelopeId } = filedata || "";
  let compiles1 = (compiled && compiled[1]) || [];
  let { date: date1, formData: formData1 } = compiles1 || {};

  let filedata1 = formData1 && formData1[0];
  let filedata2 = formData1 && formData1[1];
  let filedata3 = formData1 && formData1[2];
  let filedata4 = formData1 && formData1[3];

  let { fileNameOriginal: fileNameOriginal1, fileNameUpdated: fileNameUpdated1 } = filedata1 || "";
  let { fileNameOriginal: fileNameOriginal2, fileNameUpdated: fileNameUpdated2 } = filedata2 || "";
  let { fileNameOriginal: fileNameOriginal3, fileName: fileName3, fileNameUpdated: fileNameUpdated3 } = filedata3 || "";
  let { fileNameOriginal: fileNameOriginal4, fileName: fileName4, fileNameUpdated: fileNameUpdated4 } = filedata4 || "";
  let compiles2 = (compiled && compiled[2]) || [];
  let { action: action2, date: date2 } = compiles2 || {};
  let compiles3 = (compiled && compiled[3]) || [];
  let { action: action3, date: date3 } = compiles3 || {};

  let compiles4 = (compiled && compiled[4]) || [];
  let { action: action4, date: date4, formData: formData4 } = compiles4 || {};

  const { name: SignerName } = compileSignedValue || {};
  const SignedDate = compileSignedValue ? (compileSignedValue.signedAt ? new Date(compileSignedValue.signedAt).toString().slice(0, 15) : "") : "";

  const editOperatingSignee = async (resend) => {
    if (resend) {
      const data = {
        applicationID: ItemId,
        OperatingSignee: application?.selectedOperatingSignee[0],
      };
      await updateOperatingSignee(data);
      applicationRefetch();
      return;
    }

    if (selectedValue[0]) {
      const data = {
        applicationID: ItemId,
        OperatingSignee: selectedValue[0],
      };
      await updateOperatingSignee(data);
      window.location.reload();
    } else {
      alert("Please Select Operating Signee");
    }
  };

  return loading ? (
    <>
      <UploadDraftLoading />
      <UploadDraftLoading />
    </>
  ) : (
    <div>
      <div className="container mt-4">
        <div className="row">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-lg-8 offset-lg-2">
              <div id="files-upload" className="">
                <div className="inner-form card mb-4 position-relative comment-card">
                  <div className="card-header border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Compiled License Agreement</h2>
                      <small className="text-muted">By HNTB</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <label htmlFor="license-agreement" className="form-label">
                      Uploaded License Agreement
                    </label>
                    <div className="card bg-secondary border-0">
                      <div className="card-body p-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <i className="fal fa-file-pdf"></i>
                          </div>
                          <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal}</div>
                          <div className="flex-shrink-0">
                            <a
                              href={
                                API_URL +
                                File +
                                (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length > 1 && !downloadSignedDocuments2
                                  ? signedDocumentsEnvelopeId.length - 1 + "_"
                                  : signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length + "_") +
                                fileNameUpdated
                              }
                              target="_blank"
                              className="btn btn-link"
                            >
                              View
                            </a>
                            <a
                              href={
                                API_URL +
                                File +
                                (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length > 1 && !downloadSignedDocuments2
                                  ? signedDocumentsEnvelopeId.length - 1 + "_"
                                  : signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length + "_") +
                                fileNameUpdated
                              }
                              target="_blank"
                              className="btn btn-link"
                              download
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer py-0 justify-content-center bg-success">
                    <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                      <span className="d-flex justify-content-between align-items-center">
                        <span>
                          <i className="fal fa-check"></i> {action}{" "}
                        </span>
                        <span>
                          By HNTB Representative on <small> {date ? date.slice(0, 15) : ""}</small>
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
                <div className="card mb-4 comment-card">
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Uploaded Insurance</h2>
                      <small className="text-muted">By Applicant</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <label className="text-muted d-block">Uploaded Certificates of Insurance</label>
                      <div className="card bg-secondary border-0 mb-2">
                        <div className="card-body">
                          <label className="text-muted d-block form-label">North Carolina Railroad Co., 2809 Highwoods Blvd, Raleigh, NC 27604</label>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <i className="fal fa-file-pdf"></i>
                            </div>
                            <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal1}</div>
                            <div className="flex-shrink-0">
                              <a href={API_URL + File + fileNameUpdated1} target="_blank" className="btn btn-link">
                                View
                              </a>
                              <a href={API_URL + File + fileNameUpdated1} target="_blank" download className="btn btn-link">
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card bg-secondary border-0 mb-2">
                        <div className="card-body ">
                          <label className="text-muted d-block form-label">Norfolk Southern Railway Co., Risk Management Dept, 650 West Peachtree Street NW, Box 46, Atlanta, GA 30308</label>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <i className="fal fa-file-pdf"></i>
                            </div>
                            <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal2}</div>
                            <div className="flex-shrink-0">
                              <a href={API_URL + File + fileNameUpdated2} target="_blank" className="btn btn-link">
                                View
                              </a>
                              <a href={API_URL + File + fileNameUpdated2} target="_blank" download className="btn btn-link">
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="bg-black" style={{ backgroundColor: " rgba(0, 0, 0, 0.5)" }} />
                      <div className="card bg-secondary border-0 mb-3 p-4" id="optionCard1">
                        <div>
                          <div className="form-check mb-3 d-flex align-items-center">
                            <input className="form-check-input" type="checkbox" value="optionOne" id="optionOne" checked={!riskFinanceFee} disabled={true} />
                            <label className="form-check-label text-muted" htmlFor="optionOne" style={{ fontFamily: "Oswald,sans-serif" }}>
                              Option 1
                            </label>
                          </div>
                          <div id="optn1">
                            <p>
                              <small>Upload Proof of Railroad Protective Liability Insurance for NCRR and NSR as specified in the body of the agreement and Exhibit B, Article 9.</small>
                            </p>
                            {!riskFinanceFee && fileName3 && (
                              <div className="card bg-secondary border-0 mb-2">
                                <div className="card-body">
                                  <label className="text-muted d-block form-label">North Carolina Railroad Co., 2809 Highwoods Blvd, Raleigh, NC 27604</label>
                                  <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0">
                                      <i className="fal fa-file-pdf"></i>
                                    </div>
                                    <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal3}</div>
                                    <div className="flex-shrink-0">
                                      <a href={API_URL + File + fileNameUpdated3} target="_blank" className="btn btn-link">
                                        View
                                      </a>
                                      <a href={API_URL + File + fileNameUpdated3} target="_blank" download className="btn btn-link">
                                        Download
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {!riskFinanceFee && fileName4 && (
                              <div className="card bg-secondary border-0 mb-2">
                                <div className="card-body">
                                  <label className="text-muted d-block form-label">Norfolk Southern Railway Co., Risk Management Dept, 650 West Peachtree Street NW, Box 46, Atlanta, GA 30308</label>
                                  <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0">
                                      <i className="fal fa-file-pdf"></i>
                                    </div>
                                    <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal4}</div>
                                    <div className="flex-shrink-0">
                                      <a href={API_URL + File + fileNameUpdated4} target="_blank" className="btn btn-link">
                                        View
                                      </a>
                                      <a href={API_URL + File + fileNameUpdated4} target="_blank" download className="btn btn-link">
                                        Download
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card bg-secondary border-0 mb-3 p-4" id="optionCard2">
                        <div>
                          <div className="form-check mb-3 d-flex align-items-center">
                            <input className="form-check-input" type="checkbox" value="" id="flexSwitchCheckChecked" disabled={true} checked={riskFinanceFee} />
                            <label className="form-check-label text-muted" htmlFor="flexSwitchCheckChecked" style={{ fontFamily: "Oswald,sans-serif" }}>
                              Option 2
                            </label>
                          </div>
                          <div id="option2">
                            <div>
                              <p id="optn2">
                                <small>
                                  Pay ${REACT_APP_NS_risk_finance_fee_original} Risk Financing Fee to be added to NSR's Master Railroad Protective Liability Insurance coverage, as described in the
                                  attached special provision.
                                </small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer py-0 justify-content-center bg-success">
                    <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                      <span className="d-flex justify-content-between align-items-center">
                        <span>
                          <i className="fal fa-check"></i> {action}
                        </span>
                        <span>
                          By Applicant's on <small> {date1 ? date1.slice(0, 15) : ""}</small>
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
                <CompiledPayment />

                {roleName === "applicant" || roleName === "Engineering Representative" || roleName === "Approving Representative" || roleName === "Signing Representative" ? (
                  <div className="card mb-4 position-relative comment-card">
                    <div className="card-header  border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        <h2 className="mb-0">Sign Agreement</h2>
                        <small className="text-muted">By Applicant</small>
                      </div>
                    </div>
                    <div className="card-body">
                      <p className="m-0 text-center">Agreement Signed.</p>
                    </div>
                    {role === "applicant" && (
                      <div className="mt-3 text-muted" style={{ cursor: "pointer" }}>
                        <u>Resend Email</u>
                      </div>
                    )}

                    <div className="card-footer py-0 justify-content-center bg-success">
                      <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                        <span className="d-flex justify-content-between align-items-center">
                          <span>
                            <i className="fal fa-check"></i> Signed
                          </span>
                          <span>
                            By {SignerName} on <small> {SignedDate ? SignedDate : ""}</small>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                ) : null}

                <div className="card mb-4 ">
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Validated Fee, Insurance and Agreement</h2>
                      <small className="text-muted">By NCRR</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <p className="m-0 text-center">Validated By NCRR</p>
                  </div>
                  <div className="card-footer py-0 justify-content-center bg-success">
                    <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                      <span className="d-flex justify-content-between align-items-center">
                        <span>
                          <i className="fal fa-check"></i> {action2}{" "}
                        </span>
                        <span>
                          By Justin Madigan, Engineering Rep., NCRR on <small>{date2 ? date2.slice(0, 15) : ""}</small>
                        </span>
                      </span>
                    </a>
                  </div>
                </div>

                <div className="card mb-4 ">
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Validated and Signed</h2>
                      <small className="text-muted">By V.P. Engineering, NCRR</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <p className="m-0 text-center">Validated By V.P. Engineering, NCRR</p>
                  </div>
                  <div className="card-footer py-0 justify-content-center bg-success">
                    <a href="#" className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                      <span className="d-flex justify-content-between align-items-center">
                        <span>
                          <i className="fal fa-check"></i>
                          {action3}{" "}
                        </span>
                        <span>
                          By Donald Arant, V.P. Engineering, NCRR on <small> {date3 ? date3.slice(0, 15) : ""}</small>
                        </span>
                      </span>
                    </a>
                  </div>
                </div>

                {!formData4 && (
                  <div className="card mb-4 border-primary shadow ">
                    <div className="card-header  border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        <h2 className="mb-0">Upload Letter</h2>
                        {roleName !== "Operating Representative" ? (
                          <small className="text-muted">For Norfolk Southern</small>
                        ) : (
                          <small className="text-muted">
                            {selectedValue == "" ? (
                              <>
                                <Controller
                                  control={control}
                                  rules={{ required: true }}
                                  name="citySelect"
                                  render={({}) => (
                                    <>
                                      <Select
                                        className="dropdown"
                                        placeholder="Select Operating Signee"
                                        value={searchdata.filter((obj) => selectedValue.includes(obj.email))} // set selected values
                                        options={searchdata} // set list of the data
                                        onChange={handleChange} // assign onChange function
                                        isDisabled={!downloadSignedDocuments2}
                                        required={true}
                                        isMulti
                                        isClearable
                                      />
                                    </>
                                  )}
                                />

                                {errors.citySelect && <p className="text-danger h6">*required </p>}
                              </>
                            ) : (
                              <Select
                                className="dropdown"
                                placeholder="Select Operating Signee"
                                value={searchdata.filter((obj) => selectedValue.includes(obj.email))} // set selected values
                                options={searchdata} // set list of the data
                                onChange={handleChange} // assign onChange function
                                isMulti
                                isClearable
                              />
                            )}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="card-body">
                      {roleName == "Operating Representative" ? (
                        <div className="mb-3">
                          {downloadSignedDocuments2 ? (
                            <a
                              style={{ cursor: "pointer" }}
                              href={
                                API_URL +
                                File +
                                (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length > 1 && !downloadSignedDocuments2
                                  ? signedDocumentsEnvelopeId.length - 1 + "_"
                                  : signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length + "_") +
                                fileNameUpdated
                              }
                              target="_blank"
                              className="btn btn-link "
                            >
                              View Agreement
                            </a>
                          ) : (
                            <div className="mb-3">
                              <a className={downloadSignedDocuments2 ? "btn btn-sm btn-success" : "btn btn-sm btn-primary"} href={`${API_URL}/eg001?applicationID=${ItemId}`}>
                                {!downloadSignedDocuments2 ? "Updated License Agreement for validation" : " Update License Agreement for validation"}
                              </a>
                            </div>
                          )}
                        </div>
                      ) : (
                        " "
                      )}

                      <div className="mb-3">
                        <label className="text-muted d-block">Norfolk Southern Approval Letter</label>
                        <div className="input-group mb-3">
                          <input
                            type="file"
                            accept=".pdf"
                            className="form-control"
                            id="Construction_Approval_Letter"
                            {...register("Construction_Approval_Letter", {
                              required: true,
                              onChange: (e) => {
                                const fileName = e.target.files[0].name;
                                if (!checkFileName(fileName)) {
                                  setCharacterError((data) => ({
                                    ...data,
                                    Construction_Approval_Letter: true,
                                    Construction_Approval_LetterName: fileName,
                                  }));
                                } else {
                                  setCharacterError((data) => ({
                                    ...data,
                                    Construction_Approval_Letter: false,
                                    Construction_Approval_LetterName: "",
                                  }));
                                }
                              },
                            })}
                            disabled={!downloadSignedDocuments2 || roleName !== "Operating Representative"}
                          />

                          <label className="input-group-text bg-secondary" htmlFor="Construction_Approval_Letter">
                            Upload
                          </label>
                        </div>
                        <span className="text-danger">
                          <span>
                            {errors.Construction_Approval_Letter && errors.Construction_Approval_Letter.type === "required" && <span>This field is required</span>}
                            {characterError.Construction_Approval_Letter && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                          </span>
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="text-muted d-block">Recent Insurance Approvals</label>
                        <div className="input-group mb-3">
                          <input
                            type="file"
                            accept=".pdf"
                            className="form-control"
                            id="Recent_Insurance_Approvals"
                            {...register("Recent_Insurance_Approvals", {
                              required: true,
                              onChange: (e) => {
                                const fileName = e.target.files[0].name;
                                if (!checkFileName(fileName)) {
                                  setCharacterError((data) => ({
                                    ...data,
                                    Recent_Insurance_Approvals: true,
                                    Recent_Insurance_ApprovalsName: fileName,
                                  }));
                                } else {
                                  setCharacterError((data) => ({
                                    ...data,
                                    Recent_Insurance_Approvals: false,
                                    Recent_Insurance_ApprovalsName: "",
                                  }));
                                }
                              },
                            })}
                            disabled={!downloadSignedDocuments2 || roleName !== "Operating Representative"}
                          />

                          <label className="input-group-text bg-secondary" htmlFor="Recent_Insurance_Approvals">
                            Upload
                          </label>
                        </div>
                        <span className="text-danger">
                          <span>
                            {errors.Recent_Insurance_Approvals && errors.Recent_Insurance_Approvals.type === "required" && <span>This field is required</span>}
                            {characterError.Recent_Insurance_Approvals && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                          </span>
                        </span>
                      </div>
                    </div>
                    {roleName !== "Operating Representative" ? (
                      <div className="card-footer justify-content-center " style={{ backgroundColor: "rgb(233 237 241)" }}>
                        Awaiting for Norfolk Southern
                        <i className="fal fa-chevron-right ms-2"></i>
                      </div>
                    ) : (
                      <div className={downloadSignedDocuments2 ? "card-footer justify-content-center bg-primary" : "card-footer justify-content-center bg-primary disabled"}>
                        <button style={{ border: "none", backgroundColor: "#5E74E4" }} className="d-block text-white" disabled={!downloadSignedDocuments2 || roleName !== "Operating Representative"}>
                          {roleName !== "Operating Representative" ? "Awaiting For Norfolk Southern" : " Upload"}

                          <i className="fal fa-chevron-right ms-2"></i>
                        </button>
                      </div>
                    )}
                  </div>
                )}

                {formData4 &&
                (roleName == "applicant" ||
                  roleName == "Approving Representative" ||
                  roleName == "Operating Representative" ||
                  roleName == "Engineering Representative" ||
                  roleName == "Signing Representative" ||
                  roleName == "Approving Accountant" ||
                  roleName == "Operating Signee") ? (
                  <div className="card mb-4 ">
                    <div className="card-header  border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        <h2 className="mb-0">Uploaded Letter</h2>
                        <small className="text-muted">By Norfolk Southern</small>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="">
                        <label htmlFor="license-agreement" className="form-label">
                          Norfolk Southern Approval Letter Uploaded
                        </label>
                        <div className="card bg-secondary border-0">
                          <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <i className="fal fa-file-pdf"></i>
                              </div>
                              <div className="flex-grow-1 ms-3 w-50">{compiles4.formData[0].fileNameOriginal}</div>
                              <div className="flex-shrink-0">
                                <a href={API_URL + File + compiles4.formData[0].fileNameUpdated} target="_blank" className="btn btn-link">
                                  View
                                </a>
                                <a href={API_URL + File + compiles4.formData[0].fileNameUpdated} target="_blank" download className="btn btn-link">
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div className="mb-3">
                        <label htmlFor="license-agreement" className="form-label">
                          Recent Insurance Approvals
                        </label>
                        <div className="card bg-secondary border-0">
                          <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <i className="fal fa-file-pdf"></i>
                              </div>
                              <div className="flex-grow-1 ms-3 w-50">{compiles4.formData[1].fileNameOriginal}</div>
                              <div className="flex-shrink-0">
                                <a href={API_URL + File + compiles4.formData[1].fileNameUpdated} target="_blank" className="btn btn-link">
                                  View
                                </a>
                                <a href={API_URL + File + compiles4.formData[1].fileNameUpdated} target="_blank" download className="btn btn-link">
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer py-0 justify-content-center bg-success">
                      <a href="#" className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                        <span className="d-flex justify-content-between align-items-center">
                          <span>
                            <i className="fal fa-check"></i> {action4}{" "}
                          </span>
                          <span>
                            By Norfolk Southern on <small> {date4 ? date4.slice(0, 15) : ""}</small>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                ) : null}

                {formData4 ? (
                  <div className="card mb-4 border-primary shadow ">
                    <div className="card-header  border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        <h2 className="mb-0">Validate and Sign</h2>

                        {roleName !== "Operating Representative" ? (
                          <small className="text-muted">For VP, Real Estate for Norfolk Southern</small>
                        ) : (
                          <small className="text-muted">
                            {selectedValue == "" ? (
                              <>
                                <Controller
                                  control={control}
                                  rules={{ required: true }}
                                  name="citySelect"
                                  render={({}) => (
                                    <>
                                      <Select
                                        className="dropdown"
                                        placeholder="Select Operating Signee"
                                        value={searchdata.filter((obj) => selectedValue.includes(obj.email))} // set selected values
                                        options={searchdata} // set list of the data
                                        onChange={handleChange} // assign onChange function
                                        isDisabled={!downloadSignedDocuments2}
                                        required={true}
                                        isMulti
                                        isClearable
                                      />
                                    </>
                                  )}
                                />

                                {errors.citySelect && <p className="text-danger h6">*required </p>}
                              </>
                            ) : (
                              <Select
                                className="dropdown"
                                placeholder="Select Operating Signee"
                                value={searchdata.filter((obj) => selectedValue.includes(obj.email))} // set selected values
                                options={searchdata} // set list of the data
                                onChange={handleChange} // assign onChange function
                                isMulti
                                isClearable
                              />
                            )}
                          </small>
                        )}
                      </div>
                    </div>
                    {roleName !== "applicant" && (
                      <>
                        <div className="alert-danger alert fs-5 m-2 mb-0 text-center">
                          {`Waiting for ${selectOpeatingName && selectOpeatingName[0] && selectOpeatingName[0].value} to digitally sign this Application.`}
                        </div>
                        {application?.selectedOperatingSignee.length > 0 && (
                          <div
                            className="m-3 text-primary"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => editOperatingSignee("resend")}
                          >
                            <u>Resend Email</u>
                          </div>
                        )}
                      </>
                    )}
                    {roleName !== "Operating Signee" && roleName !== "Operating Representative" ? (
                      <div className="card-footer justify-content-center " style={{ backgroundColor: "rgb(233 237 241)" }}>
                        Awaiting for VP, Real Estate for Norfolk Southern
                        <i className="fal fa-chevron-right ms-2"></i>
                      </div>
                    ) : roleName === "Operating Representative" || roleName === "Approving Representative" || roleName === "Engineering Representative" ? (
                      <>
                        {selectedValue.length > 0 ? (
                          <div className="card-footer justify-content-center bg-primary">
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "#5E74E4",
                              }}
                              className="d-block text-white"
                              type="button"
                              disabled={roleName !== "Operating Representative"}
                              onClick={() => editOperatingSignee()}
                            >
                              Reassign
                              <i className="fal fa-chevron-right ms-2"></i>
                            </button>
                            <input readOnly type="hidden" id="Construction_Approval_Letter_Uploaded" defaultValue={compiles4.formData[0].fileNameOriginal} />
                          </div>
                        ) : (
                          <div className="card-footer justify-content-center " style={{ backgroundColor: "rgb(233 237 241)" }}>
                            Awaiting for VP, Real Estate for Norfolk Southern
                            <i className="fal fa-chevron-right ms-2"></i>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="card-footer justify-content-center bg-primary">
                        <button style={{ border: "none", backgroundColor: "#5E74E4" }} className="d-block text-white" disabled={roleName !== "Operating Signee"}>
                          {roleName !== "Operating Signee" ? "Awaiting For VP, Real Estate for Norfolk Southern" : " Validate and Sign"}

                          <i className="fal fa-chevron-right ms-2"></i>
                        </button>
                        <input readOnly type="hidden" id="Construction_Approval_Letter_Uploaded" defaultValue={compiles4.formData[0].fileNameOriginal} />
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
