import { useEffect, useState } from "react";
import AssignContacts from "./AssignContacts";
import { getAssignedContacts, updateContactCompanydetails } from "../../api/crm";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useUser } from "../../hooks/useUser";
import CommonNavigation from "../commonNavigation/CommonNavigation";
import { useApplicationRefetch } from "../../hooks/useApplication";

export const AssignContactsPage = () => {
  const { applicationID } = useParams();
  const user = useUser();
  const applicationRefetch = useApplicationRefetch();
  const [assignedContacts, setAssignedContacts] = useState({
    signatory: "",
    primaryRepresentative: "",
    billingRepresentative: "",
    engineeringRepresentative: "",
    engineeringRepresentativeCompany: "",
  });
  const [companyContactApplication, setCompanyContactApplication] = useState({});

  useEffect(() => {
    checkContacts(applicationID, user);
  }, [applicationID, user]);

  const checkContacts = async (applicationID, user) => {
    try {
      const { data } = await getAssignedContacts(applicationID);
      if (data.status) {
        if (data.data) {
          const { billingContact, primaryContact, consultantContact } = data.data;
          if (billingContact || primaryContact || consultantContact) {
            setCompanyContactApplication(data.data);
          }
        }
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSave = async () => {
    const data = {
      applicationID,
      assignedContacts,
    };
    await updateContactCompanydetails(data);
    applicationRefetch();
    toast.success("Saved Successfully.");
  };
  return (
    <div className="pb-4">
      <div className="border-bottom mb-4 bg-white">
        <div className="w-75 mx-auto">
          <div className="">
            <CommonNavigation ItemId={applicationID} />
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row applicant-section">
          <div className="col-lg-12">
            <AssignContacts onSave={handleSave} setAssignedContacts={setAssignedContacts} companyContactApplication={companyContactApplication} />
          </div>
        </div>
      </div>
    </div>
  );
};
