import React from "react";
import { useHistory } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { getApplicationPaymentForDashboard, getCompiledAmountForDashboard } from "../../utils/getPaymentAmount";
export default function AggreementGridViewCard(props) {
  let { v } = props;
  let { draft } = v.agreement || {};
  let { formData: formData1 } = (draft && draft[2]) || {};
  let activity_number = (formData1 && formData1[0]) || "";
  const { _id, formName, applicationData } = props.v || {};
  const { roleName = "" } = useUser() || {};

  const history = useHistory();
  activity_number = activity_number.toString();
  return (
    <div onClick={() => history.push(`/finalRelease/${_id}`)} style={{ cursor: "pointer" }}>
      <div className="card mb-3">
        <div className="card-header bg-transparent">
          <div className="row align-items-center">
            <div className="col-md-3">
              <label className="text-muted d-block">Agreement</label>
              <p>#{v.ApplicationTitle && v.ApplicationTitle ? v.ApplicationTitle : v._id}</p>
            </div>
            <div className="col-md-3">
              <label className="text-muted d-block">HNTB File#</label>
              <p>{v.HNTBFileNumber}</p>
            </div>
            <div className="col-md-2">
              <label className="text-muted d-block">Activity Number</label>
              {/* Activity 4 */}
              <p>{activity_number ? activity_number : ""}</p>
            </div>

            <div className="col-md-3">
              <label className="text-muted d-block">Application Type</label>
              <p>{formName}</p>
            </div>
            <div className="col-md-3">
              <label className="text-muted d-block">Status</label>
              <p>{"Completed"}</p>
            </div>
          </div>
        </div>
        <div className="card-header bg-transparent">
          <div className="row align-items-center">
            <div className="col-md-3">
              <label className="text-muted d-block">Applicant</label>
              <p>{v?.applicantDetails && v?.applicantDetails?.consultantContact?.name}</p>
            </div>
            {roleName === "Approving Accountant" && (
              <div className="col-md-2">
                <label className="text-muted d-block">Payment1</label>
                <p>
                  <ol className="m-0 px-3">
                    <li className="mb-0">{getApplicationPaymentForDashboard(v)}</li>
                    <li className="mb-0">{getCompiledAmountForDashboard(v)}</li>
                  </ol>
                </p>
              </div>
            )}
            <div className="col-md-3">
              <label className="text-muted d-block">Applicant’s Primary Contact</label>
              <p>{v.applicantDetails && v.applicantDetails?.facilityCompany?.name}</p>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <label className="text-muted d-block">Proposed work involves</label>
              <p>{applicationData["Proposed Work Involves"] || ""} </p>
            </div>
            <div className="col-md-4">
              <label className="text-muted d-block">Orientation of proposed pipeline installation:</label>
              <p>{applicationData["Orientation of proposed pipeline installation"] || ""}</p>
            </div>
            <div className="col-md-4">
              <label className="text-muted d-block">Installation is:</label>
              <p>{applicationData["Installation is"] || ""}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label className="text-muted d-block">Type of proposed installation:</label>
              <p>{applicationData["Type of proposed installation"] || ""}</p>
            </div>
            <div className="col-md-3">
              <label className="text-muted d-block">Total aerial length on the NCRR Corridor:</label>
              <p>{applicationData["Total aerial length on the NCRR Corridore"] || ""}</p>
            </div>
            <div className="col-md-3">
              <label className="text-muted d-block">Total buried length on the NCRR Corridor:</label>
              <p>{applicationData["Total buried length on the NCRR Corridor"]}</p>
            </div>
            <div className="col-md-3">
              <label className="text-muted d-block">Name of contractor:</label>
              <p>{applicationData["Name of contractor"] || ""}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
