import React from "react";
import { useForm } from "react-hook-form";
import UploadDraftLoading from "../loaders/UploadDraftLoading";
import checkFileName from "../../utils/checkSpecialCharacter";
import { useUser } from "../../hooks/useUser";
import { ApplicantDetailsModel } from "../models/ApplicantDetailsModel";

export default function CompiledAgreement(props) {
  const [loading, setLoading] = React.useState(false);
  const [characterError, setCharacterError] = React.useState({
    License_Agreement: false,
    Payment_Instructions: false,
    License_AgreementName: "",
    Payment_InstructionsName: "",
  });
  const { roleName = "" } = useUser() || {};
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (!characterError.License_Agreement && !characterError.Payment_Instructions) props.compiledAgreementdata(data);
  };
  return (
    <div>
      <ApplicantDetailsModel />
      {loading ? (
        <UploadDraftLoading />
      ) : (
        <div className="container mt-4">
          <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div id="files-upload" className="">
                  <div className="inner-form card mb-4">
                    <div className="card-header text-center border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        <h2 className="mb-0">Compiled License Agreement</h2>

                        <small className="text-muted">For HNTB</small>
                      </div>
                    </div>
                    <div className="card-header border-bottom">
                      <p className="mb-0 text-center">
                        <i>
                          <a className="text-primary" data-bs-toggle="modal" data-bs-target="#applicant-detail">
                            <u>Copy applicant details</u>
                          </a>{" "}
                          to compiled agreement
                        </i>
                      </p>
                    </div>
                    <div className="card-body">
                      <div className="mb-3">
                        <label htmlFor="license_agreement" className="form-label">
                          Upload License Agreement
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="License_Agreement"
                          accept=".pdf"
                          disabled={roleName !== "Engineering Representative"}
                          {...register("License_Agreement", {
                            required: true,
                            onChange: (e) => {
                              const fileName = e.target.files[0].name;
                              if (!checkFileName(fileName)) {
                                setCharacterError((data) => ({
                                  ...data,
                                  License_Agreement: true,
                                  License_AgreementName: fileName,
                                }));
                              } else {
                                setCharacterError((data) => ({
                                  ...data,
                                  License_Agreement: false,
                                  License_AgreementName: "",
                                }));
                              }
                            },
                          })}
                        />
                        <span className="text-danger">
                          <span>
                            {errors.License_Agreement && errors.License_Agreement.type === "required" && <span>This field is required</span>}
                            {characterError.License_Agreement && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                          </span>
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Payment_Instructions">
                          Upload Payment Instructions
                        </label>

                        <div className="input-group mb-3">
                          <input
                            type="file"
                            accept=".pdf"
                            className="form-control"
                            id="Payment_Instructions"
                            {...register("Payment_Instructions", {
                              required: true,
                              onChange: (e) => {
                                const fileName = e.target.files[0].name;
                                if (!checkFileName(fileName)) {
                                  setCharacterError((data) => ({
                                    ...data,
                                    Payment_Instructions: true,
                                    Payment_InstructionsName: fileName,
                                  }));
                                } else {
                                  setCharacterError((data) => ({
                                    ...data,
                                    Payment_Instructions: false,
                                    Payment_InstructionsName: "",
                                  }));
                                }
                              },
                            })}
                            disabled={roleName !== "Engineering Representative"}
                          />
                        </div>
                        <span className="text-danger">
                          <span>
                            {errors.Payment_Instructions && errors.Payment_Instructions.type === "required" && <span>This field is required</span>}
                            {characterError.Payment_Instructions && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                          </span>
                        </span>
                      </div>
                    </div>
                    {roleName !== "Engineering Representative" ? (
                      <div className="card-footer justify-content-center " style={{ backgroundColor: "rgb(233 237 241)" }}>
                        Awaiting for HNTB
                        <i className="fal fa-chevron-right ms-2"></i>
                      </div>
                    ) : (
                      <div className="card-footer justify-content-center bg-primary">
                        <button
                          disabled={roleName !== "Engineering Representative"}
                          //href="compile-license-agreement-customer.html"
                          style={{ border: "none", backgroundColor: "#5E74E4" }}
                          className="d-block text-white"
                        >
                          {roleName !== "Engineering Representative" ? "Awaiting for HNTB" : "Upload"} <i className="fal fa-chevron-right ms-2"></i>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
