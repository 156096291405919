import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { useApplication, useApplicationRefetch } from "../../hooks/useApplication";
export default function CommonNavigation() {
  let location = useLocation();
  const { roleName = "" } = useUser() || {};
  const { applicationStage = 0, _id } = useApplication() || {};
  const refetch = useApplicationRefetch() || {};

  return (
    <div className="d-flex justify-content-between align-items-center">
      <ul className="steps">
        {roleName === "Engineering Representative" || roleName === "Operating Representative" ? null : (
          <>
            <li
              className={
                applicationStage < 1
                  ? "step step--incomplete step--active step-current"
                  : applicationStage >= 1
                    ? `step step--complete ${location.pathname.includes("assignContacts") && "step--active"}`
                    : "step step--incomplete step--inactive"
              }
            >
              <span className="step__icon">{applicationStage >= 1 ? <i className="fal fa-check"></i> : null}</span>
              <Link to={`/assignContacts/${_id}`}>
                <a className="step__label">Assign Contacts</a>
              </Link>
            </li>
            <li
              className={
                applicationStage === 2
                  ? "step step--incomplete step--active step-current"
                  : applicationStage > 2 || applicationStage === 1
                    ? `step step--complete ${location.pathname.includes("applicantView") && "step--active"}`
                    : "step step--incomplete step--inactive"
              }
            >
              <span className="step__icon">
                {applicationStage === 2 || applicationStage === 26 || applicationStage === 1 || applicationStage === 3 || applicationStage >= 6 ? <i className="fal fa-check"></i> : null}
              </span>
              <Link to={`/applicantView/${_id}`}>
                <a className="step__label">Applicant Review</a>
              </Link>
            </li>
            <li
              className={
                applicationStage === 3
                  ? "step step--incomplete step--active step-current "
                  : applicationStage > 3 || applicationStage === 1 || applicationStage === 26
                    ? `step step--complete ${location.pathname.includes("applicationReview") && "step--active"}`
                    : "step step--incomplete step--inactive"
              }
            >
              <span className="step__icon">{applicationStage === 3 || applicationStage === 1 || applicationStage >= 6 ? <i className="fal fa-check"></i> : null}</span>
              <Link to={`/applicationReview/${_id}`}>
                <a className={`step__label ${applicationStage < 3 && applicationStage != 1 ? "disabled" : null}`}>Application Review</a>
              </Link>
            </li>
            <li
              className={
                applicationStage === 1 || applicationStage === 26
                  ? "step step--incomplete step--active step-current "
                  : applicationStage > 3
                    ? `step step--complete ${location.pathname.includes("applicationPayment") && "step--active"}`
                    : "step step--incomplete step--inactive"
              }
            >
              <span className="step__icon">{applicationStage === 3 || applicationStage >= 6 ? <i className="fal fa-check"></i> : null}</span>
              <Link to={`/applicationPayment/${_id}`}>
                <a className={`step__label ${applicationStage <= 3 && applicationStage != 1 ? "disabled" : null}`}>Application Payment</a>
              </Link>
            </li>
          </>
        )}

        {roleName === "Engineering Representative" && (
          <li
            className={
              applicationStage === 2
                ? "step step--incomplete step--active step-current"
                : applicationStage > 2
                  ? `step step--complete ${location.pathname.includes("applicantView") && "step--active"}`
                  : "step step--incomplete step--inactive"
            }
          >
            <span className="step__icon">{applicationStage >= 2 ? <i className="fal fa-check"></i> : null}</span>
            <Link to={`/applicantView/${_id}`}>
              <a className="step__label">Applicant Review</a>
            </Link>
          </li>
        )}
        <li
          className={
            applicationStage === 6
              ? "step step--incomplete step--active step-current "
              : applicationStage > 6 && applicationStage != 26
                ? `step step--complete ${location.pathname.includes("review") && "step--active"}`
                : "step step--incomplete step--inactive"
          }
        >
          <span className="step__icon">{!applicationStage >= 7 ? null : <i className="fal fa-check"></i>}</span>
          <Link to={`/review/${_id}`}>
            {" "}
            <a className={`step__label ${applicationStage < 6 && applicationStage != 26 ? "disabled" : null}`}>Engineering review</a>
          </Link>
        </li>

        {roleName != "applicant" && (
          <>
            <li
              className={
                applicationStage === 8 || applicationStage === 9 || applicationStage === 10 || applicationStage === 11
                  ? "step step--incomplete step--active step-current"
                  : applicationStage > 11 && applicationStage != 26
                    ? `step step--complete ${location.pathname.includes("draftAgreement") && "step--active"}`
                    : "step step--incomplete step--inactive"
              }
            >
              <span className="step__icon">{!applicationStage >= 14 && applicationStage === 26 ? null : <i className="fal fa-check"></i>}</span>
              <Link to={`/draftAgreement/${_id}`}>
                <a className={`step__label ${applicationStage < 7 && applicationStage === 26 ? "disabled" : null}`}>Draft Agreement</a>
              </Link>
            </li>
          </>
        )}

        <li
          className={
            applicationStage === 14 ||
            applicationStage === 15 ||
            applicationStage === 16 ||
            applicationStage === 20 ||
            applicationStage === 18 ||
            applicationStage === 21 ||
            applicationStage === 22 ||
            applicationStage === 17 ||
            applicationStage === 23 ||
            applicationStage === 24
              ? "step step--incomplete step--active step-current"
              : (applicationStage > 24 && applicationStage !== 26) || applicationStage === 24
                ? `step step--complete ${location.pathname.includes("compiledAgreement") && "step--active"}`
                : "step step--incomplete step--inactive"
          }
        >
          <span className="step__icon">{!applicationStage === 25 ? null : <i className="fal fa-check"></i>} </span>
          <Link to={`/compiledAgreement/${_id}`}>
            <a className={`step__label ${applicationStage < 14 ? "disabled" : null}`}>Compile Agreement </a>
          </Link>
        </li>
        <li className={applicationStage === 25 ? `step step--complete ${location.pathname.includes("finalRelease") && "step--active"}` : "step step--incomplete step--inactive"}>
          <span className="step__icon">{!applicationStage === 25 ? null : <i className="fal fa-check"></i>} </span>
          <Link to={`/finalRelease/${_id}`}>
            {" "}
            <a className={`step__label ${applicationStage <= 24 ? "disabled" : null}`}> Final Release</a>{" "}
          </Link>
        </li>
      </ul>
      {process.env.REACT_APP_ENV !== "production" && (
        <button className="btn btn-primary h-100" onClick={refetch}>
          Refetch
        </button>
      )}
    </div>
  );
}
