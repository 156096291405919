import React from "react";
import { AuthCard } from "../components/authentication/AuthCard";
import { VerifyForm } from "../components/authentication/VerifyForm";
const Page = () => {
  return (
    <AuthCard>
      <VerifyForm />
    </AuthCard>
  );
};

export default Page;
