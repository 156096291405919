import React from "react";
import { useApplication } from "../../hooks/useApplication";
import { useUser } from "../../hooks/useUser";
import { REACT_APP_URL, PAYMENT_LINK } from "../../api";

export const OldApplication = ({ updateTransictionId, editPaymentNotes, updatePaymentTransiction }) => {
  const application = useApplication();
  const { roleName = "" } = useUser() || {};
  const { event1paymentType, applicationStage, _id, payment, event1transactionId = "" } = application || {};
  const { event1StripeResponse } = payment || [];
  let response = event1StripeResponse.find((each) => each.payment_status === "paid") || {};

  if (Object.keys(response).length === 0 && event1StripeResponse.length > 0) {
    response = event1StripeResponse[event1StripeResponse.length - 1];
  }

  const payment_intent = response?.payment_intent || "";

  return (
    <>
      <div className="accordion print" id="accordionExample1">
        <div className="accordion-item mb-4">
          <div className="card">
            <div className="card-body p-0">
              <h2 className="accordion-header border-bottom" id="PaymentSec">
                <button
                  className=" accordion-button py-4 px-4 d-flex justify-content-between "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Payment1"
                  aria-expanded="false"
                  aria-controls="Payment1"
                >
                  Payment
                </button>
              </h2>
              <div id="Payment1" className="accordion-collapse show " aria-labelledby="PaymentSec" data-bs-parent="#accordionExample1">
                <div className="accordion-body p-0">
                  <div className="field-col mt-4 ml-2 p-3">
                    <h3>Payment Method </h3>
                    <p className="d-flex justify-content-between p-0 align-items-center">
                      <span>
                        Payment Type:
                        <strong>{event1paymentType ? event1paymentType : "Stripe"}</strong>
                        <br />
                        {event1paymentType === "ACH" && (roleName === "Approving Accountant" || roleName === "Approving Representative" || roleName === "applicant") && (
                          <>
                            ACH Transaction Trace Number : <strong className="text-dark">Not Available</strong>
                          </>
                        )}
                      </span>

                      {!event1paymentType && event1paymentType !== "ACH" && event1paymentType !== "Check" && roleName === "Approving Accountant" && (
                        <a href={`${PAYMENT_LINK}${payment_intent}`} target="_blank" className="btn btn-link p-2">
                          Payment Intent
                        </a>
                      )}
                    </p>
                  </div>
                  <div className="card-footer py-0 justify-content-center bg-success">
                    <a className="d-block text-white disabled py-4 px-0" tabIndex="-1" role="button" aria-disabled="true">
                      <span className="d-flex justify-content-between align-items-center">
                        <span>
                          <i className="fal fa-check"></i>Paid
                        </span>
                        <span>
                          Submit By Applicant on : <strong className="text-white">Not Available</strong>
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion print" id="accordionExample2">
        <div className="accordion-item mb-4">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button py-4 px-4 d-flex justify-content-between "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <h2 className="mb-0">Authorize Application Fee</h2>
            </button>
          </h2>
          <div id="collapseThree" className="accordion-collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample2">
            <div className="accordion-body p-0">
              <div className="card ">
                <div className="card-header  border-bottom pb-0">
                  {roleName === "Approving Accountant" &&
                    (editPaymentNotes
                      ? applicationStage > 1 && (
                          <>
                            <div className="px-0 card-header bg-transparent border-0 pb-0"></div>

                            <div className="mb-4">
                              <label forhtml="transactionId">Update Payment Notes</label>
                              <textarea defaultValue={event1transactionId} className="form-control" id="transactionId" name="transactionId" />
                            </div>
                            <button
                              className="btn btn-link"
                              style={{
                                textDecoration: "underline",
                                pointerEvents: "fill",
                              }}
                              onClick={() => {
                                updatePaymentTransiction("event1paymentType");
                              }}
                            >
                              <i className="far fa-check"></i> Save
                            </button>
                            <a
                              className="btn btn-link text-warning"
                              style={{
                                textDecoration: "underline",
                                pointerEvents: "fill",
                              }}
                              href={REACT_APP_URL + "/applicationPayment/" + _id}
                            >
                              <i className="far fa-ban"></i> Cancel
                            </a>
                          </>
                        )
                      : applicationStage !== 26 && (
                          <>
                            <div className="mb-4 text-muted">
                              <label forhtml="transactionId">Payment Notes</label>
                              <textarea defaultValue={event1transactionId} disabled className="form-control mt-2" id="transactionId" name="transactionId" />
                            </div>
                            <button
                              className="btn btn-link"
                              style={{
                                textDecoration: "underline",
                                pointerEvents: "fill",
                              }}
                              onClick={updateTransictionId}
                            >
                              <i className="far fa-pen"></i> Edit
                            </button>
                          </>
                        ))}

                  {roleName !== "Approving Accountant" && (
                    <>
                      {
                        <div className="alert alert-success  text-dark" role="alert">
                          Successfully acknowledged payment. The application has now moved on to the next stage.
                        </div>
                      }
                    </>
                  )}
                </div>

                <div className="card-footer py-0 justify-content-center bg-success">
                  <a className="d-block text-white btn border-0 disabled py-4 px-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i> {"Authorized"}
                      </span>
                      <span>
                        By Accountant on : <strong className="text-white">Not Available</strong>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
