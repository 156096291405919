import logo from "../../images/logo.png";
import { useEffect, useState } from "react";
import qs from "query-string";
import { useApplication, useApplicationRefetch } from "../../hooks/useApplication";
import { putNextStatus, putenvelopeId, sendNotify } from "../../api/currentApplications";
import { useHistory } from "react-router-dom";
import { useAtoms } from "../../hooks/useAtoms";
import { useUser } from "../../hooks/useUser";
import DashBoardLoading from "../loaders/DashBoardLoading";
const stage = {
  loading: "LOADING",
  waiting: "WAITITNG",
  success: "SUCCESS",
};

export const AgreementSignature = () => {
  let queryString = qs.parse(window.location.search);
  const [state, setState] = useState(stage.loading);
  const history = useHistory();
  const application = useApplication();
  const refetchApplication = useApplicationRefetch();
  const user = useUser();
  const { setNavigationContent } = useAtoms();
  const API_URL = process.env.REACT_APP_API_URL;
  const REACT_APP_URL = process.env.REACT_APP_API_URL;

  const downloadFirstAgreement = async (applicationID, contactRoleId, applicationStage, event, envelopeId, downloaded) => {
    if (applicationStage === 18 || applicationStage === 19 || applicationStage === 20) {
      if (event === "signing_complete" && envelopeId && downloaded !== "True") {
        setState(stage.waiting);
        let envelopeDetails = {
          applicationID,
          envelopeId,
          agreementType: "compiled",
          action: "Uploaded",
          role: "Engineering Representative",
        };
        await putenvelopeId(envelopeDetails);

        let notifyDetails = {
          applicationID,
          signerEmail: ["Test"],
          userId: contactRoleId,
          LinkUrl: REACT_APP_URL + `/compiledAgreement/${applicationID}`,
          status: "Complete",
          agreementType: "CompiledAgreement",
        };

        await sendNotify(notifyDetails);
        await putNextStatus(applicationID, 1);
        window.location = `${API_URL}/eg001?applicationID=${applicationID}`;
      }
    }
  };

  const downloadRepresentativeAgreement = async (applicationID, applicationStage, event, envelopeId, downloaded) => {
    if (applicationStage === 21 || applicationStage === 23) {
      if (event === "signing_complete" && envelopeId && downloaded !== "True") {
        setState(stage.waiting);
        let envelopeDetails = {
          applicationID,
          envelopeId,
          agreementType: "compiled",
          action: "Uploaded",
          role: "Engineering Representative",
        };
        await putenvelopeId(envelopeDetails);
        await putNextStatus(applicationID, 1);
        window.location = `${API_URL}/eg001?applicationID=${applicationID}`;
      }
    }
  };

  useEffect(() => {
    let timeout;
    if (application && user) {
      const { id: contactRoleId } = user;
      const { applicationStage, _id } = application;
      const { event, envelopeId, downloaded } = queryString;
      downloadFirstAgreement(_id, contactRoleId, applicationStage, event, envelopeId, downloaded);
      downloadRepresentativeAgreement(_id, applicationStage, event, envelopeId, downloaded);
      if (downloaded === "True") {
        setNavigationContent(true);
        setState(stage.success);
        timeout = setTimeout(() => {
          refetchApplication();
          history.push(`/compiledAgreement/${_id}`);
        }, 5000);
      } else {
        setNavigationContent(false);
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [application, user, queryString, history]);

  if (!application || state === stage.loading) {
    return <DashBoardLoading />;
  }

  return (
    <div className="bg-img h-100 d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
      <div className="container h-100">
        <div className="row align-items-center h-100">
          <div className="col-md-6 offset-md-3">
            <div className="card border-0">
              <div className="card-header bg-dark text-center">
                <img src={logo} alt="NCRR" style={{ width: "252px" }} />
              </div>
              <div className="card-body pt-1">
                {state === stage.waiting && (
                  <div className="text-center  p-1">
                    <div className=" step--complete step--active text-center pt-2 mt-2 pb-0" style={{ cursor: "default" }}>
                      <span className="step__icon steps-1 mx-auto bg-primary border-primary">
                        <i className="fas fa-spinner fs-1 fa-pulse"></i>
                      </span>
                      <br />
                    </div>
                    <div className="card-body pt-0 pb-0">
                      <h2 className="h1 mb-2 pb-2 d-block">Verifying the signature. Please wait...</h2>
                    </div>
                  </div>
                )}
                {state === stage.success && (
                  <div className="text-center  p-1">
                    <div className=" step--complete step--active text-center pt-2 mt-2 pb-0" style={{ cursor: "default" }}>
                      <span className="step__icon steps-1 mx-auto">
                        <i className="fas fa-check fs-1 "></i>
                      </span>
                      <br />
                    </div>
                    <div className="card-body pt-0 pb-0">
                      <h2 className="h1 mb-2 pb-2 d-block">Signature has been verified successfully and is awaiting authorization from NCRR.</h2>
                      <span className="fs-6 text-muted">No further actions required. Redirecting...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
