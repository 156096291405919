import React from "react";
import HtmlParser from "react-html-parser";
import { useParams } from "react-router";
export default function CommentsLables({
  label = "",
  name = "",
  name1 = "",
  type = "text",
  defaultValue = "",
  placeholder = "",
  css = {},
  validation,
  register,
  errors,
  handleChange,
  id,
  fieldLabel = "",
  fieldLabelPosition = "",
  fieldType = "",
  comments = "",
  commentsIconIsRequired = false,
  showOnChange = [],
  commentsStyle = {},
  condtionalLabel = "",
  disabled = false,
  parallel = 0,
  crossing = 0,
  setSchema = () => {},
  getLocalStorageValue = () => {},
  editPage,
}) {
  return (
    <div>
      <label className="form-label" style={commentsStyle}>
        {commentsIconIsRequired ? <i className="far fa-hand-point-right"></i> : null}
        {HtmlParser(comments)}
      </label>
    </div>
  );
}
