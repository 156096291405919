import React, { useState } from "react";
import { sendotp } from "../../api/auth";
import { useHistory, useLocation } from "react-router-dom";
import { useAtoms } from "../../hooks/useAtoms";

export const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { setNavigationContent } = useAtoms();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  setNavigationContent(false);

  const handleChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    sendotp(email)
      .then((res) => {
        if (res.data.status) {
          localStorage.setItem("userEmail", email);
          const redirectParam = searchParams.get("redirect");
          history.push({
            pathname: `/verify`,
            search: redirectParam && `?redirect=${encodeURIComponent(redirectParam)}`,
            props: {
              email,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        document.getElementById("err").innerText = "Some problem occured. Please try again after some time.";
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form className="mt-4 text-start" onSubmit={handleSubmit}>
      <h2 className="text-center">Enter Your Personal Details</h2>
      <label htmlfor="email" className="form-label">
        Enter Your Email
      </label>
      <div className="mb-3 input-group">
        <input type="email" required={true} className="form-control" id="email" onChange={handleChange} />
        <button className={`btn btn-dark px-5 ${email.length > 5 ? "" : "disabled"} ${loading ? "disabled" : ""}`} type="submit">
          {loading ? (
            <>
              <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
              Submitting...
            </>
          ) : (
            "Get Secret code"
          )}
        </button>
      </div>
      <span
        className="pt-2"
        id="err"
        style={{
          color: "red",
          fontSize: "12px",
          fontWeight: "700",
        }}
      ></span>
      <span
        className="pt-2"
        id="authination"
        style={{
          color: "red",
          fontSize: "12px",
          fontWeight: "700",
        }}
      ></span>
    </form>
  );
};
