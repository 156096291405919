import { useRecoilValue, useSetRecoilState } from "recoil";
import { refetchUserSignal, user } from "../atoms/user";
import GetmyRole from "../utils/GetmyRole";

export const useUser = () => {
  const { contactRoleId } = GetmyRole() || {};
  try {
    return useRecoilValue(user(contactRoleId));
  } catch (error) {
    return null;
  }
};

export const useUserRefetch = () => {
  const setRefetchSignal = useSetRecoilState(refetchUserSignal);
  return () => {
    setRefetchSignal((prev) => prev + 1);
  };
};
