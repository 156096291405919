import React, { useState } from "react";
import { debounce } from "../dashboard/Dashboard";
import { getCompanyByName } from "../../api/crm";
import { getCompanyByNameFromNetSuite } from "../../api/Netsuite";

const CompanyDetails = ({ form, setForm, setIsCompanyExist = () => {}, error = {}, Heading, label, type }) => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  const findCompany = async (companyName) => {
    const res = await getCompanyByName(companyName, type);
    if (res.data.status) {
      const companies = res?.data?.data || [];
      if (companies?.length === 0) {
        const netSuiteCompanies = await getCompanyByNameFromNetSuite(companyName, type);
        setCompanies(netSuiteCompanies);
      } else {
        setCompanies(companies);
      }
    }
    setLoading(false);
  };

  const selectCompany = (id) => {
    const company = companies.find((item) => item.id === id);
    setCompanies([]);
    setForm((initialValue) => ({
      ...initialValue,
      sameAsMailingAddress: false,
      ...company,
    }));
    if (!company.isNetSuite) {
      setIsCompanyExist(true);
    }
  };

  return (
    <div className="card-body">
      <div id="company-details">
        <div className="d-flex justify-content-between">
          <h3>{Heading}</h3>
        </div>
        <div className="row ">
          <div className="col">
            <div className="mt-3">
              <label className="form-label d-flex justify-content-between">
                {label}
                <small className="text-danger ms-1">{error.name}</small>
              </label>
              <div className="container p-0">
                <div className="dropdown col-lg-12 p-0">
                  <input
                    type="text"
                    className="form-control"
                    id="searchBar"
                    placeholder="Search..."
                    value={form.name}
                    onChange={(e) => {
                      setLoading(true);
                      setForm((initialValue) => ({
                        ...initialValue,
                        name: e.target.value,
                      }));
                      setIsCompanyExist(false);
                      debounce(() => findCompany(e.target.value), 500);
                    }}
                  />

                  <ul className="dropdown-menu show w-100 h-0 rounded-0 pb-0 pt-0" aria-labelledby="automplete">
                    {loading && (
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                    {companies.length > 0 && (
                      <li>
                        <button type="button" className="close px-3 pt-2 " aria-label="Close" onClick={() => setCompanies([])}>
                          <span aria-hidden="true" className="h1">
                            &times;
                          </span>
                        </button>
                      </li>
                    )}
                    {companies.map((item, index) => {
                      return (
                        <li className="border-bottom" key={item.id}>
                          <button type="button" onClick={() => selectCompany(item.id)} className="dropdown-item d-flex justify-content-between cursor-pointer">
                            <span>{item.name}</span>
                            <span className="text text-primary">Select</span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div id="" className="form-text">
                *Please ensure that the exact legal name is provided with no abbreviations.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
