import React from "react";

const text = ({ data, handleNetSuiteChange, recordName, col, value }) => {
  let disablevalue = data.disabled || data.validateFinalHideField || data.validateHideField ? data.disabled || data.validateFinalHideField || data.validateHideField : false;
  return (
    <>
      {data.type === "text" && (
        <div className="mb-3">
          <label forhtml={data.name} className="text-muted form-label">
            {data.label}
          </label>

          <input type={data.type} onChange={(e) => handleNetSuiteChange(data, e, recordName, col)} className="form-control" name={data.name} id={data.name} value={value} disabled={disablevalue} />
        </div>
      )}
    </>
  );
};

export default text;
