import countyReducer from "./county";
import dropdownReducer from "./dropdown";
import selectedOperatingSigneeReducer from "./selectedOperatingSignee";
import { combineReducers } from "redux";

const allReducers = combineReducers({
  county: countyReducer,
  dropdown: dropdownReducer,
  selectedOperatingSignee: selectedOperatingSigneeReducer,
});

export default allReducers;
