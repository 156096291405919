/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DraftAggrementFinalReview from "./DraftAggrementFinalReview";
import DraftAggrementReview from "./DraftAggrementReview";
import UploadDraftAgreement from "./UploadDraftAgreement";
import { draftAgreementPost, getCommentsDraftAgreement, getcurrentStatus, getFormData, getLicenseStatus, putNextStatus } from "../../api/currentApplications";
import { useHistory } from "react-router";
import toast from "react-hot-toast";
import UploadDraftLoading from "../loaders/UploadDraftLoading";
import ReviewDraftAgreement from "./ReviewDraftAgreement";
import CommonNavigation from "../commonNavigation/CommonNavigation";
import PromptComponent from "../promptComponent/PromptComponent";
import checkFileName from "../../utils/checkSpecialCharacter";
import { useAtoms } from "../../hooks/useAtoms";
import { useUser } from "../../hooks/useUser";

export default function DraftAggrement(props) {
  let history = useHistory();
  let ItemId = props.match.params.applicationId;
  const { setNavigation, setHideNavigation } = useAtoms();
  setHideNavigation(false);
  const [laodSubmit, setLoadSubmit] = useState(false);
  const [stage, setStage] = useState("");
  const [loading, setLoading] = useState(true);
  const [comments, setAllComments] = useState([]);
  const [draftValue, setDraftValue] = useState({});
  const [applicationTitle, setApplicationTitle] = useState();
  const [HNTBFileNumber, setHNTBFileNumber] = useState("");

  const { roleName = "" } = useUser() || {};

  useEffect(async () => {
    let newRes = await getcurrentStatus(ItemId);
    setStage(newRes?.data?.data);

    let responseComment = await getCommentsDraftAgreement(ItemId);

    responseComment?.data?.data?.draft.map((value) => (value.action === "Upload and Send to NCRR" ? setAllComments(value?.comment && value?.comment) : null));
    setLoading(false);
  }, []);

  const uploadDraftAggrement = async (data) => {
    const fileNames = [];
    const license_file = document.getElementById("aggrement").files[0];

    var filename = license_file.name;
    fileNames.push(filename);

    const formData = new FormData();
    formData.append("action", "Upload and Send to NCRR");
    formData.append("applicationID", ItemId);
    formData.append("role", roleName);
    formData.append("date", new Date());
    formData.append("licensefile", license_file, filename);
    let multiFile = data["supporting_doc"];

    let supportingdocFilesData = [];
    if (multiFile) {
      for (let i = 0; i < multiFile.length; i++) {
        let fileData = multiFile[i];
        if (fileData) {
          formData.append("supporting_doc_files", fileData);
          supportingdocFilesData.push({
            fileNameOriginal: fileData.name,
            fileName: "",
            fileNameUpdated: "",
          });

          fileNames.push(fileData.name);
        }
      }
    }
    let AgreementData = [
      {
        fileLabel: document.getElementById("aggrement").name,
        fileNameOriginal: filename,
        fileName: "",
      },
    ];
    let license_fee = [
      {
        license_fee: data.license_fee,
      },
    ];
    let supportingData = [
      {
        fileLabel: "supportingdoc",
        fileDataMultiple: supportingdocFilesData,
        fileName: "",
      },
    ];

    formData.append("AgreementData", JSON.stringify(AgreementData));
    formData.append("licenseFee", JSON.stringify(license_fee));
    formData.append("supportingData", JSON.stringify(supportingData));
    const FileNameResult = checkFileName(fileNames);
    setLoading(true);
    if (FileNameResult === true) {
      let response = await draftAgreementPost(formData);
      if (stage.currentStage != 23 && stage.currentStage != 4) {
        let newRes = await putNextStatus(ItemId, 1);
        setStage(newRes.data.data);
      }
      setLoading(false);
    } else {
      // toast.error(`Please Change the Names Of This File Before Submiting ${FileNameResult.toString()}`);
      alert(`Please Change the Names Of This File Before Submiting ${FileNameResult.toString()}`);
      window.location.reload();
      setLoading(false);
    }
  };

  const draftAggrementReview = async (data) => {
    setLoadSubmit(true);
    const formData = new FormData();
    formData.append("action", "Send to HNTB");
    formData.append("applicationID", ItemId);
    formData.append("role", roleName);
    formData.append("date", new Date());
    formData.append("licenseData", data.activity_number);
    let licenseData = [
      {
        activity_number: data.activity_number,
      },
    ];
    formData.append("licenseData", JSON.stringify(licenseData));
    setLoading(true);
    let response = await draftAgreementPost(formData);
    if (stage.currentStage != 23 && stage.currentStage != 4) {
      let newRes = await putNextStatus(ItemId, 1);
      setLoadSubmit(false);
      setStage(newRes.data.data);
    }
    setLoading(false);
  };
  const draftAggrementFinalReview = async (data) => {
    setLoadSubmit(true);
    const formData = new FormData();
    formData.append("action", "Send to HNTB");
    formData.append("applicationID", ItemId);
    formData.append("role", roleName);
    formData.append("date", new Date());
    formData.append("HNTBFileNumber", HNTBFileNumber);
    formData.append("licenseData", applicationTitle);
    let licenseData = [
      {
        ncrr_fileNumber: applicationTitle,
      },
    ];

    formData.append("licenseData", JSON.stringify(licenseData));
    setLoading(true);
    let response = await draftAgreementPost(formData);

    if (stage.currentStage != 23 && stage.currentStage != 4) {
      let newRes = await putNextStatus(ItemId, 1);

      setStage(newRes.data.data);
      setLoadSubmit(false);
      if (newRes.data.data.currentStage === "Compile License Agreement") {
        history.push(`/compiledAgreement/${ItemId}`);
      }
    }
    setLoading(false);
  };
  const reviewDraftAgreement = async () => {
    setLoadSubmit(true);
    const formData = new FormData();
    formData.append("action", "Send to Northfolk Southern");
    formData.append("applicationID", ItemId);
    formData.append("role", roleName);
    formData.append("date", new Date());
    setLoading(true);
    await draftAgreementPost(formData);
    if (stage.currentStage != 23 && stage.currentStage != 4) {
      let newRes = await putNextStatus(ItemId, 1);
      setLoadSubmit(false);
      setStage(newRes.data.data);
    }
    setLoading(false);
  };

  useEffect(async () => {
    getFormData(ItemId).then(({ data }) => {
      setApplicationTitle(data.ApplicationTitle);
    });
    let response = await getLicenseStatus(ItemId);
    setDraftValue(response.data.data);
  }, []);

  let draft = draftValue.draft || {};

  setNavigation(`Draft Agreement ${" "} ${applicationTitle ? applicationTitle : ItemId} `);

  let ddr = (draft && draft[3]) || [];
  let { action: action2 } = ddr || {};

  return loading ? (
    <>
      <UploadDraftLoading />
      <UploadDraftLoading />
    </>
  ) : (
    <div>
      <div className={laodSubmit ? "submitting" : null}></div>
      <PromptComponent applicationId={ItemId} />
      <div className="border-bottom mb-4 bg-white">
        <div className="w-75 mx-auto">
          <div className="">
            <CommonNavigation ItemId={ItemId} />
          </div>
        </div>
      </div>
      {stage.currentStage === "Draft Agreement Assign Signee" ||
      (stage.currentStage === "Signature" && stage.id === 9) ||
      stage.currentStage === "Validate Signature" ||
      stage.currentStage === "Draft Agreement Generation" ? (
        <UploadDraftAgreement uploadDraftAggrement={uploadDraftAggrement} ItemId={ItemId} stage={stage} />
      ) : (
        <div> {stage.currentStep < 6 ? <h2 className="col d-flex justify-content-center">Please complete the previous steps</h2> : null} </div>
      )}
      {stage.currentStage === "Draft Agreement Review" ? <DraftAggrementReview draftAggrementReview={draftAggrementReview} ItemId={ItemId} /> : null}
      {stage.currentStage === "Draft Final Review" ? (
        <DraftAggrementFinalReview
          draftAggrementFinalReview={draftAggrementFinalReview}
          ItemId={ItemId}
          applicationTitle={applicationTitle}
          setApplicationTitle={setApplicationTitle}
          setHNTBFileNumber={setHNTBFileNumber}
          HNTBFileNumber={HNTBFileNumber}
        />
      ) : null}

      {action2 === "Send to HNTB" ? <DraftAggrementFinalReview draftAggrementFinalReview={draftAggrementFinalReview} ItemId={ItemId} /> : null}
      {stage.currentStage === "NCRR Review Draft Agreement" ? (
        <ReviewDraftAgreement reviewDraftAgreement={reviewDraftAgreement} ItemId={ItemId} setAllComments={setAllComments} comments={comments} />
      ) : null}
    </div>
  );
}
