import AggreementGridViewCard from "./AggreementGridViewCard";
import ApplicationGridViewCard from "./ApplicationGridViewCard";

const GridView = ({ agreementsApplication, searchValue, roleName, postApplications, setGridView, gridView, currentApplicationsByUser, onGoingApplication, currentAgreementsByUser }) => {
  return (
    <div className="card-body">
      <div className="mb-4">
        <div className="card-y">
          {agreementsApplication || searchValue ? (
            <>
              <div className="card-header border-0">
                <div className="d-sm-flex justify-content-between">
                  <h2 className="mb-3 mb-sm-0">Ongoing Applications</h2>
                  {roleName === "applicant" ? (
                    <div className="btn-col text-right">
                      <button
                        onClick={() => {
                          postApplications("form210", 210);
                        }}
                        className="btn btn-primary mb-3 mb-md-0 d-block d-sm-inline-block"
                      >
                        New Wire/Conduit/Cable Occupancy Application
                      </button>
                      <button
                        onClick={() => {
                          postApplications("form220", 220);
                        }}
                        className="btn btn-primary mb-3 mb-md-0 d-block d-sm-inline-block"
                      >
                        New Pipeline Occupancy Application
                      </button>
                    </div>
                  ) : null}
                  <a
                    className="btn  mb-3 mb-md-0 d-block d-sm-inline-block"
                    onClick={() => {
                      setGridView(!gridView);
                      localStorage.setItem("GridView", !gridView);
                    }}
                  >
                    <i className={gridView ? "fa fa-th" : "fa fa-list"}></i>
                  </a>
                </div>
              </div>
              {currentApplicationsByUser.length > 0 ? (
                currentApplicationsByUser.map((v, i) => (
                  <div key={i} id="applications">
                    {(v.applicationStage === -1 && roleName != "applicant") || v.applicationStage === 0 ? null : v.applicationStage !== 25 ? (
                      <>
                        {(roleName === "Engineering Representative" && v.applicationStage < 6) || (roleName === "Operating Representative" && v.applicationStage < 6) ? null : (
                          <ApplicationGridViewCard v={v} />
                        )}
                      </>
                    ) : null}
                    {currentApplicationsByUser.length === 0 ? (
                      <div className="card mb-4">
                        <div className="card-body text-center">
                          <p className="my-5 text-muted fst-italic">No Current Applications</p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))
              ) : (
                <div className="card mb-4">
                  <div className="card-body text-center">
                    <p className="my-5 text-muted fst-italic">No Current Applications</p>
                  </div>
                </div>
              )}
            </>
          ) : null}

          {onGoingApplication || searchValue ? (
            <>
              <div className="card-header border-0 d-flex justify-content-between" id="agreement">
                <h2>Agreements</h2>
                {!searchValue ? (
                  <a
                    className="btn  mb-3 mb-md-0 d-block d-sm-inline-block"
                    onClick={() => {
                      setGridView(!gridView);
                      localStorage.setItem("GridView", !gridView);
                    }}
                  >
                    <i className={gridView ? "fa fa-th" : "fa fa-list"}></i>
                  </a>
                ) : null}
              </div>
              {currentAgreementsByUser.length > 0 ? (
                currentAgreementsByUser.map((v, i) => (
                  <div key={i}>
                    <AggreementGridViewCard v={v} />
                  </div>
                ))
              ) : (
                <div className="card"></div>
              )}
              {currentAgreementsByUser.length === 0 ? (
                <div className="card-body text-center bg-white">
                  <p className="my-5 text-muted fst-italic">No Current Agreements</p>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default GridView;
