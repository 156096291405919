import React, { useEffect, useState } from "react";
import { getApplications, getCsvData, getcurrentStatus } from "../../api/currentApplications";
import { CSVLink } from "react-csv";
import {
  PAYMENT_LINK,
  REACT_APP_NCRR_Application_fee_original,
  REACT_APP_HNTB_Engineering_review_original,
  REACT_APP_NS_initial_fee_original,
  REACT_APP_NS_risk_finance_fee_original,
} from "../../api";
import DashBoardLoading from "../loaders/DashBoardLoading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import createCsvData from "../../utils/createCsvData";

export function Payments() {
  const [lodash, setLoading] = useState(true);
  const [pages, setTotalPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentApplicationsByUser, setCurrentApplicationsByUser] = useState([]);
  const [csvLoading, setCsvLoading] = useState(true);
  const [csvData, setCsvData] = useState([]);
  const history = useHistory();

  const getCsvDataFunc = async () => {
    try {
      const response = await getCsvData();
      const csvFileData = createCsvData(response?.data?.applications || []);
      setCsvData(csvFileData);
      setCsvLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCsvDataFunc();
  }, []);

  const GetApplications = async (pageNumber) => {
    getApplications(pageNumber, "Approving Accountant")
      .then(({ data }) => {
        setCurrentApplicationsByUser(data?.applications || []);
        setTotalPages(data?.total_page || 0);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  useEffect(() => {
    GetApplications(currentPage || 1);
  }, [currentPage]);

  const getPaginationGroup = () => {
    let array = [];
    for (let i = 0; i < pages; i++) array.push(i + 1);
    return array;
  };
  function goToNextPage() {
    setCurrentPage((page) => page + 1);
    GetApplications(currentPage + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
    GetApplications(currentPage - 1);
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
    GetApplications(pageNumber);
  }

  const Callback = async (v) => {
    if (v.applicationStage === 1 || v.applicationStage === 26) {
      history.push(`/applicationPayment/${v._id}`);
      return;
    }
    if (v.applicationStage === -1 || v.applicationStage === 0) {
      history.push(`/${v.formName}/${v._id}`);
      return;
    }
    localStorage.setItem("headerId", v._id);
    let newRes = await getcurrentStatus(v._id);
    if (newRes.data.data.currentStage === "Applicant Review") {
      history.push(`/applicantView/${v._id}`);
      return;
    }
    if (newRes.data.data.currentStage === "Initial Review" || newRes.data.data.currentStage === "Approved") {
      history.push(`/applicationReview/${v._id}`);
      return;
    }
    if (
      newRes.data.data.currentStage === "Draft Agreement Assign Signee" ||
      (newRes.data.data.currentStage === "Signature" && newRes.data.data.id === 9) ||
      newRes.data.data.currentStage === "Validate Signature" ||
      newRes.data.data.currentStage === "Draft Agreement Generation" ||
      newRes.data.data.currentStage === "Draft Final Review" ||
      newRes.data.data.currentStage === "Draft Agreement Review" ||
      newRes.data.data.currentStage === "NCRR Review Draft Agreement"
    ) {
      history.push(`/draftAgreement/${v._id}`);
      return;
    }
    if (newRes.data.data.currentStage === "Engineering Review") {
      history.push(`/review/${v._id}`);
      return;
    }
    if (newRes.data.data.currentStage === "Payment" && newRes.data.data.id === 18) {
      history.push(`/compiledAgreement/${v._id}`);
      return;
    }
    if (
      newRes.data.data.currentStage === "Compile Agreement Assign Signee" ||
      newRes.data.data.currentStage === "Compile License Agreement" ||
      newRes.data.data.currentStage === "Upload Insurance" ||
      newRes.data.data.currentStage === "Validate Uploaded Insurance" ||
      (newRes.data.data.currentStage === "Signature" && newRes.data.data.id === 20) ||
      (newRes.data.data.currentStage === "Signature" && newRes.data.data.id === 24) ||
      newRes.data.data.currentStage === "Validate Fee, Insurance and Agreement" ||
      newRes.data.data.currentStage === "Upload Letter and Validate" ||
      newRes.data.data.currentStage === "Validate and Sign" ||
      newRes.data.data.currentStage === "Final Release"
    ) {
      history.push(`/compiledAgreement/${v._id}`);
      return;
    }
    if (newRes.data.data.currentStage === "Completed" && v.applicationStage != -1) {
      history.push(`/finalRelease/${v._id}`);
      return;
    }
  };
  return (
    <>
      <div className="container-fluid mt-4 m-0">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="card-header pb-1 d-flex justify-content-between px-5">
              <h3>Applications Payment Details</h3>
              {csvLoading ? (
                <button className="btn btn-primary" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                </button>
              ) : (
                <CSVLink className="btn btn-primary" filename="payment-details-ncrr.csv" data={csvData}>
                  Export to CSV
                </CSVLink>
              )}
            </div>
            <div className="card-col card">
              <div className="card-body px-0">
                <div className="item-col ps-2 pe-2">
                  <div className="heading border-bottom d-none d-sm-block">
                    <div className="row">
                      <div className="col-sm-1">
                        <label className="text-muted h5">Application Numaber</label>
                      </div>
                      <div className="col-sm-1">
                        <label className="text-muted h5">Applicant</label>
                      </div>
                      <div className="col-sm-1">
                        <label className="text-muted h5">HNTB FILE#</label>
                      </div>
                      <div className="col-sm-1 p-0">
                        <label className="text-muted h5">Paid on Stage</label>
                      </div>
                      <div className="col-sm-1">
                        <label className="text-muted h5">Payment Method</label>
                      </div>

                      <div className="col-sm-1">
                        <label className="text-muted h5">Payment Amount</label>
                      </div>

                      <div className="col-sm-2">
                        <label className="text-muted h5">Date OF Payment</label>
                      </div>
                      <div className="col-sm-2 p-0">
                        <label className="text-muted h5">Payment Link / ACH Transaction Number</label>
                      </div>
                      <div className="col-sm-1 ps-0">
                        <label className="text-muted h5">Accountant Comment</label>
                      </div>
                      <div className="col-sm-1 ">
                        <label className="text-muted h5">Current Status</label>
                      </div>
                    </div>
                  </div>
                  {lodash || currentApplicationsByUser.length === 0 ? (
                    <DashBoardLoading />
                  ) : currentApplicationsByUser.length > 0 ? (
                    currentApplicationsByUser.map((v, i) => {
                      //First Payment Details
                      const event1StripeResponseArray = v?.payment?.event1StripeResponse || [];
                      let event1StripeResponse = event1StripeResponseArray.find((each) => each.payment_status === "paid" && each.payment_intent !== null) || {};
                      if (Object.keys(event1StripeResponse).length === 0 && event1StripeResponseArray.length > 0)
                        event1StripeResponse = event1StripeResponseArray[event1StripeResponseArray.length - 1];
                      const event1payment_intent = event1StripeResponse?.payment_intent || "";

                      let event1paymentType = v?.event1paymentType;
                      if (!event1paymentType) {
                        if (v.applicationStage >= 6 && event1payment_intent) event1paymentType = "Stripe";
                        else event1paymentType = "-";
                      }

                      let event1paymentAmount = `$${Number(REACT_APP_HNTB_Engineering_review_original) + Number(REACT_APP_NCRR_Application_fee_original)}`;
                      if (event1paymentType === "-") event1paymentAmount = "-";

                      let event1paymentDate;
                      try {
                        event1paymentDate = v?.event1paymentOn?.toString()?.slice(0, 10) || "-";
                      } catch (err) {
                        event1paymentDate = "-";
                      }

                      let event1paymentLinkOrAchNumber;
                      if (event1paymentType === "Stripe") {
                        event1paymentLinkOrAchNumber = PAYMENT_LINK + event1payment_intent;
                      } else if (event1paymentType === "ACH" && v.event1transactionNumber) {
                        event1paymentLinkOrAchNumber = v.event1transactionNumber;
                      } else {
                        event1paymentLinkOrAchNumber = "-";
                      }

                      let event1AccountantComment = "-";
                      if (v.event1transactionId) event1AccountantComment = v.event1transactionId;

                      //Second Payment Details
                      const event2StripeResponseArray = v?.payment?.event2StripeResponse || [];
                      let event2StripeResponse = event2StripeResponseArray.find((each) => each.payment_status === "paid" && each.payment_intent !== null) || {};
                      if (Object.keys(event2StripeResponse).length === 0 && event2StripeResponseArray.length > 0)
                        event2StripeResponse = event2StripeResponseArray[event2StripeResponseArray.length - 1];
                      const event2payment_intent = event2StripeResponse?.payment_intent || "";

                      let event2paymentType = v?.event2paymentType;
                      if (!event2paymentType) {
                        if (v.applicationStage >= 17 && event2payment_intent) event2paymentType = "Stripe";
                        else event2paymentType = "-";
                      }

                      let event2paymentAmount = "-";
                      if (v.applicationStage >= 17) {
                        let agreement = v?.agreement;
                        let riskFeeOriginal = 0;
                        let license_fee = 0;
                        if (agreement?.compiled && agreement?.compiled.length) {
                          const signAndPaid = agreement?.compiled?.find((each) => each.action === "Sign and Paid" && each.role === "applicant") || {};
                          if (signAndPaid.riskFinanceFee === "true") riskFeeOriginal = Number(REACT_APP_NS_risk_finance_fee_original);
                        }
                        if (agreement?.draft && agreement?.draft.length) {
                          const Upload_and_Send_to_NCRR = agreement?.draft?.find((each) => each.action === "Upload and Send to NCRR" && each.role === "Engineering Representative") || {};
                          if (Upload_and_Send_to_NCRR) {
                            let formData = Upload_and_Send_to_NCRR?.formData || [];
                            let { license_fee: license_fee2 } = (formData && formData[1]) || [];
                            let licenseFee_array = Upload_and_Send_to_NCRR?.licenseFee || {};
                            let { license_fee: license_fee1 } = (licenseFee_array && licenseFee_array[0]) || "";
                            license_fee = license_fee2 || license_fee1;
                          }
                        }

                        let compiledPaymentFeeOriginal = Number(REACT_APP_NS_initial_fee_original) + Number(license_fee) + Number(riskFeeOriginal);
                        event2paymentAmount = "$" + compiledPaymentFeeOriginal;
                      }

                      let event2paymentDate = "-";
                      if (v?.event2paymentOn) {
                        try {
                          event2paymentDate = v?.event2paymentOn?.toString()?.slice(0, 10) || "-";
                        } catch (err) {
                          event2paymentDate = "-";
                        }
                      }

                      let event2paymentLinkOrAchNumber;
                      if (event2paymentType === "Stripe") {
                        event2paymentLinkOrAchNumber = PAYMENT_LINK + event2payment_intent;
                      } else if (event2paymentType === "ACH" && v.event2transactionNumber) {
                        event2paymentLinkOrAchNumber = v.event2transactionNumber;
                      } else {
                        event2paymentLinkOrAchNumber = "-";
                      }

                      let event2AccountantComment = "-";
                      if (v.event2transactionId) event2AccountantComment = v.event2transactionId;

                      return (
                        <div index={i} onClick={() => Callback(v)} style={{ cursor: "pointer" }}>
                          <div className={event2StripeResponse.length > 0 || event2paymentType !== "-" ? "detail " : "detail border-bottom pt-2 pb-2"}>
                            <div className="row py-2">
                              <div className="col-sm-1">
                                <label className="d-block d-sm-none text-muted">Application Numaber</label>
                                <span className="text">#{v.ApplicationTitle}</span>
                              </div>

                              <div className="col-sm-1 mt-2">
                                <label className="d-block d-sm-none text-muted">Applicant</label>
                                <span className="text ">{v.applicantDetails && v.applicantDetails?.facilityCompany?.name}</span>
                              </div>
                              <div className="col-sm-1">
                                <label className="d-block d-sm-none text-muted">HNTB FILE#</label>
                                <span className="text">{v.HNTBFileNumber}</span>
                              </div>
                              <div className="col-sm-1 p-0">
                                <label className="d-block d-sm-none text-muted">Paid on Stage</label>
                                <span className="text">Application Payment</span>
                              </div>
                              <div className="col-sm-1">
                                <label className="d-block d-sm-none text-muted">Payment Method</label>
                                <span className="text">{event1paymentType}</span>
                              </div>
                              <div className="col-sm-1">
                                <label className="d-block d-sm-none text-muted">Payment Amount</label>
                                <span className="text">{event1paymentAmount}</span>
                              </div>

                              <div className="col-sm-2">
                                <label className="d-block d-sm-none text-muted">Date OF Payment</label>
                                <span className="text">{event1paymentDate}</span>
                              </div>
                              <div className="col-sm-2 p-0">
                                <label className="d-block d-sm-none text-muted">Payment Link / ACH Transaction Number</label>
                                <span className="text">
                                  {event1paymentType === "Stripe" ? (
                                    <a className="btn-link" href={event1paymentLinkOrAchNumber} target="_blank" rel="noreferrer">
                                      payment_intent
                                    </a>
                                  ) : (
                                    event1paymentLinkOrAchNumber
                                  )}
                                </span>
                              </div>
                              <div className="col-sm-1">
                                <label className="d-block d-sm-none text-muted">Accountant Comment</label>
                                <span className="text">{event1AccountantComment}</span>
                              </div>
                              <div className="col-sm-1">
                                <label className="d-block d-sm-none text-muted">Current Status</label>
                                <span className="text">
                                  {v.applicationStage === 1 ? (
                                    <>
                                      {" "}
                                      Payment <span className="value">(Application Payment) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 2 ? (
                                    <>
                                      {" "}
                                      Applicant Review <span className="value">(Initial Review) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 3 ? (
                                    <>
                                      {" "}
                                      Applicanttion Review <span className="value">(Initial Review) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 4 || v.applicationStage === 5 ? "Engineering Review" : null}
                                  {v.applicationStage === 6 ? "Engineering Review" : null}
                                  {v.applicationStage === 7 ? (
                                    <>
                                      Draft Agreement <span className="value">(Signature) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 8 ? (
                                    <>
                                      {" "}
                                      Draft Agreement <span className="value">(Assign Signee) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 9 ? (
                                    <>
                                      {" "}
                                      Draft Agreement <span className="value">(Validate Signature) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 10 ? (
                                    <>
                                      {" "}
                                      Draft Agreement <span className="value">(Agreement Generation) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 11 ? (
                                    <>
                                      {" "}
                                      Draft Agreement <span className="value">(NCRR Review) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 12 ? (
                                    <>
                                      {" "}
                                      Draft Agreement <span className="value">(NS Review) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 13 ? (
                                    <>
                                      {" "}
                                      Draft Agreement <span className="value">(HNTB Review) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 14 ? (
                                    <>
                                      {" "}
                                      Compile Agreement <span className="value">(License Agreement) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 15 ? (
                                    <>
                                      {" "}
                                      Compile Agreement <span className="value">(Upload Insurance) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 16 ? (
                                    <>
                                      {" "}
                                      Compile Agreement <span className="value">(Validate Uploaded Insurance) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 17 ? (
                                    <>
                                      {" "}
                                      Compile Agreement <span className="value">(Payment) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 18 ? (
                                    <>
                                      {" "}
                                      Compile Agreement <span className="value">(Signature) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 19 ? (
                                    <>
                                      {" "}
                                      Compile Agreement <span className="value">(Assign Signee) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 20 ? (
                                    <>
                                      {" "}
                                      Compile Agreement <span className="value">(Validate Fee, Insurance and Agreement) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 21 ? (
                                    <>
                                      {" "}
                                      Compile Agreement <span className="value">(Validate and Sign By V.P) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 22 ? (
                                    <>
                                      {" "}
                                      Compile Agreement <span className="value">(Upload Letter and Validate) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 23 ? (
                                    <>
                                      {" "}
                                      Compile Agreement <span className="value">(Validate and Sign By Operating Signee) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 24 ? (
                                    <>
                                      {" "}
                                      Compile Agreement <span className="value">(Final Release) </span>
                                    </>
                                  ) : null}
                                  {v.applicationStage === 25 ? "Final Release" : null}
                                  {v.applicationStage === 26 ? (
                                    <>
                                      {" "}
                                      Payment <span className="value">(Validate Application Payment) </span>
                                    </>
                                  ) : null}
                                </span>
                              </div>
                            </div>
                          </div>

                          {(event2StripeResponse.length > 0 || event2paymentType !== "-") && (
                            <div className="detail border-bottom">
                              <div className="row py-2">
                                <div className="col-sm-2">
                                  <label className="d-block d-sm-none text-muted">Application Numaber</label>
                                  <span className="text">{/* #{v.ApplicationTitle} */}</span>
                                </div>

                                <div className="col-sm-1">
                                  <label className="d-block d-sm-none text-muted">HNTB FILE#</label>
                                  <span className="text">{""}</span>
                                </div>
                                <div className="col-sm-1 p-0">
                                  <label className="d-block d-sm-none text-muted">Paid on Stage</label>
                                  <span className="text">Agreement Payment</span>
                                </div>
                                <div className="col-sm-1">
                                  <label className="d-block d-sm-none text-muted">Payment Method</label>
                                  <span className="text">{event2paymentType}</span>
                                </div>
                                <div className="col-sm-1">
                                  <label className="d-block d-sm-none text-muted">Payment Amount</label>
                                  <span className="text">{event2paymentAmount}</span>
                                </div>

                                <div className="col-sm-2">
                                  <label className="d-block d-sm-none text-muted">Date OF Payment</label>
                                  <span className="text">{event2paymentDate}</span>
                                </div>
                                <div className="col-sm-2 p-0">
                                  <label className="d-block d-sm-none text-muted">Payment Link</label>
                                  <span className="text">
                                    {event2paymentType === "Stripe" ? (
                                      <a className="btn-link" href={event2paymentLinkOrAchNumber} target="_blank" rel="noreferrer">
                                        payment_intent
                                      </a>
                                    ) : (
                                      event2paymentLinkOrAchNumber
                                    )}
                                  </span>
                                </div>
                                <div className="col-sm-1">
                                  <label className="d-block d-sm-none text-muted">Accountant Comment</label>
                                  <span className="text">{event2AccountantComment}</span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className="Page navigation example mt-4">
        {getPaginationGroup().length > 1 && (
          <ul className="pagination  justify-content-center pb-6">
            <li className="page-item" onClick={goToPreviousPage} style={currentPage === 1 ? { pointerEvents: "none", opacity: "0.6" } : null}>
              <a className="page-link" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            {getPaginationGroup().map((item, index) => (
              <li className="page-item" key={index} onClick={(event) => changePage(event)}>
                <a className="page-link">{item}</a>
              </li>
            ))}
            <li className="page-item" onClick={goToNextPage} style={currentPage === pages ? { pointerEvents: "none", opacity: "0.6" } : null}>
              <a className="page-link" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        )}
      </nav>
    </>
  );
}
