const dropdownReducer = (
  state = {
    RailRoad_line_transverse: "",
    RailRoad_line_Begin_longitudinal: "",
    RailRoad_line_End_longitudinal: "",
    RailRoad_line_Begin_longitudinal_transvere: "",
    RailRoad_line_End_longitudinal_transvere: "",
  },
  action,
) => {
  switch (action.type) {
    case "UPDATE_DROPDOWN":
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export default dropdownReducer;
