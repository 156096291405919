import React from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { actionCountyUpdate, actionDropdownUpdate } from "../../redux/actions";
import { formSchemaPresent } from "../../api/currentApplications";
import markup from "../Helper/CountyValue";

const DropDown = ({ label = "", name = "", validation, register, options = [], errors, requiredLable = false }) => {
  let params = useParams();
  let data = JSON.parse(localStorage.getItem(params.applicationID)) || {};
  const dispatch = useDispatch();
  const county = useSelector((state) => state.county);
  const dropdown = useSelector((state) => state.dropdown);
  const [editpage, setEditpage] = React.useState(false);
  React.useEffect(() => {
    let data = JSON.parse(localStorage.getItem(params.applicationID)) || {};

    data["county"] && dispatch(actionCountyUpdate(data["county"]));
    data["RailRoad line transverse"] &&
      dispatch(
        actionDropdownUpdate({
          RailRoad_line_transverse: data["RailRoad line transverse"],
        }),
      );
    data["RailRoad line End longitudinal"] &&
      dispatch(
        actionDropdownUpdate({
          RailRoad_line_End_longitudinal: data["RailRoad line End longitudinal"],
        }),
      );
    data["RailRoad line Begin longitudinal"] &&
      dispatch(
        actionDropdownUpdate({
          RailRoad_line_Begin_longitudinal: data["RailRoad line Begin longitudinal"],
        }),
      );
    data["RailRoad line Begin longitudinal transvere"] &&
      dispatch(
        actionDropdownUpdate({
          RailRoad_line_Begin_longitudinal_transvere: data["RailRoad line Begin longitudinal transvere"],
        }),
      );
    data["RailRoad line End longitudinal transvere"] &&
      dispatch(
        actionDropdownUpdate({
          RailRoad_line_End_longitudinal_transvere: data["RailRoad line End longitudinal transvere"],
        }),
      );
  }, []);

  function getMin(county_value, line) {
    let min;
    let index = markup.findIndex((object) => {
      return object.id === county_value;
    });
    if (index !== -1) {
      markup[
        markup.findIndex((object) => {
          return object.id === county_value;
        })
      ].lines.map((item, index) => {
        if (item.line === line) {
          min = item.min;
        }
      });
    }
    return min;
  }

  function getMax(county_value, line) {
    let max;
    let index = markup.findIndex((object) => {
      return object.id === county_value;
    });
    if (index !== -1) {
      markup[
        markup.findIndex((object) => {
          return object.id === county_value;
        })
      ].lines.map((item, index) => {
        if (item.line === line) {
          max = item.max;
        }
      });
    }
    return max;
  }
  const changeFunction = (name, e) => {
    let data = {
      [name]: e,
    };
    let values = JSON.parse(localStorage.getItem(params.applicationID));
    if (values) {
      values = { ...values, ...data };
      localStorage.setItem(params.applicationID, JSON.stringify(values));
    } else {
      localStorage.setItem(params.applicationID, JSON.stringify(data));
    }
  };

  return (
    <>
      <div className="input d-flex flex-column py-2">
        <label className="form-label ">
          {label}

          {requiredLable ? <sup className="text-danger">*</sup> : null}
        </label>
        <select
          id={name}
          className="form-select  mb-3"
          disabled={
            (name === "RailRoad line transverse" ||
              name === "RailRoad line Begin longitudinal" ||
              name === "RailRoad line End longitudinal" ||
              name === "RailRoad line Begin longitudinal + transvere" ||
              name === "RailRoad line End longitudinal + transvere") &&
            county === ""
              ? true
              : false
          }
          {...register(name, { ...validation })}
          onChange={(e) => {
            if (name === "county") {
              var lines = "",
                text = "";
              dispatch(actionCountyUpdate(e.target.value));
              markup[
                markup.findIndex((object) => {
                  return object.id === e.target.value;
                })
              ].lines.map((item, index) => {
                if (item.line === "Mainline" || item.line === "H-Line" || item.line === "EC-Line") lines = lines + " " + item.line;
              });
              if (lines.includes("Mainline"))
                text = text + `Mainline – Increasing milepost from Greensboro (MP ${getMin(e.target.value, "Mainline")}) to Charlotte (MP ${getMax(e.target.value, "Mainline")})\n`;

              if (lines.includes("H-Line"))
                text = text + `H-Line – Increasing milepost from Greensboro (MP H-${getMin(e.target.value, "H-Line")}) to Goldsboro (MP H-${getMax(e.target.value, "H-Line")}) \n`;

              if (lines.includes("EC-Line"))
                text = text + `EC-Line – Increasing milepost from Goldsboro (MP EC-${getMin(e.target.value, "EC-Line")}) to Morehead City (MP EC-${getMax(e.target.value, "EC-Line")})`;

              document.getElementById("range1").innerText = text;
              document.getElementById("range2").innerText = text;
              document.getElementById("range3").innerText = text;
              document.getElementById("range4").innerText = text;
              document.getElementById("range5").innerText = text;
              document.getElementById("RailRoad line transverse").selectedIndex = 0;
              document.getElementById("RailRoad line Begin longitudinal").selectedIndex = 0;
              document.getElementById("RailRoad line End longitudinal").selectedIndex = 0;
              document.getElementById("RailRoad line Begin longitudinal + transvere").selectedIndex = 0;
              document.getElementById("RailRoad line End longitudinal + transvere").selectedIndex = 0;
              document.getElementById("Railroad Milestone MP").value = "";
              document.getElementById("Begin at Railroad Milepost parallel to tracks Mp").value = "";
              document.getElementById("End at Railroad Milepost parallel to tracks Mp").value = "";
              document.getElementById("Begin at Railroad Milepost transverse crossing Mp").value = "";
              document.getElementById("End at Railroad Milepost transverse crossing Mp").value = "";
              dispatch(
                actionDropdownUpdate({
                  RailRoad_line_transverse: "select",
                  RailRoad_line_Begin_longitudinal: "select",
                  RailRoad_line_End_longitudinal: "select",
                  RailRoad_line_Begin_longitudinal_transvere: "select",
                  RailRoad_line_End_longitudinal_transvere: "select",
                }),
              );
            } else if (name === "RailRoad line transverse") {
              changeFunction(name, e.target.value);
              if (document.getElementById("milepost-error-1") !== null) document.getElementById("milepost-error-1").innerText = `Enter a value`;
              document.getElementById("Railroad Milestone MP").value = "";
              dispatch(
                actionDropdownUpdate({
                  RailRoad_line_transverse: e.target.value,
                  RailRoad_line_Begin_longitudinal: dropdown.RailRoad_line_Begin_longitudinal,
                  RailRoad_line_End_longitudinal: dropdown.RailRoad_line_End_longitudinal,
                  RailRoad_line_Begin_longitudinal_transvere: dropdown.RailRoad_line_Begin_longitudinal_transvere,
                  RailRoad_line_End_longitudinal_transvere: dropdown.RailRoad_line_End_longitudinal_transvere,
                }),
              );
            } else if (name === "RailRoad line Begin longitudinal") {
              changeFunction(name, e.target.value);
              if (document.getElementById("milepost-error-2") !== null) document.getElementById("milepost-error-2").innerText = `Enter a value`;
              document.getElementById("Begin at Railroad Milepost parallel to tracks Mp").value = "";
              dispatch(
                actionDropdownUpdate({
                  RailRoad_line_transverse: dropdown.RailRoad_line_transverse,
                  RailRoad_line_Begin_longitudinal: e.target.value,
                  RailRoad_line_End_longitudinal: dropdown.RailRoad_line_End_longitudinal,
                  RailRoad_line_Begin_longitudinal_transvere: dropdown.RailRoad_line_Begin_longitudinal_transvere,
                  RailRoad_line_End_longitudinal_transvere: dropdown.RailRoad_line_End_longitudinal_transvere,
                }),
              );
            } else if (name === "RailRoad line End longitudinal") {
              changeFunction(name, e.target.value);
              if (document.getElementById("milepost-error-3") !== null) document.getElementById("milepost-error-3").innerText = `Enter a value`;
              document.getElementById("End at Railroad Milepost parallel to tracks Mp").value = "";
              dispatch(
                actionDropdownUpdate({
                  RailRoad_line_transverse: dropdown.RailRoad_line_transverse,
                  RailRoad_line_Begin_longitudinal: dropdown.RailRoad_line_Begin_longitudinal,
                  RailRoad_line_End_longitudinal: e.target.value,
                  RailRoad_line_Begin_longitudinal_transvere: dropdown.RailRoad_line_Begin_longitudinal_transvere,
                  RailRoad_line_End_longitudinal_transvere: dropdown.RailRoad_line_End_longitudinal_transvere,
                }),
              );
            } else if (name === "RailRoad line Begin longitudinal + transvere") {
              changeFunction(name, e.target.value);
              if (document.getElementById("milepost-error-4") !== null) document.getElementById("milepost-error-4").innerText = `Enter a value`;
              document.getElementById("Begin at Railroad Milepost transverse crossing Mp").value = "";
              dispatch(
                actionDropdownUpdate({
                  RailRoad_line_transverse: dropdown.RailRoad_line_transverse,
                  RailRoad_line_Begin_longitudinal: dropdown.RailRoad_line_Begin_longitudinal,
                  RailRoad_line_End_longitudinal: dropdown.RailRoad_line_End_longitudinal,
                  RailRoad_line_Begin_longitudinal_transvere: e.target.value,
                  RailRoad_line_End_longitudinal_transvere: dropdown.RailRoad_line_End_longitudinal_transvere,
                }),
              );
            } else if (name === "RailRoad line End longitudinal + transvere") {
              changeFunction(name, e.target.value);
              if (document.getElementById("milepost-error-5") !== null) document.getElementById("milepost-error-").innerText = `Enter a value`;
              document.getElementById("End at Railroad Milepost transverse crossing Mp").value = "";
              dispatch(
                actionDropdownUpdate({
                  RailRoad_line_transverse: dropdown.RailRoad_line_transverse,
                  RailRoad_line_Begin_longitudinal: dropdown.RailRoad_line_Begin_longitudinal,
                  RailRoad_line_End_longitudinal: dropdown.RailRoad_line_End_longitudinal,
                  RailRoad_line_Begin_longitudinal_transvere: dropdown.RailRoad_line_Begin_longitudinal_transvere,
                  RailRoad_line_End_longitudinal_transvere: e.target.value,
                }),
              );
            }
          }}
        >
          {name === "RailRoad line transverse" ||
          name === "RailRoad line Begin longitudinal" ||
          name === "RailRoad line End longitudinal" ||
          name === "RailRoad line Begin longitudinal + transvere" ||
          name === "RailRoad line End longitudinal + transvere" ? (
            <option value="select" selected>
              Select
            </option>
          ) : null}

          {name === "RailRoad line transverse" ||
          name === "RailRoad line Begin longitudinal" ||
          name === "RailRoad line End longitudinal" ||
          name === "RailRoad line Begin longitudinal + transvere" ||
          name === "RailRoad line End longitudinal + transvere" ? (
            markup.findIndex((object) => {
              return object.id === county;
            }) !== -1 ? (
              markup[
                markup.findIndex((object) => {
                  return object.id === county;
                })
              ].lines.map((item, index) => (
                <option key={index} value={item === "select" ? "" : item.line} selected={item.line === data[name]}>
                  {item.line}
                </option>
              ))
            ) : data[name] ? (
              <option value={data[name]} selected={data[name]}>
                {data[name]}
              </option>
            ) : null
          ) : (
            options.map((value, index) => (
              <option key={index} value={value === "Select" ? "" : value} selected={value === data[name]}>
                {value}
              </option>
            ))
          )}
        </select>
        <span className="text-danger">{errors[name] && <span>Select a value for {name}</span>}</span>
      </div>
    </>
  );
};
export default DropDown;
