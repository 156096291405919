/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router";

import { approveOrReject, formSchemaPresent, getComments, getcurrentStatus, getFormData, putNextStatus, sendNotifications, postNCRRFileNumber } from "../../api/currentApplications";
import { pipelineApplicationSchema } from "../../schema/pipeLineFormSchema";
import ApplicantformFilledStatic from "./ApplicantformFilledStatic";
import Rejected from "../rejectedForm/RejectedForm";
import InitalReviewLoading from "../loaders/InitalReviewLoading";
import CommonNavigation from "../commonNavigation/CommonNavigation";
import { useAtoms } from "../../hooks/useAtoms";
import { useUser } from "../../hooks/useUser";
import { useApplication, useApplicationRefetch } from "../../hooks/useApplication";
import { IsExpeditedCheckbox } from "../ui/IsExpeditedCheckbox";

function ApplicationReview(props) {
  let history = useHistory();
  const [applicationData, setApplicationData] = useState();
  const [loading, setLoading] = useState(true);
  const [allComments, setAllComments] = useState([]);
  const [resolvedComment, setResolvedComments] = useState([]);
  const [formSchema, setFormSchema] = useState(pipelineApplicationSchema);
  const [formName, setFormName] = useState("");
  const [error, setError] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [required, setRequired] = useState(false);
  const [check, SetCheck] = useState("");
  const [notification, setNotification] = useState({});
  const [applicationTitle, setApplicationTitle] = React.useState();
  const [singleApplicant, setSingleApplicant] = useState(false);
  const [singleApprover, setSingleApprover] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [hntbFileNumber, setHNTBFileNumber] = useState();
  const [hntbFileNo, setHntbFileNo] = useState(false);
  const [hntbFileSave, setHntbFileSave] = useState();
  let ItemId = props.match.params.applicationID;
  const { setNavigation, setHideNavigation } = useAtoms();
  setHideNavigation(false);
  setNavigation(`Application Review ${" "} ${applicationTitle ? applicationTitle : ItemId} `);

  const { roleName = "", id: contactRoleId } = useUser() || {};
  React.useEffect(async () => {
    let newRes = await getcurrentStatus(props.match.params.applicationID);
    SetCheck(newRes.data.data.currentStep);
  }, []);

  const application = useApplication();
  const applicationRefetch = useApplicationRefetch();
  useEffect(() => {
    getFormData(props.match.params.applicationID)
      .then(({ data }) => {
        if (data.rejected) {
          setApplicationData(data);
          setRejected(true);
          setLoading(false);
        } else {
          setHNTBFileNumber(data.HNTBFileNumber);
          setHntbFileSave(data.HNTBFileNumber);
          // setReviewedBy(data.reviewBy);
          setFormName(data.formName);
          setApplicationTitle(data.ApplicationTitle);
          setApplicationData(data.applicationData);
          getAllComments();
        }
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });

    getFormSchema();
  }, []);

  const handleSave = (data) => {
    if (data) {
      PutncrrFileNumber();
      setHntbFileNo(true);
      applicationRefetch();
    }
  };
  const PutncrrFileNumber = async () => {
    const formData = new FormData();
    formData.append("HNTBFileNumber", JSON.stringify(hntbFileNumber));
    await postNCRRFileNumber({
      applicationID: props.match.params.applicationID,
      HNTBFileNumber: hntbFileNumber,
    });
  };
  function getFormSchema() {
    formSchemaPresent(props.match.params.applicationID)
      .then((data) => {
        if (data.data.present) {
          const newSchema = JSON.parse(data.data.schema);
          setFormSchema(JSON.parse(newSchema));
        }
      })
      .catch((err) => console.log(err));
  }

  function getUnique(comments) {
    var uniqueComments = [comments[0]];
    comments.forEach((v) => {
      if (!uniqueComments.filter((d) => d.questionID === v.questionID).length) {
        uniqueComments.push(v);
      }
    });
    return uniqueComments;
  }

  async function handleApprove() {
    const data = {
      rejected: false,
      applicationID: props.match.params.applicationID,
    };
    const HNTBFileNumberAdded = !!((hntbFileNo === true && hntbFileNumber) || hntbFileSave);
    if (!HNTBFileNumberAdded) {
      setRequired(true);
      return;
    }
    try {
      setApproveLoading(true);
      await approveOrReject(data);
      setApproveLoading(false);
      putNextStatus(ItemId, 3);
      history.push("/dashboard");
    } catch (error) {
      setApproveLoading(false);
      // toast.error(error)
      // alert(error);
    }
  }

  function getAllComments() {
    getComments(props.match.params.applicationID)
      .then(({ data }) => {
        setResolvedComments(data.comments.applicationComments.resolved);
        setAllComments(data.comments);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function enableTab(tabId) {
    const element = document.getElementById(tabId);
    const closest = element.closest(".accordion-collapse");
    closest.classList.add("show");
  }

  const editApplication = () => {
    if (formName === "form210") {
      history.push(`/form210/${props.match.params.applicationID}?startloading=true`);
    }
    if (formName === "form220") {
      history.push(`/form220/${props.match.params.applicationID}`);
    }
  };
  let role = roleName;
  let applicationCommentsId = allComments && allComments.applicationComments && allComments.applicationComments.applicant && allComments.applicationComments.applicant.map((d) => d.questionID);
  let uniqueNum = [...new Set(applicationCommentsId)];
  let applicationResolvedId = allComments && allComments.applicationComments && allComments.applicationComments.resolved && allComments.applicationComments.resolved.map((d) => d.questionID);
  let valId = uniqueNum && uniqueNum.filter((value) => !applicationResolvedId.includes(value));
  let IdLen = applicationResolvedId && applicationResolvedId.length;
  const sendNotification = async (commentType, notificationTo) => {
    setNotification({});
    let data = {
      reviewType: "applicationComments",
      commentType: commentType,
      applicationID: props.match.params.applicationID,
      notificationTo: notificationTo,
      custID: contactRoleId,
    };

    let datas = await sendNotifications(data);
    setNotification(datas.data.responseData.commentType);

    setSingleApplicant(false);
    setSingleApprover(false);
  };

  return error ? (
    <div>Not a valid form page</div>
  ) : !rejected ? (
    <div className="review-page">
      <div className="d-flex flex-column">
        <div className="border-bottom mb-4 bg-white">
          <div className="w-75 mx-auto">
            <div className="">
              <CommonNavigation ItemId={props.match.params.applicationID} />
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <div className="row">
            <div className="col-lg-3 sidebar">
              {/* <h2 className="mt-3">Comments Overview</h2> */}
              <div className="progress-outer position-sticky">
                <h2 className="mt-3">Comments Overview</h2>
                <div className="progress-col">
                  <div className="right">
                    {formSchema.sections.map((v, i) => (
                      <>
                        <div className={`${"#" + v.label.split(" ").join("_") === window.location.hash ? "current" : ""}`}>
                          <a href={`#${v.label.split(" ").join("_").split("'").join("_") + i}`}>
                            <small className="">{v.label}</small>
                          </a>
                          <>
                            {role === "applicant" ? (
                              <>
                                {allComments.applicationComments
                                  ? v.questions.map((d, i) =>
                                      getUnique(allComments.applicationComments.applicant)
                                        .filter((v) => v && v.questionID && v.questionID === d.id)
                                        .map((x, i) => (
                                          <>
                                            <div key={i} className="card mt-3">
                                              {resolvedComment.filter((m) => m.questionID === x.questionID).length > 0 ? (
                                                <div className="card-body p-3 pe-4 position-relative">
                                                  <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                    <a className="position-absolute ">&nbsp;</a>
                                                    <p className="mb-0 position-relative w-100">
                                                      {ReactHtmlParser(x.comment)}
                                                      <i className="mark-icon far fa-check text-muted position-absolute top-50 end-0"></i>
                                                    </p>
                                                  </a>
                                                </div>
                                              ) : (
                                                <div className="card-body p-3 pe-4 position-relative">
                                                  <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                    <a className="position-absolute ">&nbsp;</a>
                                                    <p className="mb-0 position-relative w-100">{ReactHtmlParser(x.comment)}</p>
                                                  </a>
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        )),
                                    )
                                  : null}
                              </>
                            ) : (
                              <>
                                {role === "Approving Representative" && allComments.applicationComments
                                  ? v.questions.map((d, i) =>
                                      getUnique(allComments.applicationComments.applicant)
                                        .filter((v) => v && v.questionID && v.questionID === d.id)
                                        .map((x, i) => (
                                          <>
                                            <div key={i} className="card mt-3">
                                              {resolvedComment.filter((m) => m.questionID === x.questionID).length > 0 ? (
                                                <div className="card-body p-3 pe-4 position-relative">
                                                  <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                    <a className="position-absolute ">&nbsp;</a>
                                                    <p className="mb-0 position-relative w-100">
                                                      {ReactHtmlParser(x.comment)}
                                                      <i className="mark-icon far fa-check text-muted position-absolute top-50 end-0"></i>
                                                    </p>
                                                  </a>
                                                </div>
                                              ) : (
                                                <div className="card-body p-3 pe-4 position-relative">
                                                  <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                    <a className="position-absolute ">&nbsp;</a>
                                                    <p className="mb-0 position-relative w-100">{ReactHtmlParser(x.comment)}</p>
                                                  </a>
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        )),
                                    )
                                  : null}
                              </>
                            )}
                          </>
                        </div>
                      </>
                    ))}
                    <hr />

                    {check <= 3 && role === "applicant" && (
                      <div className="d-grid gap-2">
                        {notification == "applicant" ? (
                          <>
                            <a className="btn btn-success d-block  m-0 disabled">
                              <small>
                                <i className="fas fa-check-double"></i> Notification Sent
                              </small>
                            </a>
                          </>
                        ) : (
                          <>
                            {singleApprover === true ? (
                              <>
                                <a className="btn btn-outline-success d-block  m-0 disabled">
                                  <small>
                                    <i className="far fa-check"></i> Notification Sent
                                  </small>
                                </a>
                              </>
                            ) : (
                              allComments &&
                              allComments.applicationComments &&
                              allComments.applicationComments.applicant &&
                              allComments.applicationComments.applicant.length > 0 &&
                              uniqueNum &&
                              uniqueNum.length != IdLen && (
                                <a onClick={() => sendNotification("applicant", "approvingRepresentatives", setSingleApprover(true))} className="btn btn-primary text-white d-block  m-0">
                                  <small>Submit Revisions</small>
                                </a>
                              )
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {check <= 3 && role != "applicant" && (
                  <div className="d-grid gap-2 mt-3">
                    {notification == "applicant" ? (
                      <>
                        <a className="btn btn-success d-block  m-0 disabled">
                          <small>
                            <i className="fas fa-check-double"></i> Notification Sent
                          </small>
                        </a>
                      </>
                    ) : (
                      <>
                        {singleApplicant === true ? (
                          <>
                            <a className="btn btn-outline-success d-block  m-0 disabled">
                              <small>
                                <i className="far fa-check"></i> Notification Sent
                              </small>
                            </a>
                          </>
                        ) : (
                          allComments &&
                          allComments.applicationComments &&
                          allComments.applicationComments.applicant &&
                          allComments.applicationComments.applicant.length > 0 &&
                          uniqueNum &&
                          uniqueNum.length != IdLen && (
                            <a onClick={() => sendNotification("applicant", "applicant", setSingleApplicant(true))} className="btn btn-primary d-block text-white m-0">
                              <small>Notify Applicant</small>
                            </a>
                          )
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            {loading ? (
              <InitalReviewLoading />
            ) : (
              <div className="col-lg-9">
                {roleName === "applicant" && check < 5 && valId.length > 0 ? (
                  <div className="row justify-content-end my-3">
                    <div className="col-md-4">
                      <div className="d-grid">
                        <button className="btn-primary btn-lg btn d-flex align-items-center justify-content-between py-3" onClick={editApplication}>
                          <span>Edit Application </span>
                          <i className="fal fa-chevron-right fs-3 ms-3"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}

                {roleName === "Approving Representative" ? (
                  <div className="row justify-content-end my-3">
                    {approveLoading ? (
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        {check < 4 && (
                          <>
                            <div className="col-md-3">
                              <div className="d-grid">
                                <button
                                  type="button"
                                  className="btn-danger btn-lg btn d-flex align-items-center justify-content-between py-3"
                                  data-bs-toggle="modal"
                                  data-bs-target="#reject"
                                  disabled={application.applicationStage !== 3}
                                >
                                  <span> Reject</span>
                                  <i className="fal fa-times fs-3 ms-3"></i>
                                </button>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="d-grid">
                                <button
                                  disabled={(uniqueNum && uniqueNum.length != IdLen) || application.applicationStage !== 3}
                                  className="btn-primary btn-lg btn d-flex align-items-center justify-content-between py-3"
                                  onClick={handleApprove}
                                >
                                  <span>Approve & Proceed</span>
                                  <i className="fal fa-chevron-right fs-3 ms-3"></i>
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                ) : null}
                {roleName === "Approving Representative" && (
                  <>
                    {/* <IsExpeditedCheckbox /> */}
                    <label className="form-label">
                      HNTB File Number
                      <span className="text-danger">
                        <span>*</span>
                      </span>
                    </label>
                    <div className="">
                      <div className="input-group">
                        <input
                          id="Hntb_Number"
                          value={hntbFileNumber}
                          disabled={(hntbFileNo === true && hntbFileNumber) || hntbFileSave}
                          className="form-control"
                          onChange={(e) => {
                            setHNTBFileNumber(e.target.value);
                            setHntbFileNo(false);
                          }}
                        />

                        <button
                          className={(hntbFileNo === true && hntbFileNumber) || hntbFileSave ? "btn btn-success" : "btn btn-primary"}
                          disabled={(hntbFileNo === true && hntbFileNumber) || hntbFileSave}
                          onClick={(e) => {
                            handleSave(hntbFileNumber);
                          }}
                        >
                          Save
                        </button>
                      </div>
                      <span className="text-danger ">
                        <span>{required && <span>This field is required</span>}</span>
                      </span>
                    </div>
                    <br />
                  </>
                )}
                {check > 4 ? (
                  <div className="row justify-content-end my-3">
                    <div className="col-md-3">
                      <div className="d-grid">
                        <button disabled className="btn-success btn-lg btn d-flex align-items-center justify-content-between py-3">
                          <span>Approved</span>
                          <i className="fal fa-check"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                <ApplicantformFilledStatic
                  formSchema={formSchema}
                  applicationData={applicationData}
                  applicationID={props.match.params.applicationID}
                  allComments={allComments}
                  resolvedComment={resolvedComment}
                  ItemId={ItemId}
                />
              </div>
            )}
            {check > 4 ? (
              <div className="row justify-content-end my-3">
                <div className="col-md-3">
                  <div className="d-grid">
                    <button disabled className="btn-success btn-lg btn d-flex align-items-center justify-content-between py-3">
                      <span>Approved</span>
                      <i className="fal fa-check"></i>
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Rejected applicationData={applicationData} />
  );
}

export default ApplicationReview;
