import React from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { formContext } from "../../routes/routes";

function Delete({ carrier_index_value, conduit_index_value, text, cbody }) {
  let params = useParams();
  let applicationID = params.applicationID;
  const schemaContext = useContext(formContext);
  let handleDelete = () => {
    const local_schema = JSON.parse(localStorage.getItem(`${applicationID}schema`));
    if (conduit_index_value !== undefined && carrier_index_value !== null && conduit_index_value !== null) {
      local_schema.sections.forEach((section, sectionindex) => {
        if (section.label === "Facilities Details") {
          section.questions.forEach((e, v) => {
            if (e.header.includes("casing")) {
              if (e.index === carrier_index_value) {
                let conduitlistlength = local_schema.sections[2].questions[v].rows.length;
                local_schema.sections[sectionindex].questions[v].rows[conduitlistlength - 1].columns[0].conduitlist.splice(conduit_index_value, 1);
                local_schema.sections[sectionindex].questions[v].rows[conduitlistlength - 1].columns[0].conduitlist.forEach((conduit, conduitindex) => {
                  local_schema.sections[sectionindex].questions[v].rows[conduitlistlength - 1].columns[0].conduitlist[conduitindex].conduit_position_index = conduitindex;
                  local_schema.sections[sectionindex].questions[v].rows[conduitlistlength - 1].columns[0].conduitlist[conduitindex].rows[0].columns[0].conduit_index_value = conduitindex;
                });
                schemaContext.setSchema(local_schema);
              }
            }
          });
        }
      });
    } else {
      local_schema.sections.forEach((section) => {
        if (section.label === "Facilities Details") {
          section.questions.forEach((e, v) => {
            if (e.header.includes("casing")) {
              if (e.index === carrier_index_value) {
                local_schema.sections[2].questions.splice(v, 1);
                schemaContext.setSchema(local_schema);
              }
            }
          });
        }
      });
    }
    localStorage.setItem(`${applicationID}schema`, JSON.stringify(local_schema));
  };

  return (
    <div className="text-right position-absolute end-0 edit-delete-icon-col">
      <button onClick={handleDelete} type="button" className="btn p-1 text-danger">
        <i className="fas fa-trash-alt"></i>
      </button>
    </div>
  );
}

export default Delete;
