/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

let debounceTimer;

const debounce = (callback, time) => {
  window.clearTimeout(debounceTimer);
  debounceTimer = window.setTimeout(callback, time);
};

export const SearchBar = ({ values = [], onSearch = async () => {}, onSelectValue = () => {}, label = "", placeholder = "", showValueObjectName = "[fields][entityid]", valuesPerPage = "10" }) => {
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [page, setPage] = useState(1);
  const pagination = React.useRef(null);

  useEffect(() => {
    const data = values.filter((data, i) => {
      const pageStart = 0;
      if (i < valuesPerPage && i >= pageStart) {
        return true;
      } else {
        return false;
      }
    });
    setPageData(data);
  }, [values, valuesPerPage]);

  const changePage = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= Math.floor(values.length / valuesPerPage) + 1 && values) {
      setPage(pageNumber);
      const filteredData = values.filter((data, i) => {
        const pageStart = (pageNumber - 1) * valuesPerPage;
        const endPage = valuesPerPage * pageNumber;
        if (i < endPage && i >= pageStart) {
          return true;
        } else {
          return false;
        }
      });
      setPageData(filteredData);
    }
    setTimeout(() => {
      pagination.current.classList.add("show");
    });
  };

  const search = async (event) => {
    setLoading(true);
    try {
      await onSearch(event.target.value);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  function getValueByPath(obj, path) {
    return path.split(".").reduce((acc, key) => {
      if (acc && acc[key] !== undefined) {
        return acc[key];
      }
      return undefined;
    }, obj);
  }

  const select = (value) => {
    setTimeout(() => pagination.current.classList.remove("show"));
    onSelectValue(value);
  };

  return (
    <div className="row g-3 align-items-center">
      <div className="col-auto">
        <h4 htmlFor="automplete" className=" m-0 text-muted">
          {label}
        </h4>
      </div>
      <div className="col-lg-9 ui-widget select-col position-relative">
        <input
          type="text"
          id="automplete"
          className="form-control  border-dark"
          data-bs-toggle="dropdown"
          //   aria-expanded="false"
          placeholder={placeholder}
          onChange={(e) => debounce(() => search(e), 1000)}
        />
        <ul className="dropdown-menu show w-100 h-0 rounded-0 pb-0 pt-0" aria-labelledby="automplete" id="custom_pagination" ref={pagination}>
          {loading && (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {values.length > 0 && (
            <li>
              <button type="button" className="close  pr-2 h3" aria-label="Close" onClick={() => setTimeout(() => pagination.current.classList.remove("show"))}>
                <span aria-hidden="true">&times;</span>
              </button>
            </li>
          )}
          {values.length > 0 &&
            pageData.map((each) => {
              return (
                <li className="border-bottom" key={each.id} onClick={() => select(each)}>
                  <a className="dropdown-item d-flex justify-content-between">
                    <span>{getValueByPath(each, showValueObjectName)}</span>
                    <span className="text text-primary">Select</span>
                  </a>
                </li>
              );
            })}
          {values.length > valuesPerPage && (
            <nav aria-label="Page navigation example" aria-expanded="false">
              <ul className="pagination d-flex justify-content-center">
                <li className="page-item">
                  <a className="page-link" aria-label="Previous" onClick={() => changePage(page - 1)}>
                    <span aria-hidden="false">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" onClick={() => changePage(1)}>
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" onClick={() => changePage(2)}>
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" onClick={() => changePage(3)}>
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" onClick={() => changePage(page + 1)} aria-label="Next">
                    <span aria-hidden="false">&raquo;</span>
                    <span className="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          )}
        </ul>
      </div>
    </div>
  );
};
