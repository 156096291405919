/* eslint-disable react-hooks/exhaustive-deps */
import HtmlParser from "react-html-parser";
import React, { useContext, useEffect, useRef, useState, memo } from "react";
import { watchContext } from "../FormOnAFly";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { actionCountyUpdate, actionDropdownUpdate } from "../../redux/actions";
import "./MultiFileUpload.css";
import $ from "jquery";
import markup from "../Helper/CountyValue";

const Text = ({
  label = "",
  name = "",
  name1 = "",
  type = "text",
  defaultValue = "",
  placeholder = "",
  css = {},
  validation,
  register,
  errors,
  handleChange,
  id,
  fieldLabel = "",
  fieldLabelPosition = "",
  fieldType = "",
  comments = "",
  commentsIconIsRequired = false,
  showOnChange = [],
  commentsStyle = {},
  condtionalLabel = "",
  disabled = false,
  parallel = 0,
  crossing = 0,
  setSchema = () => {},
  requiredLable = false,
  changeFunction,
  getLocalStorageValue = () => {},
  editPage,
}) => {
  const lastSec = useRef();
  lastSec.current = [];
  const watch = useContext(watchContext);
  const county = useSelector((state) => state.county);
  const dropdown = useSelector((state) => state.dropdown);
  const [error, setError] = useState({
    milepost_error_1: null,
    milepost_error_2: null,
    milepost_error_3: null,
    milepost_error_4: null,
    milepost_error_5: null,
  });
  const dispatch = useDispatch();
  let params = useParams();
  useEffect(() => {}, [dropdown, county]);
  useEffect(async () => {
    setError((prevState) => ({
      ...prevState,
      milepost_error_1: false,
      milepost_error_2: false,
      milepost_error_3: false,
      milepost_error_4: false,
      milepost_error_5: false,
    }));
    if (document.getElementById("Railroad Milestone MP").value) {
      var existing = JSON.parse(localStorage.getItem(params.applicationID));
      // console.log(document.getElementById("Railroad Milestone MP").value)
      if (existing !== null) {
        existing["Railroad Milestone MP"] = document.getElementById("Railroad Milestone MP").value;
        await localStorage.setItem(params.applicationID, JSON.stringify(existing));
      }
      document.getElementById("Railroad Milestone MP").disabled = false;
    }

    if (document.getElementById("Begin at Railroad Milepost parallel to tracks Mp").value) {
      var existing = JSON.parse(localStorage.getItem(params.applicationID));
      // console.log(document.getElementById("Railroad Milestone MP").value)
      if (existing != null) {
        existing["Begin at Railroad Milepost parallel to tracks Mp"] = document.getElementById("Begin at Railroad Milepost parallel to tracks Mp").value;
        await localStorage.setItem(params.applicationID, JSON.stringify(existing));
      }
      document.getElementById("Begin at Railroad Milepost parallel to tracks Mp").disabled = false;
    }

    if (document.getElementById("End at Railroad Milepost parallel to tracks Mp").value) {
      var existing = JSON.parse(localStorage.getItem(params.applicationID));
      // console.log(document.getElementById("Railroad Milestone MP").value)
      if (existing != null) {
        existing["End at Railroad Milepost parallel to tracks Mp"] = document.getElementById("End at Railroad Milepost parallel to tracks Mp").value;
        await localStorage.setItem(params.applicationID, JSON.stringify(existing));
      }
      document.getElementById("End at Railroad Milepost parallel to tracks Mp").disabled = false;
    }

    if (document.getElementById("Begin at Railroad Milepost transverse crossing Mp").value) {
      var existing = JSON.parse(localStorage.getItem(params.applicationID));
      // console.log(document.getElementById("Railroad Milestone MP").value)
      if (existing != null) {
        existing["Begin at Railroad Milepost transverse crossing Mp"] = document.getElementById("Begin at Railroad Milepost transverse crossing Mp").value;
        await localStorage.setItem(params.applicationID, JSON.stringify(existing));
      }
      document.getElementById("Begin at Railroad Milepost transverse crossing Mp").disabled = false;
    }

    if (document.getElementById("End at Railroad Milepost transverse crossing Mp").value) {
      var existing = JSON.parse(localStorage.getItem(params.applicationID));
      // console.log(document.getElementById("Railroad Milestone MP").value)
      if (existing != null) {
        existing["End at Railroad Milepost transverse crossing Mp"] = document.getElementById("End at Railroad Milepost transverse crossing Mp").value;
        await localStorage.setItem(params.applicationID, JSON.stringify(existing));
      }
      document.getElementById("End at Railroad Milepost transverse crossing Mp").disabled = false;
    }

    if (document.getElementById("RailRoad line transverse").value && document.getElementById("RailRoad line transverse").value !== "select")
      document.getElementById("RailRoad line transverse").disabled = false;

    if (document.getElementById("RailRoad line Begin longitudinal").value && document.getElementById("RailRoad line Begin longitudinal").value !== "select")
      document.getElementById("RailRoad line Begin longitudinal").disabled = false;

    if (document.getElementById("RailRoad line End longitudinal").value && document.getElementById("RailRoad line End longitudinal").value !== "select")
      document.getElementById("RailRoad line End longitudinal").disabled = false;

    if (document.getElementById("RailRoad line Begin longitudinal + transvere").value && document.getElementById("RailRoad line Begin longitudinal + transvere").value !== "select")
      document.getElementById("RailRoad line Begin longitudinal + transvere").disabled = false;

    if (document.getElementById("RailRoad line End longitudinal + transvere").value && document.getElementById("RailRoad line End longitudinal + transvere").value !== "select")
      document.getElementById("RailRoad line End longitudinal + transvere").disabled = false;

    if (JSON.parse(localStorage.getItem(params.applicationID))) {
      var lines = "",
        text = "";
      var existing = JSON.parse(localStorage.getItem(params.applicationID));
      // console.log(document.getElementById("Railroad Milestone MP").value)
      // console.log(existing.county)
      // dispatch(actionCountyUpdate(existing.county));
      if (existing.county !== "") {
        markup[
          markup.findIndex((object) => {
            return object.id === existing.county;
          })
        ].lines.map((item, index) => {
          if (item.line === "Mainline" || item.line === "H-Line" || item.line === "EC-Line") lines = lines + " " + item.line;
        });
        if (lines.includes("Mainline"))
          text = text + `Mainline – Increasing milepost from Greensboro (MP ${getMin(existing.county, "Mainline")}) to Charlotte (MP ${getMax(existing.county, "Mainline")})\n`;

        if (lines.includes("H-Line"))
          text = text + `H-Line – Increasing milepost from Greensboro (MP H-${getMin(existing.county, "H-Line")}) to Goldsboro (MP H-${getMax(existing.county, "H-Line")}) \n`;

        if (lines.includes("EC-Line"))
          text = text + `EC-Line – Increasing milepost from Goldsboro (MP EC-${getMin(existing.county, "EC-Line")}) to Morehead City (MP EC-${getMax(existing.county, "EC-Line")})`;

        document.getElementById("range1").innerText = text;
        document.getElementById("range2").innerText = text;
        document.getElementById("range3").innerText = text;
        document.getElementById("range4").innerText = text;
        document.getElementById("range5").innerText = text;
      }
    }
  }, [watch]);

  function getMin(county_value, line) {
    let min;
    let index = markup.findIndex((object) => {
      return object.id === county_value;
    });
    if (index !== -1) {
      markup[
        markup.findIndex((object) => {
          return object.id === county_value;
        })
      ].lines.map((item, index) => {
        if (item.line === line) {
          min = item.min;
        }
      });
    }
    return min;
  }

  function getMax(county_value, line) {
    let max;
    let index = markup.findIndex((object) => {
      return object.id === county_value;
    });
    if (index !== -1) {
      markup[
        markup.findIndex((object) => {
          return object.id === county_value;
        })
      ].lines.map((item, index) => {
        if (item.line === line) {
          max = item.max;
        }
      });
    }
    return max;
  }

  let total = Number(crossing) + Number(parallel);
  if (name === "Total Length of Occupancy within the NCRR Corridor") {
    localStorage.setItem("Total Length of Occupancy within the NCRR Corridor", total);
    getLocalStorageValue(localStorage.getItem("Total Length of Occupancy within the NCRR Corridor"));
    register(name, { value: total });
  }
  let data = JSON.parse(localStorage.getItem(params.applicationID)) || {};

  const padileStaone = (input, padChar, maxLength, min, max) => {
    if (input >= max) return max;

    if (input < min) return min;

    var s = input.toString(10);
    var padding = "";
    for (var i = 0; i < maxLength; ++i) padding += padChar;
    // console.log(min, "console.log(maxLength);");
    return padding.substring(0, maxLength - s.length) + s;
  };

  $(".feetr").on("keyup", function () {
    if (!$(this).val()) return;
    $(this).val(padileStaone(parseInt($(this).val()), "0", 4, "0000", 9999));
  });

  const padFeet = (e) => {
    if (name === "longitude" && e.target.value !== "  ") {
      let numericValue = "";
      let el_down = document.getElementById("longitude");
      numericValue = parseInt(e.target.value);
      if (Math.sign(numericValue) !== -1) {
        el_down.value = -numericValue;
      }
    }
  };

  const handleBlur = (e) => {
    const number = parseInt(e.target.value);
    if (e.target.name === "Railroad Milestone MP") {
      let min, max;
      let index = markup.findIndex((object) => {
        return object.id === county;
      });
      if (index !== -1) {
        markup[
          markup.findIndex((object) => {
            return object.id === county;
          })
        ].lines.map((item, index) => {
          if (item.line === data["RailRoad line transverse"]) {
            min = item.min;
            max = item.max;
          }
        });
      }

      if (parseInt(number) > max || parseInt(number) < min) {
        let iv = parseInt(number) > max ? max : min;
        iv = iv >= 0 && iv <= 9 ? `00${iv}` : iv >= 10 && iv <= 99 ? `0${iv}` : iv;
        e.target.value = iv;
        changeFunction(e.target.name, iv);
        setError((prevState) => ({
          ...prevState,
          milepost_error_1: true,
        }));
      } else {
        setError((prevState) => ({
          ...prevState,
          milepost_error_1: false,
        }));
        let iv = parseInt(number);
        iv = iv >= 0 && iv <= 9 ? `00${iv}` : iv >= 10 && iv <= 99 ? `0${iv}` : iv;
        e.target.value = iv;
        changeFunction(e.target.name, iv);
      }
    } else if (e.target.name === "Begin at Railroad Milepost parallel to tracks Mp") {
      let min, max;
      let index = markup.findIndex((object) => {
        return object.id === county;
      });
      if (index !== -1) {
        markup[
          markup.findIndex((object) => {
            return object.id === county;
          })
        ].lines.map((item, index) => {
          if (item.line === data["RailRoad line Begin longitudinal"]) {
            min = item.min;
            max = item.max;
          }
        });
      }

      if (parseInt(number) > max || parseInt(number) < min) {
        let iv = parseInt(number) > max ? max : min;
        iv = iv >= 0 && iv <= 9 ? `00${iv}` : iv >= 10 && iv <= 99 ? `0${iv}` : iv;
        e.target.value = iv;
        setError((prevState) => ({
          ...prevState,
          milepost_error_2: true,
        }));
        changeFunction(e.target.name, iv);
      } else {
        setError((prevState) => ({
          ...prevState,
          milepost_error_2: false,
        }));
        let iv = parseInt(number);
        iv = iv >= 0 && iv <= 9 ? `00${iv}` : iv >= 10 && iv <= 99 ? `0${iv}` : iv;
        e.target.value = iv;
        changeFunction(e.target.name, iv);
      }
    } else if (e.target.name === "End at Railroad Milepost parallel to tracks Mp") {
      let min, max;
      let index = markup.findIndex((object) => {
        return object.id === county;
      });
      if (index !== -1) {
        markup[
          markup.findIndex((object) => {
            return object.id === county;
          })
        ].lines.map((item, index) => {
          if (item.line === data["RailRoad line End longitudinal"]) {
            min = item.min;
            max = item.max;
          }
        });
      }
      if (parseInt(number) > max || parseInt(number) < min) {
        let iv = parseInt(number) > max ? max : min;
        iv = iv >= 0 && iv <= 9 ? `00${iv}` : iv >= 10 && iv <= 99 ? `0${iv}` : iv;
        e.target.value = iv;
        setError((prevState) => ({
          ...prevState,
          milepost_error_3: true,
        }));
        changeFunction(e.target.name, iv);
      } else {
        setError((prevState) => ({
          ...prevState,
          milepost_error_3: false,
        }));
        let iv = parseInt(number);
        iv = iv >= 0 && iv <= 9 ? `00${iv}` : iv >= 10 && iv <= 99 ? `0${iv}` : iv;
        e.target.value = iv;
        changeFunction(e.target.name, iv);
      }
    } else if (e.target.name === "Begin at Railroad Milepost transverse crossing Mp") {
      let min, max;
      let index = markup.findIndex((object) => {
        return object.id === county;
      });
      if (index !== -1) {
        markup[
          markup.findIndex((object) => {
            return object.id === county;
          })
        ].lines.map((item, index) => {
          if (item.line === data["RailRoad line Begin longitudinal + transvere"]) {
            min = item.min;
            max = item.max;
          }
        });
      }
      if (parseInt(number) > max || parseInt(number) < min) {
        let iv = parseInt(number) > max ? max : min;
        iv = iv >= 0 && iv <= 9 ? `00${iv}` : iv >= 10 && iv <= 99 ? `0${iv}` : iv;
        e.target.value = iv;
        setError((prevState) => ({
          ...prevState,
          milepost_error_4: true,
        }));
        changeFunction(e.target.name, iv);
      } else {
        setError((prevState) => ({
          ...prevState,
          milepost_error_4: false,
        }));
        let iv = parseInt(number);
        iv = iv >= 0 && iv <= 9 ? `00${iv}` : iv >= 10 && iv <= 99 ? `0${iv}` : iv;
        e.target.value = iv;
        changeFunction(e.target.name, iv);
      }
    } else if (e.target.name === "End at Railroad Milepost transverse crossing Mp") {
      let min, max;
      let index = markup.findIndex((object) => {
        return object.id === county;
      });
      if (index !== -1) {
        markup[
          markup.findIndex((object) => {
            return object.id === county;
          })
        ].lines.map((item, index) => {
          if (item.line === data["RailRoad line End longitudinal + transvere"]) {
            min = item.min;
            max = item.max;
          }
        });
      }
      if (parseInt(number) > max || parseInt(number) < min) {
        let iv = parseInt(number) > max ? max : min;
        iv = iv >= 0 && iv <= 9 ? `00${iv}` : iv >= 10 && iv <= 99 ? `0${iv}` : iv;
        e.target.value = iv;
        setError((prevState) => ({
          ...prevState,
          milepost_error_5: true,
        }));
        changeFunction(e.target.name, iv);
      } else {
        setError((prevState) => ({
          ...prevState,
          milepost_error_5: false,
        }));
        let iv = parseInt(number);
        iv = iv >= 0 && iv <= 9 ? `00${iv}` : iv >= 10 && iv <= 99 ? `0${iv}` : iv;
        e.target.value = iv;
        changeFunction(e.target.name, iv);
      }
    }
  };
  React.useEffect(() => {
    let data = JSON.parse(localStorage.getItem(params.applicationID)) || {};

    data["county"] && dispatch(actionCountyUpdate(data["county"]));
    const object = {};
    object["RailRoad_line_transverse"] = data["RailRoad line transverse"] ? data["RailRoad line transverse"] : undefined;
    object["RailRoad_line_Begin_longitudinal"] = data["RailRoad line Begin longitudinal"] ? data["RailRoad line Begin longitudinal"] : undefined;
    object["RailRoad_line_Begin_longitudinal_transvere"] = data["RailRoad line Begin longitudinal transvere"] ? data["RailRoad line Begin longitudinal transvere"] : undefined;
    object["RailRoad_line_End_longitudinal_transvere"] = data["RailRoad line End longitudinal transvere"] ? data["RailRoad line End longitudinal transvere"] : undefined;
    object["RailRoad_line_End_longitudinal"] = data["RailRoad line End longitudinal"] ? data["RailRoad line End longitudinal"] : undefined;
    dispatch(actionDropdownUpdate(object));
  }, []);
  return (
    <div className="input d-flex flex-column py-2">
      {fieldType === "group" ? (
        <>
          <label className="form-label">
            {label} {requiredLable ? <sup className="text-danger">*</sup> : null}
          </label>
          <div className="input-group mb-3">
            <form className="d-flex input-group">
              <span className="input-group-text bg-secondary  text-dark">MP</span>
              <input
                id={name}
                type={"number"}
                className={`form-control px-2`}
                aria-label="default"
                aria-describedby="inputGroup-sizing-default"
                style={css}
                step="any"
                defaultValue={data[name]}
                placeholder={placeholder}
                pattern={type === "number" ? "[0-9]+([.,][0-9]+)?" : null}
                name={name}
                onBlurCapture={handleBlur}
                {...register(name, { ...validation })}
              />
              {/* {console.log("-------",id)} */}
              <span className="input-group-text bg-secondary">+</span>
              <input
                type={"number"}
                className="form-control px-2 feetr"
                aria-label="default"
                aria-describedby="inputGroup-sizing-default"
                style={css}
                pattern={type === "number" ? "[0-9]+([.,][0-9]+)?" : null}
                // step={
                //   name === "longitude" || name === "latitude"
                //     ? "0.00000001"
                //     : "0.0001"
                // }
                // defaultValue={"0000"}
                defaultValue={data[name1]}
                placeholder={placeholder}
                name={name1}
                max={9999}
                min={0}
                // onInput={(e) => e.target.padFeet}
                // defaultValue={ data[name] }
                {...register(name1, { ...validation })}
              />
              <span className="input-group-text bg-secondary  text-dark mr-1">Feet</span>
            </form>
          </div>
          <label className="form-label" style={commentsStyle}>
            {commentsIconIsRequired ? <i className="far fa-hand-point-right"></i> : null}
            {HtmlParser(comments)}
          </label>

          {/* <span className="text-danger">
            {errors[name] && <span>Enter a valid value for {label}</span>}
          </span> */}
        </>
      ) : (
        <>
          <label className="form-label">
            {label}
            {requiredLable ? <sup className="text-danger">*</sup> : null}
          </label>

          <div className="input-group">
            {name === "Total Length of Occupancy within the NCRR Corridor" ? (
              <input
                style={css}
                className="form-control"
                type={fieldLabel.length > 0 ? "number" : type}
                // defaultValue={data[name]}
                placeholder={total}
                step="any"
                disabled={disabled}
                // step={
                //   name === "longitude" || name === "latitude"
                //     ? "0.00000001"
                //     : "0.0001"
                // }
                defaultValue={data[name]}
                aria-label="Default"
                pattern={type === "number" ? "[0-9]+([.,][0-9]+)?" : null}
                aria-describedby="inputGroup-sizing-default"
              />
            ) : (
              <>
                <input
                  style={css}
                  id={name}
                  className="form-control"
                  type={fieldLabel.length > 0 ? "number" : type}
                  // placeholder={placeholder}
                  name={name}
                  step="any"
                  //defaultValue={ data[name] }
                  // step={
                  //   name === "longitude" || name === "latitude"
                  //     ? "0.00000001"
                  //     : "0.0001"
                  // }
                  onInput={(e) => padFeet(e)}
                  // pattern={type === "number" ? "[0-9]+([.,][0-9]+)?" : null}
                  disabled={disabled}
                  {...register(name, { ...validation })}
                  // onChange={(e) => handleEvents(e, name)}
                  defaultValue={
                    name === "TOTAL BURIED LENGTH ON THE NCRR CORRIDOR" ||
                    name === "BURY DEPTH FROM BASE OF RAIL TO TOP OF CASING" ||
                    name === "BURY DEPTH BELOW DITCHES" ||
                    name === "MINIMUM DEPTH ON THE NCRR CORRIDOR BUT NOT BENEATH TRACKS" ||
                    name === "ENCASEMENT MATERIAL" ||
                    name === "NOMINAL DIAMETER" ||
                    name === "WALL THICKNESS"
                      ? ""
                      : data[name]
                  }
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                />
              </>
            )}

            {fieldLabelPosition === "right" ? (
              <div className="input-group-append">
                <span className="input-group-text bg-secondary  text-dark">{fieldLabel}</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <label className="form-label" style={commentsStyle}>
            {commentsIconIsRequired ? <i className="far fa-hand-point-right"></i> : null}
            {HtmlParser(comments)}
          </label>
        </>
      )}

      {name === "Railroad Milestone MP" ? (
        <span className="text-danger">
          {(errors[name] || error.milepost_error_1 === true) && (
            <span id="milepost-error-1">
              Enter a valid value{" "}
              {getMin(county, dropdown.RailRoad_line_transverse) >= 0 ? `between ${getMin(county, dropdown.RailRoad_line_transverse)} and ${getMax(county, dropdown.RailRoad_line_transverse)}` : ""}
              {/* {getMin()} and{getMax()} */}
            </span>
          )}
        </span>
      ) : name === "Begin at Railroad Milepost parallel to tracks Mp" ? (
        <span className="text-danger">
          {(errors[name] || error.milepost_error_2 === true) && (
            <span id="milepost-error-2">
              Enter a valid value{" "}
              {getMin(county, dropdown.RailRoad_line_Begin_longitudinal) >= 0
                ? `between ${getMin(county, dropdown.RailRoad_line_Begin_longitudinal)} and ${getMax(county, dropdown.RailRoad_line_Begin_longitudinal)}`
                : ""}{" "}
            </span>
          )}
        </span>
      ) : name === "End at Railroad Milepost parallel to tracks Mp" ? (
        <span className="text-danger">
          {(errors[name] || error.milepost_error_3 === true) && (
            <span id="milepost-error-3">
              Enter a valid value{" "}
              {getMin(county, dropdown.RailRoad_line_End_longitudinal) >= 0
                ? `between ${getMin(county, dropdown.RailRoad_line_End_longitudinal)} and ${getMax(county, dropdown.RailRoad_line_End_longitudinal)}`
                : ""}{" "}
            </span>
          )}
        </span>
      ) : name === "Begin at Railroad Milepost transverse crossing Mp" ? (
        <span className="text-danger">
          {(errors[name] || error.milepost_error_4 === true) && (
            <span id="milepost-error-4">
              Enter a valid value{" "}
              {getMin(county, dropdown.RailRoad_line_Begin_longitudinal_transvere) >= 0
                ? `between ${getMin(county, dropdown.RailRoad_line_Begin_longitudinal_transvere)} and ${getMax(county, dropdown.RailRoad_line_Begin_longitudinal_transvere)}`
                : ""}{" "}
            </span>
          )}
        </span>
      ) : name === "End at Railroad Milepost transverse crossing Mp" ? (
        <span className="text-danger">
          {(errors[name] || error.milepost_error_5 === true) && (
            <span id="milepost-error-5">
              Enter a valid value{" "}
              {getMin(county, dropdown.RailRoad_line_End_longitudinal_transvere) >= 0
                ? `between ${getMin(county, dropdown.RailRoad_line_End_longitudinal_transvere)} and ${getMax(county, dropdown.RailRoad_line_End_longitudinal_transvere)}`
                : ""}{" "}
            </span>
          )}
        </span>
      ) : (
        <span className="text-danger">{errors[name] && <span>Enter a valid value for {label}</span>}</span>
      )}

      <div id={`conditionalInput${name}`} className=""></div>
    </div>
  );
};

export default memo(Text);
