import React from "react";

const dropdown = ({ data, handleNetSuiteChange, recordName, col, stage, formNumber, value }) => {
  if ((stage = 10)) {
    var disablevalue = data.disabled ? data.disabled : false;
  } else {
    var disablevalue = data.disabled || data.validateFinalHideField || data.validateHideField ? data.disabled || data.validateFinalHideField || data.validateHideField : false;
  }

  return (
    <>
      {(col.formType === formNumber || col.formType === undefined) && data.type === "dropdown" && (
        <div className="mb-3">
          <label forhtml={data.name} className="text-muted form-label">
            {data.label}
          </label>
          <div class="select-col position-relative">
            <select name={data.name} class="form-select" id={data.name} onChange={(e) => handleNetSuiteChange(data, e, recordName, col)} disabled={disablevalue} value={value}>
              {col.formType === formNumber || col.formType === undefined
                ? data.options.map((value, index) => {
                    return (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    );
                  })
                : ""}
            </select>
          </div>
        </div>
      )}
    </>
  );
};

export default dropdown;
