import { atom, atomFamily, selectorFamily } from "recoil";
import { getUserDetailsByContactRoleId } from "../api/crm";

export const refetchUserSignal = atom({
  key: "refetchUser",
  default: 0,
});

export const user = atomFamily({
  key: "user",
  default: selectorFamily({
    key: "userSelecter",
    get:
      (id) =>
      async ({ get }) => {
        const facilityId = localStorage.getItem("facilityId");
        try {
          if (!id) {
            return null;
          }
          get(refetchUserSignal);
          const res = await getUserDetailsByContactRoleId(id);
          if (res.data.status) {
            const user = res.data.data;
            if (user?.company?.companyType === "FACILITY") {
              if (user?.company?.id !== facilityId) {
                localStorage.setItem("facilityId", user?.company?.id);
              }
            }
            return user;
          } else {
            localStorage.clear();
            window.location = "/login";
          }
        } catch (error) {
          return {};
        }
      },
  }),
});
