import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { application, refetchApplicationSignal } from "../atoms/application";

export const useApplication = () => {
  const params = useParams();
  const id = params.applicationId || params.applicationID;
  try {
    return useRecoilValue(application(id));
  } catch (error) {
    return null;
  }
};

export const useApplicationRefetch = () => {
  const setRefetchSignal = useSetRecoilState(refetchApplicationSignal);
  return () => {
    setRefetchSignal((prev) => prev + 1);
  };
};
export const useApplicationUpdate = () => {
  const params = useParams();
  const id = params.applicationId || params.applicationID;
  const setApplicationSignal = useSetRecoilState(application(id));
  return (applicationData = {}) => {
    setApplicationSignal((value) => ({ ...value, ...applicationData }));
  };
};
