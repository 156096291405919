import { AgreementSignature } from "../components/webhooks/AgreementSignature";
import { useApplication } from "../hooks/useApplication";
import { useUser } from "../hooks/useUser";
import DashBoardLoading from "../components/loaders/DashBoardLoading";

const Page = () => {
  const application = useApplication();
  const user = useUser();
  if (!application || !user) {
    return <DashBoardLoading />;
  }
  return <AgreementSignature />;
};

export default Page;
