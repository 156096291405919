import React, { useEffect, useState } from "react";
import { useApplication } from "../../hooks/useApplication";
import { useUser } from "../../hooks/useUser";
import PaymentsLoading from "../loaders/PaymentLoading";
import { getApplicationPaymentFee } from "../../utils/getPaymentAmount";

export const PaymentInformation = () => {
  const [fee, setFee] = useState(null);
  const application = useApplication();
  const { roleName } = useUser();
  const { ApplicationTitle, formNumber, applicantDetails, applicationStage, event1paymentType, event1applicationFee, event1engineeringFee, event1transactionFee } = application || {};
  const { primaryContact, billingContact } = applicantDetails || {};
  let applicationPaymentFee = event1applicationFee ? Number(event1applicationFee) : Number(fee?.applicationFee);
  let engineeringPaymentFee = event1engineeringFee ? Number(event1engineeringFee) : Number(fee?.engineeringFee);
  let transactionPaymentFee = event1transactionFee ? Number(event1transactionFee) : Number(fee?.transactionFee);

  const getFee = async (application) => {
    const fee = await getApplicationPaymentFee(application);
    setFee(fee);
  };

  useEffect(() => {
    if (!event1transactionFee || !event1applicationFee || !event1engineeringFee) {
      getFee(application);
    }
  }, [event1applicationFee, event1engineeringFee, event1transactionFee, application]);

  return (
    <div className="accordion" id="accordionExample">
      <div className="accordion-item mb-4">
        <h2 className="accordion-header" id="PaymentInformation">
          <button
            className=" accordion-button py-4 px-4 d-flex justify-content-between "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#PaymentCollapse"
            aria-expanded="false"
            aria-controls="PaymentCollapse"
          >
            Payment Information
          </button>
        </h2>
        <div id="PaymentCollapse" className="accordion-collapse show" aria-labelledby="PaymentInformation" data-bs-parent="#accordionExample">
          <div className="accordion-body pt-0">
            <div className="card-col">
              <div className="card-body px-0">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="field-col">
                      <h3>Application Information</h3>
                      <p>
                        Application number: <strong>#{ApplicationTitle}</strong>
                        <br />
                        Form type: <strong>{formNumber}</strong>
                        <br />
                        HNTB File Number: <strong>{application?.HNTBFileNumber}</strong>
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="field-col"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="field-col">
                      <h3>Primary Contact</h3>
                      {primaryContact && (
                        <p>
                          <strong className="d-block mb-2">{primaryContact?.jobTitle + " " + primaryContact?.name}</strong>
                          {primaryContact?.name}
                          <br />
                          <br />
                          {primaryContact?.address?.address}
                          <br />
                          {primaryContact?.address?.city},{primaryContact?.address?.state}
                          <br />
                          {primaryContact?.email}
                          <br />
                          {primaryContact?.address?.zip}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="field-col">
                      <h3>Billing Contact</h3>
                      {billingContact && (
                        <p>
                          <strong className="d-block mb-2">{billingContact?.jobTitle + " " + billingContact?.name}</strong> {billingContact?.email}
                          <br />
                          <br />
                          {billingContact?.address?.address}
                          <br />
                          {billingContact?.address?.city},{billingContact?.address?.state}
                          <br />
                          {billingContact?.phone}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="item-col mt-5">
                  <div className="heading py-2 border-bottom d-none d-sm-block">
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="text-muted">Item</label>
                      </div>
                      <div className="col-sm-3">
                        <label className="text-muted">Qty</label>
                      </div>

                      <div className="col-sm-3">
                        <label className="text-muted">Amount</label>
                      </div>
                    </div>
                  </div>
                  {
                    <>
                      {applicationPaymentFee ? (
                        <div className="detail">
                          <div className="row py-2">
                            <div className="col-sm-6">
                              <label className="d-block d-sm-none text-muted">Item</label>
                              <span className="text">Application Fee</span>
                            </div>
                            <div className="col-sm-3">
                              <label className="d-block d-sm-none text-muted">Qty</label>
                              <span className="text">1</span>
                            </div>

                            <div className="col-sm-3">
                              <label className="d-block d-sm-none text-muted">Total</label>
                              <span className="text">$ {applicationPaymentFee}</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <PaymentsLoading />
                      )}

                      {engineeringPaymentFee ? (
                        <div className="detail">
                          <div className="row py-2">
                            <div className="col-sm-6">
                              <label className="d-block d-sm-none text-muted">Item</label>
                              <span className="text">Engineering Fee</span>
                            </div>
                            <div className="col-sm-3">
                              <label className="d-block d-sm-none text-muted">Qty</label>
                              <span className="text">1</span>
                            </div>

                            <div className="col-sm-3">
                              <label className="d-block d-sm-none text-muted">Total</label>
                              <span className="text">$ {engineeringPaymentFee}</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <PaymentsLoading />
                      )}
                    </>
                  }
                  <hr className="m-0 p-0" />

                  {(event1paymentType === "Stripe" || applicationStage === 26) && roleName === "applicant" && (
                    <>
                      {transactionPaymentFee ? (
                        <div className="detail">
                          <div className="row py-2">
                            <div className="col-sm-6">
                              <label className="d-block d-sm-none text-muted">Item</label>
                              <span className="text-muted">Transaction Fee</span>
                            </div>
                            <div className="col-sm-3">
                              <label className="d-block d-sm-none text-muted">Qty</label>
                            </div>

                            <div className="col-sm-3">
                              <label className="d-block d-sm-none text-muted">Total</label>
                              <span className="text">${Number(transactionPaymentFee)}</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <PaymentsLoading />
                      )}
                    </>
                  )}
                  <hr className="mt-0 pt-0 mb-3" />

                  {applicationPaymentFee ? (
                    <div className="total">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              <span className="text text-muted">Total</span>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                              <span className="text">
                                $
                                {applicationStage > 1 && (event1paymentType === "Stripe" || applicationStage === 26) && roleName === "applicant"
                                  ? Number(engineeringPaymentFee) + Number(applicationPaymentFee) + Number(transactionPaymentFee)
                                  : Number(engineeringPaymentFee) + Number(applicationPaymentFee)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <PaymentsLoading />
                  )}
                  {(event1paymentType === "Check" || event1paymentType === "ACH") && (
                    <>
                      <hr />
                      <div className="row justify-content-center">
                        <div className="col-md-4">
                          <button className="btn btn-outline-primary btn-lg w-100" onClick={() => window.print()}>
                            Print
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
