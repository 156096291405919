import { useRecoilValue, useSetRecoilState } from "recoil";
import { refetchContactRole, contactRole } from "../atoms/contactRoles";
import { useUser } from "./useUser";

export const useContactRole = () => {
  const { contact = {} } = useUser() || {};
  const { id } = contact;

  try {
    return useRecoilValue(contactRole(id));
  } catch (error) {
    return null;
  }
};

export const useContactRoleRefetch = () => {
  const setRefetchSignal = useSetRecoilState(refetchContactRole);
  return () => {
    setRefetchSignal((prev) => prev + 1);
  };
};
