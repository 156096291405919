import { useParams } from "react-router-dom";
import { HandleFieldType } from "../HandleFieldType";
import { Title } from "../Helper/Title";
import "bootstrap/dist/css/bootstrap.min.css";

export const HandleForms = ({ children, register, errors, setSchema, watch, clearErrors, changeFunction, unregister, editPage }) => {
  return children.questions.map((child, index) => (
    <div id={child.id} key={index} style={child.hide === true ? { display: "none" } : null}>
      <div className={child.casing == "casing" ? `card border-dark ${child.header === "" ? "" : "border-top-0"} border-bottom` : ""}>
        <div
          // className="text-center border-bottom card-header"
          id={child.scroll}
        >
          {/* <Title title={child.label} /> */}
        </div>
        <div className={child.casing == "casing" ? "card-body" : ""}>
          <div>
            <HandleFieldType
              setSchema={setSchema}
              errors={errors}
              items={child}
              changeFunction={changeFunction}
              register={register}
              clearErrors={clearErrors}
              unregister={unregister}
              watch={watch}
              editPage={editPage}
            />
          </div>
        </div>
      </div>
    </div>
  ));
};
