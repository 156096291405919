import axios from "axios";
const { REACT_APP_API_URL } = process.env;
// const { REACT_APP_API_URL } = process.env;
const URL = REACT_APP_API_URL;

function notify_error(data) {
  const { message, stack } = data.error;
  data.error = { message, stack };
  if (data.url && data.customer && data.error) {
    return axios.post(`${URL}/error/notify`, data, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
}

export { notify_error };
