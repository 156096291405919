import { React, useState, useEffect } from "react";
import { useApplication } from "../../hooks/useApplication";

const NetsuiteLink = (props) => {
  const [netSuiteIds, setNetSuiteIds] = useState({});
  const application = useApplication();
  useEffect(() => {
    async function fetchData() {
      try {
        const { netSuite = {} } = application;
        setNetSuiteIds(netSuite);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [props.applicationID]);
  let netsuitevalue = netSuiteIds ? netSuiteIds?.[props.v.netSuiteIdKeyName]?.id : "";
  return netsuitevalue ? (
    <a target="_blank" href={props.v.Link + netsuitevalue} className="btn btn-link" rel="noreferrer">
      Link to Netsuite
    </a>
  ) : null;
};

export default NetsuiteLink;
