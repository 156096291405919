const API_URL = process.env.REACT_APP_API_URL;
const File = process.env.REACT_APP_FILE_PATH;
const PAYMENT_LINK = process.env.REACT_APP_PAYMENT_LINK;
const REACT_APP_URL = process.env.REACT_APP_URL;
const REACT_APP_NCRR_Application_fee_original = process.env.REACT_APP_NCRR_Application_fee_original;
const REACT_APP_NCRR_Application_fee = process.env.REACT_APP_NCRR_Application_fee;
const REACT_APP_HNTB_Engineering_review_original = process.env.REACT_APP_HNTB_Engineering_review_original;
const REACT_APP_HNTB_Engineering_review = process.env.REACT_APP_HNTB_Engineering_review;
const REACT_APP_NS_initial_fee_original = process.env.REACT_APP_NS_initial_fee_original;
const REACT_APP_NS_initial_fee = process.env.REACT_APP_NS_initial_fee;
const REACT_APP_NS_risk_finance_fee_original = process.env.REACT_APP_NS_risk_finance_fee_original;
const REACT_APP_NS_risk_finance_fee = process.env.REACT_APP_NS_risk_finance_fee;
const REACT_APP_NCRR_Application_transaction_fee = process.env.REACT_APP_NCRR_Application_transaction_fee;
const REACT_APP_NCRR_Agreement_transaction_fee = process.env.REACT_APP_NCRR_Agreement_transaction_fee;
const REACT_APP_NS_initial_transaction_fee = process.env.REACT_APP_NS_initial_transaction_fee;

export {
  API_URL,
  PAYMENT_LINK,
  REACT_APP_URL,
  REACT_APP_NCRR_Application_fee_original,
  REACT_APP_NCRR_Application_fee,
  REACT_APP_HNTB_Engineering_review_original,
  REACT_APP_HNTB_Engineering_review,
  REACT_APP_NS_initial_fee,
  REACT_APP_NS_initial_fee_original,
  REACT_APP_NS_risk_finance_fee,
  REACT_APP_NS_risk_finance_fee_original,
  File,
  REACT_APP_NCRR_Application_transaction_fee,
  REACT_APP_NCRR_Agreement_transaction_fee,
  REACT_APP_NS_initial_transaction_fee,
};
