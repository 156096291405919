import React from "react";

const ApplicantViewLoading = () => {
  return (
    <div className="container py-4">
      <div className="row">
        <div className="col-lg-10 m-auto loading">
          <div className=" form-col">
            <div className="card-body">
              <div className="block">
                <div id="col8" className="section mb-2 position-relative side-nav-col">
                  <div className="row">
                    <div className="col-sm-6 ">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block d-block">Proposed work involves (check all that apply)</label>
                        <p className="text">Installation of a new facility</p>
                      </div>
                    </div>
                    <div className="col-sm-6 ">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block d-block">Existing Agreement for Installation?</label>
                        <p className="text">Yes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" form-col">
            <div className="card-body">
              <div className="block">
                <div id="col9" className="section mb-2 position-relative side-nav-col">
                  <div className="loading mb-3">
                    <div className="line"></div>
                    <h3 className="d-block">Location of Installation:</h3>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            NearestStreet:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            NearestTown:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            County:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            State:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            Latitude:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            Longitude:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" form-col">
            <div className="card-body">
              <div className="block">
                <div id="col9" className="section mb-2 position-relative side-nav-col">
                  <div className="loading mb-3">
                    <div className="line"></div>
                    <h3 className="d-block">Location of Installation:</h3>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            NearestStreet:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            NearestTown:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            County:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            State:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            Latitude:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="loading">
                        <div className="line"></div>
                        <div className="">
                          <label htmlFor="" className="text-muted d-block">
                            Longitude:
                          </label>
                          <p className="text">Test</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantViewLoading;
