import React, { useEffect, useState } from "react";
import { AddContactForm } from "../contacts/forms/AddContact";
import { EditContactForm } from "../contacts/forms/EditContact";
import { useParams } from "react-router-dom";
import { getContactsByCompanyId } from "../../api/crm";
import { useUser } from "../../hooks/useUser";
import { getAgreementsCount, getApplicationsCount } from "../../api/currentApplications";
import { ConsultantEditModel } from "../consultant/ConsultantEdit";
import { FacilityEditModel } from "../facility/FacilityEdit";

export default function Settings({ user }) {
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  let [showAddModal, setAddShowModal] = useState(false);
  let [showEditModal, setEditShowModal] = useState(false);
  const { company } = user || {};
  const [selectedContact, setSelectedContact] = useState(null);
  const { companyId } = useParams();

  const getContacts = async (companyId) => {
    const res = await getContactsByCompanyId(companyId);
    if (res.data.status) {
      setContacts(res.data.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getContacts(companyId);
  }, [companyId]);

  const handleEditClick = (contact) => {
    setSelectedContact(contact);
    setEditShowModal(true);
    document.body.classList.add("modal-open");
    const backdrop = document.createElement("div");
    backdrop.setAttribute("id", "modal-backdrops");
    backdrop.classList.add("modal-backdrop", "fade", "show");
    document.body.appendChild(backdrop);
  };
  const openModal = () => {
    setAddShowModal(true);
    document.body.classList.add("modal-open");
    const backdrop = document.createElement("div");
    backdrop.setAttribute("id", "modal-backdrops");
    backdrop.classList.add("modal-backdrop", "fade", "show");
    document.body.appendChild(backdrop);
  };
  const closeModal = () => {
    setEditShowModal(false);
    setAddShowModal(false);
    document.body.classList.remove("modal-open");
    const childElement = document.getElementById("modal-backdrops");
    childElement.parentNode.removeChild(childElement);
  };
  return loading ? (
    <div className="d-flex align-items-center justify-content-center  display-2" style={{ height: "calc(100% - 85px)" }}>
      <i className="fas fa-spinner fa-spin"></i>
    </div>
  ) : (
    <div style={{ marginTop: "20px" }}>
      <div style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 py-4">
              <div className="inner-form card mb-4">
                <nav id="newlink">
                  <div className="nav nav-tabs tab-link" id="nav-tab" role="tablist">
                    <button
                      className=" nav-link mb-3 mb-md-0 d-block d-sm-inline-block fw-normal p-3 active"
                      id="nav-home-tab "
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      <h2 className="mb-0"> Company Details</h2>
                    </button>
                    <button
                      className=" nav-link mb-3 mb-md-0 d-block d-sm-inline-block fw-normal p-3 "
                      id="nav-profile-tab "
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      <h2 className="mb-0"> Contact Details</h2>
                    </button>
                    {company?.companyType === "CONSULTANT" && (
                      <button
                        className=" nav-link mb-3 mb-md-0 d-block d-sm-inline-block fw-normal p-3 "
                        id="facility-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#facility-profile"
                        type="button"
                        role="tab"
                        aria-controls="facility-profile"
                        aria-selected="false"
                      >
                        <h2 className="mb-0"> Facility Details</h2>
                      </button>
                    )}
                    <button
                      className="nav-link  mb-3 mb-md-0 flex-fill  fw-normal p-3 "
                      id="facility-profile-tabs "
                      data-bs-toggle=""
                      data-bs-target="#facility-profiles"
                      type="button"
                      role="tab"
                      aria-controls="facility-profiles"
                      aria-selected="false"
                      disabled
                      style={{ cursor: "initial" }}
                    >
                      <h2 className="mb-0"> </h2>
                    </button>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div id="files-upload ">
                      <div className="card p-0 shadow border-primary rounded-0 border-top-0">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 p-0 m-0">
                              <div class="accordion" id="Customer-details">
                                <div class="accordion-item rounded-0">
                                  <div class="card rounded-0">
                                    <div class="card-body p-0">
                                      <div id="Collapse6" class="accordion-collapse collapse show" aria-labelledby="PaymentInformation" data-bs-parent="#Customer-details">
                                        <ShowCompanyDetails company={company} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <div id="files-upload ">
                      <div className="card p-0 shadow border-primary rounded-0 border-top-0">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 p-0 m-0">
                              <div class="accordion" id="applications">
                                <div class="accordion-item rounded-0">
                                  <div class="card rounded-0">
                                    <div class="card-body p-0">
                                      <div id="Collapse5" class="accordion-collapse collapse show" aria-labelledby="PaymentInformation1" data-bs-parent="#applications">
                                        <ShowContactDetails openModal={openModal} contacts={contacts} handleEditClick={handleEditClick} companyId={companyId} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="facility-profile" role="tabpanel" aria-labelledby="facility-profile-tab">
                    <div id="files-upload ">
                      <div className="card p-0 shadow border-primary rounded-0 border-top-0">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 p-0 m-0">
                              <div class="accordion" id="Customer-details">
                                <div class="accordion-item rounded-0">
                                  <div class="card rounded-0">
                                    <div class="card-body p-0">
                                      <div id="Collapse6" class="accordion-collapse collapse show" aria-labelledby="PaymentInformation" data-bs-parent="#Customer-details">
                                        <div class="accordion-body pt-0 p-0">
                                          <div class="card-col card">
                                            <div className="card-body p-0">
                                              <div className="card-body" id="Representative-details">
                                                <ShowfacilityDetails />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddContactForm showModal={showAddModal} setShowModal={setAddShowModal} getContact={getContacts} closeModal={closeModal} ID={"new-contact"} />
      <EditContactForm selectedContact={selectedContact} showModal={showEditModal} setShowModal={setEditShowModal} getContact={getContacts} closeModal={closeModal} ID={"edit-contact"} />
    </div>
  );
}

const ShowCompanyDetails = ({ company }) => {
  const [showModal, setShowModal] = useState({
    CONSULTANT: false,
    FACILITY: false,
  });

  const { companyType = "" } = company;

  const openModal = () => {
    setShowModal((model) => ({ ...model, [companyType]: true }));
  };

  const closeModal = () => {
    setShowModal((model) => ({ ...model, [companyType]: false }));
  };
  return (
    <div class="accordion-body pt-0 p-0">
      <div className="card-body" id="Representative-details">
        <div className="card  position-relative">
          <div className="delete-edit d-flex justify-content-end mt-2 mr-2">
            <button type="button" onClick={openModal} className="btn p-1 text-primary">
              <i className="far fa-edit"></i>
            </button>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <label className="text-muted d-block form-label">Name</label>
                <p>{company.name}</p>
              </div>
              {company.stateOfFormation && (
                <div className="col-md-4">
                  <label className="text-muted d-block form-label">State Of Formation</label>
                  <p>{company.stateOfFormation}</p>
                </div>
              )}
              {company.billingPeriod && (
                <div className="col-md-4">
                  <label className="text-muted d-block form-label">Billing Period</label>
                  <p>{company.billingPeriod}</p>
                </div>
              )}
              {company.applicantshipType && (
                <div className="col-md-4">
                  <label className="text-muted d-block form-label">Applicantship Type</label>
                  <p>{company.applicantshipType}</p>
                </div>
              )}
              {company.mailingAddress && (
                <>
                  <div className="d-flex justify-content-between my-3">
                    <h3 className="text-muted">Mailing Address</h3>
                    <p className="m-0 text-muted"></p>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label className="text-muted d-block form-label">Street Address:</label>
                      <p>{company.mailingAddress.address}</p>
                    </div>
                    <div className="col-md-4">
                      <label className="text-muted d-block form-label">City</label>
                      <p>{company.mailingAddress.city}</p>
                    </div>
                    <div className="col-md-4">
                      <label className="text-muted d-block form-label">Country</label>
                      <p>{company.mailingAddress.country}</p>
                    </div>
                    <div className="col-md-4">
                      <label className="text-muted d-block form-label">State</label>
                      <p>{company.mailingAddress.state}</p>
                    </div>
                    <div className="col-md-4">
                      <label className="text-muted d-block form-label">Zip</label>
                      <p>{company.mailingAddress.zip}</p>
                    </div>
                  </div>
                </>
              )}
              {company.billingAddress && (
                <>
                  {" "}
                  <div className="d-flex justify-content-between my-3">
                    <h3 className="text-muted">Billing Address</h3>
                    <p className="m-0 text-muted"></p>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label className="text-muted d-block form-label">Street Address:</label>
                      <p>{company.billingAddress.address}</p>
                    </div>
                    <div className="col-md-4">
                      <label className="text-muted d-block form-label">City</label>
                      <p>{company.billingAddress.city}</p>
                    </div>
                    <div className="col-md-4">
                      <label className="text-muted d-block form-label">Country</label>
                      <p>{company.billingAddress.country}</p>
                    </div>
                    <div className="col-md-4">
                      <label className="text-muted d-block form-label">State</label>
                      <p>{company.billingAddress.state}</p>
                    </div>
                    <div className="col-md-4">
                      <label className="text-muted d-block form-label">Zip</label>
                      <p>{company.billingAddress.zip}</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {companyType === "CONSULTANT" && <ConsultantEditModel showModal={showModal.CONSULTANT} closeModal={closeModal} />}
      {companyType === "FACILITY" && <FacilityEditModel showModal={showModal.FACILITY} closeModal={closeModal} />}
    </div>
  );
};

const ShowContactDetails = ({ openModal, contacts, handleEditClick, companyId }) => {
  return (
    <div class="accordion-body pt-0 p-0">
      <div class="card-col card">
        <div class="card-body pt-0">
          <div class="mb-4">
            <div class="card-header border-0 bg-transparent">
              <div class="d-sm-flex justify-content-between align-items-center">
                <h2 class="mb-3 mb-sm-0" id="applications">
                  {""}
                </h2>
                <button type="button" onClick={() => openModal()} className="btn btn-outline-primary btn-lg">
                  Add New Contact
                </button>
              </div>
            </div>
            {contacts?.map((contact) => (
              <div className="card  position-relative mb-4 " key={contact.id}>
                <div className="delete-edit d-flex justify-content-end mt-2 mr-2">
                  <button type="button" onClick={() => handleEditClick(contact)} className="btn p-1 text-primary">
                    <i className="far fa-edit"></i>
                  </button>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2">
                      <label className="text-muted d-block form-label">Name</label>
                      <p>{contact.name}</p>
                    </div>
                    <div className="col-md-2">
                      <label className="text-muted d-block form-label">Job Title</label>
                      <p>{contact.jobTitle}</p>
                    </div>
                    <div className="col-md-3">
                      <label className="text-muted d-block form-label">Email</label>
                      <p>{contact.email}</p>
                    </div>
                    <div className="col-md-2">
                      <label className="text-muted d-block form-label">Phone</label>
                      <p>{contact.phone}</p>
                    </div>
                    <div className="col-md-3">
                      <label className="text-muted d-block form-label">Role</label>
                      <ul>
                        {contact.companyroles.map((each) => {
                          if (each.company.id === companyId) {
                            return <li>{each.role.role}</li>;
                          }
                          return null;
                        })}
                      </ul>
                    </div>

                    <div className="d-flex justify-content-between my-3">
                      <h3 className="text-muted">Address</h3>
                      <p className="m-0 text-muted"></p>
                    </div>
                    <div className="col-md-2">
                      <label className="text-muted d-block form-label">Street Address</label>
                      <p>{contact?.address?.address}</p>
                    </div>
                    <div className="col-md-2">
                      <label className="text-muted d-block form-label">City</label>
                      <p>{contact?.address?.city}</p>
                    </div>
                    <div className="col-md-3">
                      <label className="text-muted d-block form-label">Country</label>
                      <p>{contact?.address?.country}</p>
                    </div>
                    <div className="col-md-2">
                      <label className="text-muted d-block form-label">State</label>
                      <p>{contact?.address?.state}</p>
                    </div>
                    <div className="col-md-2 ">
                      <label className="text-muted d-block form-label">Zip</label>
                      <p>{contact?.address?.zip}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {contacts.length === 0 && <div className="d-flex justify-content-center align-items-center">No contacts found</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

const ShowfacilityDetails = () => {
  const user = useUser();
  const { company, id: contactRoleId } = user || {};
  const { consultantApplications = [] } = company || {};
  const facilities = new Set();
  const facilityApplications = consultantApplications?.filter((each) => {
    if (facilities.has(each?.facilityCompany?.id)) {
      return false;
    }
    facilities.add(each?.facilityCompany?.id);
    return true;
  });

  return (
    <>
      {facilityApplications.map((facilityApplication) => {
        const { facilityCompany = {} } = facilityApplication || {};
        if (!facilityCompany) return null;
        const { id, name } = facilityCompany || {};
        return <FacilityDetailsCard name={name} id={id} contactRoleId={contactRoleId} />;
      })}
    </>
  );
};

const FacilityDetailsCard = ({ name, id, contactRoleId }) => {
  const [ongoingApplication, setOngoingApplication] = useState({});
  const [agreementApplication, setAgreementApplication] = useState({});
  const fetchApplicationscount = async (id, contactRoleId) => {
    const ongoingApplication = await getApplicationsCount(contactRoleId, id);
    const agreementApplication = await getAgreementsCount(contactRoleId, id);
    setOngoingApplication({ total: ongoingApplication.totalCount, total_application: ongoingApplication.total_applications });
    setAgreementApplication({ total_agreement: agreementApplication.total_agreements });
  };

  useEffect(() => {
    fetchApplicationscount(id, contactRoleId);
  }, [id, contactRoleId]);
  return (
    <div className="card  position-relative">
      <div className="card-body text-center">
        <div className="row">
          <div className="col-md-3">
            <label className="text-muted d-block form-label">Company Name</label>
            <p>{name}</p>
          </div>
          <div className="col-md-3 ">
            <label className="text-muted d-block form-label">Ongoing Applications</label>
            <p className="">{ongoingApplication?.total_application}</p>
          </div>
          <div className="col-md-3 ">
            <label className="text-muted d-block form-label">Agreements</label>
            <p className="">{agreementApplication?.total_agreement}</p>
          </div>
          <div className="col-md-3 ">
            <label className="text-muted d-block form-label">Total Application</label>
            <p className="">{ongoingApplication?.total}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
