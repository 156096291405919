import DashBoardLoading from "../components/loaders/DashBoardLoading";
import { ApplicationSignature } from "../components/webhooks/ApplicationSignature";
import { useApplication } from "../hooks/useApplication";
import { useUser } from "../hooks/useUser";

const Page = () => {
  const application = useApplication();
  const user = useUser();
  if (!application || !user) {
    return <DashBoardLoading />;
  }
  return <ApplicationSignature />;
};

export default Page;
