import React from "react";
import { Checkbox, Divider } from "antd";
import "./MultiFileUpload.css";
const CheckboxGroup = Checkbox.Group;

const defaultCheckedList = ["Apple", "Orange"];

const DummyCheckBox = ({
  label = "",
  type = "checkbox",
  name = "",
  options = [],
  css = {},
  register,
  errors,
  validation,
  handleChange,
  id,
  styleInner,
  isChecked = false,
  checkBoxPosition = "",
  // linkAction="",
  // linkContext="",
  // subLable="",
  hr = false,
  style = "",
  checkBoxStyle = {},
  additonalComments = "",
  additonalLabel = "",
  condtionalLabel = "",
  checkboxGroup = false,
}) => {
  const [checkedList, setCheckedList] = React.useState([]);
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);

  const onChange = (list) => {
    //  console.log(list);
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? options.map((val) => val.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  register(name, { value: checkedList });
  //console.log(checkedList);
  return (
    <>
      <div>
        <h3>{label}</h3>{" "}
      </div>
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
        Check all
      </Checkbox>
      <Divider />
      <CheckboxGroup className="ant-checkbox-wrapper" options={options} value={checkedList} rules={[{ required: true, message: "Please input your name" }]} onChange={onChange} />

      <span className="text-danger">{checkedList.length === 0 && <span>Select a value for {name}</span>}</span>
    </>
  );
};
export default DummyCheckBox;
