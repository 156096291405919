import axios from "axios";
import { API_URL } from ".";

function formDataUpload(data) {
  return axios.post(`${API_URL}/formsAPI/application`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
      // "content-type": "multipart/form-data",
    },
  });
}

export { formDataUpload };
