import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function Checkbox({ label = "", name = "", disabled = false, value = false, className = "", tooltip = false, tooltipdescription = "", onChange = () => {}, ...options }) {
  return (
    <div className={`form-check ${className}`}>
      <input
        type="checkbox"
        name={name}
        id={name}
        className={`form-check-input`}
        style={{ cursor: "pointer" }}
        disabled={disabled}
        checked={value}
        value={value}
        onChange={(e) => onChange(e.target.checked)}
        {...options}
      />
      <label className="form-check-label pe-auto" style={{ cursor: "pointer" }} htmlFor={name}>
        {label}
      </label>
      {tooltip && <ToolTip tooltipdescription={tooltipdescription} />}
    </div>
  );
}

const ToolTip = ({ tooltipdescription = "" }) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltipdescription}
    </Tooltip>
  );
  return (
    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
      <span className="ps-1">
        <i className="fas fa-question-circle text-dark ps-1"></i>
      </span>
    </OverlayTrigger>
  );
};

export default ToolTip;
