import React from "react";
import formateDate from "../../utils/formatedate";

const date = ({ data, handleNetSuiteChange, recordName, col, value }) => {
  let disablevalue = data.disabled || data.validateFinalHideField || data.validateHideField ? data.disabled || data.validateFinalHideField || data.validateHideField : false;

  return (
    <>
      {data.type === "date" && (
        <div className="mb-3">
          <label forhtml={data.name} className="text-muted form-label">
            {data.label}
          </label>

          <input
            type={data.type}
            className="form-control"
            name={data.name}
            id={data.name}
            onChange={(e) => handleNetSuiteChange(data, e, recordName, col)}
            value={formateDate(value)}
            disabled={disablevalue}
          />
        </div>
      )}
    </>
  );
};

export default date;
