import ApplicantViewComponent from "../components/applicantView/ApplicantView";
import ApplicantViewLoading from "../components/loaders/ApplicantViewLoading";
import { useApplication } from "../hooks/useApplication";
import { useUser } from "../hooks/useUser";

const Page = (props) => {
  const application = useApplication();
  const user = useUser();
  if (!application || !user) {
    return <ApplicantViewLoading />;
  }
  return <ApplicantViewComponent {...props} />;
};

export default Page;
