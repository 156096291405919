import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { useAtoms } from "../../hooks/useAtoms";

function Rejected(props) {
  let params = useParams();
  const { setNavigation, setHideNavigation } = useAtoms();
  setHideNavigation(false);
  setNavigation(`Application Review ${" "} ${props.applicationData.ApplicationTitle && props.applicationData.ApplicationTitle} `);

  return (
    <div className="">
      <div className="border-bottom">
        <div className="container"></div>
        <div className="container">
          <div className="">
            <ul className="steps">
              <li className="step step--complete step--active">
                <Link to={`/applicantView/${params.applicationID}`}>
                  <span className="step__icon">
                    <i className="fal fa-check"></i>
                  </span>
                  <a className="step__label">Applicant Review</a>
                </Link>
              </li>
              <li className="step step--incomplete step--active error-active">
                <span className="step__icon">
                  <i className="fal fa-times"></i>
                </span>
                <a href="engineering-review.html" className="step__label">
                  Rejected
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="card">
              <div className="card-header">
                <h2 className="mb-0">Reason of Rejection</h2>
              </div>
              <div className="card-body">
                <p>{props.applicationData.reason}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <FormFilledStatic
        formSchema={formSchema}
        applicationData={props.applicationData}
        applicationID={params.applicationID}
      /> */}
    </div>
  );
}

export default Rejected;
