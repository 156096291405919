import React, { Component } from "react";
import { notify_error } from "../api/error";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      customer: null,
      url: null,
      notified: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    const { customer } = this.props;
    this.setState({
      ...this.state,
      hasError: true,
      error: error,
      customer: customer,
      url: window.location.href,
    });
  }

  handleLogin() {
    localStorage.clear();
    window.location = "/login";
  }

  async notify_development_team() {
    const { url, customer, error } = this.state;
    const data = {
      url,
      customer,
      error,
    };
    try {
      await notify_error(data);
      this.setState({ ...this.state, notified: true });
      localStorage.clear();
      window.location = "/login";
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div className="container-fluid" style={{ height: "100vh" }}>
            <div className="h-100 d-flex flex-column justify-content-center">
              <div className="card position-relative">
                <div className="card-header bg-transparent">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Utility Application</label>
                        <p>#AP123458</p>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Application Type</label>
                        <p>New Wire/Conduit/Cable Occupancy Application</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Status</label>
                        <div className="d-flex">
                          <p className="pe-3">Engineering review</p>
                          <p className="border-start ps-3">13 Open comments</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-header bg-transparent">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Utility Application</label>
                        <p>#AP123458</p>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Application Type</label>
                        <p>New Wire/Conduit/Cable Occupancy Application</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Status</label>
                        <div className="d-flex">
                          <p className="pe-3">Engineering review</p>
                          <p className="border-start ps-3">13 Open comments</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-header bg-transparent">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Utility Application</label>
                        <p>#AP123458</p>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Application Type</label>
                        <p>New Wire/Conduit/Cable Occupancy Application</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Status</label>
                        <div className="d-flex">
                          <p className="pe-3">Engineering review</p>
                          <p className="border-start ps-3">13 Open comments</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-header bg-transparent">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Utility Application</label>
                        <p>#AP123458</p>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Application Type</label>
                        <p>New Wire/Conduit/Cable Occupancy Application</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Status</label>
                        <div className="d-flex">
                          <p className="pe-3">Engineering review</p>
                          <p className="border-start ps-3">13 Open comments</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-header bg-transparent">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Utility Application</label>
                        <p>#AP123458</p>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Application Type</label>
                        <p>New Wire/Conduit/Cable Occupancy Application</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Status</label>
                        <div className="d-flex">
                          <p className="pe-3">Engineering review</p>
                          <p className="border-start ps-3">13 Open comments</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-header bg-transparent">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Utility Application</label>
                        <p>#AP123458</p>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Application Type</label>
                        <p>New Wire/Conduit/Cable Occupancy Application</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Status</label>
                        <div className="d-flex">
                          <p className="pe-3">Engineering review</p>
                          <p className="border-start ps-3">13 Open comments</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-header bg-transparent">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Utility Application</label>
                        <p>#AP123458</p>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Application Type</label>
                        <p>New Wire/Conduit/Cable Occupancy Application</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Status</label>
                        <div className="d-flex">
                          <p className="pe-3">Engineering review</p>
                          <p className="border-start ps-3">13 Open comments</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-header bg-transparent">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Utility Application</label>
                        <p>#AP123458</p>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Application Type</label>
                        <p>New Wire/Conduit/Cable Occupancy Application</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="loading">
                        <div className="line"></div>
                        <label className="text-muted d-block">Status</label>
                        <div className="d-flex">
                          <p className="pe-3">Engineering review</p>
                          <p className="border-start ps-3">13 Open comments</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "block" }}>
              <div className="modal-dialog modal-dialog-centered">
                <div
                  className="modal-content bg-white "
                  style={{
                    boxShadow: " 0 3px 5px 0 rgba(0, 0, 0, 0.5)",
                    width: "36em",
                  }}
                >
                  <div className="modal-body text-center ">
                    <div className="text-center pt-2 mb-2 pb-0" style={{ cursor: "default" }}>
                      <span className="steps-1 mx-auto">
                        <i className="fa fa-exclamation-triangle fa-4x text-danger" aria-hidden="true"></i>
                      </span>
                      <br />
                    </div>
                    <h2 style={{ color: "#360066" }}>Oops! An Error Occurred</h2>
                    <p className="text-muted mb-0 ps-2 pe-2">
                      We're sorry, but it seems like something went wrong on our end. Please take a moment to send a report to our development team so that we can better understand the problem and
                      provide a quick resolution. Click 'Send Report' to automatically send the necessary information.
                    </p>
                  </div>
                  <div className="modal-footer d-flex justify-content-between border-top-0 pb-3">
                    <button type="button" className="btn border" data-bs-dismiss="modal" onClick={() => this.handleLogin()}>
                      Dismiss
                    </button>

                    <button type="button" className="btn btn-primary px-3 py-2 fs-7" onClick={() => this.notify_development_team(this.state)}>
                      Send Report
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
