import React, { useCallback, useEffect, useState } from "react";
import { Model, ModelBody, ModelFooter } from "../../models/Model";
import { State } from "../../data/StateValues";
import ToolTip from "../../ui/ToolTip";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { createContact, getAssignedContacts, getCompanyById, getContactsByCompanyId } from "../../../api/crm";
import { useUser } from "../../../hooks/useUser";
import { setMyRole } from "../../../utils/setMyRole";
import toast from "react-hot-toast";
import GetmyRole from "../../../utils/GetmyRole";

const initialValue = {
  companyId: "",
  name: "",
  jobTitle: "",
  email: "",
  phone: "",
  role: [],
  default: false,
  address: {
    address: "",
    city: "",
    state: "North Carolina",
    zip: "",
    poBox: "",
    country: "United States",
  },
};

const initialError = {
  companyId: "",
  name: "",
  jobTitle: "",
  email: "",
  phone: "",
  role: "",
  default: "",
  address: {
    address: "",
    city: "",
    state: "",
    zip: "",
    poBox: "",
    country: "",
  },
};

const facilityRoles = ["Primary Representative", "Billing Representative"];

const consultantRoles = ["Engineering Representative"];

export const AddContactForm = ({ getContact, showModal, setShowModal, closeModal = () => {}, ID }) => {
  const { companyId, applicationID } = useParams();

  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(initialValue);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [error, setError] = useState(initialError);
  const [roles, setRoles] = useState([]);
  const [companyID, setCompanyID] = useState(companyId);
  const [company, setCompany] = useState({});
  const [isEmailAdded, setIsEmailAdded] = useState(false);
  const { email } = GetmyRole() || {};
  const user = useUser();

  useEffect(() => {
    if (!companyId) {
      getAssignedContacts(applicationID).then((res) => {
        if (res.data.status) {
          const { facilityCompany } = res.data.data;
          setCompanyID(facilityCompany.id);
        }
      });
    }
  }, [companyId, applicationID]);

  const getContacts = async (companyID, email, user, company) => {
    const res = await getContactsByCompanyId(companyID);
    if (res.data.status) {
      const contacts = res.data.data;
      const isExist = contacts.find((each) => each.email === email);
      setContacts(contacts);

      if (isExist) {
        setIsEmailAdded(true);
        setForm((initialForm) => ({ ...initialForm, email: "" }));
        return;
      }

      if (user?.role?.roleType === "CONSULTANT" && company?.companyType === "FACILITY") {
        setIsEmailAdded(true);
        setForm((initialForm) => ({ ...initialForm, email: "" }));
        return;
      }

      setForm((initialForm) => ({ ...initialForm, email: email }));
      setIsEmailAdded(false);
    }
  };

  useEffect(() => {
    getContacts(companyID, email, user, company);
  }, [companyID, email, user, company]);

  const validateZipCode = (zip) => {
    // Example regex for US ZIP code validation
    return /^\d{5}(-\d{4})?$/.test(zip);
  };

  const validatePhone = (phone) => {
    // Example regex for US phone number validation
    return /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/.test(phone);
  };

  const validateEmail = (email) => {
    // Simple email validation regex
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleChange = (e) => {
    if (Array.isArray(e)) {
      const name = "role";
      setSelectedRoles(e);
      const roles = e.map((role) => role.value);
      setForm((initialForm) => ({
        ...initialForm,
        [name]: roles,
      }));
      checkError();
    } else {
      const { type, name, value } = e.target;
      const types = ["text", "select-one", "radio"];
      const mailType = ["address"];

      if (mailType.find((each) => name.startsWith(each))) {
        const mailType = name.split(".")[0];
        const mailingName = name.split(".")[1];
        if (types.includes(type)) {
          setForm((initialForm) => ({
            ...initialForm,
            [mailType]: {
              ...initialForm[mailType],
              [mailingName]: value,
            },
          }));
        }
        checkError();
        return;
      }

      if (types.includes(type)) {
        setForm((initialForm) => ({
          ...initialForm,
          [name]: value,
        }));
        checkError();
      }

      if (type === "checkbox") {
        setForm((initialForm) => ({
          ...initialForm,
          [name]: !initialForm[name],
        }));
        checkError();
      }
    }
  };

  const handleError = (error) => {
    setError({ ...initialError, ...error });
  };

  const checkError = () => {
    let errors = {};

    if (!form.name) {
      errors.name = "*Required";
    }
    if (!form.jobTitle) {
      errors.jobTitle = "*Required";
    }
    if (!form.email) {
      errors.email = "*Required";
    } else if (!validateEmail(form.email)) {
      errors.email = "*Invalid email format";
    }
    if (!form.phone) {
      errors.phone = "*Required";
    } else if (!validatePhone(form.phone)) {
      errors.phone = "*Invalid phone number";
    }
    if (selectedRoles.length === 0) {
      errors.role = "*Required";
    }
    if (!form.address.address) {
      errors.address = { ...errors.address, address: "*Required" };
    }
    if (!form.address.city) {
      errors.address = { ...errors.address, city: "*Required" };
    }
    if (!form.address.zip) {
      errors.address = { ...errors.address, zip: "*Required" };
    } else if (!validateZipCode(form.address.zip)) {
      errors.address = { ...errors.address, zip: "*Invalid Zip code" };
    }

    if (Object.keys(errors).length > 0) {
      handleError(errors);
      return false;
    }

    setError(initialError);
    return true;
  };

  const checkCompanyType = async (companyID) => {
    const res = await getCompanyById(companyID);
    if (res.data.status) {
      const company = res.data.data;
      setCompany(company);
      const result = [];
      if (company?.companyType === "FACILITY") {
        facilityRoles.forEach((each, index) => {
          result[index] = {
            value: each,
            label: each,
          };
        });
      }
      if (company?.companyType === "CONSULTANT") {
        setSelectedRoles([{ value: consultantRoles[0], label: consultantRoles[0] }]);
        setForm((initialValue) => ({ ...initialValue, role: [consultantRoles[0]] }));
        consultantRoles.forEach((each, index) => {
          result[index] = {
            value: each,
            label: each,
          };
        });
      }
      setRoles(result);
    }
  };

  useEffect(() => {
    checkCompanyType(companyID);
  }, [companyID]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(initialError);

    const result = checkError();

    try {
      if (result) {
        const isContactExist = contacts.find((each) => each.email === email);
        const data = {
          ...form,
          companyId: companyID,
        };
        //create a contact
        const response = await createContact(data);
        if (response.data.status) {
          await getContact(companyID);
          setIsEmailAdded(true);
          setForm(initialValue);
          toast.success("Added Successfully!");
          if (response.data.customer) {
            setMyRole(response.data.customer);
          }
          closeModal();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  return (
    <div onKeyDown={handleKeyPress}>
      {showModal && ID === "new-contact" && (
        <Model
          heading={`New Contact for ${company?.name}`}
          discription=" Please enter contact details of other people who work for your Facility Owner and will be involved with Application appropvals."
          id="new-contact"
          showModal={showModal}
          setShowModal={setShowModal}
          closeModal={closeModal}
        >
          <form onSubmit={handleSubmit}>
            <ModelBody>
              <div className="card border-0 inner-form">
                <div className="row">
                  <div className="col">
                    <div className="mt-3 position-relative">
                      <label className="form-label d-flex justify-content-between">
                        Name
                        <small className="text-danger ms-1">{error.name}</small>
                      </label>
                      <input className="form-control" type="text" id="name" name="name" disabled={loading} onChange={handleChange} value={form.name} />
                    </div>
                  </div>
                  <div className="col">
                    <div className="mt-3">
                      <label className="form-label d-flex justify-content-between">
                        Job Title
                        <small className="text-danger ms-1">{error.jobTitle}</small>
                      </label>
                      <input className="form-control" type="text" id="jobTitle" name="jobTitle" disabled={loading} onChange={handleChange} value={form.jobTitle} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="mt-3">
                      <label className="form-label d-flex justify-content-between">
                        Email
                        <small className="text-danger ms-1">{error.email}</small>
                      </label>
                      <input className="form-control" type="text" id="email" name="email" disabled={!isEmailAdded || loading} onChange={handleChange} value={form.email} />
                    </div>
                  </div>
                  <div className="col">
                    <div className="mt-3">
                      <label className="form-label d-flex justify-content-between">
                        Phone
                        <small className="text-danger ms-1">{error.phone}</small>
                      </label>
                      <input className="form-control" type="text" id="phone" name="phone" disabled={loading} onChange={handleChange} value={form.phone} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mt-3">
                      <label className="form-label d-flex justify-content-between">
                        <ToolTip data={"Role"} text="Role" />
                        <small className="text-danger ms-1">{error.role}</small>
                      </label>
                      <Select placeholder="Select Roles" required={true} isMulti isClearable onChange={handleChange} value={selectedRoles} options={roles} />
                    </div>
                  </div>
                </div>
                {/* <div className="form-check mt-3">
                <input
                  type="checkbox"
                  name="default"
                  id="default"
                  className="form-check-input"
                  checked={form.default}
                  onChange={handleChange}
                  disabled={loading}
                />
                <label class="form-check-label" for="default">
                  Make default
                </label>
              </div> */}
                <div className="row">
                  <h3 className="mt-4 mb-0">Address</h3>
                  <div className="col">
                    <div className="mt-3">
                      <label className="form-label d-flex justify-content-between">
                        Street Address
                        <small className="text-danger ms-1">{error?.address?.address}</small>
                      </label>
                      <input className="form-control" type="text" id="address.address" name="address.address" disabled={loading} onChange={handleChange} value={form.address.address} />
                    </div>
                  </div>
                  <div className="col">
                    <div className="mt-3">
                      <label className="form-label d-flex justify-content-between">
                        City
                        <small className="text-danger ms-1">{error?.address?.city}</small>
                      </label>
                      <input className="form-control" type="text" id="address.city" name="address.city" onChange={handleChange} value={form.address.city} disabled={loading} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="mt-3">
                      <label className="form-label d-flex justify-content-between">State</label>
                      <select className="form-select  mb-3" name="address.state" id="address.state" onChange={handleChange} value={form.address.state} disabled={loading}>
                        {State.map((name, index) => (
                          <option value={name} key={index}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col">
                    <div className="mt-3">
                      <label className="form-label d-flex justify-content-between">
                        Zip
                        <small className="text-danger ms-1">{error?.address?.zip}</small>
                      </label>
                      <input className="form-control" type="text" id="address.zip" name="address.zip" onChange={handleChange} value={form.address.zip} disabled={loading} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="">
                      <label className="form-label d-flex justify-content-between">Country</label>
                      <select class="form-select mb-3" onChange={handleChange} value={form.address.country} disabled={loading}>
                        <option value="US">United States</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </ModelBody>
            <ModelFooter>
              <button type="button" className="btn btn-secondary btn-lg" onClick={() => closeModal()}>
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary btn-lg"
                onClick={(e) => handleSubmit(e)}
                // data-bs-dismiss="modal"
              >
                Save
              </button>
            </ModelFooter>
          </form>
        </Model>
      )}
    </div>
  );
};
