const markup = [
  {
    id: "Mecklenburg",
    lines: [
      {
        line: "Mainline",
        min: 365,
        max: 376,
      },
    ],
  },
  {
    id: "Cabarrus",
    lines: [
      {
        line: "Mainline",
        min: 348,
        max: 365,
      },
    ],
  },
  {
    id: "Rowan",
    lines: [
      {
        line: "Mainline",
        min: 328,
        max: 348,
      },
    ],
  },
  {
    id: "Davidson",
    lines: [
      {
        line: "Mainline",
        min: 303,
        max: 328,
      },
    ],
  },
  {
    id: "Randolph",
    lines: [
      {
        line: "Mainline",
        min: 302,
        max: 303,
      },
    ],
  },
  {
    id: "Guilford",
    lines: [
      {
        line: "Mainline",
        min: 284,
        max: 302,
      },
      {
        line: "H-Line",
        min: 0,
        max: 15,
      },
    ],
  },
  {
    id: "Alamance",
    lines: [
      {
        line: "H-Line",
        min: 15,
        max: 32,
      },
    ],
  },
  {
    id: "Orange",
    lines: [
      {
        line: "H-Line",
        min: 32,
        max: 49,
      },
    ],
  },
  {
    id: "Durham",
    lines: [
      {
        line: "H-Line",
        min: 49,
        max: 65,
      },
    ],
  },
  {
    id: "Wake",
    lines: [
      {
        line: "H-Line",
        min: 65,
        max: 93,
      },
    ],
  },
  {
    id: "Johnston",
    lines: [
      {
        line: "H-Line",
        min: 93,
        max: 119,
      },
    ],
  },
  {
    id: "Wayne",
    lines: [
      {
        line: "H-Line",
        min: 119,
        max: 130,
      },
      {
        line: "EC-Line",
        min: 0,
        max: 11,
      },
    ],
  },
  {
    id: "Lenoir",
    lines: [
      {
        line: "EC-Line",
        min: 11,
        max: 30,
      },
    ],
  },
  {
    id: "Jones",
    lines: [
      {
        line: "EC-Line",
        min: 30,
        max: 34,
      },
    ],
  },
  {
    id: "Craven",
    lines: [
      {
        line: "EC-Line",
        min: 34,
        max: 79,
      },
    ],
  },
  {
    id: "Carteret",
    lines: [
      {
        line: "EC-Line",
        min: 79,
        max: 94,
      },
    ],
  },
];
export default markup;
