import React, { useState } from "react";
import { useHistory } from "react-router";
import CompanyDetails from "../ui/CompanyDetails";
import { createConsultantCompany, getCompanyByName } from "../../api/crm";
import GetmyRole from "../../utils/GetmyRole";
import { setCustomerId } from "../../hooks/useRole";
const initialValue = {
  name: "",
  createdBy: "",
};

const initialError = {
  name: "",
};

export const ConsultantForm = () => {
  const [form, setForm] = useState(initialValue);
  const [error, setError] = useState(initialError);
  const [loading, setLoading] = useState(false);
  const [isCompanyExist, setIsCompanyExist] = useState(false);

  const history = useHistory();

  const handleError = (error) => {
    setError({ ...initialError, ...error });
  };

  const checkError = () => {
    if (!form.name) {
      handleError({ name: "Consultant Company Name is Required!" });
      return;
    }
    setError(initialError);
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(initialError);
    const result = checkError();

    try {
      if (result) {
        const { email } = GetmyRole();
        if (isCompanyExist) {
          form["createdBy"] = email;
          const data = { ...form, createdBy: email };
          const response = await createConsultantCompany(data);

          if (response.data.status) {
            const company = response.data.data;
            setCustomerId(company.id);
            localStorage.setItem("facilityId", "ALL");
            history.push(`/contacts/${company.id}`);
          }
          return;
        }

        const res = await getCompanyByName(form.name, "CONSULTANT");

        if (res.data.status) {
          const companies = res.data.data;
          const formName = form.name.trim().toLowerCase();

          const data = form;
          const isCompanyExist = companies.find(({ name }) => name.toLowerCase() === formName);

          if (isCompanyExist && isCompanyExist.companyType === "CONSULTANT") {
            const isConfirmed = window.confirm("A company with the same name already exists. Would you like to join this existing company?");

            if (!isConfirmed) {
              return;
            }

            data["id"] = isCompanyExist.id;
            data["createdBy"] = email;
          } else {
            delete data["id"];
          }

          data["createdBy"] = email;
          const response = await createConsultantCompany(data);

          if (response.data.status) {
            const company = response.data.data;
            setCustomerId(company.id);
            localStorage.setItem("facilityId", "ALL");
            history.push(`/contacts/${company.id}`);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="inner-form card mb-4 mt-4">
      <form onSubmit={handleSubmit} className="py-4 px-2">
        <div className=" border-bottom card-header">
          <h2>Engineering Representative for Facility Owner Details</h2>
          <p className="text-dark mb-0">
            <i className="far fa-hand-point-right"></i> Following information creates your profile in the NCRR portal and this information is used with all utility applications in the future.
          </p>
        </div>
        <CompanyDetails
          form={form}
          setForm={setForm}
          setIsCompanyExist={setIsCompanyExist}
          disabled={loading}
          error={error}
          type={"CONSULTANT"}
          label={"Engineering Representative Facility Owner Name"}
          Heading={"Engineering Representative for Facility Owner Details"}
        />
        <div className="btn-col text-center d-flex justify-content-end px-4">
          <button type="submit" className="btn btn-primary btn-lg">
            {loading ? (
              <>
                <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                Submitting...{" "}
              </>
            ) : (
              "Save and Proceed"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
