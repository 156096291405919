import { ConsultantForm } from "./Form";

export const ConsultantSignup = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <div className="container">
        <div className="w-75 m-auto">
          <ConsultantForm />
        </div>
      </div>
    </div>
  );
};
