import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import queryString from "query-string";

import { FormOnAFly } from "../../../forms/FormOnAFly";
import { formContext } from "../../../routes/routes";
import { formSchemaPresent, getFormData, putNextStatus, getcurrentStatus } from "../../../api/currentApplications";
import { formDataUpload } from "../../../api/formsAPi";
import { PipeSchema } from "../../../schema/NewPipeSchema";
import { useParams } from "react-router";

export default function Form220(props) {
  let params = useParams();
  const [editPage, setEditPage] = useState(false);
  const [checks, SetCheck] = useState(0);
  const history = useHistory();
  const [schema, setSchema] = useState((JSON.parse(localStorage.getItem(props.id + "schema")) && JSON.parse(localStorage.getItem(props.id + "schema"))) || PipeSchema);
  const [indication, setIndication] = useState(false);
  const [radioKey, setRadioKey] = useState([]);
  const [checkBoxKey, setCheckBoxKey] = useState([]);
  const [steps, setStep] = useState(0);
  const [hide, setHide] = useState(true);
  const [schemadata, setSchemadata] = useState();
  let localStorageSchema = localStorage.getItem(props.id + "schema");
  if (localStorageSchema === null && !localStorage.getItem(props.id + "saved")) {
    localStorage.setItem(props.id + "schemaNeedsToUpdate", true);
  }

  useEffect(() => {
    const getFormDataAndCustomerDetails = async () => {
      try {
        let formDataResponse = await getFormData(props.id);

        if (formDataResponse.data.applicationData) {
          localStorage.setItem(props.id, JSON.stringify(formDataResponse.data.applicationData));
        }
      } catch (error) {
        console.error("Error fetching form data and customer details:", error);
      }
    };

    getFormDataAndCustomerDetails();
  }, []);
  let sectionArray = schema.sections.map((section, inde) => section);
  let array = [];

  sectionArray.map((val, ind) => {
    if (val.label === "Applicant's Checklist") {
      val.questions.map((row) => {
        row.rows.map((col) =>
          col.columns.map((value) => {
            if (value.validation) {
              let check = value.options.filter((option) => option.isChecked);

              array.push(check.length);
            }
          }),
        );
      });
    }
  });
  let v = 0;
  let qs = queryString.parse(window.location.search);
  useEffect(() => {
    const getCurrentStatus = async () => {
      let newRes = await getcurrentStatus(params.applicationID);
      SetCheck(newRes.data.data.currentStep);
    };
    getCurrentStatus();
  }, []);
  useEffect(() => {
    formSchemaPresent(params.applicationID)
      .then((data) => {
        if (data.data.present) {
          const newSchemas = JSON.parse(data?.data?.schema);
          setSchemadata(JSON.parse(newSchemas));
        }
      })
      .catch((err) => console.log(err));
  }, [params.applicationID, steps]);
  useEffect(() => {
    formSchemaPresent(params.applicationID)
      .then((data) => {
        if (data.data.present && checks >= 3) {
          const newSchema = data.data.schema;
          if (newSchema) {
            setEditPage(true);
          } else {
            setEditPage(false);
          }

          if (!qs.stopReload) {
            window.location = `/form220/${params.applicationID}?stopReload=1`;
            localStorage.setItem(props.id + "schema", JSON.parse(newSchema));
          }
        } else {
          if (localStorage.getItem(props.id + "schemaNeedsToUpdate")) {
            if (data?.data?.schema) {
              localStorage.setItem(props.id + "schema", JSON.parse(data?.data?.schema));
              localStorage.removeItem(props.id + "schemaNeedsToUpdate");
              window.location.reload(true);
            } else {
              localStorage.setItem(props.id + "schema", JSON.stringify(schema));
              localStorage.removeItem(props.id + "schemaNeedsToUpdate");
            }
          } else {
            localStorage.setItem(props.id + "schema", JSON.stringify(schema));
          }
        }
      })
      .catch((err) => console.log(err));
  });
  let filedata = schemadata?.sections;
  var arrSchema = [];
  if (filedata) {
    for (let i = 0; i < filedata.length; i++) {
      let fileData = filedata[i];
      let datafile = fileData.questions;
      if (datafile) {
        for (let i = 0; i < datafile.length; i++) {
          let dataFile = datafile[i];
          if (dataFile.hide && dataFile.id && fileData.label != "Applicant's Checklist") {
            arrSchema.push({
              FileLable: fileData.label,
              SectionId: dataFile.id,
              Hide: dataFile.hide,
            });
          }
        }
      }
    }
  }
  const handleFormSubmit = async (data, index, lastIndex) => {
    setStep(index + 1);

    let values = JSON.parse(localStorage.getItem(props.id));

    if (values) {
      if (
        data["RailRoad line transverse"] === undefined ||
        data["RailRoad line Begin longitudinal"] === undefined ||
        data["RailRoad line End longitudinal"] === undefined ||
        data["RailRoad line Begin longitudinal + transvere"] === undefined ||
        data["RailRoad line End longitudinal + transvere"] === undefined
      ) {
        data["RailRoad line transverse"] = values["RailRoad line transverse"];
        data["RailRoad line Begin longitudinal"] = values["RailRoad line Begin longitudinal"];
        data["RailRoad line End longitudinal"] = values["RailRoad line End longitudinal"];
        data["RailRoad line Begin longitudinal + transvere"] = values["RailRoad line Begin longitudinal + transvere"];
        data["RailRoad line End longitudinal + transvere"] = values["RailRoad line End longitudinal + transvere"];
      }
      const res = await getFormData(props.id);
      const formApplicationData = res.data.applicationData;
      values = { ...formApplicationData, ...values, ...data };
      localStorage.setItem(props.id, JSON.stringify(values));
    } else {
      localStorage.setItem(props.id, JSON.stringify(data));
    }

    const formData = new FormData();
    formData.append("applicationID", props.id);
    formData.append("lastModifiedOn", localStorage.getItem(`${props.id}lastModifiedOn`));
    formData.append(props.id, JSON.stringify(values));

    if (index + 1 === lastIndex) {
      formData.append("finalStep", 1);
    }

    let multiFile = values?.["design_and_construction_plans"];
    let specFile = values?.["Upload specifications and computations for the proposed occupancy"];

    if (multiFile) {
      multiFile.forEach((fileData, i) => {
        formData.append("design_and_construction_plans[" + i + "]", fileData);
      });
    }

    var fileCount = 0;
    if (specFile) {
      fileCount = multiFile.length == null ? 0 : multiFile.length;
      specFile.forEach((file) => {
        formData.append("design_and_construction_plans[" + fileCount + "]", file);
      });
    }

    let check = false;

    if (values?.["Total Length of Occupancy within the NCRR Corridor"]) {
      let total = Number(values?.["Total aerial length on the NCRR Corridor"]) + Number(values?.["Total buried length on the NCRR Corridor"]);

      if (total > values?.["Total Length of Occupancy within the NCRR Corridor"]) {
        check = true;
      }
    }

    formData.append("schema", JSON.stringify(localStorage.getItem(props?.id + "schema")));
    if (!check) {
      if (index + 1 === lastIndex) {
        setIndication(true);
      }
      try {
        let value = values?.["Orientation of proposed pipeline installation"];
        if (value === "Transverse crossing only") {
          let subV = values?.["Railroad Milestone MP"] + "+" + values?.["Railroad Mailstone Feet"];
          let Char = values?.["RailRoad line transverse"];
          let subs = Char?.charAt(0)?.toLowerCase();
          formData.append("ApplicationTitle", `o_${subs}-${subV}`);
        }
        if (value === "Longitudinal parallel to tracks only") {
          let subV = values?.["Begin at Railroad Milepost parallel to tracks Mp"] + "+" + values?.["Begin at Railroad Milepost parallel to tracks Feet"];
          let Char = values?.["RailRoad line Begin longitudinal"];
          let subs = Char?.charAt(0)?.toLowerCase();
          formData.append("ApplicationTitle", `o_${subs}-${subV}`);
        }
        if (value === "Longitudinal and transverse crossing") {
          let subV = values?.["Begin at Railroad Milepost transverse crossing Mp"] + "+" + values?.["Begin at Railroad Milepost transverse crossing Feet"];
          let Char = values?.["RailRoad line Begin longitudinal + transvere"];
          let subs = Char?.charAt(0)?.toLowerCase();
          formData.append("ApplicationTitle", `o_${subs}-${subV}`);
        }
      } catch (error) {
        console.log(error, "Error");
      }
      console.log("Submitting");
      formDataUpload(formData)
        .then((data) => {
          localStorage.setItem(`${props.id}lastModifiedOn`, data.data.responseData.lastModifiedOn);
          if (!editPage) {
            let applicationID = data.data.responseData._id ? data.data.responseData._id : params.applicationID ? params.applicationID : props.id ? props.id : "";

            if (index + 1 === lastIndex) {
              if (applicationID) {
                putNextStatus(applicationID, 2).then((vv) => {
                  localStorage.removeItem(props.id);
                  localStorage.removeItem(props.id + "schema");
                  localStorage.removeItem(props.id + "lastModifiedOn");
                  localStorage.setItem(props.id + "saved", true);
                  window.location = `${process.env.REACT_APP_URL}/dashboard`;
                });
              }
            }
          } else {
            if (index + 1 === lastIndex) {
              localStorage.removeItem(props.id);
              localStorage.removeItem(`${props.id + "schema"}`);
              localStorage.removeItem(props.id + "lastModifiedOn");
              localStorage.setItem(props.id + "saved", true);
              if (checks === 3) {
                history.push(`/applicationReview/${props.id}`);
              }
              if (checks === 4 || checks === 5 || checks === 6) {
                history.push(`/review/${props.id}`);
              }
            }
          }
        })

        .catch((err) => {
          console.log(err);
        });
    } else {
      // toast.error("Total Length of Occupancy within the NCRR Corridor is not exceed more than Total aerial length on the NCRR Corridor + Total buried length on the NCRR Corridor ");
      alert("Total Length of Occupancy within the NCRR Corridor is not exceed more than Total aerial length on the NCRR Corridor + Total buried length on the NCRR Corridor ");
    }
  };

  return (
    <div>
      <div className={indication ? "submitting" : null}></div>
      {schema.sections.map((section, index) => (
        <div className="accordion form-steps" id="accordionExample">
          <div className="accordion-item mb-4">
            <div className="card">
              <div className="card-body p-0">
                <h2 className="accordion-header" id="headingOne" onClick={() => (steps > index ? setStep(index) : null)}>
                  <a
                    className={`${
                      steps > index
                        ? "accordion-button collapsed bg-success text-white d-flex justify-content-between py-4 px-5"
                        : "accordion-button collapsed py-4 px-5 d-flex justify-content-between pointer-none"
                    }`}
                  >
                    <span>{section.label}</span>
                    <span className="acc-step">
                      {" "}
                      {steps > index && <i className="fal fa-check"></i>}Step {index + 1} OF {schema.sections.length}{" "}
                    </span>
                  </a>
                </h2>
                <div id="collapseOne" className={`accordion-collapse collapse ${steps === index ? "show" : "hide"}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body px-5">
                    <formContext.Provider
                      value={{
                        schema,
                        setSchema,
                        newArray: [],
                        valuesToStore: [],
                        radioKey: radioKey,
                        checkBoxKey: checkBoxKey,
                        setRadioKey: setRadioKey,
                        setHide: setHide,
                        hide: hide,
                        setCheckBoxKey: setCheckBoxKey,
                      }}
                    >
                      <FormOnAFly
                        submit={(data, index) => handleFormSubmit(data, index, schema.sections.length)}
                        setSchema={setSchema}
                        index={index}
                        hide={hide}
                        indication={indication}
                        lastIndex={schema.sections.length}
                        array={array}
                      >
                        {section}
                      </FormOnAFly>
                    </formContext.Provider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {indication ? (
        <div className="d-flex align-items-center justify-content-center  display-2 submit-loader">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : null}
    </div>
  );
}
