import React from "react";
import { useParams } from "react-router";
export const Tabel = ({
  label = "",
  name = "",
  css = {},
  defaultValue = "",
  placeholder = "",
  validation,
  handleChange = () => {},
  register = () => {},
  errors = "",
  heading = [],
  rowValue = [],
  isCheckboxRequired = false,
}) => {
  const [checkedState, setCheckedState] = React.useState(new Array(rowValue.length).fill(false));

  const handleOnChange = (value, number, e) => {
    const updatedCheckedState = checkedState.map((item, index) => (index === number ? !item : item));
    setCheckedState(updatedCheckedState);
    if (!e.target.checked) {
      document.getElementById(`${rowValue[number].rowData[0].name}`).value = " ";
      document.getElementById(`${rowValue[number].rowData[1].name}`).value = " ";
    }
  };
  let params = useParams();
  let datas = JSON.parse(localStorage.getItem(params.applicationID)) || {};
  return (
    <div className="table-responsive">
      <table className="table table-striped mb-3">
        <thead>
          <tr>
            {heading.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowValue.map((value, index) => (
            <tr key={value.id}>
              <td>
                {value.label}
                {value.label === "other" ? (
                  <>
                    <input
                      className="form-control"
                      type={"text"}
                      defaultValue={datas.other}
                      name={"other"}
                      // disabled={isCheckboxRequired && !checkedState[index]}
                      {...register("other")}
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                    />
                  </>
                ) : null}
              </td>
              {value.rowData.map((data, unikey) => (
                <td key={unikey}>
                  <div className="input-group">
                    <input
                      className="form-control px-2"
                      id={data.name}
                      type={data.type}
                      name={data.name}
                      defaultValue={datas[data.name]}
                      step={isCheckboxRequired ? "0.00001" : null}
                      // disabled={isCheckboxRequired && !checkedState[index]}
                      {...register(data.name)}
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                    />

                    {data.fieldLabelPosition === "right" ? (
                      <div className="input-group-append">
                        <span className="input-group-text bg-secondary  text-dark">{"Feet"}</span>
                      </div>
                    ) : null}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
