export const actionCountyUpdate = (name) => {
  return {
    type: "UPDATE_COUNTY",
    payload: name,
  };
};

export const actionDropdownUpdate = (name) => {
  return {
    type: "UPDATE_DROPDOWN",
    payload: name,
  };
};

export const actionSelectedOperatingSignee = (name) => {
  return {
    type: "UPDATE_SELECTED_OPERATING_SIGNEE",
    payload: name,
  };
};
