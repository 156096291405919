import React, { useEffect, useState } from "react";
import { Model, ModelBody, ModelFooter } from "../../models/Model";
import { State } from "../../data/StateValues";
import ToolTip from "../../ui/ToolTip";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { updateContact, getCompanyById, getContactsByCompanyId } from "../../../api/crm";
import toast from "react-hot-toast";
const initialValue = {
  companyId: "",
  name: "",
  jobTitle: "",
  email: "",
  phone: "",
  role: [],
  default: false,
  address: {
    address: "",
    city: "",
    state: "North Carolina",
    zip: "",
    poBox: "",
    country: "United States",
  },
};

const initialError = {
  companyId: "",
  name: "",
  jobTitle: "",
  email: "",
  phone: "",
  role: "",
  default: "",
  address: {
    address: "",
    city: "",
    state: "",
    zip: "",
    poBox: "",
    country: "",
  },
};

const facilityRoles = [
  "Primary Representative",
  "Billing Representative",
  // "Engineering Representative",
];

const consultantRoles = ["Engineering Representative"];

export const EditContactForm = ({ selectedContact, getContact, showModal, setShowModal, closeModal = () => {}, ID, companyId: company_id, heading }) => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(initialValue);
  const [error, setError] = useState(initialError);
  const [totalRoles, setTotalRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [company, setCompany] = useState({});
  const { companyId = company_id } = useParams();

  const getContacts = async (companyId) => {
    const res = await getContactsByCompanyId(companyId);
    if (res.data.status) {
      const contacts = res.data.data;
      setContacts(contacts);
    }
  };

  const checkCompanyType = async (companyId) => {
    const res = await getCompanyById(companyId);
    if (res.data.status) {
      const company = res.data.data;
      setCompany(company);
    }
  };

  const getRoles = (companyId, selectedContact, company) => {
    const form = selectedContact || initialValue;
    form.role = [];
    const roles = [];
    selectedContact?.companyroles?.forEach((each) => {
      if (each.company.id === companyId) {
        roles.push({
          value: each.role.role,
          label: each.role.role,
        });
        form.role.push(each.role.role);
      }
    });
    setForm(form);
    setRoles(roles);
    if (company?.companyType === "FACILITY") {
      const roles = facilityRoles.map((each) => {
        return {
          value: each,
          label: each,
        };
      });
      setTotalRoles(roles);
    }
    if (company?.companyType === "CONSULTANT") {
      const roles = consultantRoles.map((each) => {
        return {
          value: each,
          label: each,
        };
      });
      setTotalRoles(roles);
    }
  };

  useEffect(() => {
    checkCompanyType(companyId);
  }, [selectedContact, companyId]);

  useEffect(() => {
    getRoles(companyId, selectedContact, company);
  }, [selectedContact, companyId, company]);

  useEffect(() => {
    getContacts(companyId);
  }, [companyId]);

  const validateZipCode = (zip) => {
    // Example regex for US ZIP code validation
    return /^\d{5}(-\d{4})?$/.test(zip);
  };
  const validatePhone = (phone) => {
    // Example regex for US phone number validation
    return /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(phone);
  };

  const validateEmail = (email) => {
    // Simple email validation regex
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleChange = (e) => {
    if (Array.isArray(e)) {
      const name = "role";
      const selectedRoles = Array.isArray(e) ? e.map((x) => x.value) : [];
      setForm((initialForm) => ({
        ...initialForm,
        [name]: selectedRoles,
      }));
      setRoles(e);
    } else {
      const { type, name, value } = e.target;
      const types = ["text", "select-one", "radio"];
      const mailType = ["address"];

      if (mailType.find((each) => name.startsWith(each))) {
        const mailType = name.split(".")[0];
        const mailingName = name.split(".")[1];
        if (types.includes(type)) {
          setForm((initialForm) => ({
            ...initialForm,
            [mailType]: {
              ...initialForm[mailType],
              [mailingName]: value,
            },
          }));
        }
        return;
      }

      if (types.includes(type)) {
        setForm((initialForm) => ({
          ...initialForm,
          [name]: value,
        }));
      }

      if (type === "select-multiple") {
        const selectedRoles = Array.from(e.target.selectedOptions, (option) => option.value);
        setForm((initialForm) => ({
          ...initialForm,
          [name]: selectedRoles,
        }));
      }

      if (type === "checkbox") {
        setForm((initialForm) => ({
          ...initialForm,
          [name]: !initialForm[name],
        }));
      }
    }
  };

  const handleError = (error) => {
    setError({ ...initialError, ...error });
  };

  const checkError = () => {
    let errors = {};

    if (!form.name) {
      errors.name = "*Required";
    }
    if (!form.jobTitle) {
      errors.jobTitle = "*Required";
    }
    if (!form.email) {
      errors.email = "*Required";
    } else if (!validateEmail(form.email)) {
      errors.email = "*Invalid email format";
    }
    if (!form.phone) {
      errors.phone = "*Required";
    } else if (!validatePhone(form.phone)) {
      errors.phone = "*Invalid phone number";
    }
    if (form.role.length === 0) {
      errors.role = "*Required";
    }
    if (!form?.address?.address) {
      errors.address = { ...errors.address, address: "*Required" };
    }
    if (!form?.address?.city) {
      errors.address = { ...errors.address, city: "*Required" };
    }
    if (!form?.address?.zip) {
      errors.address = { ...errors.address, zip: "*Required" };
    } else if (!validateZipCode(form.address.zip)) {
      errors.address = { ...errors.address, zip: "*Invalid Zip code" };
    }

    if (Object.keys(errors).length > 0) {
      handleError(errors);
      return false;
    }

    setError(initialError);
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(initialError);

    const result = checkError();
    try {
      if (result) {
        const data = {
          ...form,
          companyId,
        };
        if (!data?.address?.country) {
          data.address.country = "United States";
        }
        if (!data?.address?.state) {
          data.address.state = "North Carolina";
        }
        const response = await updateContact(data);
        if (response.data.status) {
          await getContact(companyId);
          setForm(initialValue);
          toast.success("Updated Successfully!");
          closeModal();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };
  return (
    <div onKeyDown={handleKeyPress}>
      {showModal && ID === "edit-contact" && (
        <Model
          heading={heading || "Update Contact"}
          discription="Please enter contact
                details of other people who work for your Facility Owner and will be
                involved with Application appropvals."
          id="edit-contact"
          showModal={showModal}
          setShowModal={setShowModal}
          closeModal={closeModal}
        >
          <form onSubmit={handleSubmit}>
            <ModelBody>
              <div className="card border-0 inner-form">
                <div className="row">
                  <div className="col">
                    <div className="mt-3 position-relative">
                      <label className="form-label d-flex justify-content-between">
                        Name
                        <small className="text-danger ms-1">{error.name}</small>
                      </label>
                      <input className="form-control" type="text" id="name" name="name" disabled={loading} onChange={handleChange} value={form.name} />
                    </div>
                  </div>
                  <div className="col">
                    <div className="mt-3">
                      <label className="form-label d-flex justify-content-between">
                        Job Title
                        <small className="text-danger ms-1">{error.jobTitle}</small>
                      </label>
                      <input className="form-control" type="text" id="jobTitle" name="jobTitle" disabled={loading} onChange={handleChange} value={form.jobTitle} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="mt-3">
                      <label className="form-label d-flex justify-content-between">
                        Email
                        <small className="text-danger ms-1">{error.email}</small>
                      </label>
                      <input className="form-control" type="text" id="email" name="email" disabled={true} onChange={handleChange} value={form.email} />
                    </div>
                  </div>
                  <div className="col">
                    <div className="mt-3">
                      <label className="form-label d-flex justify-content-between">
                        Phone
                        <small className="text-danger ms-1">{error.phone}</small>
                      </label>
                      <input className="form-control" type="text" id="phone" name="phone" disabled={loading} onChange={handleChange} value={form.phone} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mt-3">
                      <label className="form-label d-flex justify-content-between">
                        <ToolTip data={"Role"} text="Role" />
                        <small className="text-danger ms-1">{error.role}</small>
                      </label>
                      <Select placeholder="Select Roles" isDisabled={false} required={true} isMulti isClearable onChange={handleChange} value={roles} options={totalRoles} />
                    </div>
                  </div>
                </div>
                {/* <div className="form-check mt-3">
                  <input
                    type="checkbox"
                    name="default"
                    id="default"
                    className="form-check-input"
                    checked={form.default}
                    onChange={handleChange}
                    disabled={loading}
                  />
                  <label class="form-check-label" for="default">
                    Make default
                  </label>
                </div> */}
                <div className="row">
                  <h3 className="mt-4 mb-0">Address</h3>
                  <div className="col">
                    <div className="mt-3">
                      <label className="form-label d-flex justify-content-between">
                        Street Address
                        <small className="text-danger ms-1">{error.address.address}</small>
                      </label>
                      <input className="form-control" type="text" id="address.address" name="address.address" disabled={loading} onChange={handleChange} value={form.address?.address} />
                    </div>
                  </div>
                  <div className="col">
                    <div className="mt-3">
                      <label className="form-label d-flex justify-content-between">
                        City
                        <small className="text-danger ms-1">{error.address.city}</small>
                      </label>
                      <input className="form-control" type="text" id="address.city" name="address.city" onChange={handleChange} value={form.address?.city} disabled={loading} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="mt-3">
                      <label className="form-label d-flex justify-content-between">State</label>
                      <select className="form-select  mb-3" name="address.state" id="address.state" onChange={handleChange} value={form?.address?.state} disabled={loading}>
                        {State.map((name, index) => (
                          <option value={name} key={index}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col">
                    <div className="mt-3">
                      <label className="form-label d-flex justify-content-between">
                        Zip
                        <small className="text-danger ms-1">{error?.address?.zip}</small>
                      </label>
                      <input className="form-control" type="text" id="address.zip" name="address.zip" onChange={handleChange} value={form.address?.zip} disabled={loading} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="">
                      <label className="form-label d-flex justify-content-between">Country</label>
                      <select class="form-select mb-3" onChange={handleChange} value={form?.address?.country} disabled={loading}>
                        <option value="US">United States</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </ModelBody>
            <ModelFooter>
              <button type="button" className="btn btn-secondary btn-lg" onClick={() => closeModal()}>
                Close
              </button>
              <button type="button" className="btn btn-primary btn-lg" onClick={handleSubmit}>
                Update
              </button>
            </ModelFooter>
          </form>
        </Model>
      )}
    </div>
  );
};
