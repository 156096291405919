import axios from "axios";
import { API_URL } from ".";

function getCompanyByName(name, type) {
  return axios.get(`${API_URL}/crm/company?name=${name}&type=${type}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function getCompaniesByCompanyType(type) {
  return axios.get(`${API_URL}/crm/companies?type=${type}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function getCompanyById(id) {
  return axios.get(`${API_URL}/crm/company/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

async function createFacilityCompany(data) {
  return axios.post(`${API_URL}/crm/facility/signup`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

async function updateFacilityCompany(data) {
  return axios.put(`${API_URL}/crm/facility`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

async function updateConsultantCompany(data) {
  return axios.put(`${API_URL}/crm/consultant`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

async function createConsultantCompany(data) {
  return axios.post(`${API_URL}/crm/consultant/signup`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

async function getContactsByCompanyId(companyId) {
  return axios.get(`${API_URL}/crm/contacts?companyId=${companyId}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

async function getContactByEmail(email) {
  return axios.get(`${API_URL}/crm/contacts?email=${email}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

async function createContact(data) {
  return axios.post(`${API_URL}/crm/contact`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

async function updateContact(data) {
  return axios.put(`${API_URL}/crm/contact`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

async function getAssignedContacts(applicationID) {
  return axios.get(`${API_URL}/crm/assignedContacts?applicationID=${applicationID}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

async function getConsultantContacts() {
  return axios.get(`${API_URL}/crm/consultantContacts`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

async function updateContactCompanydetails(data) {
  return axios.put(`${API_URL}/crm/updateContactCompanydetails`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

async function getUserDetailsByContactRoleId(contactRoleId) {
  return axios.get(`${API_URL}/crm/companyContactRole?contactroleid=${contactRoleId}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

async function getContactRoleById(id) {
  return axios.get(`${API_URL}/crm/contact?id=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export {
  getContactByEmail,
  getCompanyByName,
  createFacilityCompany,
  createConsultantCompany,
  getContactsByCompanyId,
  getCompanyById,
  createContact,
  updateContact,
  getAssignedContacts,
  getConsultantContacts,
  updateContactCompanydetails,
  getUserDetailsByContactRoleId,
  getContactRoleById,
  getCompaniesByCompanyType,
  updateConsultantCompany,
  updateFacilityCompany,
};
