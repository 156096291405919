import { atom, useRecoilValue, useSetRecoilState } from "recoil";

export const navigation = atom({
  key: "navigation",
  default: "",
});

export const hideNavigation = atom({
  key: "hideNavigation",
  default: true,
});

export const navigationContent = atom({
  key: "navigationContent",
  default: true,
});

export const useNavigation = () => {
  return useRecoilValue(navigation);
};

export const useSetNavigation = () => {
  return useSetRecoilState(navigation);
};

export const useHideNavigation = () => {
  return useRecoilValue(hideNavigation);
};

export const useSetHideNavigation = () => {
  return useSetRecoilState(hideNavigation);
};
export const useNavigationContent = () => {
  return useRecoilValue(navigationContent);
};

export const useSetNavigationContent = () => {
  return useSetRecoilState(navigationContent);
};
