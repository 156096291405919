import React, { useEffect } from "react";
import { Tooltip } from "bootstrap";

const ToolTip = ({ data, text = "" }) => {
  const dataTooltip = [
    {
      Description: "Select Your Role",
      title: "Select from the following role options for the utility application of the future.",
    },
    {
      Description: "Role",
      title: "Please select role-options for utility application in the future.",
    },
    {
      Description: "SIGNATORY",
      title: "Please select the contacts from the list given below for application signature in NCRR portal in future.",
    },
    {
      Description: "BILLING REPRESENTATIVE",
      title: "Please select the contacts from the list given below for application billing in NCRR portal in future.",
    },
    {
      Description: "PRIMARY REPRESENTATIVE",
      title: "Please select the contacts from the list given below for application primary in NCRR portal in future.",
    },
    {
      Description: "ENGINEERING REPRESENTATIVE",
      title: "Please select the contacts from the list given below for application engineering in NCRR portal in future.",
    },
    {
      Description: "Assign Contacts",
      title: "Select the contacts from the list given below and the selected list used for approval in NCRR portal in future.",
    },
  ];

  // Function to get title based on description
  const getTitleByDescription = (description) => {
    const item = dataTooltip.find((obj) => obj.Description === description);
    return item ? item.title : null;
  };

  // Get the title based on the provided data
  const dataToolTip = getTitleByDescription(data);

  // Initialize tooltips when component mounts
  useEffect(() => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map((tooltipTriggerEl) => {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  return (
    <span
      className="ps-1 Tooltip"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      data-bs-title={dataToolTip}
      data-bs-original-title=""
      title=""
      // style={{ zIndex: "1084" }}
    >
      {text}
      <i className="fas fa-question-circle text-dark ps-1"></i>
    </span>
  );
};

export default ToolTip;
