import React, { useState } from "react";
import { RoleType } from "../data/StateValues";
import toast from "react-hot-toast";

export const SelectRole = ({ setCompanyRole }) => {
  const [selectedRole, setSelectedRole] = useState("");
  const [error, setError] = useState("");

  const handleRoleTypeChange = (event) => {
    setSelectedRole(event.target.value);
    setError("");
  };

  const handleRoleSubmit = () => {
    if (!selectedRole) {
      setError("*Please select a Role.");
      // toast.error("Please select a role", {
      //   duration: 3000,
      //   position: "top-center",
      // });
    } else {
      setCompanyRole(selectedRole);
    }
  };

  return (
    <div className="bg-img h-100 d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
      <div className="container h-100">
        <div className="row align-items-center h-100">
          <div className="col-sm-7 offset-sm-3">
            <div className="card">
              <div className="border-bottom card-header">
                <h2 className="d-sm-inline-block">Select Your Role</h2>
                <p className="text-dark mb-0">
                  <i className="far fa-hand-point-right"></i> &nbsp; The following role information will establish your profile within the NCRR portal as the designated role.
                </p>
              </div>
              <div className="card-body">
                {error && (
                  <small className="text-danger text-left ps-4" id="error">
                    {error}
                  </small>
                )}
                <div className="  d-flex justify-content-between">
                  <div className="role-options my-3 ">
                    {RoleType.map(({ name, value }, index) => (
                      <div key={index} className={"col-md-12 "}>
                        <div className="form-check">
                          <input
                            type="radio"
                            name="applicant_type"
                            value={value}
                            id={value}
                            checked={selectedRole === value}
                            onChange={handleRoleTypeChange}
                            className={`form-check-input ${error ? "border-danger" : ""}`}
                          />
                          <label htmlFor={value}>{name}</label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="btn-col text-center d-flex justify-content-between ps-3">
                  <button className="btn btn-primary" onClick={handleRoleSubmit} disabled={!!error}>
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
