import React from "react";
import Form210 from "./stepperComponents/Form210";
import { useAtoms } from "../../hooks/useAtoms";
export default function NewForm210(props) {
  const { setHideNavigation } = useAtoms();
  setHideNavigation(false);

  return (
    <div>
      <div className="pb-4">
        <div className="border-bottom py-4 mb-5 bg-white">
          <div className="container">
            <div className="d-flex justify-content-between">
              <h1 className="mb-0">New Utility Application</h1>
              <h2 className="mb-0 text-capitalize">Wire/Conduit/Cable Occupancy Application (NCRR form 210)</h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-form">
                <Form210 id={props.match.params.applicationID} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
