import React from "react";

const textArea = ({ data, handleNetSuiteChange, recordName, col, value }) => {
  return (
    <>
      {data.type === "textarea" && (
        <div class="mb-3">
          <label forhtml="memo" class="text-muted form-label">
            {data.label}
            <span class="text-muted p-1" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title={data.dataTooltip}>
              <i class="fas fa-question-circle text-dark"></i>
            </span>
          </label>
          <textarea
            type="text"
            style={{ height: "90px" }}
            class="form-control"
            id="memo"
            name="memo"
            disabled={data.disabled}
            onChange={(e) => handleNetSuiteChange(data, e, recordName, col)}
            value={value}
          ></textarea>
        </div>
      )}
    </>
  );
};

export default textArea;
