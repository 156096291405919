import { atom, useRecoilValue, useSetRecoilState } from "recoil";

export const checkedState = atom({
  key: "checkedState",
  default: "",
});

export const useCheckedState = () => {
  return useRecoilValue(checkedState);
};

export const useSetCheckedState = () => {
  return useSetRecoilState(checkedState);
};
