import React, { useRef } from "react";
import { useParams } from "react-router";
export default function TotalAerialInput({
  label = "",
  name = "",
  name1 = "",
  type = "text",
  defaultValue = "",
  placeholder = "",
  css = {},
  validation,
  register,
  errors,
  handleChange,
  id,
  fieldLabel = "",
  fieldLabelPosition = "",
  fieldType = "",
  comments = "",
  commentsIconIsRequired = false,
  showOnChange = [],
  commentsStyle = {},
  condtionalLabel = "",
  disabled = false,
  totalAerial = () => {},
  setSchema = () => {},
  total,
  parallel,
  crossing,
  warningValue,
  requiredLable = false,
}) {
  const lastSec = useRef();
  lastSec.current = [];
  let params = useParams();
  let data = JSON.parse(localStorage.getItem(params.applicationID)) || {};
  return (
    <>
      <label className="form-label d-flex justify-content-between">
        {label}
        <small className="text-danger ms-1">{requiredLable ? "*required" : null}</small>
      </label>
      <div className="input-group">
        <input
          style={css}
          className="form-control"
          type={"number"}
          defaultValue={data[name]}
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          pattern={type === "number" ? "[0-9]+([.,][0-9]+)?" : null}
          step={name === "longitude" || name === "latitude" ? "0.00000001" : "0.0001"}
          {...register(name, { ...validation })}
          onChange={(e) => totalAerial(e)}
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
        />
        {fieldLabelPosition === "right" ? (
          <div className="input-group-append">
            <span className="input-group-text bg-secondary  text-dark">{fieldLabel}</span>
          </div>
        ) : (
          ""
        )}
      </div>
      <span className="text-danger">{errors[name] && <span>Enter a valid value for {label}</span>}</span>
      {/* {name === "Total aerial length on the NCRR Corridor" ||
      name === "Total buried length on the NCRR Corridor" ? (
        <span className="text-danger">
          <span>
            Value is not execeed more than
            {
               localStorage.getItem(
                  "Total Length of Occupancy within the NCRR Corridor"
                )
              }
          </span>
        </span>
      ) : null} */}
    </>
  );
}
