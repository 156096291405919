import React, { useState } from "react";
import { useHistory } from "react-router";
import { socket } from "../../utils/socket-io";

export default function PromptComponent(props) {
  let history = useHistory();
  const [currentPrompt, setCurrentPrompt] = useState({});
  socket.on("currentApplicationStage", (msg) => {
    if (msg.applicationID === props.applicationId) {
      setCurrentPrompt(msg);
    }
  });
  const moveTOCurrent = () => {
    if (currentPrompt.currentStage === "Applicant Review") {
      history.push(`/applicantView/${currentPrompt.applicationID}`);
    } else {
      window.location.reload();
    }
    if (currentPrompt.currentStage === "Initial Review" || currentPrompt.currentStage === "Approved") {
      history.push(`/applicationReview/${currentPrompt.applicationID}`);
    } else {
      window.location.reload();
    }
    if (
      (currentPrompt.currentStage === "Signature" && currentPrompt.id === 9) ||
      currentPrompt.currentStage === "Validate Signature" ||
      currentPrompt.currentStage === "Draft Agreement Assign Signee" ||
      currentPrompt.currentStage === "Draft Agreement Generation" ||
      currentPrompt.currentStage === "Draft Final Review" ||
      currentPrompt.currentStage === "Draft Agreement Review" ||
      currentPrompt.currentStage === "NCRR Review Draft Agreement"
    ) {
      history.push(`/draftAgreement/${currentPrompt.applicationID}`);
    } else {
      window.location.reload();
    }
    if (currentPrompt.currentStage === "Engineering Review") {
      history.push(`/review/${currentPrompt.applicationID}`);
    } else {
      window.location.reload();
    }

    if (
      currentPrompt.currentStage === "Compile License Agreement" ||
      currentPrompt.currentStage === "Upload Insurance" ||
      currentPrompt.currentStage === "Validate Uploaded Insurance" ||
      // (currentPrompt.currentStage === "Payment" && currentPrompt.id===18) ||
      currentPrompt.currentStage === "Compile Agreement Assign Signee" ||
      (currentPrompt.currentStage === "Signature" && currentPrompt.id === 20) ||
      currentPrompt.currentStage === "Validate Fee, Insurance and Agreement" ||
      currentPrompt.currentStage === "Upload Letter and Validate" ||
      currentPrompt.currentStage === "Validate and Sign" ||
      currentPrompt.currentStage === "Final Release"
    ) {
      history.push(`/compiledAgreement/${currentPrompt.applicationID}`);
    } else {
      window.location.reload();
    }
    if (currentPrompt.currentStage === "Completed") {
      history.push(`/finalRelease/${currentPrompt.applicationID}`);
    } else {
      window.location.reload();
    }
  };

  return (
    <div>
      {currentPrompt.currentStage != "Payment" || currentPrompt.currentStage != "Signature" ? (
        <>
          {" "}
          <div className={Object.keys(currentPrompt).length != 0 ? "modal-backdrop fade show" : ""}></div>
          <div
            className={`modal fade ${Object.keys(currentPrompt).length != 0 ? "show" : null}`}
            id="exampleModalCenter"
            tabIndex="-1"
            style={Object.keys(currentPrompt).length != 0 ? { display: "block" } : null}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">The Current Stage of application has been updated. Click Ok to proceed</div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      moveTOCurrent();
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
