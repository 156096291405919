import { atom, useRecoilValue, useSetRecoilState } from "recoil";

export const showHeader = atom({
  key: "showHeader",
  default: true,
});

export const useShowHeader = () => {
  return useRecoilValue(showHeader);
};

export const useSetShowHeader = () => {
  return useSetRecoilState(showHeader);
};
