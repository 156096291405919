import React from "react";

const FilterLoading = () => {
  return (
    <div className="card-header bg-transparent border-0">
      <div className=" align-items-center">
        <div className="col-md-12">
          <div className="loading">
            <div className="line"></div>
            <p>#AP123458</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterLoading;
