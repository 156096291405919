import React, { useEffect, useState } from "react";
import { useApplication } from "../../hooks/useApplication";
import { useUser } from "../../hooks/useUser";
import { getApplicationPaymentFee } from "../../utils/getPaymentAmount";
import { Netsuite } from "../../schema/NetSuiteSchema";
import { getNetsuiteInitialValue } from "../../api/Netsuite";
import NetsuiteLink from "../ui/NetsuiteLink";
import FieldAll from "../ui/FieldAll";
import { TriggerTransactionButton } from "../ui/TriggerTransaction";
import { REACT_APP_URL } from "../../api";

export const AuthorizeApplicationFee = ({ netsuiteField, setNetsuiteValue, updateManualPayment, editPaymentNotes, updatePaymentTransiction, updateTransictionId, validatepayment }) => {
  const [fee, setFee] = useState(null);
  const [records, setRecords] = React.useState([]);
  const [arrayOfnesuite, setArrayOfnesuite] = React.useState([]);

  const application = useApplication();
  const { roleName = "" } = useUser() || {};
  const { payment, applicationStage, event1transactionNumberDate, event1paymentType, event1applicationFee, event1engineeringFee, event1transactionFee, _id, event1validatedOn, event1transactionId } =
    application;
  const { event1StripeResponse } = payment || [];
  let applicationPaymentFee = event1applicationFee ? Number(event1applicationFee) : Number(fee?.applicationFee);
  let engineeringPaymentFee = event1engineeringFee ? Number(event1engineeringFee) : Number(fee?.engineeringFee);

  const totalAmountForOfflinePayment = applicationPaymentFee + engineeringPaymentFee;

  const getFee = async (application) => {
    const fee = await getApplicationPaymentFee(application);
    setFee(fee);
  };

  const handleNetSuiteChange = (data, event, recordName, column) => {
    let item = 0;
    if (column?.itemId) {
      item = column.itemId;
    }
    const { keyName = "" } = data;
    const { value } = event.target;
    if ((data.type === "text" || data.type === "date") && item === 0) {
      setNetsuiteValue((initialValue) => ({
        ...initialValue,
        [recordName]: { ...initialValue[recordName], [keyName]: value },
      }));
    }
  };

  const validated = event1StripeResponse.find((each) => each?.validated === true)?.validated || false;

  const payment_status = event1StripeResponse.find((each) => each?.payment_status === "paid") ? true : false;
  useEffect(() => {
    const arrayOfnesuite = [];
    Netsuite.sections.forEach((section) => {
      section.questions.forEach((val) => {
        if (val.applicationPayment === "true") {
          arrayOfnesuite.push(val);
        }
      });
    });
    setArrayOfnesuite(arrayOfnesuite);
  }, []);

  useEffect(() => {
    const array = arrayOfnesuite.map((section, inde) => {
      return section?.recordName;
    });
    setRecords(array);
  }, [arrayOfnesuite]);

  useEffect(() => {
    records.forEach(async (records) => {
      let res = await getNetsuiteInitialValue(_id, records);
      let response = res?.data?.data;
      setNetsuiteValue((initialValue) => ({
        ...initialValue,
        [records]: response,
      }));
    });
  }, [records, _id]);

  useEffect(() => {
    if (!event1transactionFee || !event1applicationFee || !event1engineeringFee) {
      getFee(application);
    }
  }, [event1applicationFee, event1engineeringFee, event1transactionFee, application]);

  const isPaymentCompleted = (event1transactionNumberDate && event1paymentType === "ACH") || event1paymentType === "Check" || (payment_status && applicationStage !== 1);
  return (
    <>
      {isPaymentCompleted && (
        <div className="accordion print" id="accordionExample2">
          <div className="accordion-item mb-4">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button py-4 px-4 d-flex justify-content-between "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h2 className="mb-0">Authorize Application Fee</h2>
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample2">
              <div className="accordion-body p-0">
                <div className="card ">
                  <div id="files-upload ">
                    <div className="card p-0 shadow  border-top-0">
                      {(roleName === "Approving Representative" || roleName === "Approving Accountant") &&
                        arrayOfnesuite.map((v) => {
                          const { recordName } = v;
                          return (
                            <>
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-12 p-0 m-0">
                                    <div className="accordion" id="accordionExample">
                                      <div id="files-upload Cash Sale">
                                        <div className="accordion-item border-end-0 border-start-0 rounded-0">
                                          <div className="card">
                                            <div className="card-body p-0">
                                              <h2 className="accordion-header" id="PaymentInformation7">
                                                <button
                                                  className=" accordion-button py-4 px-4 d-flex justify-content-between bg-transparent text-dark fw-normal h2 mb-0 collapsed"
                                                  type="button"
                                                  data-bs-toggle="collapse"
                                                  data-bs-target={`#Collapse${v.id}`}
                                                  aria-expanded="false"
                                                  aria-controls={`Collapse${v.id}`}
                                                >
                                                  {v.header}
                                                </button>
                                              </h2>
                                              <div id={`Collapse${v.id}`} className="accordion-collapse collapse" aria-labelledby="PaymentInformation7" data-bs-parent="#accordionExample">
                                                <div className="accordion-body pt-0 p-0">
                                                  <div className="card-col card">
                                                    <div className="card-body pt-0">
                                                      <div className="row">
                                                        <div className="col-lg-12 pt-3 p-0 d-flex flex-row-reverse">
                                                          <div className="form-check p-0">
                                                            <NetsuiteLink applicationID={_id} v={v} />
                                                          </div>
                                                        </div>
                                                        <div className="col-md-12 p-0">
                                                          <div className="row  m-0 p-0">
                                                            {v &&
                                                              v.rows.map((col, ind) => {
                                                                let item = 0;
                                                                if (col?.itemId) {
                                                                  item = col?.itemId;
                                                                }

                                                                return (
                                                                  <>
                                                                    {col.header ? <h3 class="">{col.header}</h3> : ""}
                                                                    {col.label ? (
                                                                      <div class="">
                                                                        <h3 class="form-label">{col.label}</h3>{" "}
                                                                      </div>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                    {col.columns.map((data) => {
                                                                      let value = netsuiteField?.[recordName]?.[data.keyName] || "";

                                                                      if (item !== 0) {
                                                                        try {
                                                                          value = netsuiteField?.[recordName]?.items[item - 1]?.[data.keyName];
                                                                        } catch (error) {
                                                                          value = undefined;
                                                                          console.log(error);
                                                                        }
                                                                      }
                                                                      return (
                                                                        <div className={data.col ? data.col : "col-md-6"}>
                                                                          <FieldAll data={data} handleNetSuiteChange={handleNetSuiteChange} recordName={recordName} col={col} value={value} />
                                                                        </div>
                                                                      );
                                                                    })}
                                                                  </>
                                                                );
                                                              })}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div className={`${event1validatedOn && roleName === "Approing Accountant" ? "card-header  border-bottom p-0" : "card-header  border-bottom pb-0"}`}>
                    {(validated || event1validatedOn) && roleName === "applicant" && (
                      <>
                        <div className="field-col border-bottom ">
                          <h3>Payment Notes:</h3>
                          <p>
                            {event1transactionId && event1transactionId}
                            <br />
                          </p>
                        </div>
                      </>
                    )}

                    <TriggerTransactionButton role={roleName} />

                    {roleName === "Approving Accountant" && event1paymentType && applicationStage === 1 && (
                      <>
                        <div className="mb-4">
                          <label forhtml="transactionId">Payment Notes</label>
                          <textarea className="form-control" id="transactionId" name="transactionId" />
                        </div>
                        <button
                          className="btn btn-block btn-primary"
                          onClick={() => {
                            updateManualPayment("event1paymentType");
                          }}
                        >
                          Received Payment of $<span>{totalAmountForOfflinePayment}</span>
                        </button>
                      </>
                    )}

                    {!validated && roleName !== "Approving Accountant" && event1paymentType && applicationStage === 1 && (
                      <>
                        {event1paymentType ? (
                          <div className="alert alert-warning text-dark text-center" role="alert">
                            {event1paymentType === "ACH" && roleName === "applicant"
                              ? "The payment is not yet confirmed. If you have submitted ACH, please contact NCRR accounting for an update."
                              : event1paymentType === "Check" && roleName === "applicant"
                                ? "The payment is not yet confirmed. If you have submitted Check, please contact NCRR accounting for an update."
                                : "The payment is not yet confirmed. Awaiting NCRR accounting for an update."}
                          </div>
                        ) : roleName !== "applicant" ? (
                          <div className="alert alert-warning mt-3 text-dark" role="alert">
                            Applicant has not made the payment yet.
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                    {roleName === "Approving Accountant" &&
                      applicationStage > 1 &&
                      (editPaymentNotes
                        ? applicationStage > 1 && (
                            <>
                              <div className="mb-4">
                                <label forhtml="transactionId">Update Payment Notes</label>
                                <textarea defaultValue={event1transactionId} className="form-control" id="transactionId" name="transactionId" />
                              </div>
                              <button
                                className="btn btn-link"
                                style={{
                                  textDecoration: "underline",
                                  pointerEvents: "fill",
                                }}
                                onClick={() => {
                                  updatePaymentTransiction("event1paymentType");
                                }}
                              >
                                <i className="far fa-check"></i> Save
                              </button>
                              <a
                                className="btn btn-link text-warning"
                                style={{
                                  textDecoration: "underline",
                                  pointerEvents: "fill",
                                }}
                                href={REACT_APP_URL + "/applicationPayment/" + _id}
                              >
                                <i className="far fa-ban"></i> Cancel
                              </a>
                            </>
                          )
                        : applicationStage !== 26 && (
                            <>
                              <div className="mb-4 text-muted">
                                <label forhtml="transactionId">Payment Notes</label>
                                <textarea defaultValue={event1transactionId} disabled className="form-control mt-2" id="transactionId" name="transactionId" />
                              </div>
                              <button
                                className="btn btn-link"
                                style={{
                                  textDecoration: "underline",
                                  pointerEvents: "fill",
                                }}
                                onClick={updateTransictionId}
                              >
                                <i className="far fa-pen"></i> Edit
                              </button>
                            </>
                          ))}

                    {roleName === "Approving Accountant" && applicationStage > 1 && applicationStage !== 1 && applicationStage !== 17 && !event1paymentType && !event1transactionId && (
                      <>
                        <div className="px-0 card-header bg-transparent border-0 pb-0"></div>

                        <div className="mb-4">
                          <label forhtml="transactionId">Payment Notes</label>
                          <textarea className="form-control" id="transactionId" name="transactionId" />
                        </div>
                        <button
                          className="btn btn-block btn-primary"
                          onClick={() => {
                            updateManualPayment("event1paymentType");
                          }}
                        >
                          Received Payment of $<span>{totalAmountForOfflinePayment}</span>
                        </button>
                      </>
                    )}

                    {applicationStage > 1 && roleName !== "Approving Accountant" && (
                      <>
                        {!!event1validatedOn ? (
                          <div className="alert alert-success  text-dark" role="alert">
                            Successfully acknowledged payment. The application has now moved on to the next stage.
                          </div>
                        ) : (
                          <div className="alert alert-warning text-dark text-center" role="alert">
                            The payment is not yet confirmed. Awaiting NCRR accounting for an update.
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {!editPaymentNotes && !validated && !event1validatedOn && applicationStage < 6 && applicationStage !== 26 && roleName === "Approving Accountant" && event1transactionId && (
                    <div className="card-footer py-0 justify-content-center bg-primary ">
                      <a className="d-block text-white btn py-4 px-0" tabIndex="-1" role="button" onClick={validatepayment}>
                        <span className="d-flex justify-content-between align-items-center">
                          <span>
                            {"Authorize"} <i className="fal fa-chevron-right ms-2"></i>
                          </span>
                        </span>
                      </a>
                    </div>
                  )}

                  {(validated || event1validatedOn || (applicationStage >= 6 && applicationStage !== 26)) && (
                    <div className="card-footer py-0 justify-content-center bg-success">
                      <a className="d-block text-white btn border-0 disabled py-4 px-0" tabIndex="-1" role="button" aria-disabled="true">
                        <span className="d-flex justify-content-between align-items-center">
                          <span>
                            <i className="fal fa-check"></i> {"Authorized"}
                          </span>
                          <span>By Accountant on {event1validatedOn && <small> {event1validatedOn}</small>}</span>
                        </span>
                      </a>
                    </div>
                  )}
                  {!event1validatedOn && !validated && !event1paymentType && roleName !== "Approving Accountant" ? (
                    <div
                      className="card-footer justify-content-center "
                      style={{
                        backgroundColor: "rgb(233 237 241)",
                      }}
                    >
                      Awaiting for Accountant
                      <i className="fal fa-chevron-right ms-2"></i>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
