import { HandleFieldType } from "../HandleFieldType";
import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { watchContext } from "../FormOnAFly";
import { formContext } from "../../routes/routes";
export const Switch = ({
  label = "",
  name = "",
  options = [],
  css = {},
  register,
  errors,
  validation,
  checkBoxPosition = "",
  style = "",
  checkBoxStyle = {},
  additonalComments = "",
  additonalLabel = "",
  condtionalLabel = "",
  requiredLable = false,
}) => {
  const schemaContext = useContext(formContext);
  const watch = useContext(watchContext);

  if (!Array.isArray(options)) throw new Error(`Accepted radio options as Array but got ${typeof options}`);

  function handleConditionalRender(items, e) {
    if (items) {
      const div = document.createElement("div");
      div.classList.add(`child_${e.target.value.split(" ").join("_")}`);
      if (e.target.checked) {
        document.getElementById(`conditionalAreaCheckbox${e.target.value.split(" ").join("_")}`).appendChild(div);
        ReactDOM.render(
          <formContext.Provider value={schemaContext}>
            <watchContext.Provider value={watchContext}>
              <HandleFieldType items={items} register={register} errors={errors} />
            </watchContext.Provider>
          </formContext.Provider>,
          div,
        );
      } else {
        const parent = document.getElementById(`conditionalAreaCheckbox${e.target.value.split(" ").join("_")}`);
        parent.removeChild(document.querySelector(`.child_${e.target.value.split(" ").join("_")}`));
      }
    }
  }
  function handleOptionalSectionRender(items = {}, clear, e) {
    if (e.target.checked) {
      if (Object.keys(items).length && schemaContext) {
        const newSchema = schemaContext.schema.sections.map((v) => {
          if (v.label == items.section) {
            v.questions = [...v.questions, ...items.schema];
          }
          return v;
        });

        schemaContext.setSchema({ sections: newSchema });
      }
    } else {
      if (Object.keys(items).length && schemaContext) {
        const blal = schemaContext.schema.sections.map((v) => {
          if (v.label == items.section) {
            v.questions.pop();
          }
          return v;
        });

        schemaContext.setSchema({ sections: blal });
      }
    }
  }

  function handleDifferntSectionsRender(check, check_value, render, e) {
    if (check) {
      for (let i = 0; i < check.length; i++) {
        if (watch[check[i]] === check_value[i] && schemaContext && e.target.checked) {
          if (Object.keys(render).length) {
            const newSchema = schemaContext.schema.sections.map((v) => {
              if (v.label == render.section) {
                let lastBeforeIndex = v.questions.length - 1;
                v.questions.splice(lastBeforeIndex, 0, render.schema[i]);
              }

              return v;
            });
            schemaContext.setSchema({ sections: newSchema });
          }
        } else {
          const newSchema = schemaContext.schema.sections.map((v) => {
            if (v.label == render.section) {
              v.questions = v.questions.filter((d) => d.id && d.id !== render.schema[i].id && render.schema[i].id);
            }
            return v;
          });
          schemaContext.setSchema({ sections: newSchema });
        }
      }
    }
  }
  function handleAvailable(showOnSelect) {
    const promise = new Promise((resolve, reject) => {
      if (showOnSelect) {
        resolve("sucess");
      } else {
        // reject('fail')
      }
    });
    return promise;
  }

  return (
    <div>
      <div className={`${checkBoxPosition} row`}>
        {label.length ? (
          <h3 className="form-label d-flex justify-content-between">
            {label}
            <small className="text-danger ms-1">{requiredLable ? "*required" : null}</small>
          </h3>
        ) : null}

        {additonalComments ? <label style={{ color: "#8898AA", fontSize: "13.6px", marginLeft: "8px" }}>{additonalComments}</label> : null}
        {additonalLabel ? (
          <label className="form-label d-flex justify-content-between">
            {additonalLabel}
            <small className="text-danger ms-1">{requiredLable ? "*required" : null}</small>
          </label>
        ) : null}
        {condtionalLabel ? (
          <label
            style={{
              color: "#0079C2",
              fontWeight: "600",
              fontSize: "17px",
            }}
          >
            {condtionalLabel}
          </label>
        ) : null}
        {options.map((option, index) => (
          <div key={index} className={style || "`mb-2`"} style={checkBoxStyle}>
            <div className="custom-control custom-switch" style={option.styleInner}>
              <input
                style={css}
                type={"checkbox"}
                className="custom-control-input"
                data-option={option}
                name={name}
                id={option.value}
                value={option.value}
                onClick={(e) => {
                  if (e.target.value) {
                    handleAvailable(option.showOnSelect).then((data) => {
                      handleConditionalRender(option.showOnSelect, e);
                    });
                  }
                  handleOptionalSectionRender(option.optionalInSection, option.clear, e);
                  handleDifferntSectionsRender(option.check, option.check_value, option.render, e);
                }}
                {...register(name, { ...validation })}
              />

              <label className="form-check-label" htmlFor={option.value} style={css}>
                {option.label}
              </label>

              {option.linkContext && (
                <a href={option.linkAction} target="_blank">
                  {" "}
                  {option.linkContext}
                </a>
              )}

              {option.subLabel && (
                <label className="mx-2 my-1" htmlFor={option.value}>
                  {option.subLabel}
                </label>
              )}
              <div>
                <div id={`conditionalAreaCheckbox${option.value.split(" ").join("_")}`} className="" />
              </div>
            </div>
          </div>
        ))}

        <span className="text-danger">{errors[name] && <span>Select a value for {name}</span>}</span>
      </div>
    </div>
  );
};
