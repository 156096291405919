import React, { useContext, useRef } from "react";
import { formContext } from "../../routes/routes";
import { showOnSelect, showOnHide } from "./renderFunction";
import { useParams } from "react-router";
export const Radio = ({
  label = "",
  type = "radio",
  name = "",
  options = [],
  css = {},
  register,
  errors,
  validation,
  handleChange,
  id,
  condtionalLabel = "",
  radioPosition = "",
  hide,
  requiredLable = false,
  editPage,
}) => {
  const schemaContext = useContext(formContext);
  const lastSec = useRef();
  lastSec.current = [];

  if (!Array.isArray(options)) throw new Error(`Accepted radio options as Array but got ${typeof options}`);

  let params = useParams();
  let data = JSON.parse(localStorage.getItem(params.applicationID)) || {};

  if (localStorage.getItem(params.applicationID + "saved")) {
    localStorage.setItem(params.applicationID + "schema", JSON.stringify(schemaContext.schema));
  }

  return (
    <>
      {!hide ? (
        <>
          {label ? (
            <>
              <label className="form-label">
                {label}
                {requiredLable ? <sup className="text-danger">*</sup> : null}
              </label>
            </>
          ) : null}
          {condtionalLabel ? (
            <label
              className="form-label"
              style={{
                color: "#0079C2",
                fontWeight: "600",
                fontSize: "17px",
              }}
            >
              {condtionalLabel}
            </label>
          ) : null}
          {options.map((option, index) => (
            <div key={index} className={radioPosition ? "col" : radioPosition}>
              <div className="form-check form-check-inline ">
                <input
                  className="form-check-input input-radio"
                  style={css}
                  type={type}
                  name={name}
                  id={option.value}
                  defaultChecked={option.value === data[name] ? true : false}
                  value={option.value}
                  onClick={(e) => {
                    localStorage.setItem("renderKeyRadio", option.key);
                    localStorage.setItem("hideKey", option.hideKey);
                    showOnSelect(e, option.showOnSelect, option.show, schemaContext, params.applicationID);
                    showOnHide(e, option.showOnHide, option.show, schemaContext, params.applicationID);
                  }}
                  {...register(name, { ...validation })}
                />
                <label htmlFor={option.value} className="form-check-label">
                  {option.label}
                </label>
              </div>
            </div>
          ))}
          <span className="text-danger">{errors[name] && <span>Select a value for {name}</span>}</span>
          <div id={`conditionalAreaRadio${name}`} className=""></div>{" "}
        </>
      ) : null}
    </>
    // </div>
  );
};
