import React, { useState } from "react";
export const File = ({
  label = "",
  name = "",
  type = "file",
  css = {},
  validation,
  register,
  errors,
  fileTypes = "",
  linkAction = "",
  linkContext = "",
  subLabel = "",
  additionalcomments = "",
  fileUpload = "",
  requiredLable = false,
}) => {
  const [fileValue1, setFileValue1] = useState("");

  return (
    <>
      <label className="form-label d-flex justify-content-between">
        {label}
        <small className="text-danger ms-1">{requiredLable ? "*required" : null}</small>
      </label>
      {linkContext && (
        <a href={linkAction} target="_blank">
          {" "}
          {linkContext}
        </a>
      )}{" "}
      {subLabel && <label className="form-label">{subLabel}</label>}
      <div className="input-group mb-3">
        <input
          accept=".pdf"
          style={css}
          // value={fileValue}
          className="form-control"
          type={type}
          //defaultValue={datas[name]}
          {...register(name, { ...validation })}
          //onChange={(e) => fileUploads(e)}
        />

        {fileTypes ? (
          <span className="input-group-text bg-secondary  text-dark" id="basic-addon2">
            .pdf, .cad or .doc up to 100 MB
          </span>
        ) : null}

        <span className="text-danger">{errors[name] && <span>Select a valid file for {label}</span>}</span>
      </div>
      {fileValue1 && <div className="single-file">{fileValue1}</div>}
    </>
  );
};
