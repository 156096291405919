import { atom, atomFamily, selectorFamily, useRecoilValue, useSetRecoilState } from "recoil";
import { getApplicationData } from "../api/currentApplications";

export const onGoingApplication = atom({
  key: "onGoingApplication",
  default: false,
});

export const useOnGoingApplication = () => {
  return useRecoilValue(onGoingApplication);
};

export const useSetOnGoingApplication = () => {
  return useSetRecoilState(onGoingApplication);
};

export const refetchApplicationSignal = atom({
  key: "refetchApplication",
  default: 0,
});

export const application = atomFamily({
  key: "application",
  default: selectorFamily({
    key: "applicationSelecter",
    get:
      (id) =>
      async ({ get }) => {
        try {
          if (!id) {
            return null;
          }
          get(refetchApplicationSignal);
          const res = await getApplicationData(id);
          if (res.data.status === "Success") {
            return res.data.data;
          } else {
            window.location = "/dashboard";
          }
        } catch (error) {
          return {};
        }
      },
  }),
});
