import { clearLocalStorage } from "../utils/clearLocalStorage";
import GetmyRole from "../utils/GetmyRole";

export const verifyUser = (location, history) => {
  const { role, details = {}, contactRoleId } = GetmyRole();

  clearLocalStorage();

  //this is for those who have already logged in but has user details of previous version of the app (CustId)
  if (!contactRoleId && role !== "Lead") {
    if (location?.pathname !== "/login" && location?.pathname !== "/verify") {
      localStorage.clear();
      history.push("/login");
      return;
    }
  }

  if (location?.pathname !== "/") return;

  if (role === "Lead") {
    const { companyId } = details;

    if (!companyId) {
      history.push("/signup");
      return;
    }

    history.push(`/contacts/${companyId}`);
    return;
  }

  if (!contactRoleId) {
    localStorage.clear();
    history.push("/login");
    return;
  }

  history.push("/dashboard");
};
