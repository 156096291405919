import React, { useEffect, useState } from "react";
import { State, ApplicantType } from "../data/StateValues";
import { updateFacilityCompany, getCompaniesByCompanyType, getCompanyById } from "../../api/crm";
import GetmyRole from "../../utils/GetmyRole";
import { useUserRefetch } from "../../hooks/useUser";
import toast from "react-hot-toast";
import { Model, ModelBody, ModelFooter } from "../models/Model";
import { useParams } from "react-router-dom";

const initialValue = {
  id: "",
  name: "",
  sameAsMailingAddress: true,
  mailingAddress: {
    address: "",
    city: "",
    state: "North Carolina",
    zip: "",
    poBox: "",
    country: "United States",
  },
  billingAddress: {
    address: "",
    city: "",
    state: "North Carolina",
    zip: "",
    poBox: "",
    country: "United States",
  },
  billingPeriod: "YEARLY",
  applicantshipType: "",
  stateOfFormation: "North Carolina",
  updatedBy: "",
};

const initialError = {
  name: "",
  mailingAddress: {
    address: "",
    city: "",
    state: "",
    zip: "",
    poBox: "",
    country: "",
  },
  billingAddress: {
    address: "",
    city: "",
    state: "",
    zip: "",
    poBox: "",
    country: "",
  },
  billingPeriod: "",
  applicantshipType: "",
  stateOfFormation: "",
  updatedBy: "",
};

const companyExist = (company, name, id) => {
  return company.name.toUpperCase() === name.toUpperCase() && id !== company.id;
};

export const FacilityEditModel = ({ showModal, closeModal = () => {} }) => {
  const { companyId } = useParams();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(initialValue);
  const [error, setError] = useState(initialError);
  const [companies, setCompanies] = useState([]);
  const refetchUser = useUserRefetch();

  const validateZipCode = (zip) => {
    const zipCodePattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
    return zipCodePattern.test(zip);
  };

  const getCompany = async (id) => {
    const res = await getCompanyById(id);
    if (res?.data?.data) {
      const company = res.data.data;
      setForm((initialValue) => ({ ...initialValue, sameAsMailingAddress: false, ...company }));
    }
  };

  const getAllCompanies = async () => {
    const res = await getCompaniesByCompanyType("FACILITY");
    if (res?.data?.data) {
      const companies = res?.data?.data || [];
      setCompanies(companies);
    }
  };

  useEffect(() => {
    if (companyId) {
      getCompany(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    getAllCompanies();
  }, []);

  const handleChange = (e) => {
    const { type, name, value } = e.target;
    const types = ["text", "select-one", "radio"];
    const mailType = ["mailingAddress", "billingAddress"];
    setError(initialError);
    if (mailType.find((each) => name.startsWith(each))) {
      const mailType = name.split(".")[0];
      const mailingName = name.split(".")[1];
      if (types.includes(type)) {
        setForm((initialForm) => ({
          ...initialForm,
          [mailType]: {
            ...initialForm[mailType],
            [mailingName]: value,
          },
        }));
      }
      checkError();
      return;
    }

    if (types.includes(type)) {
      setForm((initialForm) => ({
        ...initialForm,
        [name]: value,
      }));
      checkError({ ...form, [name]: value });
    }

    if (type === "checkbox") {
      setForm((initialForm) => ({
        ...initialForm,
        [name]: !initialForm[name],
      }));
      checkError();
    }
  };

  const handleError = (error) => {
    setError({ ...initialError, ...error });
  };
  const checkError = (errorform) => {
    if (!errorform) {
      errorform = form;
    }

    let errors = {};
    const alreadyExist = companies.find((company) => companyExist(company, errorform.name, errorform.id));
    if (alreadyExist) {
      errors.name = `*Facility Owner name is already taken. Please contact ${process.env.REACT_APP_SUPPORT_EMAIL || "NCRR Support"}, for further information`;
    }

    if (!errorform.name) {
      errors.name = "*required";
    }
    if (!errorform.mailingAddress.address) {
      errors.mailingAddress = { address: "*required" };
    }
    if (!errorform.mailingAddress.city) {
      errors.mailingAddress = { ...errors.mailingAddress, city: "*required" };
    }
    if (!errorform.mailingAddress.state) {
      errors.mailingAddress = { ...errors.mailingAddress, state: "*required" };
    }
    if (!errorform.mailingAddress.zip) {
      errors.mailingAddress = { ...errors.mailingAddress, zip: "*required" };
    } else if (!validateZipCode(errorform.mailingAddress.zip)) {
      errors.mailingAddress = { ...errors.mailingAddress, zip: "Invalid Zip code" };
    }
    if (!errorform.sameAsMailingAddress) {
      if (!errorform.billingAddress.address) {
        errors.billingAddress = { address: "*required" };
      }
      if (!errorform.billingAddress.city) {
        errors.billingAddress = { ...errors.billingAddress, city: "*required" };
      }
      if (!errorform.billingAddress.state) {
        errors.billingAddress = { ...errors.billingAddress, state: "*required" };
      }
      if (!errorform.billingAddress.zip) {
        errors.billingAddress = { ...errors.billingAddress, zip: "*required" };
      } else if (!validateZipCode(errorform.billingAddress.zip)) {
        errors.billingAddress = { ...errors.billingAddress, zip: "Invalid Zip code" };
      }
    }
    if (!errorform.billingPeriod) {
      errors.billingPeriod = "*required";
    }
    if (!errorform.applicantshipType) {
      errors.applicantshipType = "*required";
    }
    if (!errorform.stateOfFormation) {
      errors.stateOfFormation = "*required";
    }

    if (Object.keys(errors).length > 0) {
      handleError(errors);
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(initialError);

    const result = checkError();
    try {
      if (result) {
        const { email } = GetmyRole();
        const data = { ...form, updatedBy: email };
        if (form.sameAsMailingAddress) {
          data["billingAddress"] = data["mailingAddress"];
        }
        await updateFacilityCompany(data);
        toast.success("Updated Successfully!.");
        refetchUser();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  return (
    <div onKeyDown={handleKeyPress}>
      {showModal && (
        <Model heading={"Update Facility Owner Details"} id="edit-facility" showModal={showModal} closeModal={closeModal}>
          <form onSubmit={handleSubmit}>
            <ModelBody>
              <div className="card border-0 inner-form">
                <div className="inner-form card mb-4">
                  <div className="card-body">
                    <div id="company-details">
                      <div className="row ">
                        <div className="col">
                          <div className="mt-3">
                            <label className="form-label d-flex justify-content-between">
                              Facility Owner Legal Name
                              <small className="text-danger ms-1">{error.name}</small>
                            </label>
                            <div className="container p-0">
                              <div className="dropdown col-lg-12 p-0">
                                <input type="text" className="form-control" id="name" name="name" value={form.name} onChange={handleChange} />
                              </div>
                            </div>
                            <div id="" className="form-text">
                              *Please ensure that the exact legal name is provided with no abbreviations.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body" id="owner-details">
                    <div className="d-flex justify-content-between">
                      <h3>Mailing Address</h3>
                    </div>
                    <div className="row">
                      <div className="row">
                        <div className="col">
                          <div className="mt-3">
                            <label className="form-label d-flex justify-content-between">
                              Street Address
                              <small className="text-danger ms-1">{error.mailingAddress.address}</small>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="mailingAddress.address"
                              name="mailingAddress.address"
                              disabled={loading}
                              onChange={handleChange}
                              value={form.mailingAddress.address}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="mt-3">
                            <label className="form-label d-flex justify-content-between">
                              City
                              <small className="text-danger ms-1">{error.mailingAddress.city}</small>
                            </label>

                            <input
                              className="form-control"
                              type="text"
                              id="mailingAddress.city"
                              name="mailingAddress.city"
                              onChange={handleChange}
                              value={form.mailingAddress.city}
                              disabled={loading}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mt-3">
                            <label className="form-label d-flex justify-content-between">
                              State
                              <small className="text-danger ms-1">{error.mailingAddress.state}</small>
                            </label>
                            <select className="form-select  mb-3" name="mailingAddress.state" id="mailingAddress.state" onChange={handleChange} value={form.mailingAddress.state} disabled={loading}>
                              {State.map((name, index) => (
                                <option value={name} key={index}>
                                  {name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div class="col">
                          <div className="mt-3">
                            <label class="form-label d-flex justify-content-between">Country</label>
                            <select class="form-select mb-3" onChange={handleChange} value={form.mailingAddress.country} disabled={loading}>
                              <option value="US">United States</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="my-3">
                            <label className="form-label d-flex justify-content-between">
                              Zip
                              <small className="text-danger ms-1">{error.mailingAddress.zip}</small>
                            </label>

                            <input className="form-control" type="text" id="mailingAddress.zip" name="mailingAddress.zip" onChange={handleChange} value={form.mailingAddress.zip} disabled={loading} />
                          </div>
                        </div>
                        <div>
                          <div>
                            <div class="form-check  mb-4">
                              <input
                                type="checkbox"
                                name="sameAsMailingAddress"
                                id="sameAsMailingAddress"
                                className="form-check-input"
                                checked={form.sameAsMailingAddress}
                                onChange={handleChange}
                                disabled={loading}
                              />
                              <label class="form-check-label" for="sameAsMailingAddress">
                                Company Billing Address is same as Mailing Address
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {!form.sameAsMailingAddress ? (
                        <>
                          <div class=" mt-3">
                            <div class="d-flex justify-content-between">
                              <h3 class="form-check px-0">
                                <label class="form-check-label" for="billing">
                                  Billing Address
                                </label>
                              </h3>
                            </div>
                            <small class="text-muted d-block mt-3">
                              The billing contact is an additional person who can log in and has same permissions as the primary contact i.e. they can create new applications and view historical
                              agreements and related information.
                            </small>

                            <div className="row">
                              <div className="col">
                                <div className="mt-3">
                                  <label className="form-label d-flex justify-content-between">
                                    Street Address
                                    <small className="text-danger ms-1">{error.billingAddress.address}</small>
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    id="billingAddress.address"
                                    name="billingAddress.address"
                                    onChange={handleChange}
                                    disabled={loading}
                                    value={form.billingAddress.address}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="mt-3">
                                  <label className="form-label d-flex justify-content-between">
                                    City
                                    <small className="text-danger ms-1">{error.billingAddress.city}</small>
                                  </label>

                                  <input
                                    className="form-control"
                                    type="text"
                                    id="billingAddress.city"
                                    name="billingAddress.city"
                                    onChange={handleChange}
                                    value={form.billingAddress.city}
                                    disabled={loading}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <div className="mt-3">
                                  <label className="form-label d-flex justify-content-between">
                                    State
                                    <small className="text-danger ms-1">{error.billingAddress.state}</small>
                                  </label>
                                  <select
                                    className="form-select  mb-3"
                                    name="billingAddress.state"
                                    id="billingAddress.state"
                                    onChange={handleChange}
                                    value={form.billingAddress.state}
                                    disabled={loading}
                                  >
                                    {State.map((name, index) => (
                                      <option value={name} key={index}>
                                        {name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div class="col">
                                <div className="mt-3">
                                  <label class="form-label d-flex justify-content-between">Country</label>
                                  <select class="form-select mb-3" onChange={handleChange} value={form.billingAddress.country} disabled={loading}>
                                    <option value="US">United States</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="my-3">
                                  <label className="form-label d-flex justify-content-between">
                                    Zip
                                    <small className="text-danger ms-1">{error.billingAddress.zip}</small>
                                  </label>

                                  <input
                                    className="form-control"
                                    type="text"
                                    id="billingAddress.zip"
                                    name="billingAddress.zip"
                                    onChange={handleChange}
                                    value={form.billingAddress.zip}
                                    disabled={loading}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <div className="">
                            <label className="form-label d-flex justify-content-between">
                              Billing
                              <small className="text-danger ms-1">{error.billingPeriod}</small>
                            </label>
                            <div className="row">
                              <div className="col-md-4 undefined">
                                <div className="form-check">
                                  <input
                                    type="radio"
                                    name="billingPeriod"
                                    id="billingPeriod"
                                    value={"YEARLY"}
                                    className="form-check-input"
                                    checked={form.billingPeriod === "YEARLY"}
                                    onChange={handleChange}
                                    disabled={loading}
                                  />
                                  <label className="form-check-label" htmlFor="billingPeriod">
                                    Yearly
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <label className="form-label d-flex justify-content-between">
                            Applicant is a<small className="text-danger ms-1">{error.applicantshipType}</small>
                          </label>
                          <div className="row">
                            {ApplicantType.map((name, index) => (
                              <div key={index} className={"col-md-4"}>
                                <div className="form-check">
                                  <input
                                    type="radio"
                                    name={"applicantshipType"}
                                    value={name}
                                    id={name}
                                    checked={form.applicantshipType === name}
                                    className="form-check-input"
                                    onChange={handleChange}
                                    disabled={loading}
                                  />
                                  <label htmlFor={name}>{name}</label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="mt-3">
                            <label className="form-label d-flex justify-content-between">
                              State of formation
                              <small className="text-danger ms-1">{error.stateOfFormation}</small>
                            </label>
                            <select className="form-select mb-3" name="stateOfFormation" id="stateOfFormation" onChange={handleChange} value={form.stateOfFormation} disabled={loading}>
                              {State.map((name, index) => (
                                <option value={name} key={index}>
                                  {name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModelBody>
            <ModelFooter>
              <button type="button" className="btn btn-secondary btn-lg" onClick={() => closeModal()}>
                Close
              </button>
              <button type="submit" className="btn btn-primary btn-lg">
                {loading ? (
                  <>
                    <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                    Updating...{" "}
                  </>
                ):"Update"}
              </button>
            </ModelFooter>
          </form>
        </Model>
      )}
    </div>
  );
};
