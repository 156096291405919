const findAnySpecialCharacters = (str) => {
  const format = /^[!@#$%^&*()+\=\[\]{};':"\\|,<>\/?]*$/;
  const letters = str.split("");
  for (let i = 0; i < letters.length; i++) {
    if (letters[i].match(format) ? true : false) return false;
  }
  // console.log(true)
  return true;
};

const checkFileName = (array) => {
  if (Array.isArray(array)) {
    const errorFileName = array.filter((data) => {
      if (data.split(".").length === 2) {
        if (!findAnySpecialCharacters(data)) return data;
      } else {
        return data;
      }
    });
    if (errorFileName.length === 0) {
      return true;
    } else {
      return false;
    }
  } else {
    if (array.split(".").length === 2) {
      if (findAnySpecialCharacters(array)) return true;
    } else {
      return false;
    }
  }
};
export default checkFileName;
