import ReactHtmlParser from "react-html-parser";
export const Paragraph = ({ text, css, isIconRequired = false }) => {
  return (
    <p style={css}>
      {isIconRequired ? <i className="far fa-hand-point-right"></i> : null}

      {ReactHtmlParser(text)}
    </p>
  );
};
