import DashBoardLoading from "../components/loaders/DashBoardLoading";
import ApplicationReviewComponent from "../components/applicationReview/ApplicationReview";
import { useApplication } from "../hooks/useApplication";
import { useUser } from "../hooks/useUser";

const Page = (props) => {
  const application = useApplication();
  const user = useUser();
  if (!application || !user) {
    return <DashBoardLoading />;
  }

  return <ApplicationReviewComponent {...props} />;
};

export default Page;
