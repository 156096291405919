import { TitleSec } from "./TitleSec";
import React, { useContext, useState } from "react";
import { formContext } from "../../routes/routes";
import { useParams } from "react-router";
export const CheckboxGroup = ({
  label = "",
  name = "",
  options = [],
  css = {},
  register,
  errorMessage = false,
  checkBoxPosition = "",
  style = "",
  checkBoxStyle = {},
  additonalComments = "",
  additonalLabel = "",
  condtionalLabel = "",
}) => {
  const schemaContext = useContext(formContext);
  const [addCheck, setAddCheck] = useState(false);
  if (!Array.isArray(options)) throw new Error(`Accepted radio options as Array but got ${typeof options}`);
  const checkAllFun = (e) => {
    if (e.target.checked) {
      setAddCheck(true);
    } else {
      setAddCheck(false);
    }
    const newSchema = schemaContext.schema.sections.map((section) => {
      if (section.label === "Applicant's Checklist") {
        section.questions &&
          section.questions.map(
            (question) =>
              question.rows &&
              question.header === name &&
              question.rows.map(
                (row) =>
                  row.columns &&
                  row.columns.map((col) => {
                    if (col.options) {
                      col.options.map((option) => (option.isChecked = e.target.checked));
                    }
                  }),
              ),
          );
      }
      return section;
    });

    schemaContext.setSchema({ sections: newSchema });
  };

  const checkboxCheck = (e, i) => {
    const newSchema = schemaContext.schema.sections.map((section) => {
      if (section.label === "Applicant's Checklist") {
        section.questions &&
          section.questions.map(
            (question) =>
              question.rows &&
              question.header === name &&
              question.rows.map(
                (row) =>
                  row.columns &&
                  row.columns.map((col) => {
                    if (col.options) {
                      col.options[i].isChecked = e.target.checked;
                      if (e.target.checked) {
                        setAddCheck(true);
                      } else {
                        setAddCheck(false);
                      }
                      col.options.map((val) => {
                        if (val.isChecked) {
                          setAddCheck(true);
                        }
                      });
                    }
                  }),
              ),
          );
      }
      return section;
    });
    schemaContext.setSchema({ sections: newSchema });
  };
  return (
    <div>
      <div className={`${checkBoxPosition} row`}>
        {label.length ? <TitleSec title={label} /> : null}
        {additonalComments ? <label style={{ color: "#8898AA", fontSize: "13.6px", marginLeft: "8px" }}>{additonalComments}</label> : null}
        {additonalLabel ? <label>{additonalLabel}</label> : null}
        {condtionalLabel ? (
          <label
            style={{
              color: "#0079C2",
              fontWeight: "600",
              fontSize: "17px",
            }}
          >
            {condtionalLabel}
          </label>
        ) : null}
        <div className="`mb-12`">
          <div className="form-check p-0">
            <input
              style={{ width: "20px", height: "20px", paddingTop: "10px" }}
              type="checkbox"
              //className="form-check-input"
              value=""
              //checked={options.map((option)=>option.length)}
              onClick={(e) => {
                checkAllFun(e);
              }}
              {...register(name, { validation: true })}
            ></input>
            <label className="form-check-label" style={{ fontSize: "24px", marginLeft: "15px" }}>
              Check All
            </label>
          </div>
        </div>
        {options.map((option, index) => (
          <div key={index} className={style || "`mb-2`"} style={checkBoxStyle}>
            <div className="form-check" style={option.styleInner}>
              {/* {validation
                ? option.isChecked
                  ? pass.push(name)
                  : fail.push(name)
                : null} */}
              <input
                style={css}
                type={"checkbox"}
                className="form-check-input"
                //data-option={option}
                name={name}
                id={option.value}
                checked={option.isChecked}
                // defaultChecked={data[name] && data[name].includes(option.value) ?  true :  false}
                value={option.value}
                onClick={(e) => checkboxCheck(e, index)}
                {...register(name, { validation: true })}
                //checked={option.isChecked}
              />

              <label className="form-check-label" htmlFor={option.value} style={css}>
                {option.label}
              </label>

              {option.linkContext && (
                <a href={option.linkAction} target="_blank">
                  {" "}
                  {option.linkContext}
                </a>
              )}

              {option.subLabel && (
                <label className="mx-2 my-1" htmlFor={option.value}>
                  {option.subLabel}
                </label>
              )}
            </div>
          </div>
        ))}

        <>
          {errorMessage && (
            <>
              {!addCheck ? (
                <span className="text-danger">
                  <span>Select a value for {name}</span>
                </span>
              ) : null}{" "}
            </>
          )}
        </>
      </div>
    </div>
  );
};
