import React from "react";
import { getLicenseStatus, getcurrentStatus, getFormData } from "../../api/currentApplications";

import UploadDraftLoading from "../loaders/UploadDraftLoading";
import CommonNavigation from "../commonNavigation/CommonNavigation";
import { Netsuite } from "../../schema/NetSuiteSchema";
import { getNetsuiteInitialValue } from "../../api/Netsuite";
import Finalpage from "./Finalpage";
import FinalRealseWithNetsuite from "./FinalRealseWithNetsuite";
import { useAtoms } from "../../hooks/useAtoms";
import { useUser } from "../../hooks/useUser";
import { useApplication } from "../../hooks/useApplication";

export default function FInalReleasePage(props) {
  const [compileValue, setCompileValue] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [netSuiteIds, setNetSuiteIds] = React.useState({});
  const [netsuiteField, setNetsuiteValue] = React.useState({
    CUSTOMER: {},
    PROPERTY: {},
    UNIT: {},
    OPPORTUNITY: {},
    CASHSALE: {},
    VENDORBILL: {},
    INSURANCE: {},
    SALESORDER: {},
    CASHSALE2: {},
    VENDORBILL2: {},
    CUSTOMERDEPOSIT: {},
    AGREEMENT: {},
    UTILITY: {},
    CLOUDEXTENTEND: {},
  });
  const [records, setRecords] = React.useState([]);
  const application = useApplication();
  let ItemId = props.match.params.applicationId;
  let arrayOfnesuite = [];
  let sectionArray = Netsuite.sections.map((section, inde) => {
    section.questions.map((val, ind) => {
      if (val.final === "true") {
        arrayOfnesuite.push(val);
      }
    });
  });
  React.useEffect(() => {
    const array = arrayOfnesuite.map((section, inde) => {
      return section?.recordName;
    });
    setRecords(array);
  }, []);
  React.useEffect(() => {
    const fetchApplicationData = async () => {
      const { netSuite = {} } = application;
      setNetSuiteIds(netSuite);
    };
    fetchApplicationData();
  }, []);
  React.useEffect(() => {
    records.forEach(async (records) => {
      let res = await getNetsuiteInitialValue(ItemId, records);
      let response = res?.data?.data;
      setNetsuiteValue((initialValue) => ({
        ...initialValue,
        [records]: response,
      }));
    });
  }, [records]);
  const { setNavigation, setHideNavigation } = useAtoms();
  setHideNavigation(false);

  const [stage, setStage] = React.useState("");
  const [applicationTitle, setApplicationTitle] = React.useState();
  setNavigation(`Final Release ${" "} ${applicationTitle ? applicationTitle : ItemId} `);
  const [applicationsignedDocumentsEnvelopeId, setapplicationsignedDocumentsEnvelopeId] = React.useState("");
  const { roleName = "" } = useUser() || {};
  React.useEffect(() => {
    const fetchData = async () => {
      let response = await getLicenseStatus(ItemId);
      setCompileValue(response.data.data);
      let newRes = await getcurrentStatus(ItemId);
      setStage(newRes.data.data);
      setLoading(false);
      getFormData(ItemId).then(({ data }) => {
        setApplicationTitle(data.ApplicationTitle && data.ApplicationTitle);
        if (data && data.signedDocumentsEnvelopeId) {
          setapplicationsignedDocumentsEnvelopeId(data.signedDocumentsEnvelopeId);
        }
      });
    };
    fetchData();
  }, []);
  let { compiled } = compileValue || [];

  let compiles0 = (compiled && compiled[0]) || [];
  let { formData } = compiles0 || {};
  let filedata = formData && formData[0];
  let { fileNameOriginal, fileNameUpdated, signedDocumentsEnvelopeId } = filedata || "";
  let compiles1 = (compiled && compiled[1]) || [];
  let { formData: formData1 } = compiles1 || {};
  let filedata1 = formData1 && formData1[0];
  let filedata2 = formData1 && formData1[1];
  let { fileNameOriginal: fileNameOriginal1, fileNameUpdated: fileNameUpdated1 } = filedata1 || "";
  let { fileNameOriginal: fileNameOriginal2, fileNameUpdated: fileNameUpdated2 } = filedata2 || "";

  let compiles4 = (compiled && compiled[4]) || [];
  let { formData: formData4 } = compiles4 || {};
  let filedata4 = formData4 && formData4[0];
  let filedata7 = formData4 && formData4[1];
  let { fileNameOriginal: fileNameOriginal4, fileNameUpdated: fileNameUpdated4 } = filedata4 || "";
  let { fileNameOriginal: fileNameOriginal7, fileNameUpdated: fileNameUpdated7 } = filedata7 || "";

  let OperatingSigneeData = (compiled && compiled[5]) || [];
  let { action: OperatingSigneeaction } = OperatingSigneeData || {};

  let compiles5 = (compiled && (OperatingSigneeaction == "Send" ? compiled[5] : compiled[6])) || [];
  let { formData: formData5 } = compiles5 || {};
  let filedata5 = (formData5 && formData5[0]) || {};
  let { formData: formData6 } = compiles5;
  let formValuesD = (formData6 && formData6[1]) || [];
  let { fileNameOrginal: fileNameOrginal6, fileNameUpdated: fileNameUpdated6 } = formValuesD;
  let { fileNameOriginal: fileNameOriginal5, fileNameUpdated: fileNameUpdated5 } = filedata5 || "";

  return loading ? (
    <UploadDraftLoading />
  ) : (
    <div>
      <div className="border-bottom mb-4 bg-white">
        <div className="w-75 mx-auto">
          <div className="">
            <CommonNavigation ItemId={ItemId} />
          </div>
        </div>
      </div>
      <div>
        {stage.currentStep !== 25 ? (
          <>
            <h2 className="col d-flex justify-content-center">Please complete the previous steps</h2>
          </>
        ) : roleName === "Approving Representative" || roleName === "Approving Accountant" ? (
          <FinalRealseWithNetsuite
            applicationsignedDocumentsEnvelopeId={applicationsignedDocumentsEnvelopeId}
            setLoading={setLoading}
            fileNameOriginal={fileNameOriginal}
            signedDocumentsEnvelopeId={signedDocumentsEnvelopeId}
            fileNameUpdated={fileNameUpdated}
            fileNameUpdated1={fileNameUpdated1}
            fileNameUpdated2={fileNameUpdated2}
            fileNameUpdated4={fileNameUpdated4}
            fileNameUpdated5={fileNameUpdated5}
            fileNameUpdated6={fileNameUpdated6}
            fileNameUpdated7={fileNameUpdated7}
            arrayOfnesuite={arrayOfnesuite}
            netSuiteIds={netSuiteIds}
            netsuiteField={netsuiteField}
            fileNameOriginal1={fileNameOriginal1}
            fileNameOriginal2={fileNameOriginal2}
            fileNameOriginal4={fileNameOriginal4}
            fileNameOriginal5={fileNameOriginal5}
            fileNameOrginal6={fileNameOrginal6}
            fileNameOriginal7={fileNameOriginal7}
            ItemId={ItemId}
          />
        ) : (
          <Finalpage
            applicationsignedDocumentsEnvelopeId={applicationsignedDocumentsEnvelopeId}
            fileNameOriginal={fileNameOriginal}
            signedDocumentsEnvelopeId={signedDocumentsEnvelopeId}
            fileNameUpdated={fileNameUpdated}
            fileNameUpdated1={fileNameUpdated1}
            fileNameUpdated2={fileNameUpdated2}
            fileNameUpdated4={fileNameUpdated4}
            fileNameUpdated5={fileNameUpdated5}
            fileNameUpdated6={fileNameUpdated6}
            fileNameUpdated7={fileNameUpdated7}
            fileNameOriginal1={fileNameOriginal1}
            fileNameOriginal2={fileNameOriginal2}
            fileNameOriginal4={fileNameOriginal4}
            fileNameOriginal5={fileNameOriginal5}
            fileNameOrginal6={fileNameOrginal6}
            fileNameOriginal7={fileNameOriginal7}
            ItemId={ItemId}
          />
        )}
      </div>
    </div>
  );
}
