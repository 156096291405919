/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { getFormData } from "../../api/currentApplications";
import { getcurrentStatus, DeleteApplication } from "../../api/currentApplications";
import { useHistory } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { getApplicationPaymentForDashboard, getCompiledAmountForDashboard } from "../../utils/getPaymentAmount";
function ApplicationGridViewCard(props) {
  const { v } = props;
  const history = useHistory();
  const [applicationData, setApplicationData] = useState(null);
  const [otherApplicationData, setOtherApplicationData] = useState(null);
  useEffect(() => {
    getFormData(v._id)
      .then(({ data }) => {
        setApplicationData(data.applicationData);
        delete data.applicationData;
        setOtherApplicationData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let { draft } = v.agreement || {};
  let { formData: formData1 } = (draft && draft[2]) || {};
  let activity_number = (formData1 && formData1[0]) || "";
  const { roleName = "" } = useUser() || {};
  localStorage.setItem("applicationId", v._id);

  const callvalue = async () => {
    if (v.applicationStage === 1) {
      history.push(`/applicationReview/${v._id}`);
    }
    if (v.applicationStage === -1 || v.applicationStage === 0) {
      history.push(`/${v.formName}/${v._id}`);
    }
    localStorage.setItem("headerId", v._id);
    let newRes = await getcurrentStatus(v._id);
    if (newRes.data.data.currentStage === "Applicant Review") {
      history.push(`/applicantView/${v._id}`);
    }
    if (newRes.data.data.currentStage === "Initial Review" || newRes.data.data.currentStage === "Approved") {
      history.push(`/applicationReview/${v._id}`);
    }
    if (
      newRes.data.data.currentStage === "Draft Agreement Assign Signee" ||
      (newRes.data.data.currentStage === "Signature" && newRes.data.data.id === 9) ||
      newRes.data.data.currentStage === "Validate Signature" ||
      newRes.data.data.currentStage === "Draft Agreement Generation" ||
      newRes.data.data.currentStage === "Draft Final Review" ||
      newRes.data.data.currentStage === "Draft Agreement Review" ||
      newRes.data.data.currentStage === "NCRR Review Draft Agreement"
    ) {
      history.push(`/draftAgreement/${v._id}`);
    }
    if (newRes.data.data.currentStage === "Engineering Review") {
      history.push(`/review/${v._id}`);
    }
    if (newRes.data.data.currentStage === "Payment" && newRes.data.data.id === 18) {
      history.push(`/compiledAgreement/${v._id}`);
    }
    if (
      newRes.data.data.currentStage === "Compile Agreement Assign Signee" ||
      newRes.data.data.currentStage === "Compile License Agreement" ||
      newRes.data.data.currentStage === "Upload Insurance" ||
      newRes.data.data.currentStage === "Validate Uploaded Insurance" ||
      (newRes.data.data.currentStage === "Signature" && newRes.data.data.id === 20) ||
      (newRes.data.data.currentStage === "Signature" && newRes.data.data.id === 24) ||
      newRes.data.data.currentStage === "Validate Fee, Insurance and Agreement" ||
      newRes.data.data.currentStage === "Upload Letter and Validate" ||
      newRes.data.data.currentStage === "Validate and Sign" ||
      newRes.data.data.currentStage === "Final Release"
    ) {
      history.push(`/compiledAgreement/${v._id}`);
    }
    if (newRes.data.data.currentStage === "Completed" && v.applicationStage != -1) {
      history.push(`/finalRelease/${v._id}`);
    }
  };

  let { comments } = v || {};

  let { applicationComments, engineeringComments } = comments || {};
  let { applicant: appicantapplicationComments, resolved: resolvedapplicationComments } = applicationComments || {};

  let { team: teamengineeringComments, approver: approverengineeringComments, applicant: appicantengineeringComments, resolved: resolvedengineeringComments } = engineeringComments || {};

  let applicantCommentsId = appicantapplicationComments && appicantapplicationComments.map((aID) => aID.questionID);

  let resolveCommentId = resolvedapplicationComments && resolvedapplicationComments.map((rID) => rID.questionID);

  let applicantCommentsIdE = appicantengineeringComments && appicantengineeringComments.map((aID) => aID.questionID);
  let approverCommentIdE = approverengineeringComments && approverengineeringComments.map((aID) => aID.questionID);
  let teamCommentIdE = teamengineeringComments && teamengineeringComments.map((tID) => tID.questionID);

  let resolveCommentIdE = resolvedengineeringComments && resolvedengineeringComments.map((rID) => rID.questionID);

  let AppComm = [...new Set(applicantCommentsId)].length - resolveCommentId.length;
  let EnggComm = [...new Set([...applicantCommentsIdE, ...approverCommentIdE, ...teamCommentIdE])].length - resolveCommentIdE.length;

  const DeleteId = async () => {
    const id = localStorage.getItem("deleteId");
    if (id) {
      const obj1 = {
        applicationID: id,
      };

      try {
        await DeleteApplication(obj1);
        localStorage.removeItem("deleteId");
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }
  };
  activity_number = activity_number.toString();
  return (
    <div className="card  position-relative mb-4 ">
      <div className="card-header bg-transparent cursor-pointer">
        <div className="row align-items-top">
          <div className="col-md-3" style={{ cursor: "pointer" }} onClick={() => callvalue()}>
            <label className="text-muted d-block">Utility Application</label>
            <p>#{v.ApplicationTitle && v.ApplicationTitle ? v.ApplicationTitle : v._id}</p>
          </div>
          <div className="col-md-2" style={{ cursor: "pointer" }} onClick={() => callvalue()}>
            <label className="text-muted d-block">HNTB File#</label>
            <p>{v.HNTBFileNumber}</p>
          </div>
          <div className="col-md-2" style={{ cursor: "pointer" }} onClick={() => callvalue()}>
            <label className="text-muted d-block">Activity Number</label>
            {/* Activity 3 */}
            <p>{activity_number ? activity_number : ""}</p>
          </div>
          <div className="col-md-3" style={{ cursor: "pointer" }} onClick={() => callvalue()}>
            <label className="text-muted d-block">Application Type</label>
            <p>{v.formNumber}</p>
          </div>
          <div className="col-md-4" style={{ cursor: "pointer" }} onClick={() => callvalue()}>
            <label className="text-muted d-block">Status</label>
            <div className="d-flex">
              {otherApplicationData ? (
                <p className="pe-3">
                  {otherApplicationData ? (
                    <p className="pe-3">
                      {v.applicationStage === -1 ||
                      //v.applicationStage === 1 ||
                      v.applicationStage === 0
                        ? "Draft Application"
                        : null}
                      {otherApplicationData.rejected ? "Rejected" : null}
                      {v.applicationStage === 1 ? (
                        <>
                          {" "}
                          Payment <span className="value">(Draft Application) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 2 ? (
                        <>
                          {" "}
                          Applicant Review <span className="value">(Initial Review) </span>
                        </>
                      ) : null}

                      {v.applicationStage === 3 && !otherApplicationData.rejected ? (
                        <>
                          {" "}
                          Applicant Review <span className="value">(Initial Review) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 4 || v.applicationStage === 5 ? "Engineering Review" : null}
                      {v.applicationStage === 6 ? "Engineering Review" : null}
                      {v.applicationStage === 7 ? (
                        <>
                          Draft Agreement <span className="value">(Assign Signee) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 8 ? (
                        <>
                          {" "}
                          Draft Agreement <span className="value">(Signature) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 9 ? (
                        <>
                          {" "}
                          Draft Agreement <span className="value">(Validate Signature) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 10 ? (
                        <>
                          {" "}
                          Draft Agreement <span className="value">(Agreement Generation) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 11 ? (
                        <>
                          {" "}
                          Draft Agreement <span className="value">(NCRR Review) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 12 ? (
                        <>
                          {" "}
                          Draft Agreement <span className="value">(NS Review) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 13 ? (
                        <>
                          {" "}
                          Draft Agreement <span className="value">(HNTB Review) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 14 ? (
                        <>
                          {" "}
                          Compile Agreement <span className="value">(License Agreement) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 15 ? (
                        <>
                          {" "}
                          Compile Agreement <span className="value">(Upload Insurance) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 16 ? (
                        <>
                          {" "}
                          Compile Agreement <span className="value">(Validate Uploaded Insurance) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 17 ? (
                        <>
                          {" "}
                          Compile Agreement <span className="value">(Payment) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 18 ? (
                        <>
                          {" "}
                          Compile Agreement <span className="value">(Assign Signee) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 19 ? (
                        <>
                          {" "}
                          Compile Agreement <span className="value">(Signature) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 20 ? (
                        <>
                          {" "}
                          Compile Agreement <span className="value">(Validate Fee, Insurance and Agreement) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 21 ? (
                        <>
                          {" "}
                          Compile Agreement <span className="value">(Validate and Sign By V.P) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 22 ? (
                        <>
                          {" "}
                          Compile Agreement <span className="value">(Upload Letter and Validate) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 23 ? (
                        <>
                          {" "}
                          Compile Agreement <span className="value">(Validate and Sign By Operating Signee) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 24 ? (
                        <>
                          {" "}
                          Compile Agreement <span className="value">(Final Release) </span>
                        </>
                      ) : null}
                      {v.applicationStage === 25 ? "Final Release" : null}
                    </p>
                  ) : null}
                </p>
              ) : null}
              <p className="border-start ps-3">{EnggComm + AppComm} Open comments</p>
            </div>
          </div>
        </div>
      </div>
      <div className="card-header bg-transparent">
        <div className="row align-items-center">
          <div className="col-md-2" style={{ cursor: "pointer" }} onClick={() => callvalue()}>
            <label className="text-muted d-block">Applicant</label>
            <p>{v.applicantDetails && v.applicantDetails?.facilityCompany?.name}</p>
          </div>
          <div className="col-md-3" style={{ cursor: "pointer" }} onClick={() => callvalue()}>
            <label className="text-muted d-block">Applicant’s Primary Contact</label>
            <p>{v.applicantDetails && v.applicantDetails?.consultantContact?.name}</p>
          </div>
          {roleName === "Approving Accountant" && (
            <div className="col-md-2" style={{ cursor: "pointer" }} onClick={() => callvalue()}>
              <label className="text-muted d-block">Payment </label>
              <p>
                <ol className="m-0 px-3">
                  {getApplicationPaymentForDashboard(v) && <li className="mb-0">{getApplicationPaymentForDashboard(v)}</li>}
                  {getCompiledAmountForDashboard(v) && <li className="mb-0">{getCompiledAmountForDashboard(v)}</li>}
                </ol>
              </p>
            </div>
          )}

          <div className="col-md-3" style={{ cursor: "pointer" }} onClick={() => callvalue()}>
            <label className="text-muted d-block">Action</label>
            <p>
              {((v.applicationStage === 17 && !v.event2paymentOn) || (v.applicationStage === 1 && !v.event1paymentOn)) && roleName === "applicant" ? (
                <a style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                  Waiting for Payment
                </a>
              ) : null}

              {v.applicationStage === 15 && roleName === "applicant" ? (
                <a style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                  Waiting for insurance
                </a>
              ) : null}

              {(v.applicationStage === 7 || v.applicationStage === 8 || v.applicationStage === 18 || v.applicationStage === 19) && roleName === "applicant" ? (
                <a style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                  Waiting for Signature
                </a>
              ) : null}

              {((v.applicationStage === 1 && (v.event1paymentType === "Check" || v.event1paymentType === "ACH")) ||
                (v.applicationStage === 17 && (v.event2paymentType === "Check" || v.event2paymentType === "ACH"))) &&
              roleName === "Approving Accountant" ? (
                <a style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                  Awaiting for your Approval
                </a>
              ) : null}

              {(v.applicationStage === 2 || v.applicationStage === 3 || v.applicationStage === 6 || v.applicationStage === 11 || v.applicationStage === 16 || v.applicationStage === 20) &&
              roleName === "Approving Representative" ? (
                <a style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                  Awaiting for your Approval
                </a>
              ) : null}

              {(v.applicationStage === 6 || v.applicationStage === 9 || v.applicationStage === 10 || v.applicationStage === 13 || v.applicationStage === 14 || v.applicationStage === 24) &&
              roleName === "Engineering Representative" ? (
                <a style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                  Awaiting for your Approval
                </a>
              ) : null}

              {v.applicationStage === 23 && roleName === "Operating Signee" ? (
                <a style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                  Awaiting for your Approval
                </a>
              ) : null}

              {(v.applicationStage === 6 || v.applicationStage === 12 || v.applicationStage === 22) && roleName === "Operating Representative" ? (
                <a style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                  Awaiting for your Approval
                </a>
              ) : null}
              {(v.applicationStage === 6 || v.applicationStage === 12 || v.applicationStage === 22) && roleName === "Operating Engineering Representative" ? (
                <a style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                  Awaiting for your Approval
                </a>
              ) : null}

              {v.applicationStage === 21 && roleName === "Signing Representative" ? (
                <a style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                  Awaiting for your Signature
                </a>
              ) : null}
            </p>
          </div>
          {/* Delete Aplication */}
          <div className="col-md-1 mb-2  d-flex justify-content-end">
            {v.applicationStage === -1 && (
              <>
                <div className="dropdown" onClick={() => localStorage.setItem("deleteId", v._id)}>
                  <a className="toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <i style={{ fontSize: "15px" }} className="fa">
                      &#xf142;
                    </i>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a className="dropdown-item discard" data-bs-toggle="modal" data-bs-target="#exampleModals">
                        Discard application
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="modal fade" id="exampleModals" tabIndex="-1" aria-labelledby="exampleModalLabels" aria-hidden="true">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabels">
                          Delete Application
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">Are you sure you want to Delete this Application</div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                          NO
                        </button>
                        <button onClick={DeleteId} type="button" className="btn btn-danger">
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {otherApplicationData && otherApplicationData.rejected ? null : (
        <div className="card-body">
          <div className="row">
            {applicationData
              ? Object.keys(applicationData)
                  .slice(0, 8)
                  .map((v, i) => (
                    <div className="col-md-3" key={i}>
                      <label className="text-muted d-block">{v}</label>
                      <p>{applicationData[v]}</p>
                    </div>
                  ))
              : null}
          </div>
        </div>
      )}
    </div>
  );
}

export default ApplicationGridViewCard;
