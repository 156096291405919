import { AES, enc } from "crypto-js";
const ENCRYPTION_KEY = "your-secret-key-for-local-storage";
// type = ENCRYPT DECRYPTE
const CrypteLocalstroge = (data, type) => {
  const encryptData = (data) => {
    try {
      const ciphertext = AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
      return ciphertext;
    } catch (error) {
      console.error("Error decrypting data:", error);
      return null; // Handle decryption error appropriately
    }
  };

  const decryptData = (ciphertext) => {
    try {
      const bytes = AES.decrypt(ciphertext, ENCRYPTION_KEY);
      const decryptedData = JSON.parse(bytes.toString(enc.Utf8));
      return decryptedData;
    } catch (error) {
      console.error("Error decrypting data:", error);
      return null; // Handle decryption error appropriately
    }
  };

  let returndata = null;
  if (type === "ENCRYPT") {
    returndata = encryptData(data);
  }
  if (type === "DECRYPTE") {
    returndata = decryptData(data);
  }
  return returndata;
};

export default CrypteLocalstroge;
