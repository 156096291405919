import { useHistory, Route, useLocation } from "react-router";

export const ProtectedRoute = ({ path, Component }) => {
  let history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;
  if (!localStorage.getItem("token")) {
    const redirectUrl = encodeURIComponent(pathname + search);
    history.push({
      pathname: `/login`,
      search: redirectUrl && `?redirect=${redirectUrl}`,
    });
    return null;
  }

  return <Route key={path} component={Component} path={path} exact />;
};
