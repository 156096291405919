/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getLicenseStatus, sendNotify } from "../../api/currentApplications";
import UploadDraftLoading from "../loaders/UploadDraftLoading";
import { API_URL, File, REACT_APP_NS_risk_finance_fee_original } from "../../api";
import CompiledPayment from "../compiledPayment/CompiledPayment";
import { useUser } from "../../hooks/useUser";

export default function ValidateAndSign(props) {
  const [compileValue, setCompileValue] = useState([]);
  let { ItemId } = props;
  const [loading, setLoading] = useState(true);
  const [riskFinanceFee, setReskFinanceFee] = useState(false);

  useEffect(async () => {
    let response = await getLicenseStatus(ItemId);
    setCompileValue(response.data.data);
    let value = response.data.data.compiled && response.data.data.compiled.filter((data) => data.action === "Sign and Paid");
    setReskFinanceFee(value[0].riskFinanceFee === "true" ? true : false);
    setLoading(false);
  }, []);
  const [compileSignedValue, setCompileSignedValue] = useState([]);
  const { roleName = "" } = useUser() || {};

  useEffect(async () => {
    const object = {
      applicationID: ItemId,
      signerEmail: ["Test"],
      agreementType: "CompiledAgreement",
    };
    let response = await sendNotify(object);
    setCompileSignedValue(response.data.responseData);
  }, []);

  let { compiled } = compileValue || [];

  let compiles0 = (compiled && compiled[0]) || [];
  let { action, formData } = compiles0 || {};
  let filedata = formData && formData[0];
  let { fileNameOriginal, fileNameUpdated, signedDocumentsEnvelopeId } = filedata || "";
  let compiles1 = (compiled && compiled[1]) || [];
  let { date: date1, formData: formData1 } = compiles1 || {};
  let filedata1 = formData1 && formData1[0];
  let filedata2 = formData1 && formData1[1];
  let filedata3 = formData1 && formData1[2];
  let filedata4 = formData1 && formData1[3];

  let { fileNameOriginal: fileNameOriginal1, fileNameUpdated: fileNameUpdated1 } = filedata1 || "";
  let { fileNameOriginal: fileNameOriginal2, fileNameUpdated: fileNameUpdated2 } = filedata2 || "";
  let { fileNameOriginal: fileNameOriginal3, fileName: fileName3, fileNameUpdated: fileNameUpdated3 } = filedata3 || "";
  let { fileNameOriginal: fileNameOriginal4, fileName: fileName4, fileNameUpdated: fileNameUpdated4 } = filedata4 || "";
  let compiles2 = (compiled && compiled[2]) || [];
  let { action: action2, date: date2 } = compiles2 || {};

  const { name: SignerName } = compileSignedValue || {};
  const SignedDate = compileSignedValue ? (compileSignedValue.signedAt ? new Date(compileSignedValue.signedAt).toString().slice(0, 15) : "") : "";

  return loading ? (
    <>
      <UploadDraftLoading />
      <UploadDraftLoading />
    </>
  ) : (
    <div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div id="files-upload" className="">
              <div className="inner-form card mb-4 position-relative comment-card">
                <div className="card-header border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Compiled License Agreement</h2>
                    <small className="text-muted">By HNTB</small>
                  </div>
                </div>
                <div className="card-body">
                  <label htmlFor="license-agreement" className="form-label">
                    Uploaded License Agreement
                  </label>
                  <div className="card bg-secondary border-0">
                    <div className="card-body p-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <i className="fal fa-file-pdf"></i>
                        </div>
                        <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal}</div>
                        <div className="flex-shrink-0">
                          <a
                            href={API_URL + File + (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length === 1 ? signedDocumentsEnvelopeId.length + "_" : "") + fileNameUpdated}
                            className="btn btn-link"
                            target="_blank"
                          >
                            View
                          </a>
                          <a
                            href={API_URL + File + (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length === 1 ? signedDocumentsEnvelopeId.length + "_" : "") + fileNameUpdated}
                            className="btn btn-link"
                            target="_blank"
                          >
                            Download
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-footer py-0 justify-content-center bg-success">
                  <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i> {action}{" "}
                      </span>
                      <span>By HNTB Representative on </span>
                    </span>
                  </a>
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-header  border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Uploaded Insurance</h2>
                    <small className="text-muted">By Applicant</small>
                  </div>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <label className="text-muted d-block">Uploaded Certificates of Insurance</label>
                    <div className="card bg-secondary border-0 mb-2">
                      <div className="card-body">
                        <label className="text-muted d-block form-label">North Carolina Railroad Co., 2809 Highwoods Blvd, Raleigh, NC 27604</label>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <i className="fal fa-file-pdf"></i>
                          </div>
                          <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal1}</div>
                          <div className="flex-shrink-0">
                            <a href={API_URL + File + fileNameUpdated1} target="_blank" className="btn btn-link">
                              View
                            </a>
                            <a href={API_URL + File + fileNameUpdated1} target="_blank" download className="btn btn-link">
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card bg-secondary border-0 mb-2">
                      <div className="card-body ">
                        <label className="text-muted d-block form-label">Norfolk Southern Railway Co., Risk Management Dept, 650 West Peachtree Street NW, Box 46, Atlanta, GA 30308</label>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <i className="fal fa-file-pdf"></i>
                          </div>
                          <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal2}</div>
                          <div className="flex-shrink-0">
                            <a href={API_URL + File + fileNameUpdated2} target="_blank" className="btn btn-link">
                              View
                            </a>
                            <a href={API_URL + File + fileNameUpdated2} target="_blank" download className="btn btn-link">
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="bg-black" style={{ backgroundColor: " rgba(0, 0, 0, 0.5)" }} />
                    <div className="card bg-secondary border-0 mb-3 p-4" id="optionCard1">
                      <div>
                        <div className="form-check mb-3 d-flex align-items-center">
                          <input className="form-check-input" type="checkbox" value="optionOne" id="optionOne" checked={!riskFinanceFee} disabled={true} />
                          <label className="form-check-label text-muted" htmlFor="optionOne" style={{ fontFamily: "Oswald,sans-serif" }}>
                            Option 1
                          </label>
                        </div>
                        <div id="optn1">
                          <p>
                            <small>Upload Proof of Railroad Protective Liability Insurance for NCRR and NSR as specified in the body of the agreement and Exhibit B, Article 9.</small>
                          </p>
                          {!riskFinanceFee && fileName3 && (
                            <div className="card bg-secondary border-0 mb-2">
                              <div className="card-body">
                                <label className="text-muted d-block form-label">North Carolina Railroad Co., 2809 Highwoods Blvd, Raleigh, NC 27604</label>
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0">
                                    <i className="fal fa-file-pdf"></i>
                                  </div>
                                  <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal3}</div>
                                  <div className="flex-shrink-0">
                                    <a href={API_URL + File + fileNameUpdated3} target="_blank" className="btn btn-link">
                                      View
                                    </a>
                                    <a href={API_URL + File + fileNameUpdated3} target="_blank" download className="btn btn-link">
                                      Download
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {!riskFinanceFee && fileName4 && (
                            <div className="card bg-secondary border-0 mb-2">
                              <div className="card-body">
                                <label className="text-muted d-block form-label">Norfolk Southern Railway Co., Risk Management Dept, 650 West Peachtree Street NW, Box 46, Atlanta, GA 30308</label>
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0">
                                    <i className="fal fa-file-pdf"></i>
                                  </div>
                                  <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal4}</div>
                                  <div className="flex-shrink-0">
                                    <a href={API_URL + File + fileNameUpdated4} target="_blank" className="btn btn-link">
                                      View
                                    </a>
                                    <a href={API_URL + File + fileNameUpdated4} target="_blank" download className="btn btn-link">
                                      Download
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card bg-secondary border-0 mb-3 p-4" id="optionCard2">
                      <div>
                        <div className="form-check mb-3 d-flex align-items-center">
                          <input className="form-check-input" type="checkbox" value="" id="flexSwitchCheckChecked" disabled={true} checked={riskFinanceFee} />
                          <label className="form-check-label text-muted" htmlFor="flexSwitchCheckChecked" style={{ fontFamily: "Oswald,sans-serif" }}>
                            Option 2
                          </label>
                        </div>
                        <div id="option2">
                          <div>
                            <p id="optn2">
                              <small>
                                Pay ${REACT_APP_NS_risk_finance_fee_original} Risk Financing Fee to be added to NSR's Master Railroad Protective Liability Insurance coverage, as described in the
                                attached special provision.
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-footer py-0 justify-content-center bg-success">
                  <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i> {action}{" "}
                      </span>
                      <span>
                        {" "}
                        By Applicant's on <small> {date1 ? date1.slice(0, 15) : ""}</small>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
              <CompiledPayment />

              {roleName === "applicant" || roleName === "Engineering Representative" || roleName === "Approving Representative" || roleName === "Signing Representative" ? (
                <div className="card mb-4 position-relative comment-card">
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Sign Agreement</h2>
                      <small className="text-muted">By Applicant</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <p className="m-0 text-center">Agreement Signed.</p>
                  </div>

                  <div className="card-footer py-0 justify-content-center bg-success">
                    <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                      <span className="d-flex justify-content-between align-items-center">
                        <span>
                          <i className="fal fa-check"></i> Signed
                        </span>
                        <span>
                          By {SignerName} on <small> {SignedDate ? SignedDate : ""}</small>
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              ) : null}
              <div className="card mb-4 ">
                <div className="card-header  border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Validated Fee, Insurance and Agreement</h2>
                    <small className="text-muted">By NCRR</small>
                  </div>
                </div>
                <div className="card-body">
                  <p className="m-0 text-center">Validated By NCRR</p>
                </div>
                <div className="card-footer py-0 justify-content-center bg-success">
                  <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i> {action2}{" "}
                      </span>
                      <span>
                        By Justin Madigan, Engineering Rep., NCRR on <small>{date2 ? date2.slice(0, 15) : ""}</small>
                      </span>
                    </span>
                  </a>
                </div>
              </div>

              <div className="card mb-4 border-primary shadow ">
                <div className="card-header  border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0"> Validate and Sign</h2>
                    <small className="text-muted">For VP, Engineering</small>
                  </div>
                </div>
                {roleName !== "Signing Representative" ? (
                  <div className="card-footer justify-content-center " style={{ backgroundColor: "rgb(233 237 241)" }}>
                    Awaiting for VP, Engineering
                    <i className="fal fa-chevron-right ms-2"></i>
                  </div>
                ) : (
                  <div className="card-footer justify-content-center bg-primary">
                    <button
                      onClick={() => props.validateAndSign()}
                      className="d-block text-white"
                      disabled={roleName !== "Signing Representative"}
                      style={{ border: "none", backgroundColor: "#5E74E4" }}
                    >
                      {roleName !== "Signing Representative" ? "Awaiting for VP, Engineering" : "Validate and Sign"} <i className="fal fa-chevron-right ms-2"></i>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
