import React from "react";
import { API_URL, File } from "../../api";
import { Tooltip } from "bootstrap";
import { Netsuite } from "../../schema/NetSuiteSchema";
import formateDate from "../../utils/formatedate";
import { getLicenseStatus } from "../../api/currentApplications";
import { useUser } from "../../hooks/useUser";

export default function FinalRealseWithNetsuite(props) {
  let {
    applicationsignedDocumentsEnvelopeId,
    fileNameOriginal,
    signedDocumentsEnvelopeId,
    fileNameUpdated,
    fileNameUpdated1,
    fileNameUpdated2,
    fileNameUpdated4,
    fileNameUpdated5,
    fileNameUpdated6,
    fileNameUpdated7,
    arrayOfnesuite,
    netSuiteIds,
    netsuiteField,
    ItemId,
    fileNameOriginal1,
    fileNameOriginal2,
    fileNameOriginal4,
    fileNameOriginal5,
    fileNameOrginal6,
    fileNameOriginal7,
    setLoading,
  } = props;
  const [compileValue, setCompileValue] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async (ItemId) => {
      let response = await getLicenseStatus(ItemId);
      setCompileValue(response.data.data);
      setLoading(false);
    };
    fetchData(ItemId);
  }, [ItemId, setLoading]);
  const { roleName = "" } = useUser() || {};
  let { draft } = compileValue || {};
  let DValue = draft && draft[0];

  let { licenseFee: Dform } = DValue || {};
  let lFee = Dform && Dform[0];
  let { license_fee } = lFee || "";

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
  });
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <nav id="newlink">
            <div className="nav nav-tabs tab-link" id="nav-tab" role="tablist">
              <button
                className=" nav-link mb-3 mb-md-0 d-block d-sm-inline-block fw-normal p-3 active"
                id="nav-home-tab "
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                <h2 className="mb-0">Document</h2>
              </button>
              <button
                className=" nav-link mb-3 mb-md-0 d-block d-sm-inline-block fw-normal p-3 "
                id="nav-profile-tab "
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                <h2 className="mb-0">Netsuite</h2>
              </button>
              <button
                className="nav-link  mb-3 mb-md-0 flex-fill  fw-normal p-3 "
                id="nav-profile-tab "
                data-bs-toggle=""
                data-bs-target="#nav-profiles"
                type="button"
                role="tab"
                aria-controls="nav-profiles"
                aria-selected="false"
                disabled
                style={{ cursor: "initial" }}
              >
                <h2 className="mb-0"> </h2>
              </button>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
              <div id="files-upload" className="">
                <div className="card mb-4 shadow border-primary rounded-0 border-top-0">
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Final Release </h2>
                    </div>
                  </div>
                  <div className="card-footer border-0">
                    {roleName === "Approving Representative" || roleName === "Engineering Representative" ? (
                      <div className="mb-3">
                        <label htmlFor="license-agreement" className="form-label">
                          Application
                        </label>
                        <div className="card bg-secondary border-0">
                          <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <i className="fal fa-file-pdf"></i>
                              </div>
                              <div className="flex-grow-1 ms-3 w-50">{"Application"}</div>
                              <div className="flex-shrink-0">
                                <a href={API_URL + File + (applicationsignedDocumentsEnvelopeId ? "1_" : "") + ItemId + ".pdf"} target="_blank" rel="noreferrer" className="btn btn-link">
                                  View
                                </a>
                                <a href={API_URL + File + (applicationsignedDocumentsEnvelopeId ? "1_" : "") + ItemId + ".pdf"} target="_blank" className="btn btn-link" rel="noreferrer" download>
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {roleName === "applicant" ||
                    roleName === "Approving Representative" ||
                    roleName === "Operating Representative" ||
                    roleName === "Operating Signee" ||
                    roleName === "Engineering Representative" ||
                    roleName === "Signing Representative" ||
                    roleName === "Approving Accountant" ? (
                      <div className="mb-3">
                        <label htmlFor="license-agreement" className="form-label">
                          License Agreement
                        </label>
                        <div className="card bg-secondary border-0">
                          <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <i className="fal fa-file-pdf"></i>
                              </div>
                              <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal}</div>
                              <div className="flex-shrink-0">
                                <a
                                  href={API_URL + File + (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length > 1 ? signedDocumentsEnvelopeId.length + "_" : "") + fileNameUpdated}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="btn btn-link"
                                >
                                  View
                                </a>
                                <a
                                  href={API_URL + File + (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length > 1 ? signedDocumentsEnvelopeId.length + "_" : "") + fileNameUpdated}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="btn btn-link"
                                  download
                                >
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {roleName === "Approving Representative" ||
                    roleName === "Operating Representative" ||
                    roleName === "Operating Signee" ||
                    roleName === "Engineering Representative" ||
                    roleName === "Signing Representative" ||
                    roleName === "Approving Accountant" ? (
                      <div className="mb-3">
                        <label htmlFor="license-agreement" className="form-label">
                          Certificates of Insurance
                        </label>
                        <div className="card bg-secondary border-0 mb-2">
                          <div className="card-body">
                            <label className="text-muted d-block form-label">North Carolina Railroad Co., 2809 Highwoods Blvd, Raleigh, NC 27604</label>
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <i className="fal fa-file-pdf"></i>
                              </div>
                              <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal1}</div>
                              <div className="flex-shrink-0">
                                <a href={API_URL + File + fileNameUpdated1} target="_blank" rel="noreferrer" className="btn btn-link">
                                  View
                                </a>
                                <a href={API_URL + File + fileNameUpdated1} target="_blank" download rel="noreferrer" className="btn btn-link">
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card bg-secondary border-0 mb-2">
                          <div className="card-body ">
                            <label className="text-muted d-block form-label">Norfolk Southern Railway Co., Risk Management Dept, 650 West Peachtree Street NW, Box 46, Atlanta, GA 30308</label>
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <i className="fal fa-file-pdf"></i>
                              </div>
                              <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal2}</div>
                              <div className="flex-shrink-0">
                                <a href={API_URL + File + fileNameUpdated2} target="_blank" rel="noreferrer" className="btn btn-link">
                                  View
                                </a>
                                <a href={API_URL + File + fileNameUpdated2} target="_blank" download rel="noreferrer" className="btn btn-link">
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {roleName === "Operating Representative" ||
                    roleName === "Operating Signee" ||
                    roleName === "Approving Representative" ||
                    roleName === "Engineering Representative" ||
                    roleName === "Signing Representative" ||
                    roleName === "Approving Accountant" ? (
                      <div className="mb-3">
                        <label htmlFor="license-agreement" className="form-label">
                          Norfolk Southern Approval Letter Uploaded
                        </label>
                        <div className="card bg-secondary border-0">
                          <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <i className="fal fa-file-pdf"></i>
                              </div>
                              <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal4}</div>
                              <div className="flex-shrink-0">
                                <a href={API_URL + File + fileNameUpdated4} target="_blank" rel="noreferrer" className="btn btn-link">
                                  View
                                </a>
                                <a href={API_URL + File + fileNameUpdated4} target="_blank" download rel="noreferrer" className="btn btn-link">
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <label htmlFor="license-agreement" className="form-label">
                          Recent Insurance Approvals
                        </label>
                        <div className="card bg-secondary border-0">
                          <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <i className="fal fa-file-pdf"></i>
                              </div>
                              <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal7}</div>
                              <div className="flex-shrink-0">
                                <a href={API_URL + File + fileNameUpdated7} target="_blank" rel="noreferrer" className="btn btn-link">
                                  View
                                </a>
                                <a href={API_URL + File + fileNameUpdated7} target="_blank" download className="btn btn-link" rel="noreferrer">
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {roleName === "Approving Representative" ||
                    roleName === "Engineering Representative" ||
                    roleName === "Signing Representative" ||
                    roleName === "Signing Representative" ||
                    roleName === "Approving Accountant" ? (
                      <div className="mb-3">
                        <label htmlFor="license-agreement" className="form-label">
                          Sealed Certification Form
                        </label>
                        <div className="card bg-secondary border-0">
                          <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <i className="fal fa-file-pdf"></i>
                              </div>
                              <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal5}</div>
                              <div className="flex-shrink-0">
                                <a href={API_URL + File + fileNameUpdated5} target="_blank" rel="noreferrer" className="btn btn-link">
                                  View
                                </a>
                                <a href={API_URL + File + fileNameUpdated5} target="_blank" download rel="noreferrer" className="btn btn-link">
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {roleName === "applicant" ||
                    roleName === "Approving Representative" ||
                    roleName === "Engineering Representative" ||
                    roleName === "Signing Representative" ||
                    roleName === "Approving Accountant" ||
                    roleName === "Operating Representative" ? (
                      <div className="mb-3">
                        <label htmlFor="license-agreement" className="form-label">
                          Final Application Approval Letter (Release Letter)
                        </label>
                        <div className="card bg-secondary border-0">
                          <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <i className="fal fa-file-pdf"></i>
                              </div>
                              <div className="flex-grow-1 ms-3 w-50">{fileNameOrginal6}</div>
                              <div className="flex-shrink-0">
                                <a href={API_URL + File + fileNameUpdated6} rel="noreferrer" className="btn btn-link" target="_blank">
                                  View
                                </a>
                                <a href={API_URL + File + fileNameUpdated6} className="btn btn-link" rel="noreferrer" target="_blank" download>
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
              <div id="files-upload ">
                <div className="card p-0 shadow border-primary rounded-0 border-top-0">
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">{Netsuite.header}</h2>
                    </div>
                  </div>
                  {arrayOfnesuite.map((v) => {
                    const { recordName, licenseFee } = v;
                    let validatevalueFee = licenseFee !== license_fee;
                    if (recordName === "CUSTOMERDEPOSIT" && Number(license_fee) === 0) {
                      return null;
                    }
                    if (recordName === "SALESORDER" && Number(license_fee) === 0) {
                      return null;
                    }

                    if (!netSuiteIds?.[v.netSuiteIdKeyName]?.id) return null;

                    return (
                      <>
                        {validatevalueFee && (
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-12 p-0 m-0">
                                <div className="accordion" id={`accordionExample${v.id}`}>
                                  <div id="files-upload ">
                                    <div className="accordion-item rounded-0">
                                      <div className="card">
                                        <div className="card-body p-0">
                                          <h2 className="accordion-header" id={`PaymentInformation${v.id}`}>
                                            <button
                                              className=" accordion-button py-4 px-4 d-flex justify-content-between bg-transparent text-dark fw-normal h2 mb-0 collapsed"
                                              type="button"
                                              data-bs-toggle="collapse"
                                              data-bs-target={`#Collapse${v.id}`}
                                              aria-expanded="false"
                                              aria-controls={`Collapse${v.id}`}
                                            >
                                              {v.header}
                                            </button>
                                          </h2>
                                          <div id={`Collapse${v.id}`} className="accordion-collapse collapse" aria-labelledby={`PaymentInformation${v.id}`} data-bs-parent={`#accordionExample${v.id}`}>
                                            <div className="accordion-body pt-0 p-0">
                                              <div className="card-col card">
                                                <div className="card-body pt-0">
                                                  <div className="row">
                                                    <div className="col-lg-12 pt-3 p-0 d-flex flex-row-reverse">
                                                      <div className="form-check p-0">
                                                        <a target="_blank" href={v.Link + netSuiteIds?.[v.netSuiteIdKeyName]?.id || ""} className="btn btn-link" rel="noreferrer">
                                                          Link to Netsuite
                                                        </a>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-12 p-0">
                                                      <div className="row  m-0 p-0">
                                                        {v &&
                                                          v.rows.map((col, ind) => {
                                                            let item = 0;
                                                            if (col?.itemId) {
                                                              item = col?.itemId;
                                                            }

                                                            return (
                                                              <>
                                                                {col.header ? <h3 className="">{col.header}</h3> : ""}
                                                                {col.label ? (
                                                                  <div className="">
                                                                    <h3 className="form-label">{col.label}</h3>{" "}
                                                                  </div>
                                                                ) : (
                                                                  ""
                                                                )}
                                                                {col.columns.map((data, ind) => {
                                                                  let value = netsuiteField?.[recordName]?.[data.keyName] || "";

                                                                  if (data?.finalReleaseValue) {
                                                                    value = data?.finalReleaseValue;
                                                                  }

                                                                  if (item !== 0) {
                                                                    try {
                                                                      value = netsuiteField?.[recordName]?.items[item - 1]?.[data.keyName];
                                                                    } catch (error) {
                                                                      value = undefined;
                                                                      console.log(error);
                                                                    }
                                                                  }

                                                                  if (!value && item !== 0) {
                                                                    return null;
                                                                  }
                                                                  return (
                                                                    <div className={data.col ? data.col : "col-md-6"}>
                                                                      {data.type === "text" && (
                                                                        <div className="mb-3">
                                                                          <label forhtml={data.name} className="text-muted form-label">
                                                                            {data.label}
                                                                          </label>

                                                                          <input
                                                                            type={data.type}
                                                                            // onChange={( e) => handleNetSuiteChange( data,e, recordName,col)
                                                                            // }
                                                                            className="form-control"
                                                                            name={data.name}
                                                                            id={data.name}
                                                                            value={value}
                                                                            disabled
                                                                          />
                                                                        </div>
                                                                      )}
                                                                      {data.type === "date" && (
                                                                        <div className="mb-3">
                                                                          <label forhtml={data.name} className="text-muted form-label">
                                                                            {data.label}
                                                                          </label>

                                                                          <input
                                                                            type={data.type}
                                                                            className="form-control"
                                                                            name={data.name}
                                                                            id={data.name}
                                                                            // onChange={(
                                                                            //     e
                                                                            // ) =>
                                                                            //     handleNetSuiteChange(
                                                                            //         data,
                                                                            //         e,
                                                                            //         recordName,
                                                                            //         col
                                                                            //     )
                                                                            // }
                                                                            value={formateDate(value)}
                                                                            disabled
                                                                          />
                                                                        </div>
                                                                      )}
                                                                      {data.type === "number" && (
                                                                        <div className="mb-3">
                                                                          <label forhtml={data.name} className="text-muted form-label">
                                                                            {data.label}
                                                                          </label>

                                                                          <input
                                                                            type={data.type}
                                                                            className="form-control text-dark"
                                                                            name={data.name}
                                                                            placeholder={data.placeholder}
                                                                            // onChange={(
                                                                            //     e
                                                                            // ) =>
                                                                            //     handleNetSuiteChange(
                                                                            //         data,
                                                                            //         e,
                                                                            //         recordName,
                                                                            //         col
                                                                            //     )
                                                                            // }
                                                                            id="unitvalmap"
                                                                            value={value}
                                                                            disabled
                                                                          />
                                                                        </div>
                                                                      )}
                                                                      {data.type === "dropdown" && (
                                                                        <div className="mb-3">
                                                                          <label forhtml={data.name} className="text-muted form-label">
                                                                            {data.label}
                                                                          </label>
                                                                          <div className="select-col position-relative">
                                                                            <select
                                                                              name={data.name}
                                                                              className="form-select"
                                                                              id={data.name}
                                                                              // onChange={(
                                                                              //     e
                                                                              // ) =>
                                                                              //     handleNetSuiteChange(
                                                                              //         data,
                                                                              //         e,
                                                                              //         recordName,
                                                                              //         col
                                                                              //     )
                                                                              // }
                                                                              disabled
                                                                            >
                                                                              {data.options.map((value, index) => {
                                                                                return (
                                                                                  <option key={index} value={value}>
                                                                                    {value}
                                                                                  </option>
                                                                                );
                                                                              })}
                                                                            </select>
                                                                          </div>
                                                                        </div>
                                                                      )}

                                                                      {data.type === "textarea" && (
                                                                        <div className="mb-3">
                                                                          <label forhtml="memo" className="text-muted form-label">
                                                                            SCOPE OF WORK
                                                                            <span
                                                                              // style='max-width: 400px;'
                                                                              className="text-muted p-1"
                                                                              data-bs-toggle="tooltip"
                                                                              data-bs-placement="top"
                                                                              data-bs-title={data.dataTooltip}
                                                                            >
                                                                              <i className="fas fa-question-circle text-dark"></i>
                                                                            </span>
                                                                          </label>
                                                                          <textarea
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="memo"
                                                                            name="memo"
                                                                            disabled
                                                                            // onChange={(
                                                                            //     e
                                                                            // ) =>
                                                                            //     handleNetSuiteChange(
                                                                            //         data,
                                                                            //         e,
                                                                            //         recordName,
                                                                            //         col
                                                                            //     )
                                                                            // }
                                                                          >
                                                                            {value}
                                                                          </textarea>
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  );
                                                                })}
                                                              </>
                                                            );
                                                          })}{" "}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
